import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Button, } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ChooseItem from '../ChooseItem/ChooseItem';
import EditProperties from '../EditProperties/EditProperties'
import ConfirmChanges from '../ConfirmChanges/ConfirmChanges'
import { useStore} from 'react-redux'
import axiosCerebrum from '../../../axios-cerebrum'
import { mapObjectName, mapSearchObjectName, toTitleCase} from '../../../utilities'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import LinkToCollection from '../LinkToCollection/LinkToCollection'
import ChooseCart from '../ChooseCart/ChooseCart';
import useAlert from '../../../hooks/useAlert';
import AddProperties from '../AddProperties/AddProperties';

const styles = theme => ({
  root: {
    width: '100%',
  },
  labelText: {
    color:theme.palette.primaryText.light
  },
  selectedLabelText:{
    color:`${theme.palette.primary.main} !important`,
    fontWeight:'400 !important'
  },
  stepIcon:{
    cursor:'pointer'
  },
  buttons:{
    position:'fixed',
    justifyContent:'flex-end',
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    //width:'100%',
    display:'flex',
    alignItems:'center',
    borderTop:`1px solid ${theme.palette.listItemDivider.main}`
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepper:{
    backgroundColor:theme.palette.background.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
})

function Body(props) {

  const {
    classes,
    state,
    dispatch,
    history,
  } = props;

  const [submittingJob, setSubmittingJob] = useState(false)
  const store = useStore();
  const editMode = store.getState().bulkEdit.editMode

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    window.scrollTo(0,0);
  },[state.activeStep,state.loadingScreen])


  let steps = []

  let bodyElements = []
  if(editMode!=='cart_bulk_edit'){
    bodyElements.push(
      <ChooseItem
        state={state}
        dispatch={dispatch}
      />
    )
    if(state.itemList && state.itemList.length>0){
      steps.push(`Choose ${toTitleCase(mapObjectName(state.itemList[0].labels).replace(/_/g,' '))}(s)`)
    }else{
      steps.push('Choose Item(s)')
    }
  }else{
    bodyElements.push(
      <ChooseCart
        state={state}
        dispatch={dispatch}
      />
    )
    steps.push('Select items to update')
  }


  if(editMode!=='same_name_description_only'){
    bodyElements.push(
      <AddProperties
        properties={state.properties}
        setProperties={properties=>dispatch({type:'set_properties',properties})}
        state={state}
        dispatch={dispatch}
      />
    )
    steps.push('Confirm bulk update scope')
  }

  bodyElements.push(
    <div>
      <EditProperties
        state={state}
        dispatch={dispatch}
        editMode={editMode}
      />
      {
        editMode!=='same_name_description_only' &&
        <LinkToCollection 
          state={state}
          dispatch={dispatch}
        />
      }
    </div>
  )
  
  steps.push(editMode==='same_name_description_only'?'Add Description':'Add change detail(s)')

  bodyElements.push(
    <ConfirmChanges
      state={state}
      dispatch={dispatch}
    />
  )
  steps.push('Confirm change(s)')

  let bodyContent = bodyElements[state.activeStep];

  if(state.loadingScreen){
    bodyContent = (
      <LoadingScreen
        state={state}
        dispatch={dispatch}
        history={history}
        editMode={editMode}
      />
    )
  }


  const onConfirm = () => {
    if(!state.itemList || state.itemList.length===0){
      setTimeout(onConfirm,1000)
      return;
    }
    setSubmittingJob(true)
    let objectLabel = mapSearchObjectName(state.itemList[0].labels.toUpperCase());
    axiosCerebrum
      .post(
        '/api/platformbatches/112',
        {
          "object_type": objectLabel,
          "object_ids": state.itemList.filter(el=>el.selected===true).map(el=>el.id),
          "updates": state.changedFields.map(el=>({field:el.field,operation:el.operation,values:el.field==='DESCRIPTION'?[el.values[0].replace(/^(<br>|\s)*/,'').replace(/(<br>|\s)*$/,'')]:el.values}))
        }
      )
      .then(response=>{
        setSubmittingJob(false)
        dispatch({type:'set_loading_screen',loadingScreen:true})
        dispatch({type:'set_batch_status',batchLoading:true})
        history.push(`/my_tasks?tabName=JOBS&jobName=BULK UPDATE`)
      })
      .catch(error=>{
        setSubmittingJob(false)
        console.log(error);
        sendAlert({message:"Error occurred submitting request, please try again",type:'error'})
      })
  }

  const onNextClick = () => {
    if(state.activeStep!==steps.length-1){
      dispatch({type:'set_active_step',activeStep:state.activeStep+1})
      return;
    }
    onConfirm();
  }

  const onBackClick = () => {
    dispatch({type:'set_active_step',activeStep:state.activeStep-1})
  }

  const allFieldsFilled = () => {
    let filled = true;
    state.changedFields.forEach(el=>{
      if(editMode==='same_name_description_only' && el.field!=='DESCRIPTION')return;
      if(['REPLACE','ADD_TO_EXISTING'].includes(el.operation) && el.values.length===0){
        if(el.field==='VERIFIED_BY' && state.changedFields.find(el=>el.field==='NOT_VERIFIED_BY') && state.changedFields.find(el=>el.field==='NOT_VERIFIED_BY').values.length>0){
          return
        }
        if(el.field==='NOT_VERIFIED_BY' && state.changedFields.find(el=>el.field==='VERIFIED_BY') && state.changedFields.find(el=>el.field==='VERIFIED_BY').values.length>0){
          return
        }
        filled = false;
      }
    })
    return filled;
  }

  const stepClickable = (step) => {
    if(step===0)return true;
    if(step===1)return ((state.itemList && state.itemList.some(el=>el.selected)) || state.selectedItemType);
    if(step===2)return stepClickable(1) || editMode==='same_name_description_only';
    if(step===3){
      return stepClickable(1) && stepClickable(2) && state.changedFields.length>0 && allFieldsFilled()
    }
  }

  const onCancelClick = () => {
    if(!store.getState().bulkEdit.redirectUrl){
      history.push('/home')
    };
    history.push(store.getState().bulkEdit.redirectUrl)
    // reduxDispatch({type:action.CLEAR_BULK_EDIT_PARAM});
  }

  const onStepLabelClick = step => {
    if(stepClickable(step))dispatch({type:'set_active_step',activeStep:step})
  }
  
  return (
    <div className={classes.root}>
      {
        !state.loadingScreen &&
        <Stepper className={classes.stepper} activeStep={state.activeStep} alternativeLabel>
          {steps.map((label,index) => (
            <Step  className={classes.step}  key={label} completed={false}>
              <StepLabel 
                data-test-id={`step-label-${index}`}
                onClick={()=>onStepLabelClick(index)}
                classes={{
                  active:classes.selectedLabelText,
                  iconContainer:classes.stepIcon,
                  label:classes.labelText
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      }

      <div style={{marginBottom:110,marginTop:32}}>{bodyContent}</div>
      
      {
        !state.loadingScreen && 
        <div className={classes.buttons}>
          {
            state.activeStep!==0 && 
            <Button 
              color='primary' 
              style={{width:120,height:48}} 
              disabled={submittingJob} 
              onClick={onBackClick} 
              data-test-id="back-button"
            >
              BACK
            </Button>
          }
          <Button data-test-id="cancel-button" color='primary' style={{marginLeft:30,width:120,height:48}} onClick={onCancelClick} >
            Cancel
          </Button>
          <Button 
            variant='contained' 
            color='primary' 
            data-test-id="next-button"
            style={{marginLeft:30,width:120,height:48,marginRight:'10%'}} 
            disabled={(state.activeStep!==3 && !stepClickable(state.activeStep+1))||submittingJob} 
            onClick={onNextClick} 
          >
            {state.activeStep===steps.length-1?'CONFIRM':'NEXT'}
          </Button>
        </div>
      }


    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Body));