import React, { useState, useReducer, useEffect, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/ContentAppProfile/Body/Body';
import { handleShareClick , setInitialState,getUserRoles, getDispFields, removeUrlQueryArg} from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkProfileEditable } from '../../permissionChecker';
import useAlert from '../../hooks/useAlert';
import AddToCartButton from '../../components/UI/Buttons/AddToCartButton';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});

const initialState = {
  tagModalOpen:false,
  tabState: 0,
  contentTabState:0,
  contentSort:{},
  contentSearch:{},
  noteTabState: 0,
  noteDetailMap: {},
  childSearch:'',
  childSort:'total_usage_srt desc',
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['TABLE','COLUMN','CONTENT','CONTENT_CHILD','DATASET','FILE'],
    downstreamObjects:['TABLE','COLUMN','CONTENT','CONTENT_CHILD','DATASET','FILE'],
    
    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_child_count':
      return {
        ...state,
        childCount:action.childCount
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_lineage_data':
      return {
        ...state,lineageData:action.lineageData
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataLoading: action.basicDataLoading,
        basicDataError:action.basicDataError
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_is_steward_or_owner':{
      return {
        ...state, isStewardOrOwner:action.isStewardOrOwner
      }
    }
    case 'set_child_data':
      return {
        ...state,
        childListData:action.childListData,
        childListLoading:action.childListLoading,
        childListError:action.childListError
      }
    case 'set_show_deleted_child':
      return {
        ...state,
        showDeletedChild:action.showDeletedChild
      }
    case 'set_show_reference_child':
      return {
        ...state,
        showReferenceChild:action.showReferenceChild
      }
    case 'set_child_sort':
      return {
        ...state,
        childSort:action.childSort
      }
    case 'set_child_search':
      return {
        ...state,
        childSearch:action.childSearch
      }
    default:
      console.log(action.type)
      throw new Error("Reducer action not supported.", action);
  }
}



const ContentAppProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const roles = getUserRoles(sessionData.user_role)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const getTabOptions = () => {
    return [state.childCount?'REPORTS':undefined,'DETAILS','KNOWLEDGE','ISSUES'].filter(el=>el)
  }
  
  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName')

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.reload_issue){
        issueFetchList()
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/contentapps/${match.params.id.toLowerCase()}`
      )
      .then(async cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        if(fullResponse.reference===true){
          !state.basicData && dispatch({type:'set_show_reference_child',showReferenceChild:true})
        }
        if(fullResponse.active_flag === false){
          !state.basicData && dispatch({type:'set_show_deleted_child',showDeletedChild:true})
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'*'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...fullResponse,...response.data.response.docs[0], source_data:sourceData}})
            dispatch({type:'set_child_count',childCount:response.data.response.docs[0]?response.data.response.docs[0].child_object_count_srt:0})
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }

  const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }


  useEffect(()=>{
    if(!tabName || isNaN(state.childCount))return;
    let tabs = getTabOptions()
    if(!tabs.includes(tabName.toUpperCase()))
    dispatch({type:'set_tab_state',tabState:tabs.indexOf(tabName.toUpperCase())})
    window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
  // eslint-disable-next-line
  },[tabName, state.childCount])

  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/contentapps/${match.params.id.toLowerCase()}/related`,
        {params: {
          relationship:'IMPACTED_BY,IMPACTED_BY_SRC',
          per_page:200,
          sort:'END_DESC'
        }} 
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select',{query:'*',limit:response.data.items.length,filter:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`})
          .then(solrRes=>{
            dispatch({
              type: 'set_issue_data',
              issueData: {...response.data,items:solrRes.data.response.docs},
            })
          })
          .catch(error=>{
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }


  useEffect(()=>{
    if(!state.basicData){
      fetchList();
      issueFetchList();
    }
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  const checkIsStewardOrOwner = () => {
    axiosCerebrum
      .get(`/api/users/${sessionData.id}/related`,{params:{
        object_id:match.params.id.toLowerCase(),
        relationship:'OWNER_OF,STEWARD_OF'
      }}) 
      .then(response=>{
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:response.data.total>0})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:false})
      })
  }

  useEffect(()=>{
    checkIsStewardOrOwner()
   // eslint-disable-next-line
  },[state.insightsData])


  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])


  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({message:"Stopped following this content app",type:'info'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:"Error occurred unfollowing this content app",type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({message:"Following this content app",type:'info'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:"Error occurred following this content app",type:'error'})
      })
    }
  }
  let buttons = [];
    
  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  buttons.push(
    <DropdownButton
      iconLabel='dot_menu'
      tooltip="More actions"
      iconOnly={true}
      iconColour={theme.palette.primaryText.light}
      title={['OPEN IN APPLICATIONS']}
      optionList={[
        roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/impact_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_downstream',text:'Assess Downstream Impact',group:'OPEN IN APPLICATIONS'},
        roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/dependency_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_upstream',text:'Assess Upstream Dependencies',group:'OPEN IN APPLICATIONS'}
      ]}
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )

  buttons.push(
    <AddToCartButton
      object={state.basicData}
    />
  )
  
  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick:() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true }),iconLabel:'issue',text:'Add Issue'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        {onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'},
        checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner})?{onClick:()=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:true}),iconLabel:'info',text:'Add Property'}:undefined,
      ]}
    />
  )

  if (state.basicData.external_url) {
    buttons.push(
      <ProfileButton
        onClick={() => {
          let win = window.open(state.basicData.external_url, '_blank');
          win.focus();
        }}
        iconLabel='open'
        text="OPEN"
      />
    )
  }

  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )


  let bannerdisplayText,bannerCause,bannerVisibility;

  let openIssueCount = state.issueData && state.issueData.items.filter(i=>!i.issue_status_txt || !i.issue_status_txt.includes('CLOSED')).length
  if (state.issueData && openIssueCount > 0) {
    bannerdisplayText = `This content app has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
  }

  let data = state.basicData;
  if(data && data.active_flag === false){
    bannerdisplayText = 'This content app has been deleted';
    bannerCause = 'active';
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this content app has been deactivated in K. Information on this content app is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }
  
  let title = state.basicData.name || state.basicData.signature;
  let tabOptions = getTabOptions()


  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','note','additional_property','issue']}
        history={history}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            tabOptions={tabOptions}
            title={title}
            shouldLoadBreadCrumb
            label={state.basicData.object.name.toLowerCase()}
            buttons={buttons}
            data={state.basicData}
            history={history}
            state={state}
            dispatch={dispatch}
            bannerdisplayText={bannerdisplayText}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
            shouldLoadLinkedInstance
            onClickAddTag={()=>{
              setLinkModalOpen({
                linkableObjects:['COLLECTION_INSTANCE'],
                relations:['MEMBER_OF'],
                onLinkUpdated:()=>{
                  window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                }
              })
            }}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            basicData={state.basicData}
            // new props
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            sessionData={sessionData}
            setLinkModalOpen={setLinkModalOpen}
          />
        }
        sideBar={
          ['LINEAGE'].includes(tabOptions[state.tabState])?
          undefined:
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            editable
            fetchList={fetchList}
            cerebrumLabel={'contentapps'}
            data={state.basicData}
            mapping={['tags','sourceType','category','knowledge','verified','verifiedNot','trustscore','domain','channels','classification','owner','stewardedBy', 'createdBy']}
          />
        }
      />
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ContentAppProfile)));