import React, { useState, useReducer, useEffect, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/QueryProfile/Body/Body';
import { handleShareClick, setInitialState, removeUrlQueryArg, getUserRoles} from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkLineageLinkable, checkTermLinkable } from '../../permissionChecker';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import { getAlterName } from '../../components/UI/ProfileHeader/utils';
import useAlert from '../../hooks/useAlert';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});

const initialState = {
  tagModalOpen:false,
  tabState: 0,
  // tagsData: {items:[]},
  // basic table data
  basicData: null,
  linkedData: {},
  linkedTabState: 0,
  insightsData: null,
  insightsLoading: null,
  insightsError: null,
  // usage
  usageData: null,
  usageError: null,
  usageLoading: null,
  usagePeriod:3,
  usageType:'direct',

  //panel uage data
  usagePanelData: null,
  usagePanelError: null,
  usagePanelLoading: null,
  //
  selectedDate: null,
  lineageData:{
    tabState:0,
    view:'map',
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],
    downstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],
    
    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
  joinsView:'list',
  noteTabState:0,
  noteDetailMap:{},
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_lineage_data':
      return {
        ...state,
        lineageData:action.lineageData
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataLoading:action.basicDataLoading,
        basicDataError:action.basicDataError
      }
    case 'set_editing_description':
      return {
        ...state,
        editingDescription: action.editingDescription
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_linked_data':
      return {
        ...state,
        linkedData: action.linkedData,
      }
    case 'set_linked_tab_state':
      return {
        ...state, linkedTabState: action.linkedTabState
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    // case "set_related_data":
    //   return {
    //     ...state,
    //     relatedData: action.relatedData,
    //     relatedError: action.relatedError,
    //     relatedLoading: action.relatedLoading
    //   }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    case 'set_dq_insights_data':
      return {
        ...state,
        dqInsightsData:action.dqInsightsData
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_joins_data':
      return {
        ...state,
        joinsData:action.joinsData
      }
    case 'set_joins_view':
      return {
        ...state,
        joinsView:action.joinsView
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const ContentProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;


  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');
  let startState = setInitialState(pageCache,initialState);
  const roles = getUserRoles(sessionData.user_role);

  const getTabOptions = data => {
    return ['DETAILS','KNOWLEDGE', roles.every(el=>el==='90')||data.usage_txt==='UNKNOWN'?undefined:'USAGE', 'JOINS', 'LINEAGE','ISSUES','QUALITY','RELATED'].filter(el=>el!==undefined);
  }

  const [state, dispatch] = useReducer(reducer, startState);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let isLineageLinkable = checkLineageLinkable({sessionData})

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])


  useEffect(()=>{
    if(tabName && state.basicData){
      let tabOptions = getTabOptions(state.basicData);
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    // eslint-disable-next-line
  },[state.basicData])

  const fetchList = () => {
    dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataLoading:true })
    axiosCerebrum
      .get(`/api/queries/${match.params.id.toLowerCase()}`)
      .then(async cerebrumResponse=>{
        if(!cerebrumResponse.data)return;
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${cerebrumResponse.data.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: cerebrumResponse.data.keyword + ' / QUERY-' + ( cerebrumResponse.data.display_id || cerebrumResponse.data.name), subTitle:'Query profile',object:cerebrumResponse.data,type:'profile'})
        axiosSolr
          .get(
            `/solr/search/select`,
            {params:{
              q:"*",
              fq:`id:${match.params.id.toLowerCase()}`,
              fl:'tables:tables_txts,name:name_txt,*'
            }}
          )
          .then(solrRes=>{
            let queryFormattedName = solrRes.data.response.docs[0]?.name;
            if(!queryFormattedName)queryFormattedName = 'QUERY-'+(cerebrumResponse.data.display_id||cerebrumResponse.data.name)
            let name = cerebrumResponse.data.keyword + ' / ' + queryFormattedName
            dispatch({ 
              type: 'set_basic_data', 
              basicData: {
                ...(state.basicData||{}),
                ...(solrRes.data.response.docs[0]||{}),
                ...cerebrumResponse.data,
                disp_name:name,
                source_data:sourceData
              }})
          })
          .catch(error=>{
            console.log(error);
            dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataError:true })
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataError:true })
      })
  }

    const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }


  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.reload_issue){
        issueFetchList()
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
      if(!isLineageLinkable)return;
      if(msg.data.open_profile_link_modal ){
        setLinkModalOpen({
          relations:['SOURCE_OF','SOURCE_FROM'],
          linkableObjects:lineageObjectsMap.QUERY
        })
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[isLineageLinkable])


  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/queries/${match.params.id.toLowerCase()}/related`,
        {params: {
          relationship:'IMPACTED_BY,IMPACTED_BY_SRC',
          per_page:200,
          sort:'END_DESC'
        }} 
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select',{query:'*',limit:response.data.items.length,filter:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`})
          .then(solrRes=>{
            dispatch({
              type: 'set_issue_data',
              issueData: {...response.data,items:solrRes.data.response.docs},
            })
          })
          .catch(error=>{
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }

  useEffect(()=>{
    if(!state.basicData)fetchList();
    if(!state.issueData)issueFetchList();
    if(state.followData===undefined){
      followDataFetch()
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  // useEffect(() => {
  //   dispatch({
  //     type: 'set_related_data',
  //     relatedData:relatedData || state.relatedData, relatedError, relatedLoading
  //   })
  // // eslint-disable-next-line
  // }, [relatedLoading, relatedError, relatedData])


  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({message:'Stopped following this query',type:'info'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:'Error occurred unfollowing this query',type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({message:'Following this query',type:'info'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:'Error occurred following this query',type:'error'})
      })
    }
  }


  let buttons = [];


  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  // buttons.push(
  //   <DropdownButton
  //     iconLabel='dot_menu'
  //     tooltip="More actions"
  //     iconOnly={true}
  //     iconColour={theme.palette.primaryText.light}
  //     title={['OPEN IN APPLICATIONS']}
  //     optionList={[
  //       {onClick:() => history.push(`/lineage_explorer?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'lineage_downstream',text:'Lineage Explorer',group:'OPEN IN APPLICATIONS'},
  //     ]}
  //   />
  // )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )


  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick:() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true }),iconLabel:'issue',text:'Add Issue'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        checkTermLinkable({sessionData})?{onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'}:undefined,
      ]}
    />
  )


  if (state.basicData.external_url) {
    buttons.push(
      <ProfileButton
        onClick={() => {
          let win = window.open(state.basicData.external_url, '_blank');
          win.focus();
        }}
        iconLabel='open'
        text='OPEN'
      />
    )
  }

  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )

  let bannerdisplayText,bannerCause,bannerVisibility;

  let openIssueCount = state.issueData && state.issueData.items.filter(i=>!i.issue_status_txt || !i.issue_status_txt.includes('CLOSED')).length
  if (state.issueData && openIssueCount > 0) {
    bannerdisplayText =`This query has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
    bannerVisibility = 'visible';
  }

  let data = state.basicData;

  let isShell = state.basicData.reference===true;

  if (data && isShell){
    let sourceName = data.source_data?data.source_data.name:'Unkown Source'
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This Query is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length===1){
        bannerdisplayText=`This Query is referenced in ${data.reference_sources[0].reference_source.name}. ${sourceName} is not connected to K. Limited metadata available.`
      }else{
        bannerdisplayText=(
          <Typography>
            This Query is referenced in multiple Tools. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Query is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible';
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this query has been deactivated in K. Information on this query is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible';
  } 

  if (state.basicData && state.basicData.active_flag === false) {
    bannerdisplayText = 'This query has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  
  // let queryCode = 'QUERY-' + ( state.basicData.display_id || state.basicData.name);
  // let title = state.basicData.keyword + ' / ' + queryCode
  // if(state.basicData.name && state.basicData.name!==state.basicData.signature && state.basicData.name.trim()!=='' && state.basicData.name!==state.basicData.id)title = state.basicData.name


  let {title, description} = getAlterName(state.basicData, roles, state.basicData.disp_name)

  let tabOptions = getTabOptions(state.basicData);

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','note','issue']}
        history={history}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            tabOptions={tabOptions}
            type='content'
            title={title}
            description={description || state.basicData.parent?.location}
            // copiedTitle={queryCode}
            // description={}
            shouldLoadBreadCrumb
            label={state.basicData.object.name.toLowerCase()}
            buttons={buttons}
            data={state.basicData}
            history={history}
            state={state}
            dispatch={dispatch}
            shouldLoadLinkedInstance
            bannerdisplayText={bannerdisplayText}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
            onClickAddTag={()=>{
              setLinkModalOpen({
                linkableObjects:['COLLECTION_INSTANCE'],
                relations:['MEMBER_OF'],
                onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
              })
            }}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={155}
            maxWidth={155}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            tabState={state.tabState}
            basicData={state.basicData}
            cerebrumLabel={'queries'}
            // new props
            isLineageLinkable={isLineageLinkable}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            loading={state.basicDataLoading}
            error={state.basicDataError}
            sessionData={sessionData}
            nodeKey={state.basicData.nodeKey}
          />
        }
        hideSideBar={['LINEAGE','JOINS','RELATED','QUALITY'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'queries'}
            data={state.basicData}
            mapping={['tags','sourceType','domain','verified','verifiedNot','trustscore','knowledge','classification','channels','owner','stewardedBy', 'topUsers', 'topTeams', 'lastRun','runs']}
          />
        }
      />
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
    permissions: state.auth.permissions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ContentProfile)));