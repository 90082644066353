import React, { useEffect, useRef } from 'react';
import { withTheme,withStyles, Typography, Button } from '@material-ui/core';
import { getIconComponent, formatNumber, collectionIds, onClickResultItem} from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import SearchBar from '../../BasicSearch/SearchBar/SearchBar'
import axiosCerebrum from '../../../axios-cerebrum'
import FollowButton from '../../UI/Buttons/FollowButton'
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    width:'100%',
    display: 'flex',
    flexDirection: 'column',
  },
  metricsTitle:{
    color:theme.palette.primaryText.main,
    marginRight:12,
    fontSize:13.75,
    flexGrow:1,
    wordBreak:'break-word',
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  metricsValue:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginLeft:8,
  },
  metricsBox:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    overflow:'hidden',
    padding:16,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  clickableBox:{
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  valueBackground:{
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.chip.main,
    borderRadius:12,
    padding:'4px 8px',
    display:'flex',
    alignItems:'center',
    flexShrink:0,
    flexGrow:0,
  },
})


function RightSideBar(props) {
  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const onSearch = ({searchStr}) => {
    history.push(`/basic_search?query=${searchStr}`)
  }

  const handleFollowClick = (el) => {
    if(el.following){
      axiosCerebrum
        .delete(`/api/me/follows/${el.id.toLowerCase()}`)
        .then(()=>{
          dispatch({
            type:'set_collection_recommendations',
            collectionRecommendations:{
              ...state.collectionRecommendations,
              [el.parent.id]:{
                ...state.collectionRecommendations[el.parent.id],
                items:state.collectionRecommendations[el.parent.id].items.map(i=>{
                  if(i.id!==el.id)return i;
                  return {...i,following:false}
                })
              }
            }
          })
        })
        .catch(error=>{
          console.log(error);
          sendAlert({ message: 'Error occurred unfollowing this item', type: 'error' })
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${el.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        dispatch({
          type:'set_collection_recommendations',
          collectionRecommendations:{
            ...state.collectionRecommendations,
            [el.parent.id]:{
              ...state.collectionRecommendations[el.parent.id],
              items:state.collectionRecommendations[el.parent.id].items.map(i=>{
                if(i.id!==el.id)return i;
                return {...i,following:true}
              })
            }
          }
        })
      })
      .catch(error=>{
        console.log(error);
        sendAlert({ message: 'Error occurred following this item', type: 'error' })
      })
    }
  }
 
  return (
    <div className={classes.root}>
      <div style={{margin:'0px 1px 48px'}} data-test-id="home-page-search-bar">
        <SearchBar
          state={state}
          dispatch={dispatch}
          onSearch={onSearch}
          isPlugin={true}
        />
      </div>

      { 
        state.sourceCountData &&
        <div>
          <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:20}}>Ecosystem</Typography>
          {
            (state.sourceCountData.slice(0,5)).map(el=>(
              <div data-test-classname="ecosystem-list-item">
                <ContextMenuTrigger id={el.source_id+'_home_source'}>
                  <div className={classes.metricsBox+' '+classes.clickableBox} onClick={el.onClick}>
                    <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                      {
                        getIconComponent({label:el.source_type,size:24,colour:theme.palette.primary.main}) || getIconComponent({label:'SOURCE',size:24,colour:theme.palette.primary.main})
                      }
                    </div>
                    <KTooltip title={el.name}>
                      <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>{el.name}</Typography>
                    </KTooltip>
                    <div className={classes.valueBackground}>
                      {getIconComponent({label:el.maxCount.val,size:16,colour:theme.palette.primaryText.main})}
                      <span className={classes.metricsValue } > {formatNumber(el.maxCount.count)}</span>
                    </div>
                    
                  </div>
                </ContextMenuTrigger>

                <ContextMenu id={el.source_id+'_home_source'}>
                  <CustomMenu
                    itemUrl={el.url}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </div>
            ))
          }
          <Button data-test-id="ecosystem-see-more-button" color='primary' style={{marginTop:4,paddingLeft:16,paddingRight:16}} onClick={()=>history.push('/my_ecosystem?tabName=SOURCES')}>SEE MORE</Button>
        </div>
      }
      { 
        state.filterData && state.filterData.items.length>0 && 
        <div style={{marginTop:48}}>
          <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:20}}>Filters</Typography>
          {
            state.filterData.items.slice(0,3).map(el=>(
              <div data-test-classname="filter-list-item">
                <ContextMenuTrigger id={el.id+'_home_filter'}>
                  <div className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>history.push(`/basic_search?filterListId=${el.id}`)}>
                    <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                        {getIconComponent({label:'filter_list',size:24,colour:theme.palette.primary.main})}
                    </div>
                    <KTooltip title={el.name}>
                      <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>{el.name}</Typography>
                    </KTooltip>
                  </div>
                </ContextMenuTrigger>

                <ContextMenu id={el.id+'_home_filter'}>
                  <CustomMenu
                    itemUrl={`/basic_search?filterListId=${el.id}`}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </div>
            ))
          }
          {
            state.filterData.total >  3 && 
            <Button data-test-id="filter-see-more-button" color='primary' style={{marginTop:4,paddingLeft:16,paddingRight:16}} onClick={()=>history.push('/filters')}>SEE MORE</Button>
          }
        </div>
      }
      { 
        state.listData && state.listData.items.length>0 && 
        <div style={{marginTop:48}}>
          <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:20}}>Lists</Typography>
          {
            state.listData.items.slice(0,3).map(el=>(
              <div data-test-classname="list-list-item">
                <ContextMenuTrigger id={el.id+'_home_list'}>
                  <div className={classes.metricsBox+' '+classes.clickableBox} onClick={el.onClick}>
                    <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                        {getIconComponent({label:'list',size:24,colour:theme.palette.primary.main})}
                    </div>
                    <KTooltip title={el.name}>
                      <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>{el.name}</Typography>
                    </KTooltip>
                  </div>
                </ContextMenuTrigger>

                <ContextMenu id={el.id+'_home_list'}>
                  <CustomMenu
                    itemUrl={el.url}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </div>
            ))
          }
          {
            state.listData.total >  3 && 
            <Button data-test-id="list-see-more-button" color='primary' style={{marginTop:4,paddingLeft:16,paddingRight:16}} onClick={()=>history.push('/my_data?tabName=LIST')}>SEE MORE</Button>
          }
        </div>
      }

      {
        state.collectionRecommendations &&
        <div style={{marginTop:48}}>
          <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:20}}>Discover your Ecosystem</Typography>
          { 
            state.collectionRecommendations[collectionIds.domain] &&
            <div style={{marginTop:16}}>
              <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:16}}>Discover Domains</Typography>
              {
                state.collectionRecommendations[collectionIds.domain].items.slice(0,3).map(el=>(
                  <div data-test-classname="your-ecosystem-list-item">
                    <ContextMenuTrigger id={el.id+'_home_domain'}>
                      <div className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>{onClickResultItem({label:'collection_instance',id:el.id,item:el,history})}}>
                        <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                            {getIconComponent({label:'domain',size:24,colour:theme.palette.primary.main})}
                        </div>
                        <KTooltip title={el.name}>
                          <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>{el.name}</Typography>
                        </KTooltip>
                        <FollowButton
                          onClick={(event)=>{event.stopPropagation();handleFollowClick(el)}}
                          following={el.following}
                          object={el}
                          disableCount={true}
                          disableMargin={true}
                          size='small'
                        />
                      </div>
                    </ContextMenuTrigger>

                    <ContextMenu id={el.id+'_home_domain'}>
                      <CustomMenu
                        item={el}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenu>
                  </div>
                ))
              }
            </div>
          }

          { 
            state.collectionRecommendations[collectionIds.verifiedUseCase] &&
            <div style={{marginTop:16}}>
              <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:16}}>Discover Use Cases</Typography>
              {
                state.collectionRecommendations[collectionIds.verifiedUseCase].items.slice(0,3).map(el=>(
                  <div data-test-classname="use-case-list-item">
                    <ContextMenuTrigger id={el.id+'_home_verified'}>
                      <div className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>{onClickResultItem({label:'collection_instance',id:el.id,item:el,history})}}>
                        <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                            {getIconComponent({label:'verified',size:24,colour:theme.palette.primary.main})}
                        </div>
                        <KTooltip title={el.name}>
                          <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>{el.name}</Typography>
                        </KTooltip>
                        <FollowButton
                          onClick={()=>handleFollowClick(el)}
                          following={el.following}
                          object={el}
                          disableCount={true}
                          disableMargin={true}
                          size='small'
                        />
                      </div>
                    </ContextMenuTrigger>

                    <ContextMenu id={el.id+'_home_verified'}>
                      <CustomMenu
                        item={el}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenu>
                  </div>
                ))
              }
            </div>
          }

          {
            (state.collectionRecommendations[collectionIds.domain] || state.collectionRecommendations[collectionIds.verifiedUseCase])?
            <Button  data-test-id="discover-collection-button" color='primary' style={{marginTop:4,paddingLeft:16,paddingRight:16}} onClick={()=>{history.push('/my_ecosystem?tabName=DISCOVER')}}>SEE MORE</Button>
            :
            <div>
              <div  data-test-id="discover-report-button" className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=REPORTS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'content',size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>Discover Reports</Typography>
              </div>
              <div  data-test-id="discover-term-button" className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=TERMS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'term',size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>Discover Terms</Typography>
              </div>
              <div  data-test-id="discover-collection-button" className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=COLLECTIONS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'collection',size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>Discover Collections</Typography>
              </div>
              <div  data-test-id="discover-list-button" className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=LISTS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'list',size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>Discover Lists</Typography>
              </div>
              <div  data-test-id="discover-issue-button" className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=ISSUES')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'issue',size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>Discover Issues</Typography>
              </div>
              <div  data-test-id="discover-tag-button" className={classes.metricsBox+' '+classes.clickableBox} onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=TAGS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'tag',size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.metricsTitle} style={{textDecoration:'underline'}}>Discover Tags</Typography>
              </div>
            </div>
          }


        </div>
      }





    </div>
  )
}

export default withTheme()(withStyles(styles)(RightSideBar));