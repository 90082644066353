import * as actionTypes from '../actions/actionTypes';

const initialState = {
  url:undefined,
  params:undefined,
  redirectUrl:undefined,
  type:undefined,
};

const setBulkEditParam = (state, action) => {
  return {
    url:action.data.url,
    params:action.data.params,
    redirectUrl:action.data.redirectUrl,
    type:action.data.type,
    editMode:action.data.editMode,
    presetDescription:action.data.presetDescription
  }
}

const clearBulkEditParam = (state, action) => { 
  return {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BULK_EDIT_PARAM: return setBulkEditParam(state, action);
    case actionTypes.CLEAR_BULK_EDIT_PARAM: return clearBulkEditParam(state, action)
    default: return state;
  }
  
};

export default reducer;