import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button, IconButton  } from '@material-ui/core';
import { getDispFields, getIconComponent, onClickResultItem, toTitleCase,  } from '../../../../utilities';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getLineageRelation } from '../../../UI/Lineage/utils';
import axiosSolr from '../../../../axios-solr';
import ResultItem2 from '../../../UI/SearchResults/ResultItem2';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';

const styles = theme => ({
  
  expansionTitle:{
    fontSize:16,
    paddingLeft:16,
    color:theme.palette.header.main
  },
  expansionPanel:{
    marginBottom:24,
    paddingBottom:0,
    borderRadius:`6px !important`,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    '&::before': {
      background: 'none'
    },
  },
  expansionSummary:{
    padding:0,
    width:'100%',
    // minHeight: 64,
  },
  expanded:{
    margin:0
  },
  summaryRoot:{
    // background:theme.palette.background.main,
    borderBottom: '0',
    // height: 64,
    padding: 0,
    borderRadius:6,
    margin: 0,
    '&$expanded': {
      // height:64,
      padding: 0,
    },
  },
  expansionPanelDetails:{
    flexDirection:'column',
    background:theme.palette.background.main,
    paddingLeft:16,
    paddingRight:16,
    paddingTop:0,
    paddingBottom:0,
    borderRadius:12
  },
  sectionHeader:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.main,
    marginTop:24,
    marginBottom:16
  }
})


function ListLoader(props) {
  const {
    theme,
    classes,
    object,
    direction,
    data,
    setData,
    refData,
    setRefData,
    onItemClick
  } = props;

  const [expanded, setExpanded] = React.useState(true)

  const dedupeList = list => {
    let dedupedItems = [];
    list.forEach(el=>{
      if(!dedupedItems.find(item=>item.id===el.id)){
        dedupedItems.push(el)
      }
    })
    dedupedItems = dedupedItems.filter(item=>!data.items || !data.items.map(item=>item.id).includes(item.id))
    return {list:dedupedItems, dupeItemCount:list.length-dedupedItems.length};
  }

  const loadList = ({page=1}) => {
    setData({
      loading:true,
      ...(page===1?{}:{...data}),
      error:false,
    })
    axiosCerebrum
      .get(
        `/api/${object.object_type_txt.toLowerCase()}s/${object.id}/related`,{
          params:{
            relationship:getLineageRelation(object.object_type_txt,direction).join(','),
            object_name:'DATABASE,TOOL',
            per_page:5,
            object_reference: false,
            active_flag:true,
            object_active_flag:true,
            page,
            sort:'END_DESC'
          }
        }
      )
      .then(response=>{
        if(response.data.total===0){
          setData(response.data)
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:'*',
                fq:`id:(${response.data.items.map(item=>item.id).join(' OR ')})`,
                rows:response.data.total,
              }
            }
          )
          .then(solrRes => {
            response.data.items = response.data.items.map(item=>{
              let solrItem = solrRes.data.response.docs.find(solrItem=>solrItem.id===item.id) || {}
              return {
                ...item,
                ...solrItem
              }
            })

            let dedupedData = dedupeList(response.data.items)
            
            setData({
              ...response.data,
              dupeItemCount:(data.dupeItemCount || 0) + (dedupedData.dupeItemCount || 0),
              items:[
                ...(page===1?[]:data.items),
                ...dedupedData.list
              ]
            })
          })
          .catch(error=>{
            setData({
              error:true
            })
            console.log(error)
          })
      })
      .catch(error=>{
        setData({
          error:true
        })
        console.log(error)
      })
  }

  const loadRefList = ({page=1}) => {
    setRefData({
      loading:true,
      ...(page===1?{}:{...refData}),
      error:false,
    })
    axiosCerebrum
      .get(
        `/api/${object.object_type_txt.toLowerCase()}s/${object.id}/related`,{
          params:{
            relationship:getLineageRelation(object.object_type_txt,direction).join(','),
            object_name:'DATABASE,TOOL',
            per_page:5,
            active_flag:true,
            object_active_flag:true,
            page,
            object_reference:true,
            sort:'END_DESC'
          }
        }
      )
      .then(response=>{
        if(response.data.total===0){
          setRefData(response.data)
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:'*',
                fq:`id:(${response.data.items.map(item=>item.id).join(' OR ')})`,
                rows:response.data.total,
              }
            }
          )
          .then(solrRes => {
            response.data.items = response.data.items.map(item=>{
              let solrItem = solrRes.data.response.docs.find(solrItem=>solrItem.id===item.id) || {}
              return {
                ...item,
                ...solrItem
              }
            })

            let dedupedData = dedupeList(response.data.items)

            setRefData({
              ...response.data,
              dupeItemCount:(refData.dupeItemCount || 0) + dedupedData.dupeItemCount,
              items:[
                ...(page===1?[]:refData.items),
                ...dedupedData.list
              ]
            })
          })
          .catch(error=>{
            setRefData({
              error:true
            })
            console.log(error)
          })
      })
      .catch(error=>{
        setRefData({
          error:true
        })
        console.log(error)
      })
  }

  const shouldLoadMore = () => {
    return data.page < data.pages && !data.loading 
  }

  const shouldLoadMoreRef = () => {
    return refData.page < refData.pages && !refData.loading
  }

  useEffect(()=>{
    loadList({page:1})
    loadRefList({page:1})
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(data.total===0 && refData.total===0)setExpanded(false)
  },[data, refData])

  let total = '';
  if(!isNaN(data.total)){
    total = data.total;
    total -= data.dupeItemCount || 0;
  }
  if(!isNaN(refData.total)){
    total += refData.total;
    total -= refData.dupeItemCount || 0;
  }

  return (
    <div style={{marginBottom:24}}>
      <ExpansionPanel expanded={expanded} onChange={()=>setExpanded(!expanded)} defaultExpanded={true} elevation={0} className={classes.expansionPanel } >
        <ExpansionPanelSummary 
          onClick={undefined}
          classes={{expanded: classes.expanded,root:classes.summaryRoot}}  
          expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  
          className={classes.expansionSummary}
        >
          <Typography className={classes.expansionTitle}>{total} {toTitleCase(direction)} Sources</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <Typography className={classes.sectionHeader}>
            ONBOARDED SOURCES
          </Typography>
          {
            data.items?.map(item=>(
              <ResultItem2
                item={item}
                key={item.id}
                dispBody={getDispFields(item,'dispBody')}
                dispSubtitle={getDispFields(item,'dispSubtitle')}
                dispTitle={getDispFields(item,'dispTitle')}
                label={item.object_type_txt}
                showIcon
                showUnderline
                tailObject={
                  <div>
                    <IconButton 
                      onClick={(event)=>{
                        onClickResultItem({id:item.id,label:item.object_type_txt,newWindow:true,item:item})
                        event.stopPropagation()
                      }}
                    >
                      {getIconComponent({label:"open",size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </div>
                }
                tailObjectHoverable
                onClick={() => {
                  onItemClick(item)
                }}
              />
            ))
          }
          {
            shouldLoadMore() && !data.loading && 
            <Button color='primary' style={{marginTop:16,width:"max-content"}} onClick={()=>{loadList({page:data.page+1})}}>
              SEE MORE
            </Button>
          }
          {
            data.items?.length===0 && 
            <Typography style={{fontSize:13,color:theme.palette.primaryText.light}}>No onboarded sources {direction}</Typography>
          }
          {
            data.loading && 
            <CircularProgress color='secondary'/>
          }
          {
            data.error && 
            <Typography style={{fontSize:13,color:theme.palette.primaryText.light}}>Error loading {direction} sources</Typography>
          }
          {
            refData.total>0 && 
            <>
              <Typography className={classes.sectionHeader}>
                REFERENCE SOURCES (NOT VISIBLE ON MAP. CLICK TO VIEW LINEAGE)
              </Typography>
              {
                refData.items?.map(item=>(
                  <ResultItem2
                    item={item}
                    key={item.id}
                    dispBody={getDispFields(item,'dispBody')}
                    dispSubtitle={getDispFields(item,'dispSubtitle')}
                    dispTitle={getDispFields(item,'dispTitle')}
                    label={item.object_type_txt}
                    showIcon
                    showUnderline
                    onClick={() => {
                      onClickResultItem({id:item.id,label:item.object_type_txt,newWindow:true,item:item,query:['tabName=LINEAGE']})
                    }}
                  />
                ))
              }
              {
                shouldLoadMoreRef() && !refData.loading && 
                <Button color='primary' style={{marginTop:16,width:"max-content"}} onClick={()=>{loadRefList({page:refData.page+1})}}>
                  SEE MORE
                </Button>
              }
              {
                refData.loading && 
                <CircularProgress color='secondary'/>
              }
              {
                refData.error && 
                <Typography style={{fontSize:13,color:theme.palette.primaryText.light}}>Error loading reference assets</Typography>
              }
            </>
          }
          <div style={{height:24}}></div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default withTheme()(withStyles(styles)(ListLoader));
