import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, InputBase, Paper, Typography, IconButton, Popper, Checkbox, ClickAwayListener } from '@material-ui/core';
import { getDispFields, getIconComponent, tabGroup} from '../../../../../../utilities';
import SimpleResultItem from '../../../../SearchResults/SimpleResultItem';
import { withRouter } from 'react-router-dom'; 
import UserChip from '../../../../Chips/UserChip';

const styles = theme => ({
  inputBase:{
    ...theme.components.inputBase,
    height:48,
    width:'100%'
  },
  typeFilter:{
    display:"flex",
    alignItems:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listContainer:{
    maxHeight:'80vh',
    overflow:'auto',
    ...theme.components.customScroll
  }
})

function NodeSearch(props) {
  const {
    classes,
    theme,
    childrenMap,
    currentGroups,
  } = props;

  const [search, setSearch] = useState('')
  const [list, setList] = useState([])
  const [anchor, setAnchor] = useState(null)
  const searchRef = useRef()

  const [filters, setFilters] = useState([])


  const getType = el => el.data.obj.object_type || el.data.obj.object_type_txt

  const getListBySearch = (value, types) => {

    let items = [];

    const checkIsDup = (el) => {
      return Boolean(items.find(i=>el.type===i.type && el.data.obj.id===i.data.obj.id))
    }

    currentGroups.forEach(g=>{
      if(g.data.label.toLowerCase().includes(value.toLowerCase()) && !checkIsDup(g) ){
        if(types.length===0 || types.includes(getType(g)))items.push(g)
      }
      childrenMap[g.id]?.forEach(c=>{
        if(c.data.label.toLowerCase().includes(value.toLowerCase()) && !checkIsDup(c) ){
          if(types.length===0 || types.includes(getType(c)))items.push(c)
        }
      })
    })
    setList(items)
  }

  const onChangeSearch = value => {
    setSearch(value.trim())
  }
  
  const getAvailableTypes = () => {
    let types = []
    currentGroups.forEach(g=>{
      if(!types.includes(getType(g))) types.push(getType(g))
      childrenMap[g.id]?.forEach(c=>{
        if(!types.includes(getType(c))) types.push(getType(c))
      })
    })
    let sortedTypes = [];
    tabGroup.forEach(g=>{
      g.tabs.forEach(t=>{
        if(types.includes(t)) sortedTypes.push(t)
      })
    })
    return sortedTypes
  }

  useEffect(()=>{
    clearTimeout(searchRef.current)
    if(search.trim()===''){
      setList([])
      return;
    }
    searchRef.current = setTimeout(()=>{
      getListBySearch(search, filters)
    },100)
  // eslint-disable-next-line
  },[search, filters])

  return (
    <>
      <Paper
        style={{marginLeft:16,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main,width:450}}
      >
        <InputBase
          value={search}
          onChange={e=>onChangeSearch(e.target.value)}
          className={classes.inputBase}
          placeholder='Search map'
          endAdornment={
            <IconButton 
              onClick={e=>{
                setAnchor(e.currentTarget)
              }}
              style={{width:32,height:32,marginRight:6}}
            >
              {getIconComponent({label:'filter',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          }
        />
        {
          filters.length>0 &&
          <div style={{marginTop:16,marginBottom:16,paddingLeft:16,display:'flex',flexWrap:'wrap'}}>
            {
              filters.map(f=>(
                <UserChip
                  key={f}
                  user={{name:f}}
                  iconLabel={f}
                  onRemove={()=>setFilters(filters.filter(el=>el!==f))}
                />
              ))
            }
          </div>
        }
        <div className={classes.listContainer} style={search.trim()===''?{}:{marginTop:16}}>
          {
            search.trim()!=='' && list.length===0 && 
            <Typography style={{padding:'0px 16px 16px'}}>No results found</Typography>
          }
          {
            list.length>0 && 
            <Typography style={{fontSize:12,marginBottom:8,marginLeft:16,letterSpacing:1.5}}>{list.length} RESULT(S)</Typography>
          }
          {
            list.map(el=>(
              <SimpleResultItem
                key={el.id}
                title={getDispFields(el.data.obj,'dispTitle')}
                label={el.data.obj.object_type || el.data.obj.object_type_txt}
                item={el.data.obj}
                subTitle={getDispFields(el.data.obj,'dispSubtitle')}
                rightText={el.data.obj.score}
                onClick={() => document.getElementById(el.id)?.click()}
                height={48}
              />
            ))
          }
        </div>
      </Paper>
      <Popper open={anchor} anchorEl={anchor} placement='right-start'>
        <ClickAwayListener onClickAway={()=>setAnchor()}>
          <Paper
            style={{marginLeft:16,marginTop:-8,padding:16,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main,width:300}}
          >
            <Typography style={{fontSize:12,marginBottom:8,letterSpacing:1.5}}>FILTER BY TYPE</Typography>
            {
              getAvailableTypes().map(t=>(
                <div 
                  className={classes.typeFilter}
                  onClick={()=>{
                    if(filters.includes(t)){
                      setFilters(filters.filter(f=>f!==t))
                    }else{
                      setFilters([...filters,t])
                    }
                  }}
                >
                  <Checkbox
                    color='primary'
                    checked={filters.includes(t)}
                  />
                  <Typography style={{fontSize:13.75}}>{t}</Typography>
                </div>
              ))
            }
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

NodeSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  disableFocus: PropTypes.bool,
  dispatch: PropTypes.func,
  childrenMap: PropTypes.object,
}

export default withTheme()(withStyles(styles)(withRouter(NodeSearch)));