import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import axiosCerebrum from '../../../../axios-cerebrum'
import 'url-search-params-polyfill';
import moment from 'moment';

const styles = theme => ({
  root: {
  },
})

function Preview(props) {

  const {
    classes,
    state,
    theme
  } = props;

  // const urlSearch = new URLSearchParams(window.location.search);
  // const changeId = urlSearch.get('changeId');

  const [template, setTemplate] = useState();
  const [templateLoading, setTemplateLoading] = useState(false);
  const [templateError, setTemplateError] = useState(false)

  let KLogo = `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg style="width:52px;height:52px;" width="101px" height="101px" viewBox="0 0 101 101" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Logo - KADA no badge v2</title>
        <g id="Logo---KADA-no-badge-v2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="k_only_box">
                <circle id="Oval" fill="#001861" cx="50.5" cy="50.5" r="50.5"></circle>
                <g id="Group" transform="translate(19.000000, 17.000000)" fill="#FFFFFF">
                    <polygon id="Fill-2" points="17 67 0 50.8193404 0 0 17 0"></polygon>
                    <polygon id="Fill-3" points="64 0 43.7962264 0 34 14.8333796 46.0490566 27"></polygon>
                    <polygon id="Fill-4" points="20 33.2335497 51.3779455 65 77 65 30.4639834 18"></polygon>
                </g>
            </g>
        </g>
    </svg>
  `

  let GreyKLogo = 
    `<svg width="52px" height="64px" viewBox="0 0 185 229" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>KADA email</title>
      <g id="KADA-email" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="Fill-34" fill="#BDBDBD" points="51.6489 227.9933 47.1169 227.9933 40.2489 215.7583 37.0489 219.6463 37.0489 227.9933 33.1719 227.9933 33.1719 195.0173 37.0489 195.0173 37.0489 214.6453 37.1519 214.6453 45.8309 203.2503 50.3629 203.2503 42.8309 212.5663"></polygon>
          <polygon id="Stroke-35" points="51.6489 227.9933 47.1169 227.9933 40.2489 215.7583 37.0489 219.6463 37.0489 227.9933 33.1719 227.9933 33.1719 195.0173 37.0489 195.0173 37.0489 214.6453 37.1519 214.6453 45.8309 203.2503 50.3629 203.2503 42.8309 212.5663"></polygon>
          <path d="M65.0479,214.5148 C63.6119,216.0408 60.1929,216.3618 58.4449,217.4218 C57.1829,218.2148 56.5479,219.1778 56.5479,221.3468 C56.5479,223.8478 57.4199,225.6028 60.0499,225.6028 C62.5979,225.6028 65.0479,223.4298 65.0479,220.8858 L65.0479,214.5148 Z M53.0809,210.7288 C53.0809,205.0838 55.4789,202.5988 61.3839,202.5988 C68.9139,202.5988 68.9139,207.1268 68.9139,209.7068 L68.9139,223.5168 C68.9139,224.5298 68.9139,225.4998 70.1289,225.4998 C70.6299,225.4998 70.8619,225.4128 71.0479,225.2798 L71.0479,228.0438 C70.7679,228.0958 69.7619,228.2818 68.7909,228.2818 C67.2969,228.2818 65.4579,228.2818 65.2769,225.0978 L65.1779,225.0978 C63.9279,227.3108 61.5419,228.6478 59.2299,228.6478 C54.6029,228.6478 52.4789,225.8708 52.4789,221.4418 C52.4789,217.9788 53.9479,215.5768 57.1469,214.6058 L62.3619,213.0358 C65.1779,212.1528 65.1779,211.3678 65.1779,209.2848 C65.1779,206.9858 63.7979,205.6478 61.1979,205.6478 C56.9659,205.6478 56.9659,209.6278 56.9659,210.4998 L56.9659,210.7288 L53.0809,210.7288 Z" id="Fill-36" fill="#BDBDBD"></path>
          <path d="M65.0479,214.5148 C63.6119,216.0408 60.1929,216.3618 58.4449,217.4218 C57.1829,218.2148 56.5479,219.1778 56.5479,221.3468 C56.5479,223.8478 57.4199,225.6028 60.0499,225.6028 C62.5979,225.6028 65.0479,223.4298 65.0479,220.8858 L65.0479,214.5148 Z M53.0809,210.7288 C53.0809,205.0838 55.4789,202.5988 61.3839,202.5988 C68.9139,202.5988 68.9139,207.1268 68.9139,209.7068 L68.9139,223.5168 C68.9139,224.5298 68.9139,225.4998 70.1289,225.4998 C70.6299,225.4998 70.8619,225.4128 71.0479,225.2798 L71.0479,228.0438 C70.7679,228.0958 69.7619,228.2818 68.7909,228.2818 C67.2969,228.2818 65.4579,228.2818 65.2769,225.0978 L65.1779,225.0978 C63.9279,227.3108 61.5419,228.6478 59.2299,228.6478 C54.6029,228.6478 52.4789,225.8708 52.4789,221.4418 C52.4789,217.9788 53.9479,215.5768 57.1469,214.6058 L62.3619,213.0358 C65.1779,212.1528 65.1779,211.3678 65.1779,209.2848 C65.1779,206.9858 63.7979,205.6478 61.1979,205.6478 C56.9659,205.6478 56.9659,209.6278 56.9659,210.4998 L56.9659,210.7288 L53.0809,210.7288 Z" id="Stroke-37"></path>
          <path d="M81.8472,225.3224 C86.1422,225.3224 86.8452,221.2604 86.8452,215.6154 C86.8452,210.8154 85.9972,205.9244 81.8472,205.9244 C78.2462,205.9244 77.3152,209.7074 77.3152,215.6154 C77.3152,221.5284 78.2462,225.3224 81.8472,225.3224 L81.8472,225.3224 Z M90.4812,227.9934 L86.7822,227.9934 L86.7822,224.9484 L86.6952,224.9484 C86.1782,226.7944 84.0292,228.6474 81.3422,228.6474 C75.4812,228.6474 73.2602,223.4294 73.2602,215.6154 C73.2602,210.4484 74.0802,202.5984 81.0512,202.5984 C83.2752,202.5984 85.6302,203.9314 86.5102,206.0664 L86.6082,206.0664 L86.6082,195.0174 L90.4812,195.0174 L90.4812,227.9934 Z" id="Fill-38" fill="#BDBDBD"></path>
          <path d="M81.8472,225.3224 C86.1422,225.3224 86.8452,221.2604 86.8452,215.6154 C86.8452,210.8154 85.9972,205.9244 81.8472,205.9244 C78.2462,205.9244 77.3152,209.7074 77.3152,215.6154 C77.3152,221.5284 78.2462,225.3224 81.8472,225.3224 Z M90.4812,227.9934 L86.7822,227.9934 L86.7822,224.9484 L86.6952,224.9484 C86.1782,226.7944 84.0292,228.6474 81.3422,228.6474 C75.4812,228.6474 73.2602,223.4294 73.2602,215.6154 C73.2602,210.4484 74.0802,202.5984 81.0512,202.5984 C83.2752,202.5984 85.6302,203.9314 86.5102,206.0664 L86.6082,206.0664 L86.6082,195.0174 L90.4812,195.0174 L90.4812,227.9934 Z" id="Stroke-39"></path>
          <path d="M106.9258,214.5148 C105.4898,216.0408 102.0738,216.3618 100.3158,217.4218 C99.0648,218.2148 98.4138,219.1778 98.4138,221.3468 C98.4138,223.8478 99.2978,225.6028 101.9328,225.6028 C104.4768,225.6028 106.9258,223.4298 106.9258,220.8858 L106.9258,214.5148 Z M94.9628,210.7288 C94.9628,205.0838 97.3718,202.5988 103.2778,202.5988 C110.7908,202.5988 110.7908,207.1268 110.7908,209.7068 L110.7908,223.5168 C110.7908,224.5298 110.7908,225.4998 111.9898,225.4998 C112.5068,225.4998 112.7438,225.4128 112.9258,225.2798 L112.9258,228.0438 C112.6458,228.0958 111.6398,228.2818 110.6608,228.2818 C109.1898,228.2818 107.3398,228.2818 107.1548,225.0978 L107.0608,225.0978 C105.8098,227.3108 103.4108,228.6478 101.1078,228.6478 C96.4818,228.6478 94.3588,225.8708 94.3588,221.4418 C94.3588,217.9788 95.8418,215.5768 99.0298,214.6058 L104.2428,213.0358 C107.0608,212.1528 107.0608,211.3678 107.0608,209.2848 C107.0608,206.9858 105.6758,205.6478 103.0958,205.6478 C98.8278,205.6478 98.8278,209.6278 98.8278,210.4998 L98.8278,210.7288 L94.9628,210.7288 Z" id="Fill-40" fill="#BDBDBD"></path>
          <path d="M106.9258,214.5148 C105.4898,216.0408 102.0738,216.3618 100.3158,217.4218 C99.0648,218.2148 98.4138,219.1778 98.4138,221.3468 C98.4138,223.8478 99.2978,225.6028 101.9328,225.6028 C104.4768,225.6028 106.9258,223.4298 106.9258,220.8858 L106.9258,214.5148 Z M94.9628,210.7288 C94.9628,205.0838 97.3718,202.5988 103.2778,202.5988 C110.7908,202.5988 110.7908,207.1268 110.7908,209.7068 L110.7908,223.5168 C110.7908,224.5298 110.7908,225.4998 111.9898,225.4998 C112.5068,225.4998 112.7438,225.4128 112.9258,225.2798 L112.9258,228.0438 C112.6458,228.0958 111.6398,228.2818 110.6608,228.2818 C109.1898,228.2818 107.3398,228.2818 107.1548,225.0978 L107.0608,225.0978 C105.8098,227.3108 103.4108,228.6478 101.1078,228.6478 C96.4818,228.6478 94.3588,225.8708 94.3588,221.4418 C94.3588,217.9788 95.8418,215.5768 99.0298,214.6058 L104.2428,213.0358 C107.0608,212.1528 107.0608,211.3678 107.0608,209.2848 C107.0608,206.9858 105.6758,205.6478 103.0958,205.6478 C98.8278,205.6478 98.8278,209.6278 98.8278,210.4998 L98.8278,210.7288 L94.9628,210.7288 Z" id="Stroke-41"></path>
          <polygon id="Fill-42" fill="#BDBDBD" points="116.94 227.994 121.193 227.994 121.193 223.098 116.94 223.098"></polygon>
          <polygon id="Stroke-43" points="116.94 227.994 121.193 227.994 121.193 223.098 116.94 223.098"></polygon>
          <path d="M138.5049,214.5148 C137.0769,216.0408 133.6569,216.3618 131.9059,217.4218 C130.6549,218.2148 130.0089,219.1778 130.0089,221.3468 C130.0089,223.8478 130.8759,225.6028 133.5079,225.6028 C136.0559,225.6028 138.5049,223.4298 138.5049,220.8858 L138.5049,214.5148 Z M126.5459,210.7288 C126.5459,205.0838 128.9429,202.5988 134.8569,202.5988 C142.3779,202.5988 142.3779,207.1268 142.3779,209.7068 L142.3779,223.5168 C142.3779,224.5298 142.3779,225.4998 143.5849,225.4998 C144.0899,225.4998 144.3189,225.4128 144.5049,225.2798 L144.5049,228.0438 C144.2249,228.0958 143.2189,228.2818 142.2519,228.2818 C140.7569,228.2818 138.9229,228.2818 138.7369,225.0978 L138.6429,225.0978 C137.4009,227.3108 135.0019,228.6478 132.6869,228.6478 C128.0759,228.6478 125.9419,225.8708 125.9419,221.4418 C125.9419,217.9788 127.4129,215.5768 130.6049,214.6058 L135.8269,213.0358 C138.6429,212.1528 138.6429,211.3678 138.6429,209.2848 C138.6429,206.9858 137.2589,205.6478 134.6629,205.6478 C130.4229,205.6478 130.4229,209.6278 130.4229,210.4998 L130.4229,210.7288 L126.5459,210.7288 Z" id="Fill-44" fill="#BDBDBD"></path>
          <path d="M138.5049,214.5148 C137.0769,216.0408 133.6569,216.3618 131.9059,217.4218 C130.6549,218.2148 130.0089,219.1778 130.0089,221.3468 C130.0089,223.8478 130.8759,225.6028 133.5079,225.6028 C136.0559,225.6028 138.5049,223.4298 138.5049,220.8858 L138.5049,214.5148 Z M126.5459,210.7288 C126.5459,205.0838 128.9429,202.5988 134.8569,202.5988 C142.3779,202.5988 142.3779,207.1268 142.3779,209.7068 L142.3779,223.5168 C142.3779,224.5298 142.3779,225.4998 143.5849,225.4998 C144.0899,225.4998 144.3189,225.4128 144.5049,225.2798 L144.5049,228.0438 C144.2249,228.0958 143.2189,228.2818 142.2519,228.2818 C140.7569,228.2818 138.9229,228.2818 138.7369,225.0978 L138.6429,225.0978 C137.4009,227.3108 135.0019,228.6478 132.6869,228.6478 C128.0759,228.6478 125.9419,225.8708 125.9419,221.4418 C125.9419,217.9788 127.4129,215.5768 130.6049,214.6058 L135.8269,213.0358 C138.6429,212.1528 138.6429,211.3678 138.6429,209.2848 C138.6429,206.9858 137.2589,205.6478 134.6629,205.6478 C130.4229,205.6478 130.4229,209.6278 130.4229,210.4998 L130.4229,210.7288 L126.5459,210.7288 Z" id="Stroke-45"></path>
          <path d="M147.873,227.993 L151.754,227.993 L151.754,203.25 L147.873,203.25 L147.873,227.993 Z M147.873,199.452 L151.754,199.452 L151.754,195.018 L147.873,195.018 L147.873,199.452 Z" id="Fill-46" fill="#BDBDBD"></path>
          <path d="M147.873,227.993 L151.754,227.993 L151.754,203.25 L147.873,203.25 L147.873,227.993 Z M147.873,199.452 L151.754,199.452 L151.754,195.018 L147.873,195.018 L147.873,199.452 Z" id="Stroke-47"></path>
          <polygon id="Fill-48" fill="#BDBDBD" points="62.8755 150.2746 35.5705 124.2346 35.5705 42.4386 62.8755 42.4386"></polygon>
          <polygon id="Fill-49" fill="#BDBDBD" points="137.5088 42.4386 105.1448 42.4386 89.4458 66.3606 108.7568 85.9776"></polygon>
          <polygon id="Fill-50" fill="#BDBDBD" points="68.9233 96.0031 118.5943 146.1521 159.1403 146.1521 85.4903 71.9511"></polygon>
          <path d="M92.4629,15.3766 C49.5419,15.3766 14.6239,50.2956 14.6239,93.2166 C14.6239,136.1376 49.5419,171.0556 92.4629,171.0556 C135.3839,171.0556 170.3029,136.1376 170.3029,93.2166 C170.3029,50.2956 135.3839,15.3766 92.4629,15.3766 M92.4629,186.0556 C41.2709,186.0556 -0.3761,144.4086 -0.3761,93.2166 C-0.3761,42.0246 41.2709,0.3766 92.4629,0.3766 C143.6549,0.3766 185.3029,42.0246 185.3029,93.2166 C185.3029,144.4086 143.6549,186.0556 92.4629,186.0556" id="Fill-51" fill="#BDBDBD"></path>
      </g>
    </svg>`
    
  useEffect(()=>{
    setTemplateLoading(true);
    setTemplateError(false)
    axiosCerebrum
      .post(
        '/api/notifications/email/preview',
        {
          "recipient_user_ids": [...state.notificationUsers,...state.notificationUsersManual.filter(el=>el.selected).map(el=>el.id)],
          "additional_recipient_emails": state.notificationEmailsManual.filter(el=>el.selected).map(el=>el.id),
          "template_id": state.selectedEmailTemplate,
          "template_data": {
            "extract_id": state.extractData.id,
            "title": state.notificationInput.title,
            "business_impact":state.notificationInput.impact,
            "description": state.notificationInput.description.replaceAll("\n",'<br/>'),
            "date": moment(state.notificationInput.date).format('YYYY-MM-DD'),
            "status":state.notificationInput.status || '',
            "link": state.notificationInput.link || '',
            "contact": state.notificationInput.contact,
            'object_id':state.historyObject?.id
          },
        }
      )
      .then(response=>{
        let newBody = response.data.body
                        .replace('<img width="52" height="52" src="cid:var_image_logo">',KLogo)
                        .replace('<img width="52" height="64" src="cid:var_image_logo">',GreyKLogo)
                        .replace(/href=(["'])(.*?)(["'])/g,'')
                        .replace('id="change-email-body"','id="change-email-body" style="background:none;"')
                        .replace('id="change-email-root"','id="change-email-root" style="padding:0 0 24px;"')
        setTemplate(newBody);
        setTemplateLoading(false);
        setTemplateError(false)
      })
      .catch(error=>{
        console.log(error);
        setTemplateLoading(false);
        setTemplateError(true)
      })
    // eslint-disable-next-line
  },[])


  return (
    <div className={classes.root}>
      <div style={{marginBottom:24}}>
        <Typography style={{fontSize:20,color:theme.palette.header.main}}>NOTIFICATION DETAILS</Typography>
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>Construct a notification to users of this object</Typography>
      </div>
      <div style={{width:'100%',overflow:'hidden'}}>
        {
          templateLoading && <div style={{width:'100%',display:'flex',justifyContent:"center"}}><CircularProgress color='secondary' style={{margin:'40px auto'}}/></div>
        }
        {
          templateError && <Typography style={{color:theme.palette.primaryText.main, marginTop:30}}>Error occurred loading template.</Typography>
        }
        {
          template && 
          parse(
            template,
          )
        }
      </div>
    </div>
  )
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Preview));

