import React from 'react';
import { withStyles} from '@material-ui/core';
import Configuration from '../Configuration/Configuration';
import Results from '../Results/Results';
import LinkedTo from '../LinkedTo/LinkedTo';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    history,
    dispatch,
    state,
  } = props;


  return (
    <div className={classes.root}>
      <Configuration state={state} dispatch={dispatch}/>
      <LinkedTo  state={state} dispatch={dispatch} history={history}/>
      <Results state={state} dispatch={dispatch} selectedRecord={state.selectedRecord}/>
    </div>
  )
}


export default withStyles(styles)(Body);