import React, { useState, useEffect, useRef } from 'react';
import { withTheme, withStyles, Checkbox, IconButton, MenuItem, Select, InputBase, CircularProgress } from '@material-ui/core';
import { checkIsHexColour, collectionIds, getDispFields, getFontColourByBackground, getIconComponent, toTitleCase, validateUrl } from '../../../utilities';
import { Typography } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import SearchSelector from '../SearchSelector/SearchSelector';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import { templates } from '../InteractiveInput/Templates';
import InteractiveInputBody from '../InteractiveInput/InteractiveInputBody';
import AddCIModal from '../InteractiveInput/AddCIModal';
import axiosSolr from '../../../axios-solr';
import UserChip from '../Chips/UserChip';
import { GenAILoadingIcon, explainationText, hasLLMKeySet, overwriteText, triggerText } from '../UpdateInput/genAIComponents';
import { generateDescription } from '../UpdateInput/genAIUtils';
import KTooltip from '../KTooltip/KTooltip';
import PropTypes from 'prop-types';
import useAlert from '../../../hooks/useAlert';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { getIconLabel } from '../SearchResults/utils';
import ColourPickerPopper from '../ColourPicker/ColourPickerPopper';
import { darkBackground, lightBackground } from '../../../theme';

const styles = theme => ({
  propertyContainer: {
    // display:'flex',
    // height:60,
    // marginBottom:32,
    // alignItems:'flex-start',
    overflow: 'visible',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`
  },
  fieldName: {
    flexShrink: 0,
    marginBottom: 8,
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    wordBreak: 'break-word',
    color: theme.palette.primaryText.main
  },
  fieldDescription: {
    fontSize: 10,
    color: theme.palette.primaryText.light,
    marginBottom: 8,
  },
  inputBase: {
    ...theme.components.inputBase,
    flexGrow: 1,
    width: '100%',
    '& input': {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      color: theme.palette.primaryText.main,
    },
    '& textarea': {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16
    }
    
  },
  disabledInputBase: {
    color: `${theme.palette.primaryText.main} !important`
  },
  checkbox: {
    paddingLeft: 0,
    color: theme.palette.primaryText.light
  },
  disabledButton: {
    ...theme.components.disabledButton
  },
  selector: {
    ...theme.components.selector,
    flexGrow: 1,
    paddingTop: 7,
    width: '100%',
    paddingBottom: 7,
    '& div div': {
      paddingLeft: 16,
      fontSize: 16
    }
  },
  multiSelectChip: {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    paddingLeft: 8,
    paddingRight: 8,
    width: 'max-content',
    fontSize: 13.75,
    marginBottom: 8,
    borderRadius: 16
  },
  iconButton: {
    "&:hover": {
      background: `${theme.palette.hovered.main}80`
    }
  },
  helperText: {
    color: theme.palette.primaryText.light,
    fontSize: 12,
    marginLeft: 16,
    marginTop: 4,
    whiteSpace: 'pre-wrap'
  },
  focusBorder: {
    border: `1px solid ${theme.palette.error.main} !important`,
  },
  buttons: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 8,
    boxShadow: '0px -1px 5px 0px #DDD',
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  assigneeChip: {
    marginBottom: 8,
  },
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  subSectionHeader: {
    fontSize: 12,
    color: theme.palette.primaryText.main,
    letterSpacing: 2,
    marginTop: 12,
    marginBottom: 8
  },
  genAIButton: {
    ...theme.components.genAIButton
  },
  overwriteGenAIButton: {
    ...theme.components.overwriteGenAIButton
  },
  colourSampleContainer: {
    padding: '12px 24px',
    borderRadius: 6,
    marginRight: 24
  },
  colourSampleChip: {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    padding: "0px 8px",
    width: 'max-content',
    fontSize: 13.75,
    borderRadius: 12
  }
});

const Editor = props => {
  const {
    classes,
    properties,
    hideDefaultProperty,
    theme,
    values,
    setValues,
    userMap,
    setUserMap,
    collectionMap,
    setCollectionMap,
    collection,
    objectLabel,
    object,
    isCreatingNew,
    singlePropertyEdit
  } = props;


  const [searchValues, setSearchValues] = useState({});
  const [collectionFilterMap, setCollectionFilterMap] = useState();
  const [collectionFilterLoading, setCollectionFilterLoading] = useState(false)
  const [collectionFilterError, setCollectionFilterError] = useState(false)
  const [AIGenerating, setAIGenerating] = useState(false)
  const [isAIGenerated, setIsAIGenerated] = useState(false)
  const [showAnswer, setShowAnswer] = useState(!isCreatingNew)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(() => {
    // if there's boolean type field, set to false by default if it doesn't have a value
    let newValues = { ...values }
    properties.forEach(el => {
      if (el.data_type === 'BOOLEAN' && typeof (newValues[el.id]) !== 'boolean') {
        newValues[el.id] = false
      }
    })
    setValues(newValues)
    return () => {
      isCancelledRef.current = true
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (!object) return;
    let properties = collection.properties;
    let objectValues = object.properties;
    let userIds = [];
    let collectionPropFilterIDs = [];
    properties.forEach(el => {
      if (el.data_type.includes('_MULTI') && objectValues[el.id] && !Array.isArray(objectValues[el.id])) return;
      if (['USER_LOOKUP_FILTERED', 'USER_LOOKUP_FILTERED_MULTI', 'USER_LOOKUP', 'USER_LOOKUP_MULTI'].includes(el.data_type)) {
        if (objectValues[el.id] && objectValues[el.id] !== '') {
          userIds = [...userIds, ...(['USER_LOOKUP_FILTERED_MULTI', 'USER_LOOKUP_MULTI'].includes(el.data_type) ? values[el.id] : [values[el.id]])];
        }
      }
      if (['COLLECTION_LOOKUP_FILTERED_MULTI'].includes(el.data_type)) {
        collectionPropFilterIDs.push({ filter: el.filter, propID: el.id, isMulti: true })
      }
      if (['COLLECTION_LOOKUP_FILTERED'].includes(el.data_type)) {
        collectionPropFilterIDs.push({ filter: el.filter, propID: el.id, isMulti: false })
      }
    })

    userIds = userIds.filter(i => !userMap[i])
    collectionPropFilterIDs = collectionPropFilterIDs.filter(i => objectValues[i.propID] === undefined)

    if (userIds.length > 0) {
      axiosSolr.get(
        '/solr/search/select',
        {
          params: {
            q: "*",
            fq: `id:(${userIds.join(' OR ')})`,
            fl: 'name:name_txt,id',
            rows: userIds.length
          }
        }
      ).then(response => {
        let map = {};
        response.data.response.docs.forEach(r => {
          map[r.id] = r;
        })
        setUserMap({ ...userMap, ...map })
      })
        .catch(error => {
          console.log(error)
        })
    }

    if (collectionPropFilterIDs.length > 0) {
      const onProcessCollectionMap = cis => {
        let map = {}
        let properties = {}
        collectionPropFilterIDs.forEach(p => {
          properties[p.propID] = p.isMulti ? [] : '';
        })
        cis.forEach(ci => {
          map[ci.id] = ci;
          let matchedProp = collectionPropFilterIDs.find(c => c.filter === ci.parent_id);
          if (!matchedProp) return;
          if (matchedProp.isMulti) properties[matchedProp.propID].push(ci.id)
          else { properties[matchedProp.propID] = ci.id }
        })
        setValues({ ...values, ...properties })
        setCollectionMap({ ...collectionMap, ...map });
      }

      const loadLinked = (prevData = [], page = 1) => {
        axiosCerebrum
          .get(
            `/api/collectioninstances/${object.id}/related`, {
            params: {
              relationship: 'MEMBER_OF',
              page,
              per_page: 200,
              object_name: 'COLLECTION_INSTANCE'
            }
          }
          )
          .then(response => {
            let data = [...prevData, ...response.data.items]
            if (response.data.page < response.data.pages) {
              loadLinked(data, response.data.page + 1)
            } else {
              onProcessCollectionMap(data)
            }
          })
          .catch(error => {
            console.log(error)
            onProcessCollectionMap(prevData)
          })
      }

      loadLinked()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const loadCollectionFilterMaps = async () => {
      if (!collectionFilterMap) {
        let collectionInstanceIds = [];
        let collectionIds = [];
        properties.forEach(el => {
          if (['USER_LOOKUP_FILTERED', 'USER_LOOKUP_FILTERED_MULTI'].includes(el.data_type)) {
            collectionInstanceIds.push(el.filter);
          }
          if (['COLLECTION_LOOKUP_FILTERED', 'COLLECTION_LOOKUP_FILTERED_MULTI'].includes(el.data_type)) {
            collectionIds.push(el.filter);
          }
        })
        if (collectionInstanceIds.length === 0 && collectionIds.length === 0) {
          setCollectionFilterMap({});
          return;
        }
        setCollectionFilterLoading(true);
        setCollectionFilterError(false);
        let promises = [...collectionInstanceIds.map(id => axiosCerebrum.get(`/api/collectioninstances/${id}`)), ...collectionIds.map(id => axiosCerebrum.get(`/api/collections/${id}`))]
        let childCountData = {};
        if (collectionIds.length > 0) {
          await axiosSolr
            .get(
              '/solr/search/select', {
              params: {
                q: '*',
                fq: `parent_id_srt:(${collectionIds.join(' OR ')})`,
                rows: 0,
                'json.facet': {
                  child_count: {
                    type: 'terms',
                    field: 'parent_id_srt',
                  }
                }
              }
            }
            )
            .then(response => {
              response.data.facets?.child_count?.buckets.forEach(el => {
                childCountData[el.val] = el.count;
              })
            })
            .catch(error => {
              console.log(error)
            })
        }

        await Promise.all(promises).then(response => {
          let map = {};
          response.forEach(el => {
            map[el.data.id] = { ...el.data, childCount: childCountData[el.data.id] || 0 };
          })
          setCollectionFilterMap(map);
          setCollectionFilterLoading(false);
        })
          .catch(error => {
            setCollectionFilterLoading(false);
            setCollectionFilterError(true)
          })
      }
    }
    loadCollectionFilterMaps();

    // eslint-disable-next-line
  }, [])


  const setProperties = (id, value, type, operation) => {
    if (id === 'name') {
      if (collection.category === 'KNOWLEDGE' && ['QUESTION', 'BUSINESS LOGIC', 'DECISION', 'NOTE'].includes(collection.name.toUpperCase())) {
        setValues({ ...values, [id]: value.slice(0, 250) })
        return;
      }
      if (collection.category === 'KNOWLEDGE' && ['LINK'].includes(collection.name.toUpperCase())) {
        setValues({ ...values, [id]: value.slice(0, 100) })
        return;
      }
      if (type === 'TEXT_BOX') {
        setValues({ ...values, [id]: value.slice(0, 250) })
        return;
      }
      setValues({ ...values, [id]: value.slice(0, 120) })
      return
    }
    else if (type === 'BOOLEAN') {
      setValues({ ...values, [id]: value })
      return;
    }
    else if (type === 'TEXT_BOX') {
      setValues({ ...values, [id]: value.slice(0, 3000) })
      return;
    }
    else if (type === 'TEXT_FIELD') {
      setValues({ ...values, [id]: value.slice(0, 50) })
      return;
    }
    else if (type === 'URL') {
      setValues({ ...values, [id]: value })
      return;
    }
    else if (type === 'INTEGER') {
      if (value.match(/^[-+]?\d+$/g) || value === '') {
        setValues({ ...values, [id]: value })
        return;
      } else {
        return;
      }

    }
    else if (type === 'FLOAT') {
      if (value.match(/^[-+]?\d+$/g) || value.match(/^[-+]?\d*\.?\d*$/g) || value === '') {
        setValues({ ...values, [id]: value })
        return;
      }
      else {
        return;
      }
    }
    else if (type === 'DROPDOWN_MULTI') {
      if (operation === 'add') {
        setValues({ ...values, [id]: value.filter(el => el !== 'placeholder') })
        return;
      }
      else {
        setValues({ ...values, [id]: values[id].filter(el => el !== value) })
        return;
      }
    }
    else if (type === 'DROPDOWN_SINGLE') {
      setValues({ ...values, [id]: value })
      return;
    }
    else if (type === 'DATE') {
      setValues({ ...values, [id]: moment(value).format('YYYY-MM-DD') })
      return;
    }
    else if (['USER_LOOKUP', 'USER_LOOKUP_FILTERED'].includes(type)) {
      let newValue;
      if (operation === 'add') {
        newValue = value.id;
        setUserMap({ ...userMap, [value.id]: value });
      } else {
        newValue = '';
      }
      setValues({ ...values, [id]: newValue })
      return;
    }
    else if (['USER_LOOKUP_FILTERED_MULTI', 'USER_LOOKUP_MULTI'].includes(type)) {
      let newValue;
      if (operation === 'add') {
        setUserMap({ ...userMap, [value.id]: value });
        if (values[id] && values[id].length > 0 && Array.isArray(values[id])) {
          newValue = values[id].includes(value.id) ? values[id] : [...values[id], value.id];
        } else {
          newValue = [value.id];
        }
      } else {
        newValue = values[id].filter(el => el !== value.id)
      }
      setValues({ ...values, [id]: newValue });
      return;
    }
    else if (['COLLECTION_LOOKUP_FILTERED'].includes(type)) {
      let newValue;
      if (operation === 'add') {
        newValue = value.id;
        setCollectionMap({ ...collectionMap, [value.id]: value });
      } else {
        newValue = '';
      }
      setValues({ ...values, [id]: newValue })
      return;
    }
    else if (['COLLECTION_LOOKUP_FILTERED_MULTI'].includes(type)) {
      let newValue;
      if (operation === 'add') {
        setCollectionMap({ ...collectionMap, [value.id]: value });
        if (values[id] && values[id].length > 0 && Array.isArray(values[id])) {
          newValue = values[id].includes(value.id) ? values[id] : [...values[id], value.id];
        } else {
          newValue = [value.id];
        }
      } else {
        newValue = values[id].filter(el => el !== value.id)
      }
      setValues({ ...values, [id]: newValue });
      return;
    } else {
      setValues({ ...values, [id]: value })
      return;
    }
  }


  const shouldFocus = (value, type, isRequired) => {
    if (!isRequired) return false;
    if (['TEXT_BOX', 'TEXT_FIELD', 'FLOAT', 'INTEGER', 'DATE'].includes(type) && (!value || value.trim() === '')) return true;
    if (['URL'].includes(type) && (!value || value.trim() === '' || !validateUrl(value))) return true;
    if (type === 'DROPDOWN_MULTI' && (!value || value.length === 0)) return true;
    if (type === 'DROPDOWN_SINGLE' && (!value || value === 'placeholder')) return true;
    if (['USER_LOOKUP', 'USER_LOOKUP_FILTERED'].includes(type) && (!value || value.trim() === '')) return true;
    if (['USER_LOOKUP_FILTERED_MULTI', 'USER_LOOKUP_MULTI'].includes(type) && (!value || value.length === 0)) return true;
    if (['COLLECTION_LOOKUP_FILTERED_MULTI', 'COLLECTION_LOOKUP_FILTERED'].includes(type) && (!value || value.length === 0)) return true;
    if (['COLLECTION_LOOKUP_FILTERED'].includes(type) && (!value || value.trim() === '')) return true;
    if (['COLOUR_SELECTOR'].includes(type) && (!value || !checkIsHexColour(value))) return true;
    return false;
  }

  const getUserLookUpFilter = (type, filter) => {
    if (['USER_LOOKUP', 'USER_LOOKUP_MULTI'].includes(type)) {
      return `object_type_srt:USER AND account_type_srt:"USER_ACCOUNT" AND -merge_type_srt:CANDIDATE`
    } else {
      return `object_type_srt:USER AND data_role_kc_msrt:"${collectionFilterMap[filter].name}" AND account_type_srt:"USER_ACCOUNT" AND -merge_type_srt:CANDIDATE`
    }
  }

  const getCollectionLookupFilter = (type, filter, id) => {
    if (type === 'COLLECTION_LOOKUP_FILTERED_MULTI' && values[id] && Array.isArray(values[id]) && values[id].length > 0) {
      return `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"${collectionFilterMap[filter].name.toUpperCase()}" AND -id:(${values[id].join(' OR ')})`
    }
    return `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"${collectionFilterMap[filter].name.toUpperCase()}"`
  }

  let userData;
  try {
    userData = JSON.parse(localStorage.getItem('userInfo'))
  } catch { }


  const enableGenAI = (['GLOSSARY', 'DATA_GOVERNANCE', 'DATA_MANAGEMENT'].includes(collection.category) || collection.id === collectionIds.domain || collection.id === collectionIds.verifiedUseCase);
  const disableGenAIButton = !values['name'] || values['name'].trim() === '';
  const instanceNaming = collection.category === 'GLOSSARY' ? 'term' : 'instance';

  const getTextFieldHelperText = (el) => {
    if (el.id === 'name') {
      if (collection.category === 'KNOWLEDGE') {
        if (collection.name.toUpperCase() === 'QUESTION') {
          return `Required. Max 250 characters.${objectLabel ? `\nYour question will be sent to all top users and Data Steward’s of this ${objectLabel}` : ''}`
        }

        if (['BUSINESS LOGIC', 'DECISION', 'NOTE'].includes(collection.name.toUpperCase())) {
          return `Required. Max 250 characters`
        }
        if (collection.name.toUpperCase() === 'LINK') {
          return `Required. Max 100 characters`
        }
      }
      return 'Required. Max 120 characters'
    }
    if (el.id === 'description') {
      let txt = `Optional. Max 3000 characters`
      if (enableGenAI && hasLLMKeySet() && !object) {
        txt += `. K.ai is available to generate a description after the ${instanceNaming} is created`
      }
      return txt
    }
    if (el.data_type === 'URL') {
      let text = el.required ? 'Required' : 'Optional';
      if (values[el.id] && shouldFocus(values[el.id], el.data_type, el.required)) {
        text += '. Must be a valid url'
      }
      return text;
    }
    return el.required ? 'Required' : 'Optional'
  }

  const getPlaceHolder = el => {
    if (!el.description) return `Add a ${el.name}`;
    return el.description.replace(/\[object\]/g, objectLabel || 'object')
  }


  let inputs = hideDefaultProperty ? [] : [
    <div className={classes.propertyContainer} style={{ paddingTop: 0, paddingBottom: singlePropertyEdit ? 0 : undefined, borderBottom: singlePropertyEdit ? 'none' : undefined }}>
      {
        !singlePropertyEdit &&
        <Typography
          className={classes.fieldName}
        >
          Name
        </Typography>
      }
      <div style={{ flexGrow: 1, overflow: 'visible' }} id={`edit_field_name`}>
        <InputBase
          inputProps={{
            'data-test-id': `edit-field-inputbase-name`
          }}
          id={`edit_field_inputbase_name`}
          value={values['name'] || ''}
          onChange={(event) => { setProperties('name', event.target.value, 'TEXT_FIELD') }}
          variant={'filled'}
          placeholder={'Add a name'}
          className={classes.inputBase + (shouldFocus(values['name'], 'TEXT_FIELD', true) ? " " + classes.focusBorder : '')}
          multiline={collection.name.toUpperCase() === 'QUESTION'}
          rows={5}
          autoFocus
        />
        <Typography
          className={classes.helperText}
          style={shouldFocus(values['name'], 'TEXT_FIELD', true) ? { color: theme.palette.error.main, marginTop: 0 } : { marginTop: 0 }}
        >
          {getTextFieldHelperText({ id: 'name' })}
        </Typography>
      </div>
    </div>
    ,
    <div className={classes.propertyContainer} style={{ paddingBottom: singlePropertyEdit ? 0 : undefined, borderBottom: singlePropertyEdit ? 'none' : undefined }}>
      {
        !singlePropertyEdit &&
        <Typography
          className={classes.fieldName}
        >
          Description
        </Typography>
      }
      <div style={{ flexGrow: 1, overflow: 'hidden' }} id={`edit_field_description`}>
        {
          AIGenerating ?
            <div>
              <GenAILoadingIcon />
            </div>
            :
            <InteractiveInputBody
              key={'description'}
              isHighlight={shouldFocus(values['description'] || '', 'TEXT_BOX', false)}
              onChange={(value) => setProperties('description', value.slice(0, 3000), 'TEXT_BOX')}
              initialValue={values['description'] || ''}
              triggerChar={templates.linkCI.triggerChar}
              generatePopper={({ editorRef, setPopperAnchor }) => {
                return (
                  <AddCIModal
                    editorRef={editorRef}
                    setPopperAnchor={setPopperAnchor}
                    targetItem={object}
                  />
                )
              }}
            />
        }
        {
          !AIGenerating &&
          <Typography
            className={classes.helperText}
            style={shouldFocus(values['description'] || '', 'TEXT_BOX', false) ? { color: theme.palette.error.main, marginTop: 4 } : { marginTop: 4 }}
          >
            {getTextFieldHelperText({ id: 'description' })}
          </Typography>
        }
        {
          enableGenAI && !AIGenerating && hasLLMKeySet() && object &&
          <>
            {
              isAIGenerated ?
                <Typography style={{ fontSize: 13, marginLeft: 16, color: theme.palette.primaryText.light, marginTop: 6 }}>
                  {explainationText}
                </Typography>
                :
                <KTooltip title={disableGenAIButton ? "Name must be entered" : ''}>
                  <div
                    className={values['description'] ? classes.overwriteGenAIButton : classes.genAIButton}
                    style={{ color: disableGenAIButton ? theme.palette.primaryText.light : undefined, cursor: disableGenAIButton ? 'default' : undefined }}
                    onClick={() => {
                      if (disableGenAIButton) return;
                      generateDescription({
                        data: object || { name: values['name'] || '', parent: collection, object_type_txt: 'COLLECTION_INSTANCE' },
                        onLoading: () => {
                          setAIGenerating(true)
                        },
                        onError: (msg) => {
                          setAIGenerating(false)
                          sendAlert({
                            message: msg || 'Error generating description, please try again',
                            type: 'error',
                            timeout: 99999999
                          })
                        },
                        onSuccess: (value) => {
                          setProperties('description', value.slice(0, 3000), 'TEXT_BOX')
                          setAIGenerating(false)
                          setIsAIGenerated(true)
                        }
                      })
                    }}
                  >
                    {values['description'] ? overwriteText : triggerText}
                  </div>
                </KTooltip>
            }
          </>
        }
      </div>
    </div>
  ];

  inputs.push(
    ...properties.map((el, index) => {
      if (collection.name === 'Question' && el.id === '3' && !showAnswer) {
        return (
          <div className={classes.propertyContainer} style={{ paddingTop: singlePropertyEdit ? 0 : undefined, paddingBottom: singlePropertyEdit ? 0 : undefined, borderBottom: singlePropertyEdit ? 'none' : undefined }}>
            {
              !singlePropertyEdit &&
              <Typography
                className={classes.fieldName}
              >
                {toTitleCase(el.name)}
              </Typography>
            }
            <Typography style={{ fontSize: 13, letterSpacing: 1, cursor: 'pointer' }} color='primary' onClick={() => setShowAnswer(true)}>
              CLICK TO ADD AN ANSWER TO THIS QUESTION
            </Typography>
          </div>
        )
      }

      return (
        ['URL_INTERNAL'].includes(el.data_type) ? null :
          <div className={classes.propertyContainer} style={{ paddingTop: singlePropertyEdit ? 0 : undefined, paddingBottom: singlePropertyEdit ? 0 : undefined, borderBottom: singlePropertyEdit ? 'none' : undefined }}>
            {
              !singlePropertyEdit &&
              <>
                <Typography
                  className={classes.fieldName}
                  style={{marginBottom: 0}}
                >
                  {toTitleCase(el.name)}
                </Typography>
                <Typography className={classes.fieldDescription}>
                  {el.description} {collectionIds.category === collection.id && el.id === '2' ? <span style={{cursor:'pointer',textDecoration:'underline'}} onClick={()=>window.open('https://docs.kada.ai/home/configuring-critical-collections#Configuringcriticalcollections-Category','_blank')}>See more</span>:''}
                </Typography>
              </>
            }
            {
              ['TEXT_BOX', 'TEXT_FIELD', 'FLOAT', 'INTEGER', 'URL'].includes(el.data_type) &&
              <div style={{ flexGrow: 1, overflow: (collection.name === 'Question' && el.id === '3') ? 'hidden' : 'visible' }} id={`edit_field_${el.id}`}>
                {
                  (collection.name === 'Question' && el.id === '3') ?
                    <div style={{ marginBottom: 4 }}>
                      <InteractiveInputBody
                        key={el.id}
                        isHighlight={shouldFocus(values[el.id], el.data_type, el.required)}
                        onChange={(value) => setProperties(el.id, value.slice(0, 3000), el.data_type)}
                        initialValue={values[el.id] || ''}
                      />
                    </div>
                    :
                    <InputBase
                      inputProps={{
                        'data-test-id': `edit-field-inputbase-${el.id}`
                      }}
                      id={`edit_field_inputbase_${el.id}`}
                      value={values[el.id] || ''}
                      onChange={(event) => { setProperties(el.id, event.target.value, el.data_type) }}
                      variant={'filled'}
                      placeholder={getPlaceHolder(el)}
                      className={classes.inputBase + (shouldFocus(values[el.id], el.data_type, el.required) ? " " + classes.focusBorder : '')}
                      multiline={el.data_type === 'TEXT_BOX'}
                      rows={12}
                    />
                }
                <Typography
                  className={classes.helperText}
                  style={shouldFocus(values[el.id], el.data_type, el.required) ? { color: theme.palette.error.main, marginTop: 0 } : { marginTop: 0 }}
                >
                  {getTextFieldHelperText(el)}
                </Typography>
              </div>
            }
            {
              ['DROPDOWN_MULTI', 'DROPDOWN_SINGLE'].includes(el.data_type) &&
              <div style={{ flexGrow: 1 }}>
                <Select
                  className={classes.selector + (shouldFocus(values[el.id], el.data_type, el.required) ? " " + classes.focusBorder : '')}
                  value={
                    el.data_type === 'DROPDOWN_MULTI' ?
                      ((values[el.id] && Array.isArray(values[el.id]) && values[el.id].length > 0) ? values[el.id] : ["placeholder"])
                      :
                      (values[el.id] || 'placeholder')
                  }
                  data-test-id={`edit-field-inputbase-${el.id}`}
                  disableUnderline
                  multiple={el.data_type === 'DROPDOWN_MULTI'}
                  onChange={event => setProperties(el.id, event.target.value, el.data_type, 'add')}
                  renderValue={
                    el.data_type === 'DROPDOWN_MULTI' ?
                      () => {
                        return `Select ${el.name}`
                      } : undefined
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        width: 250,
                      },
                    },
                  }}
                >
                  <MenuItem disabled value="placeholder">
                    {`Select ${el.name}`}
                  </MenuItem>
                  {
                    el.allowed_values.map(item => (
                      <MenuItem value={item}>
                        {
                          el.data_type === 'DROPDOWN_MULTI' && values[el.id] &&
                          <Checkbox checked={values[el.id] && values[el.id].includes(item)} color='primary' />
                        }
                        {
                          el.data_type === 'DROPDOWN_MULTI' && !values[el.id] &&
                          <Checkbox checked={false} color='primary' />
                        }
                        {item}
                      </MenuItem>
                    ))
                  }
                </Select>
                <Typography
                  className={classes.helperText}
                  style={shouldFocus(values[el.id], el.data_type, el.required) ? { color: theme.palette.error.main } : undefined}
                >
                  {el.required ? 'Required' : 'Optional'}
                </Typography>

                {
                  el.data_type === 'DROPDOWN_MULTI' &&
                  <div>
                    <Typography className={classes.subSectionHeader}>SELECTED ITEMS</Typography>
                    {
                      (!values[el.id] || values[el.id].length === 0 || !Array.isArray(values[el.id])) &&
                      <div className={classes.multiSelectChip} style={{ paddingRight: 60, paddingLeft: 16, background: '#eee' }}>
                        No Item selected
                      </div>
                    }
                    {
                      values[el.id] && Array.isArray(values[el.id]) && values[el.id].length > 0 &&
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                          values[el.id].map(item => (
                            <div className={classes.multiSelectChip} style={{ background: theme.palette.primary.dark, marginRight: 8 }}>
                              <Typography style={{ color: theme.palette.background.main, fontSize: 13.75, marginRight: 12 }}>{item}</Typography>
                              <IconButton className={classes.iconButton} style={{ marginLeft: 8, padding: 3, marginRight: -6 }} onClick={() => setProperties(el.id, item, el.data_type, 'delete')}>
                                {getIconComponent({ label: 'clear', size: 16, colour: theme.palette.background.main })}
                              </IconButton>
                            </div>
                          ))
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {
              ['USER_LOOKUP', 'USER_LOOKUP_FILTERED', 'USER_LOOKUP_FILTERED_MULTI', 'USER_LOOKUP_MULTI'].includes(el.data_type) &&
              <div style={{ flexGrow: 1 }}>
                {
                  ['USER_LOOKUP_FILTERED', 'USER_LOOKUP_FILTERED_MULTI'].includes(el.data_type) && collectionFilterLoading &&
                  <CircularProgress color='secondary' />
                }
                {
                  ['USER_LOOKUP_FILTERED', 'USER_LOOKUP_FILTERED_MULTI'].includes(el.data_type) && collectionFilterError &&
                  <Typography style={{ color: theme.palette.primaryText.main }}>Error occurred loading filters</Typography>
                }
                {
                  ((['USER_LOOKUP_FILTERED', 'USER_LOOKUP_FILTERED_MULTI'].includes(el.data_type) && collectionFilterMap) || !['USER_LOOKUP_FILTERED', 'USER_LOOKUP_FILTERED_MULTI'].includes(el.data_type)) &&
                  <div>
                    <div
                      className={classes.inputBase + (shouldFocus(values[el.id], el.data_type, el.required) ? " " + classes.focusBorder : '')}
                      style={{ overflow: 'hidden' }}
                    >
                      <SearchSelector
                        url='/solr/search/select'
                        params={{
                          q: `${searchValues[el.id] || ""}*`,
                          fq: getUserLookUpFilter(el.data_type, el.filter, el.id),
                          rows: 10
                        }}
                        testID={`edit-field-inputbase-${el.id}`}
                        // removeFLModify={false}
                        scrollable={true}
                        autoSuggestion={true}
                        searchValue={searchValues[el.id] || ""}
                        setSearchValue={value => setSearchValues({ ...searchValues, [el.id]: value })}
                        placeholder={el.description ? el.description.replace(/\[object\]/g, objectLabel || 'object') : ''}
                        onResultClick={user => setProperties(el.id, user, el.data_type, 'add')}
                        popperDisableportal={true}
                        hideBorder={true}
                      />
                    </div>
                    <Typography className={classes.helperText} style={shouldFocus(values[el.id], el.data_type, el.required) ? { color: theme.palette.error.main } : undefined}>{el.required ? 'Required' : 'Optional'}</Typography>
                    {
                      (values[el.id] && values[el.id].length > 0 && (!el.data_type.includes('MULTI') || Array.isArray(values[el.id])))
                      &&
                      <div className={classes.assigneeChip}>
                        <Typography className={classes.subSectionHeader}>ADDED USERS</Typography>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {
                            (el.data_type.includes('MULTI') ? values[el.id] : [values[el.id]]).map(userId => (
                              <UserChip
                                user={userMap && userMap[userId] ? userMap[userId] : { id: userId, name_txt: userId }}
                                onRemove={() => setProperties(el.id, userMap[userId], el.data_type, 'delete')}
                                variant={'light'}
                                bottomMargin={6}
                              />
                            ))
                          }
                        </div>
                      </div>
                    }
                    {
                      el.name === 'decision maker' && userData && (!values[el.id] || !values[el.id].includes(userData.id)) &&
                      <div>
                        <Typography style={{ fontSize: 12, color: theme.palette.primaryText.light, marginTop: 16, marginBottom: 8 }}>Suggestions</Typography>
                        <UserChip
                          user={userData}
                          onClick={() => setProperties(el.id, userData, el.data_type, 'add')}
                          variant={'light'}
                          bottomMargin={6}
                        />
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {
              ['COLLECTION_LOOKUP_FILTERED', 'COLLECTION_LOOKUP_FILTERED_MULTI'].includes(el.data_type) &&
              <div style={{ flexGrow: 1 }}>
                {
                  values[el.id] &&
                  values[el.id].length > 0 &&
                  (!el.data_type.includes('MULTI') || Array.isArray(values[el.id])) &&
                  collectionMap &&
                  (el.data_type.includes('MULTI') ? values[el.id] : [values[el.id]]).some(i => collectionMap[i]) &&
                  <div style={{ marginBottom: 24 }}>
                    {
                      (el.data_type.includes('MULTI') ? values[el.id] : [values[el.id]]).filter(id => collectionMap[id]).map(id => {
                        let instance = collectionMap[id]
                        return (
                          <SimpleResultItem
                            key={id}
                            title={getDispFields(instance, 'dispTitle')}
                            label={instance.object_type_txt}
                            iconLabel={getIconLabel({ label: 'collection_instance', item: instance })}
                            item={instance}
                            showUnderline
                            keepPropagation
                            tailObject={
                              <IconButton className={classes.iconButton} style={{ marginLeft: 8, padding: 4 }} onClick={() => setProperties(el.id, collectionMap[id], el.data_type, 'delete')}>
                                {getIconComponent({ label: 'clear', size: 24, colour: theme.palette.primaryText.light })}
                              </IconButton>
                            }
                          />
                        )
                      })
                    }
                  </div>
                }
                {
                  collectionFilterLoading &&
                  <CircularProgress color='secondary' />
                }
                {
                  collectionFilterError &&
                  <Typography style={{ color: theme.palette.primaryText.main }}>Error occurred loading filters</Typography>
                }
                {
                  collectionFilterMap &&
                  <div>
                    <div
                      className={classes.inputBase + (shouldFocus(values[el.id], el.data_type, el.required) ? " " + classes.focusBorder : '')}
                      style={{ overflow: 'hidden' }}
                    >
                      {
                        collectionFilterMap[el.filter] && collectionFilterMap[el.filter].childCount === 0 ?
                          <InputBase
                            disabled={true}
                            className={classes.inputBase}
                            classes={{ disabled: classes.disabledInputBase }}
                            inputProps={{
                              style: { color: theme.palette.primaryText.light }
                            }}
                            placeholder={`${collectionFilterMap[el.filter].name}s not configured. Contact your admin`}
                          />
                          :
                          <SearchSelector
                            url='/solr/search/select'
                            params={{
                              q: `${searchValues[el.id] || ""}*`,
                              fq: getCollectionLookupFilter(el.data_type, el.filter, el.id),
                              rows: 10
                            }}
                            testID={`edit-field-inputbase-${el.id}`}
                            removeFLModify={false}
                            scrollable={true}
                            autoSuggestion={true}
                            searchValue={searchValues[el.id] || ""}
                            setSearchValue={value => setSearchValues({ ...searchValues, [el.id]: value })}
                            placeholder={el.description ? el.description.replace(/\[object\]/g, objectLabel || 'object') : ''}
                            onResultClick={user => setProperties(el.id, user, el.data_type, 'add')}
                            popperDisableportal={true}
                            hideBorder={true}
                          />
                      }
                    </div>
                    <Typography className={classes.helperText} style={shouldFocus(values[el.id], el.data_type, el.required) ? { color: theme.palette.error.main } : undefined}>
                      {el.required ? 'Required' : 'Optional'}{el.data_type.includes('MULTI') ? '' : '. Single select property. Selecting a new instance will replace the existing linked instance'}
                    </Typography>
                  </div>
                }
              </div>
            }
            {
              el.data_type === 'DATE' &&
              <div style={{ flexGrow: 1 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    data-test-id={`edit-field-inputbase-${el.id}`}
                    margin="none"
                    className={classes.inputBase + (shouldFocus(values[el.id], el.data_type, el.required) ? " " + classes.focusBorder : '')}
                    emptyLabel={'Select a Date'}
                    invalidLabel={'Select a Date'}
                    value={values[el.id] || ''}
                    onChange={value => setProperties(el.id, value, el.data_type)}
                    error={false}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    DialogProps={{

                    }}
                    invalidDateMessage={''}
                    format='DD - MM - YYYY'
                  />
                </MuiPickersUtilsProvider>
                <Typography className={classes.helperText} style={shouldFocus(values[el.id], el.data_type, el.required) ? { color: theme.palette.error.main } : undefined}>{el.required ? 'Required' : 'Optional'}</Typography>
              </div>
            }
            {
              el.data_type === 'BOOLEAN' &&
              <div style={{ flexGrow: 1 }}>
                <Select
                  className={classes.selector}
                  value={values[el.id] || false}
                  data-test-id={`edit-field-inputbase-${el.id}`}
                  disableUnderline
                  onChange={event => setProperties(el.id, event.target.value, el.data_type)}
                  renderValue={value => value ? 'True' : 'False'}
                >
                  <MenuItem value={true}>
                    True
                  </MenuItem>
                  <MenuItem value={false}>
                    False
                  </MenuItem>
                </Select>
              </div>
            }
            {
              el.data_type === 'COLOUR_SELECTOR' &&
              <div style={{ flexGrow: 1 }}>
                <InputBase
                  value={values[el.id]}
                  className={classes.inputBase + (shouldFocus(values[el.id], el.data_type, el.required) ? " " + classes.focusBorder : '')}
                  onChange={event => {
                    setProperties(el.id, event.target.value, el.data_type)
                  }}
                  placeholder={`Enter a hex colour`}
                  endAdornment={
                    <ColourPickerPopper
                      setValue={(value) => {
                        setProperties(el.id, value, el.data_type)
                      }}
                      value={values[el.id]}
                    />
                  }
                />
                <Typography className={classes.helperText} style={shouldFocus(values[el.id], el.data_type, el.required) ? { color: theme.palette.error.main } : undefined}>
                  {el.required ? 'Required' : 'Optional'}. Must be Hex colour. You can pick colour from palette by clicking <span style={{ position: 'relative', top: 4 }}>{getIconComponent({ label: 'colour_picker', size: 16, colour: theme.palette.primaryText.light })}</span>
                </Typography>
                {
                  values[el.id] && checkIsHexColour(values[el.id]) &&
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                    <div className={classes.colourSampleContainer} style={{ background: lightBackground }}>
                      <div className={classes.colourSampleChip} style={{ background: values[el.id], color: getFontColourByBackground(values[el.id]) }}>
                        Light Mode
                      </div>
                    </div>
                    <div className={classes.colourSampleContainer} style={{ background: darkBackground }}>
                      <div className={classes.colourSampleChip} style={{ background: values[el.id], color: getFontColourByBackground(values[el.id]) }}>
                        Dark Mode
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
      )
    })
  )


  return (
    <div className={classes.root}>
      {inputs}
    </div>
  )
}

Editor.prototype = {
  properties: PropTypes.array.isRequired,
  hideDefaultProperty: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  userMap: PropTypes.object.isRequired,
  setUserMap: PropTypes.func.isRequired,
  collectionMap: PropTypes.object.isRequired,
  setCollectionMap: PropTypes.func.isRequired,
  collection: PropTypes.object.isRequired,
  objectLabel: PropTypes.string,
  object: PropTypes.object,
  isCreatingNew: PropTypes.bool,
  singlePropertyEdit: PropTypes.bool
}

export const EditFields = withTheme()(withStyles(styles)(Editor));

export const checkFilled = (properties, values, ignoreName) => {
  if (!properties) return false;
  if (!ignoreName && (!values['name'] || values['name'].trim() === '')) return false
  for (let i = 0; i < properties.length; i++) {
    if (!properties[i].required) continue;
    let id = properties[i].id;
    let type = properties[i].data_type;
    if (type === 'BOOLEAN') continue;
    if (!values[id]) return false;
    if (
      (['TEXT_BOX', 'TEXT_FIELD', 'FLOAT', 'INTEGER', 'DATE'].includes(type) && values[id].trim() === '') ||
      (['URL'].includes(type) && (values[id].trim() === '' || !validateUrl(values[id]))) ||
      (type === 'DROPDOWN_MULTI' && values[id].length === 0) ||
      (type === 'DROPDOWN_SINGLE' && values[id] === 'placeholder') ||
      (['USER_LOOKUP', 'USER_LOOKUP_FILTERED'].includes(type) && (!values[id] || values[id].trim() === '')) ||
      (['USER_LOOKUP_FILTERED_MULTI', 'USER_LOOKUP_MULTI'].includes(type) && (!values[id] || values[id].length === 0)) ||
      (['COLOUR_SELECTOR'].includes(type) && (!values[id] || !checkIsHexColour(values[id])))
    ) {
      return false;
    }
  }
  return true;
}