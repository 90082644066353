import React, { useState } from 'react';
import { withTheme,withStyles, Typography,} from '@material-ui/core';
import Filters from './Filters';
import axiosSolr from '../../../../axios-solr';
import { contentItems, dataItems, userItems } from '../../utils';
import List from './List';
import { getPartialMatchSearchString, onClickResultItem, toTitleCase } from '../../../../utilities';
import BasicSearch from '../../../../containers/BasicSearch/BasicSearch';
import DetailModal from './DetailModal';

const styles = theme => ({
  title:{
    fontSize:20,
    marginBottom:8,
    textTransform:'uppercase'
  }
})

const Details = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
    selectedTile,
    direction
  } = props;

  const [modalOpen, setModalOpen] = useState(false)

  const data = state.detailData[selectedTile];
  const setData = d => dispatch({type:'set_detail_data',detailData:{...state.detailData,[selectedTile]:d}})
  const filterData = state.detailFilterData[selectedTile] || {};
  const setFilterData = d => dispatch({type:'set_detail_filter_data',detailFilterData:{...state.detailFilterData,[selectedTile]:d}})
  const filterValueData = state.detailFilterValueData[selectedTile] || {};
  const setFilterValueData = d => dispatch({type:'set_detail_filter_value_data',detailFilterValueData:{...state.detailFilterValueData,[selectedTile]:d}})

  let filters = [];
  switch(selectedTile){
    case 'all':
      filters = [];
      break;
    case 'data':
    case 'content':
      filters = [
        {name:'Usage',field:'usage_srt'},
        {name:'Owner',field:'owners_msrt'}
      ]
      break;
    case 'people':
      filters = [
        {name:'Team',field:'teams_msrt'},
      ]
      break;
    default:
      filters = [
        {name:'Team',field:'teams_msrt'},
        {name:direction==='upstream'?'Identified Data Assets':'Impacted Data Assets',field:'data_impact_count_srt'},
        {name:direction==='upstream'?'Identified Content':'Impacted Content',field:'content_impact_count_srt'},
      ]
  }

  let columns = [];
  switch(selectedTile){
    case 'all':
      columns = [
        {name:'Name',field:'name_srt'},
        // {name:'Type',field:'object_type_srt'},
        {name:direction==='upstream'?'Identified Data Assets':'Impacted Data Assets',field:'data_impact_count_srt'},
        {name:direction==='upstream'?'Identified Content':'Impacted Content',field:'content_impact_count_srt'},
        {name:direction==='upstream'?'# of Users upstream':'# of Users Impacted',field:'user_impact_count_srt'},
        {name:direction==='upstream'?'# of Teams upstream':'# of Teams Impacted',field:'team_impact_count_srt'},
        {name:direction==='upstream'?'# of Owners upstream':'# of Owners Impacted',field:'owner_impact_count_srt'},
      ]
      break;
    case 'data':
    case 'content':
      columns = [
        {name:'Name',field:'name_srt'},
        {name:direction==='upstream'?'# of Users upstream':'# of Users Impacted',field:'users_msrt'},
        {name:direction==='upstream'?'# of Teams upstream':'# of Teams Impacted',field:'teams_msrt'},
        {name:'Usage',field:'usage_srt'},
        {name:'Owner',field:'owners_msrt'}
      ]
      break;
    case 'people':
      columns = [
        {name:'Name',field:'name_srt'},
        {name:'Team',field:'teams_msrt'},
        {name:direction==='upstream'?'Identified Data Assets':'Impacted Data Assets',field:'data_impact_count_srt'},
        {name:direction==='upstream'?'Identified Content':'Impacted Content',field:'content_impact_count_srt'},
      ]
      break;
    default:
      columns = [
        {name:'Name',field:'name_srt'},
        {name:direction==='upstream'?'Identified Data Assets':'Impacted Data Assets',field:'data_impact_count_srt'},
        {name:direction==='upstream'?'Identified Content':'Impacted Content',field:'content_impact_count_srt'},
        {name:direction==='upstream'?'# of Users upstream':'Impacted Users',field:'user_impact_count_srt'}
      ]
  }

  const getSearchFilter = () => {
    let fq = [];
    fq.push(`extract_id:${state.extractData.id}`)
    if(selectedTile!=='people' && !state.showReference)fq.push(`-reference_srt:YES`)
    if(selectedTile==='data'){
      fq.push(`object_type_srt:${state.resultDataObjectType}`)
    }
    else if(selectedTile==='content'){
      fq.push(`object_type_srt:${state.resultContentObjectType}`)
    }
    else if(selectedTile==='people'){
      fq.push(`object_type_srt:${userItems.join(',')}`)
      fq.push(`user_type_srt:${state.resultUserType}`)
    }else{
      fq.push(`object_type_srt:COLLECTION_INSTANCE`)
      fq.push(`parent_id_srt:${selectedTile}`)
      let collectionName = state.summaryData[selectedTile].name
      fq.push(`-object_id:("other ${collectionName.toLowerCase()}" OR "no ${collectionName.toLowerCase()}")`)
    }
    if(state.historyObject){
      fq.push(`starting_object_id:${state.historyObject.id}`)
    }else{
      fq.push(`starting_object_id:NO STARTING_OBJECT_ID`)
    }
    return fq.join(' AND ')
  }

  const onLoadData = ({filterPyload=filterData, start=0, showReference=state.showReference}) => {
    setData({...(start===0 || !data?{}:data), loading:true})
    let fq = [];
    let q = '*';
    let facet;
    let validStartingIDs = [];
    if(filterPyload.search){
      if(selectedTile!=='all'){
        fq.push(`name_srt:(${getPartialMatchSearchString(filterPyload.search.trim())})`)
      }
    }
    fq.push(`extract_id:${state.extractData.id}`)
    if(selectedTile!=='people' && selectedTile!=='all' && !showReference)fq.push(`-reference_srt:YES`)
    if(selectedTile==='all'){
      if(filterPyload.search?.trim()){
        validStartingIDs = state.multiHistoryObjects.filter(el=>el.name_txt.toLowerCase().includes(filterPyload.search.toLowerCase().trim())).map(el=>el.id)
        fq.push(`starting_object_id:(${validStartingIDs.join(' OR ')})`)
      }else{
        validStartingIDs = state.multiHistoryObjects.map(el=>el.id)
        fq.push(`starting_object_id:(${validStartingIDs.join(' OR ')})`)
      }
      let countFacet = {
        'data_count':{
          type:'query',
          q:`object_type_srt:(${dataItems.join(' OR ')})` + (showReference?'':' AND -reference_srt:YES'),
        },
        'content_count':{
          type:'query',
          q:`object_type_srt:(${contentItems.join(' OR ')})` + (showReference?'':' AND -reference_srt:YES'),
        },
        'user_count':{
          type:'query',
          q:`object_type_srt:(USER)`,
        },
        'team_count':{
          type:'query',
          q:`object_type_srt:(TEAM)`,
        },
        'owners':{
          type:'terms',
          field:'owners_msrt',
          limit:500,
          mincount:1,
        }
      }
      facet = {
        'start_id':{
          type:"terms",
          field:"starting_object_id",
          limit:10,
          sort:'count desc',
          offset:start,
          facet:countFacet
        }
      };
    }
    else if(selectedTile==='data'){
      fq.push(`object_type_srt:(${dataItems.join(' OR ')})`)
    }
    else if(selectedTile==='content'){
      fq.push(`object_type_srt:(${contentItems.join(' OR ')})`)
    }
    else if(selectedTile==='people'){
      fq.push(`object_type_srt:(${userItems.join(' OR ')})`)
    }
    else{
      fq.push(`object_type_srt:COLLECTION_INSTANCE`)
      fq.push(`parent_id_srt:${selectedTile}`)
      let collectionName = state.summaryData[selectedTile].name
      fq.push(`-object_id:("other ${collectionName.toLowerCase()}" OR "no ${collectionName.toLowerCase()}")`)
    } 
    if(state.historyObject){
      fq.push(`starting_object_id:${state.historyObject.id}`)
    }

    Object.keys(filterPyload).forEach(k=>{
      if(k==='search')return;
      let value = filterPyload[k];
      if(value==='all')return;
      if(value==='No Team' && k==='teams_msrt'){
        fq.push(`-teams_msrt:[* TO *]`)
      }else{
        fq.push(`${k}:"${value}"`)
      }
    })

    axiosSolr
      .post(
        `/solr/impact/select`,{
          params:{
            q,
            "mm": '2<-1',
            fq:fq.join(' AND '),
            rows:selectedTile==='all'?0:10,
            start:selectedTile==='all'?0:start,
            sort:'name_srt asc',
            "qf": "name_srt name_srt",
            "pf": "name_srt",
            "bf": "",
            'json.facet':facet?JSON.stringify(facet):undefined,
          }
        }
      )
      .then(response=>{
        if(selectedTile==='all'){
          let numFound = validStartingIDs.filter(el=>response.data.facets.start_id.buckets.find(b=>b.val===el)).length;
          let docs = [];
          let facet = response.data.facets.start_id.buckets;
          facet.forEach(f=>{
            let dataCount = f.data_count?.count || 0;
            let contentCount = f.content_count?.count || 0;
            let userCount = f.user_count?.count || 0;
            let teamCount = f.team_count?.count || 0;
            let ownerCount = f.owners?.buckets?.length || 0;
            let data = {
              id:f.val,
              object_id:f.val,
              name:state.multiHistoryObjects.find(el=>el.id===f.val)?.name_txt || 'Unknown',
              ...(state.multiHistoryObjects.find(el=>el.id===f.val)||{}),
              data_impact_count_srt:dataCount,
              content_impact_count_srt:contentCount,
              user_impact_count_srt:userCount,
              team_impact_count_srt:teamCount,
              owner_impact_count_srt:ownerCount,
            }
            docs.push(data)
          })
          if(start===0){
            setData({numFound,docs,start})
            return;
          }
          setData({numFound,docs:[...(data?.docs||[]),...docs],start})
          return;
        }
        setData({
          ...response.data.response,
          docs:[
            ...(start!==0?data.docs:[]),
            ...response.data.response.docs
          ]
        })
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  }

  let name = state.summaryData[selectedTile].name;
  if(!['data','content'].includes(selectedTile))name+='(s)'
  if(selectedTile==='all')name = 'asset(s)'
  if(selectedTile==='data')name = 'data asset(s)'
  
  let searchKey = selectedTile+state.showReference;
  if(['content','data'].includes(selectedTile)){
    searchKey += state[`result${toTitleCase(selectedTile)}ObjectType`]
  }
  if(selectedTile==='people'){
    searchKey += state.resultUserType
  }

  return (
    <div className={classes.root}>
      <div style={{background:theme.palette.background.main,position:'sticky',top:108,paddingBottom:12}}>
        {
          selectedTile==='all'?
          <Typography className={classes.title} data-test-id="detail-header">
            ALL {direction==='upstream'?'DEPENDENCIES':'IMPACTS'}
          </Typography>
          :
          <Typography className={classes.title} data-test-id="detail-header">
            {data?(data.numFound || data.resultsTotal || ''):state.summaryData[selectedTile].count} {direction.toUpperCase()} {direction==='upstream'?'DEPENDENT':'IMPACTED'} {name}
          </Typography>
        }
        {
          selectedTile==='all' &&
          <Filters
            extractID={state.extractData.id}
            filters={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            onLoadData={onLoadData}
            filterValueData={filterValueData}
            setFilterValueData={setFilterValueData}
            selectedTile={selectedTile}
          />
        }
      </div>
      {
        selectedTile==='all'?
        <List
          history={history}
          state={state}
          key={selectedTile+state.showReference}
          columns={columns}
          data={data}
          setData={setData}
          onLoadData={onLoadData}
          selectedTile={selectedTile}
          direction={direction}
        />
        :
        <BasicSearch
          key={searchKey}
          customID={'IA-detail'}
          initialView={'main_search'}
          indexName={'impact'}
          history={history} 
          alwaysOpenNewTab={true}
          removeContainerStyle={true}
          propQuery={'*'} 
          propSelectedFilters={filters.map(el=>el.field).join(',')}
          propFilter={getSearchFilter()}
          propColumn={columns.map(el=>el.field).filter(el=>el!=='name_srt').join(',')}
          resultItemVariant={"simplified"}
          hiddenComponents={['columnSelector','filterEdit','listTitle','searchBar','header','tab','cartButton','downloadButton']}
          customEmptyMsg={'No items found'}
          propSort={'name_srt asc'}
          propCache={data}
          propStoreCache={setData}
          hideEmptyFilterValue={true}
          forceOnItemClick={d=>{
            if(!['data','content','people'].includes(selectedTile)){
              let item = {...d,id:d.object_id};
              onClickResultItem({item, id:item.id, label:item.object_type_srt,newWindow:true})
              return;
            };
            setModalOpen({object:d})
          }}
        />
      }

      <DetailModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        object={modalOpen?.object}
        state={state}
      />
    </div>  
  )
}

export default withTheme()(withStyles(styles)(Details));