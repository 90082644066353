import React, {useState} from 'react';
import { withTheme, withStyles, Divider, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { toTitleCase, getIconComponent, processCollection, getUserRoles, getItemChildType, formatBusinessnName } from '../../../utilities';
// import PartialBar from '../../UI/PartialBar/PartialBar';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../ContextMenu/ContextMenu'
import ResultItemEditor from './ResultItemEditor';
import {useStore} from 'react-redux'
import { addBadgeToIcon, getDomainText, getIconLabel} from './utils'
import BlockBar from '../PartialBar/BlockBar';
import { removeMarkDown } from '../InteractiveInput/Templates';
import KTooltip from '../KTooltip/KTooltip';


const styles = theme => ({
  tag: {
    height: 15,
    paddingTop:1,
    letterSpacing:1,
    fontWeight:400,
    maxWidth:300,
    // minWidth:30,
    flexShrink: 0,
    fontSize: 12,
    padding: '0 6px',
    marginTop:6,
    marginRight: 6,
    overflow: 'hidden !important',
    whiteSpace: 'nowrap !important',
    textOverflow: 'ellipsis !important',
  },
  columnRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.hovered.main,
      '& #description_inline_edit':{
        display:'inline-block'
      }
    },
    overflow:'hidden',
    width:'100%'
  },
  columnTitle: {
    color: theme.palette.header.main,
    fontSize: 16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  columnType: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    marginLeft: 16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  contentBodyText: {
    // fontWeight: '300',
    width:'max-content',
    maxWidth:'100%',
    color: theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    display:'flex',
    alignItems:'center',
  },
  clickableText:{
    '&:hover':{
      // textDecoration:'underline',

    },
  },
  frequencyBar: {
    flex:'0 0',
    marginRight:48,
    marginBottom:-16,
  },
  block: {
    marginLeft: '20rem',
    marginTop: '1rem'
  },
  selector: {
    width: 150,
    '& div div': {
      paddingLeft: 16,
      fontSize:16
    }
  },
  descriptionEditIcon:{
    marginLeft:4,
    padding:1,
    display:'none',
    "&:hover":{
      background:`${theme.palette.primaryText.light}50`
    }
  }
})

const ellipseStr = str => {
  if (str.length > 16) return str.slice(0, 16) + '...'
  return str
}


function ResultItem(props) {

  let {
    classes,
    theme,
    item,
    frequency,
    maxFrequency,
    dispTitle,
    onClick,
    enableEdit,
    onDescriptionUpdated
  } = props;

  
  const [editModalOpen, setEditModalOpen] = useState(false)

  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)

  if(roles.every(r=>r==='90')){
    enableEdit = false;
  }

  if(!item)return <></>

  let labels = item.labels || '';
  if(item.object_type_txt)labels = item.object_type_txt.toLowerCase()

  let isShell;
  if(item)isShell = ['table','schema','column','database','tool'].includes(labels) && (item.reference_txt==='YES');

  let description = removeMarkDown(item.description);
  let finalDescription = description;
  if(isShell && (!description || description.trim()==='')){
    if(labels!=='tool'){
      finalDescription=`This ${toTitleCase(labels)} is referenced in a Source metadata. It is not integrated to K`
    }else{
      finalDescription=`This Tool is referenced in content metadata. It is not integrated to K`
    }
  }else{
    if(!finalDescription || finalDescription==='')finalDescription = 'No description'
  }

  let shownTitle = dispTitle
  if(['column','table','data_quality_test'].includes(labels)){
    shownTitle = formatBusinessnName({roles, dispTitle:shownTitle, item, isBoldText:true})
  }
  if(labels==='code' && item.code_type_txt==='QUERY' && item.alternate_name_txt)shownTitle = formatBusinessnName({roles, dispTitle:shownTitle, item, isBoldText:true})
  if(shownTitle===undefined)shownTitle = 'Untitled'
  
  return ( 
    <div>
      <ContextMenuTrigger id={item.id}>
        <div className={classes.columnRow} onClick={onClick}  data-testid={`UsageListItem-${item.id}`}>
          <div style={{display:'flex',alignItems:'center',overflow:'hidden'}}>
            <div style={{ marginLeft:16, marginRight: 16,flex:'0 0 24px',marginTop:-6 }}>
              {
                item && 
                addBadgeToIcon({
                  icon: getIconComponent({label:getIconLabel({label:labels,item}) ,size:24,colour:isShell?theme.palette.primaryText.light:theme.palette.primary.main}),
                  modification_badge: item.modification_badge_txt,
                  active: item.active_txt || item.active_flag,
                })
              }
            </div>
            <div style={{marginTop:16,flexGrow:1,overflow:'hidden',marginRight:16}}>
              <div style={{ marginBottom:6,display:'flex',alignItems:'center',overflow:'hidden'}}>
                <KTooltip title={shownTitle}>
                  <Typography data-test-classname="usage-item-title" className={classes.columnTitle}>
                    {shownTitle}
                  </Typography>
                </KTooltip>
                {item.column_type_txt && <Typography className={classes.columnType}>{item.column_type_txt}</Typography>}
                {(item.object_subtype_txt || item.data_type_txt) && item.object_type_txt==='DATASET_FIELD' && <Typography className={classes.columnType}>{item.data_type_txt || item.object_subtype_txt}</Typography>}
              </div>
              <div style={{ marginBottom: 6}}>
                <Typography 
                  data-test-classname="usage-item-description"
                  className={classes.contentBodyText + (!isShell && enableEdit?' '+classes.clickableText:'')} 
                >
                  <KTooltip title={finalDescription}>
                    <span style={{flexShrink:1,overflow:'hidden',textOverflow:'ellipsis'}}>
                      {finalDescription}
                    </span>
                  </KTooltip>
                  {
                    !isShell && enableEdit && 
                    <IconButton 
                      data-test-classname="usage-item-inline-edit"
                      id="description_inline_edit" 
                      className={classes.descriptionEditIcon}
                      onClick={event=>{
                        if(isShell || !enableEdit)return;
                        event.stopPropagation();
                        setEditModalOpen(true);
                      }}
                    >
                      {getIconComponent({label:'edit',size:16,colour:theme.palette.primaryText.light})}
                    </IconButton> 
                  }
                </Typography>
              </div>
            </div>
            <div className={classes.frequencyBar}>
              {
                item && (item.active_txt!=='NO' && item.active_flag!==false) && !isShell && 
                <BlockBar
                  value={frequency}
                  total={maxFrequency}
                  width={132}
                  numOfBlocks={5}
                  tooltip={`Comparative usage between ${toTitleCase(labels.replace(/_/g,' '))}`}
                />
              }
            </div>
          </div>
          <div style={{ overflow:'hidden', display: 'flex',marginLeft:56,marginRight:26,paddingBottom:13, marginTop:6,flexWrap:'wrap' }}>
            {
              ['DATABASE','SCHEMA','TABLE','CONTENT_APP','WORKSPACE','SOURCE','REPORT','DATASET','DATASET_TABLE','DATA_PIPELINE'].includes(item.object_type_txt) && item.child_object_count_srt ?
              <div className={classes.tag} style={{ backgroundColor: theme.palette.primary.dark,color:theme.palette.background.main }}>
                {item.child_object_count_srt} {getItemChildType(item, true).toUpperCase()}(S)
              </div>
              :<></>
            }
            {
              item && 
              processCollection({category_kc_txts:item.category_kc_txts},'manual','resultItem','solr').tags.map(el => (
                <div className={classes.tag} style={{ backgroundColor: el.colour,color:el.fontColour }}>
                  {el.name}
                </div>
              ))
            }
            {
              labels==='column' && item && item.primary_key_txt==='PRIMARY_KEY' && 
              <div className={classes.tag} style={{ backgroundColor: '#b8d2ff' }}>PRIMARY KEY</div>
            }
            {
              labels==='column' && item && item.foreign_key_txt==='FOREIGN_KEY' && 
              <div className={classes.tag} style={{ backgroundColor: '#b8d2ff' }}>FOREIGN KEY</div>
            }
            {
              item && !isNaN(item.number_of_dq_tests_srt) && item.number_of_dq_tests_srt>0 && item.object_type_txt!=='DATA_QUALITY_TEST' &&
              <div className={classes.tag} style={{ backgroundColor: '#00D46A' }}>DQ TESTED</div>
            }
            {
              item && item.data_quality_failed_txt==='YES' && 
              <div className={classes.tag} style={{ backgroundColor: '#F44137',color:'#fff' }}>DQ TEST FAILED</div>
            }
            {
              item && item.domain_kc_txts &&
              <div className={classes.tag} style={{  backgroundColor: '#00D46A' }}>DOMAIN:{` ${getDomainText(item.domain_kc_txts)}`}</div>
            }
            {
              item.verified_kc_txts &&
              <div className={classes.tag} style={{ display: 'flex', backgroundColor: '#00D46A' }}>{'VERIFIED'}</div>
            }
            {
              isShell &&
              <div className={classes.tag} style={{ display: 'flex', backgroundColor: '#E6F0F2' }}>REFERENCE</div>
            }
            {
              item && item.masked_txt==='YES' && 
              <div className={classes.tag} style={{ backgroundColor: '#D8D8D8' }}>MASKED</div>
            }
            {
              item && item.table_type_txt && item.table_type_txt!=='TABLE' &&
              <div className={classes.tag} style={{ display: 'flex', backgroundColor: '#b8d2ff' }}>{item.table_type_txt}</div>
            }
            {
              item && 
              processCollection(item,'auto','resultItem','solr').tags.slice(0, 4).map(el => (
                <div className={classes.tag} style={{  backgroundColor: el.colour,color:el.fontColour  }}>
                  {el.name}
                </div>
              ))
            }
            { 
              item && processCollection(item,'auto','resultItem','solr').tags.length > 4 && 
              <div className={classes.tag} style={{ backgroundColor: '#FFAB00' }}>
                +{processCollection(item,'auto','resultItem','solr').tags.length - 4}
              </div>
            }
            {
              item && 
              processCollection({...item,category_kc_txts:undefined},'manual','resultItem','solr').tags.slice(0, 4).map(el => (
                <div className={classes.tag} style={{ backgroundColor: el.colour,color:el.fontColour }}>
                  {el.name}
                </div>
              ))
            }
            { 
              item && processCollection({...item,category_kc_txts:undefined},'manual','resultItem','solr').tags.length > 4 && 
              <div className={classes.tag} style={{ backgroundColor: '#FC642D',color:'#FFF' }}>
                +{processCollection({...item,category_kc_txts:undefined},'manual','resultItem','solr').tags.length - 4}
              </div>
            }
            {
              item && item.tags_txts &&
              item.tags_txts.sort().slice(0, 4).map(el => (
                <div className={classes.tag} style={{ backgroundColor: theme.palette.avatar.main, color: '#FFF' }}>
                  {ellipseStr(el)}
                </div>
              ))
            }
            {
              item && item.tags_txts && item.tags_txts.length > 4 &&
              <div className={classes.tag} style={{ backgroundColor: theme.palette.avatar.main, color: '#FFF' }}>
                +{item.tags_txts.length - 4}
              </div>
            }
          </div>
          
          <Divider style={{background:theme.palette.listItemDivider.main}} />
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={item.id}>
        <CustomMenu
          item={item}
          actions={[
            'open_new_tab',
            'bookmark'
          ]}
        />
      </ContextMenu>

      {
        enableEdit && 
        <ResultItemEditor
          item={item}
          dispTitle={dispTitle}
          setDescription={value=>{
            onDescriptionUpdated({item,description:value})
          }}
          setModalOpen={setEditModalOpen}
          modalOpen={editModalOpen}
        />
      }
     
    </div>
  );

}


export default withTheme()(withStyles(styles)(ResultItem));