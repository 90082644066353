import React, { useState, useRef, useEffect } from 'react';
import { Typography, withStyles, Select, Button,  CircularProgress, InputBase, MenuItem } from '@material-ui/core';
import theme from '../../../../theme';
import axiosSolr from '../../../../axios-solr'
import {getSearchQuery, processObjectType } from '../Utils/Utils';
// import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';
import { capitalizeFirstWord } from '../../../../utilities';

const styles = theme => ({
  selector:{
    ...theme.components.selector,
    height:36,
    background:`${theme.palette.background.main} !important`,      
    '& div div': {
      paddingLeft: 12,
      paddingTop:6,
      paddingBottom:6,
      fontSize:16,
      background:`${theme.palette.background.main} !important`,      
    },
  },
  rangeTypeSelector:{
    ...theme.components.selector,
    height:24,
    borderRadius:18,
    background:`${theme.palette.primary.dark} !important`,
    color:theme.palette.background.main,
    '& svg':{
      color:theme.palette.background.main
    },
    '& div div': {
      fontSize:12,  
      textTransform:"uppercase",  
      padding:'0px 28px 0px 12px'
    },
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main} !important`,
  },
  selectorRoot:{
    display:'flex'
  },
  inputBase:{
    ...theme.components.inputBase,
    height:46,
    boxSizing:'border-box',
    '& input':{
      padding:'0px 12px'
    }
  },
  selectMenu:{
    width:380,
  },
  menu:{
    // marginTop:90,
    marginTop:58,
    '& ul':{
      paddingTop:0,
      minWidth:380,
      display:'flex',
      flexDirection:'column',
      maxHeight:365,
      maxWidth:380,
    },
  },
  menuItem:{
    display:'block',
    padding:'0px 16px',
    minHeight:51,
    color:theme.palette.primaryText.main,
    '&:hover':{
      background: theme.palette.hovered.main 
    },
  },
  checkbox:{
    paddingLeft:0
  },
  valueList:{
    flexGrow:1,
    overflow:'auto',
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  ellipsisText:{
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    overflow:'hidden'
  },
  selectedMenuItem:{
    backgroundColor: `${theme.palette.background.main} !important`,
    '&:hover':{
      background:`${theme.palette.hovered.main} !important` 
    },
  },
  negativeToggle:{
    width:84,
    height:24,
    borderRadius:2,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:13,
    border:`1px solid ${theme.palette.primary.main}`,
    cursor:'pointer',
    color:theme.palette.primary.main
  },
  typeIcon:{
    width:24,
    height:24,
    borderRadius:12,
    display:'flex',
    alignItems:"center",
    justifyContent:'center',
    backgroundColor:theme.palette.chip.main,
    border:`1px solid ${theme.palette.chipBorder.main}`,
    color:theme.palette.primaryText.main,
    fontSize:13
  }
});



const RangeFilter = props => {

  const {
    classes,
    state,
    dispatch,
    fieldName,
    name,
    type,
    onUpdateFilter,
    width,
    bodyOnly,
    getDefaultParams,
    indexName='search',
    forceGlobalFilterStr
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const changeTimeout = useRef(null);

  const data = state.filterStatus[fieldName];
  const setData = data => dispatch({type:'set_filter_status',filterStatus:{...state.filterStatus,[fieldName]:data}})

  const loadFilterDetail = async ({
    selected,
    searchStr,
    allFilterFq,
  }) => {
    // get min and max value using facet
    let facetObj = {
      "max":`max(${fieldName})`,
      "min":`min(${fieldName})`
    }
          
    let allFilterResponse = await axiosSolr
      .post(
        `/solr/${indexName}/select`,{
          params:{
            q:getSearchQuery({queryInput:searchStr, searchMode:state.searchMode}),
            ...getDefaultParams(),
            fq:allFilterFq,
            rows:0,
          },
          facet:facetObj
        }
      )
    let min = allFilterResponse.data.facets.min;
    let max = allFilterResponse.data.facets.max;
    
    setData({
      allLoaded:true,
      values:[min,max],
      selected:selected?.length>0?selected:['between',min,max]
    })
  }

  const loadFilterData = async ({ 
    objectType=state.selectedObjectType,
    searchStr=state.mainSearchQuery,
    mainSearchFilters=state.mainSearchFilters, 
    tabs=state.searchTabs, 
    currentData=data
  }) => {
    let selected = currentData?.selected||[];
    let isCollection = tabs && tabs.find(el=>el.objectType===objectType).isCollection;
    let isCollectionType = tabs && tabs.find(el=>el.objectType===objectType).isCollectionType;
    let isCodeType = tabs && tabs.find(el=>el.objectType===objectType).isCodeType;
    let collectionType;
    if(isCollection){
      collectionType = tabs && tabs.find(el=>el.objectType===objectType).collectionType;
    }
    if(isCollectionType){
      collectionType = tabs && processObjectType(tabs.find(el=>el.objectType===objectType).objectType);
    }
    setData({
      ...currentData,
      loading:true,
      selected
    })
    let fq = '';
    if(indexName==='search'){
      fq = `object_type_srt:${processObjectType(objectType)}`;
      if(objectType==='ALL'){
        fq = `object_type_srt:*`
      }
      if(isCollectionType){
        fq = `collection_type_srt:${processObjectType(objectType)} AND object_type_srt:COLLECTION_INSTANCE`
      }
      if(isCollection){
        fq = `collection_srt:"${processObjectType(objectType)}" AND collection_type_srt:"${collectionType}"  AND object_type_srt:COLLECTION_INSTANCE`
      }
      if(isCodeType){
        fq = `code_type_srt:"${objectType}"  AND object_type_srt:CODE`
      }
    }
    // let fq = generateSearchFq({tabs, objectType, filters: mainSearchFilters, indexName})
    if(forceGlobalFilterStr){
      fq += `${fq?' AND ':''}${forceGlobalFilterStr}`
    }
    let allFilterFq = fq;

    let filters = mainSearchFilters.filter(el=>el.match(new RegExp(`\\b[-(]?${fieldName}:`))===null)
    if(filters.length>0)allFilterFq += `${fq?' AND ':''}${filters.join(' AND ')}`;
    
    try{
      await loadFilterDetail({selected, searchStr, allFilterFq })
    }catch(error){
      console.log(error)
      setData({
        error:true
      })
    }
  }
  
  const onClear = () => {
    if(!data)return;
    if(data.selected.length===0)return;
    onUpdateFilter({updatedFieldName: fieldName,updatedSelected:['between',data.values[0],data.values[1]]});
  }

  const onChangeRangeType = type => {
    if(type===data.selected[0])return;
    let newData = {
      ...data
    }
    if(type==='between'){
      newData.selected = [type,data.values[0],data.values[1]]
    }else{
      newData.selected = [type,data.selected[0]==='between'?undefined:data.selected[1]]
    }
    setData(newData)
    onUpdateFilter({
      updatedFieldName: fieldName,
      updatedSelected: newData.selected
    })
  }

  const onChangeRangeValue = (values) => {
    let newData = {
      ...data,
      selected:values
    }
    setData(newData)
    clearTimeout(changeTimeout.current);
    if(values[0]==='between' && (!checkValueValid(values[1]) || !checkValueValid(values[2])))return;
    else{
      if(!checkValueValid(values[1]))return;
    }
    let newValues = [...values]
    changeTimeout.current = setTimeout(()=>{
      onUpdateFilter({
        updatedFieldName: fieldName,
        updatedSelected: newValues
      })
    },350)
  }

  // useEffect(()=>{
  //   if(data && data.loading)return;
  //   if(!data || !data.values)loadFilterData({})
  // // eslint-disable-next-line
  // },[data])

  useEffect(()=>{
    if(data && data.loading)return;
    loadFilterData({})
  // eslint-disable-next-line
  },[])

  const checkValueValid = value => {
    value = value+'';
    if(!value?.match)return false;
    // value must be a number, can be negative
    return Boolean(value.match(/^-?\d+$/)).valueOf()
  }

  const getTypeIcon = type => {
    let symbol;
    if(type==='between'){
      symbol = '≬'
    }
    if(type==='greater'){
      symbol = '>'
    }
    if(type==='greater or equal to'){
      symbol = '≥'
    }
    if(type==='less'){
      symbol = '<'
    }
    if(type==='less or equal to'){
      symbol = '≤'
    }
    return (
      <div className={classes.typeIcon} style={{fontSize:type==='between'?20:undefined}}>
        {symbol}
      </div>
    )
  }
  
  let isSelected = data && data.selected && data.selected.length>0
  
  let options = [
    {dispValue:"Between",value:"between"},
    {dispValue:"Greater than",value:"greater"},
    {dispValue:"Greater or equal to",value:"greater or equal to"},
    {dispValue:"Less than",value:"less"},
    {dispValue:"Less or equal to",value:"less or equal to"},
  ]

  let dropdownBody = (
    <div style={{display:'flex',flexDirection:'column',overflow:'auto'}}>
      {
        data && data.selected && 
        <div className={classes.valueList}>
          {
            <div style={{display:'flex',alignItems:'center',marginTop:16,marginBottom:12,paddingLeft:16,paddingRight:16}}>
              <Typography color='primary' style={{fontSize:13.75,letterSpacing:2,marginRight:4}}>FILTER BY:</Typography>
              <Select
                value={data.selected[0]}
                className={classes.rangeTypeSelector}
                disableUnderline
                renderValue={value=>{
                  return options.find(el=>el.value===value).dispValue
                }}
                onChange={e=>{
                  onChangeRangeType(e.target.value)
                }}
              >
                {
                  options.map(el=>(
                    <MenuItem value={el.value}>
                      <span style={{flexGrow:1,marginRight:16}}>{el.dispValue}</span>
                      {getTypeIcon(el.value)}
                    </MenuItem>
                  ))
                }
              </Select>
              <div style={{flexGrow:1}}></div>
              <Button color='secondary' onClick={onClear} style={{fontSize:12,padding:0,minWidth:0}}>RESET</Button>
            </div>
          }
          {
            data.error && <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginTop:16, paddingLeft:24}}>Error occurred loading values</Typography>
          }
          {
            data.values && !data.loading && !data.error &&
            <div style={{width:'100%',padding:'0px 16px',boxSizing:"border-box" }}>
              {
                data.selected[0]==='between' &&
                <>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <InputBase
                      value={data.selected[1]}
                      className={classes.inputBase + (checkValueValid(data.selected[1])?'':' '+classes.errorBorder)}
                      // style={{flexGrow:bodyOnly?1:undefined}}
                      style={{flexGrow:1}}
                      placeholder='From: Enter a number'
                      onChange={e=>{
                        let value = e.target.value;
                        onChangeRangeValue([data.selected[0],value,data.selected[2]])
                      }}
                    />
                    <Typography style={{marginLeft:16,marginRight:16}}>TO</Typography>
                    <InputBase
                      value={data.selected[2]}
                      className={classes.inputBase + (checkValueValid(data.selected[2])?'':' '+classes.errorBorder)}
                      // style={{flexGrow:bodyOnly?1:undefined}}
                      style={{flexGrow:1}}
                      placeholder='To: Enter a number'
                      onChange={e=>{
                        let value = e.target.value;
                        onChangeRangeValue([data.selected[0],data.selected[1],value])
                      }}
                    />
                  </div>
                  {/* <Slider
                    range
                    max={data.values[1]}
                    min={data.values[0]}
                    step={1}
                    defaultValue={[data.values[0],data.values[1]]}
                    onChangeComplete={value=>{
                      setData({
                        ...data,
                        selected:value
                      })
                    }}
                  /> */}
                </>
              }
              {
                ['greater','greater or equal to'].includes(data.selected[0]) &&
                <>
                  <InputBase
                    value={data.selected[1]}
                    className={classes.inputBase + (checkValueValid(data.selected[1])?'':' '+classes.errorBorder)}
                    // style={{width:bodyOnly?'100%':undefined}}
                    style={{width:'100%'}}
                    placeholder={`${capitalizeFirstWord(data.selected[0])}${data.selected[0]==='greater'?' than':''}: Enter a number`}
                    onChange={e=>{
                      let value = e.target.value;
                      onChangeRangeValue([data.selected[0],value])
                    }}
                  />
                </>
              }
              {
                ['less','less or equal to'].includes(data.selected[0]) &&
                <>
                  <InputBase
                    value={data.selected[1]}
                    className={classes.inputBase + (checkValueValid(data.selected[1])?'':' '+classes.errorBorder)}
                    // style={{width:bodyOnly?'100%':undefined}}
                    style={{width:'100%'}}
                    placeholder={`${capitalizeFirstWord(data.selected[0])}${data.selected[0]==='less'?' than':''}: Enter a number`}
                    onChange={e=>{
                      let value = e.target.value;
                      onChangeRangeValue([data.selected[0],value])
                    }}
                  />
                </>
              }
            </div>
          }
          <div style={{textAlign:'center',marginBottom:16,marginTop:8}}>
            {
              (data.loading) && 
              <CircularProgress color='secondary'/>
            }
          </div>
        </div>
      }
    </div>
  )

  if(bodyOnly)return dropdownBody;
  
  return (
    <div style={{width, marginBottom:6, marginRight:24,}}>
      <Typography style={{marginBottom:-8,marginLeft:8,position:'relative',zIndex:20, width:'max-content', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', maxWidth:width-24, background:theme.palette.background.main, color:isSelected?theme.palette.primary.main:theme.palette.primaryText.light,padding:'0 6px'}}> 
        {`${name}`}
      </Typography>
      <Select
        data-test-classname="search-filter"
        data-test-id={`search-filter-${name.toLowerCase()}`}
        className={classes.selector}
        style={{width:width-4, borderColor:isSelected?theme.palette.primary.main:theme.palette.primaryText.light}}
        value={['all']}
        multiple
        classes={{selectMenu:classes.selectMenu,root:classes.selectorRoot}}
        renderValue={()=>{
          let selected = data?.selected;
          let rangeType = selected?.[0]?.toLowerCase();
          if(rangeType==='between'){
            return `${selected[1]} - ${selected[2]}`
          }
          if(rangeType==='greater'){
            return `> ${selected[1]??''}`
          }
          if(rangeType==='greater or equal to'){
            return `≥ ${selected[1]??''}`
          }
          if(rangeType==='less'){
            return `< ${selected[1]??''}`
          }
          if(rangeType==='less or equal to'){
            return `≤ ${selected[1]??''}`
          }
        }}
        disableUnderline
        open={menuOpen}
        onOpen={()=>{
          setMenuOpen(true);
          if(!data || !data.values)loadFilterData({offset:0})
        }}
        onClose={()=>{
          setMenuOpen(false);
          setData({selected:data?data.selected:undefined,type})
        }}
        MenuProps={{
          className:classes.menu,
          style:{
            marginTop:type==='pdate'?58:80
          }
        }}
      >
        {dropdownBody}
      </Select>
    </div>
  )
}

export default withStyles(styles)(RangeFilter);
