import React, { useEffect} from 'react';
import { withTheme, withStyles, Drawer, IconButton, Button } from '@material-ui/core';
import { getIconComponent, mapSearchObjectName, onClickResultItem, sendMessage } from '../../../../../utilities';
import MiniProfile from '../../../../../containers/MiniProfile/MiniProfile';
import { lineageObjectsMap } from '../../utils';
import { checkCanAddKnowledge } from '../../../../../permissionChecker';
import DropdownButton from '../../../Buttons/DropdownButton';
import DrawerDragTrigger from '../../../DrawerDragTigger/DrawerDragTrigger';

const styles = theme => ({
  
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main
  },
  customScroll:{
    ...theme.components.hideScroll
  }
})


function ObjectDetailDrawer(props) {
  const {
    classes,
    history,
    theme,
    drawerOpen,
    setDrawerOpen,
    selectedItem,
    isLinkable,
    tagView='trust'
  } = props;

  let initialWidth = 600;
  try{
    if(localStorage.getItem('objectDrawerWidth')){
      initialWidth = Number(localStorage.getItem('objectDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  const [paperWidth, setPaperWidth] = React.useState(initialWidth)

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])
  
  if(!drawerOpen)return <></>

  let obj = selectedItem?.data?.obj;
  
  return (
    <Drawer anchor="right" open={drawerOpen} PaperProps={{style:{width:paperWidth,minWidth:500}}} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      { 
        selectedItem && 
        <>
          {/* <div style={{position:'absolute',height:'100vh',width:6,cursor:'col-resize'}}>

          </div> */}
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
              setPaperWidth(paperWidth+diff)
              localStorage.setItem('objectDrawerWidth',paperWidth+diff)}}
          />
          <div style={{display:'flex',alignItems:'flex-start',paddingLeft:24,paddingTop:16,paddingBottom:16}}>
            <Button 
              color='primary' 
              variant='contained'
              style={{marginRight:12}}
              onClick={()=>onClickResultItem({newWindow:true,item:obj,label:obj.object_type_txt || obj.object_type,history, id:obj.id })}
            >
              OPEN
            </Button>
            {
              lineageObjectsMap[mapSearchObjectName(obj.object_type_txt || obj.object_type,obj.code_type_txt)] && !['COLLECTION','COLLECTION_INSTANCE'].includes(obj.object_type_txt || obj.object_type) && isLinkable && 
              <Button 
                color='primary' 
                variant='outlined'
                style={{marginRight:12}}
                onClick={()=>sendMessage({open_mini_profile_link_modal:true})}
              >
                EDIT LINEAGE
              </Button>
            }
            {
              checkCanAddKnowledge(obj.object_type_txt) &&
              <DropdownButton
                iconLabel='add'
                text='ADD'
                noMargin
                height={34}
                optionList={[
                  {onClick:()=>sendMessage({mini_profile_add_note:true}),iconLabel:'note',text:'Add Knowledge'},
                  {onClick:()=>sendMessage({mini_profile_add_question:true}),iconLabel:'question',text:'Ask Question'}
                ]}
              />
            }
            <div style={{flexGrow:1}}></div>
            <IconButton 
              onClick={()=>setDrawerOpen(false)} 
              style={{width:28,height:28,padding:8}}
            >
              {
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
              }
            </IconButton>
          </div>
          {
            obj && 
            <MiniProfile
              history={history}
              id={obj.id}
              key={obj.id}
              tagView={tagView}
            />
          }
          {/* <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto'}}>
            <div style={{maxWidth:1600,flexGrow:1,flexShrink:1,marginLeft:drawerLeftPadding,marginRight:drawerRightPadding,height:'100%',overflow:'visible'}}>
             
            </div>
          </div> */}
          
        </>
      }
      
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(ObjectDetailDrawer));
