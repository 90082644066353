import React, {useState} from 'react';
import { withTheme, withStyles, Typography, ClickAwayListener, Popper, Paper, Checkbox} from '@material-ui/core';
import { getIconComponent } from '../../../utilities';

const styles = theme => ({
  option:{
    display:'flex',
    alignItems:'center', 
    height:42,
    padding:"0 16px",
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  optionName:{
    fontSize:16,
    flexGrow:1,
    marginRight:8
  },
  inlineChip:{
    marginLeft:16,
    minWidth:120,
    flexShrink:0,
    width:"max-content"
  }
});



const SearchModeSelector = props => {

  const {
    classes,
    theme,
    dispatch,
    state,
    onSearch,
  } = props;

  const [listActionOpen, setListActionOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const onListActionClick = event => {
    event.stopPropagation();
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickItem = mode => {
    dispatch({type:'set_is_filter_or_column_changed',isFilterOrColumnChanged:true})
    let newMode = state.searchMode.includes(mode)?state.searchMode.filter(m=>m!==mode):[...state.searchMode,mode]
    if(state.searchMode.includes(mode)){
      dispatch({type:'set_search_mode',searchMode:newMode})
    }else{
      dispatch({type:'set_search_mode',searchMode:newMode})
    }
    if(state.view==='main_search'){
      onSearch({
        searchMode:newMode
      })
    }
  }

  const options = [
    {
      name:'Search name only',
      onClick:()=>onClickItem('name_only'),
      checked:state.searchMode.includes('name_only')
    },
    {
      name:'Search exact match',
      onClick:()=>onClickItem('exact_match'),
      checked:state.searchMode.includes('exact_match')
    }
  ]

  let optionText = []
  if(state.searchMode.length===0){
    optionText = 'ALL RESULTS'
  }else{
    if(state.searchMode.includes('name_only'))optionText.push('NAME ONLY')
    if(state.searchMode.includes('exact_match'))optionText.push('EXACT MATCH')
    optionText = optionText.join(', ')
  }

  return(
    <>
      <div 
        style={{cursor:'pointer',display:'flex',alignItems:'center'}} 
        className={classes.inlineChip} 
        onClick={onListActionClick}
      >
        <Typography color='primary' style={{fontSize:13,marginRight:6}}>{optionText}</Typography>
        {getIconComponent({label:'triangle_down',size:20,colour:theme.palette.primary.main})}
      </div>
      <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-start'>
        <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
          <Paper onClick={event=>event.stopPropagation()} style={{marginTop:20,marginRight:-2,width:226,padding:'16px 0',border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
            {
              options.map((option,i)=>(
                <div 
                  className={classes.option}
                  onClick={option.onClick}
                >
                  <Checkbox
                    checked={option.checked}
                    color='primary'
                    style={{paddingLeft:0}}
                  />
                  <Typography className={classes.optionName}>{option.name}</Typography>
                </div>
              ))
            }
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default withTheme()(withStyles(styles)(SearchModeSelector));
