import React, {useEffect, useState, useRef} from 'react'; 
import { withTheme, withStyles, Typography, CircularProgress, InputBase, IconButton, Select, MenuItem, Popper, Paper, ClickAwayListener,MenuList } from '@material-ui/core';
import {formatNumber, getIconComponent, isInViewport} from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';
import SolrList from '../../UI/SolrList/SolrList';
import {useDispatch, useStore} from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { checkCanBulkUpdate } from '../../../permissionChecker';

const styles = theme => ({
  root:{
    marginBottom:30
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    flexGrow:1
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  selector: {
    ...theme.components.selector,
		width: 180,
  },
  nameFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
    width:160
  },
  listItem:{
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
})

function Files(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  const [loadingMore, setLoadingMore] = useState(false);
  const listRef = useRef();
  const searchTimeoutRef = useRef();

	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
  const reduxDispatch = useDispatch()

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const sortByItems = [
    {dispName:'A-Z',value:'ALPHABETICAL'},
    {dispName:'Z-A',value:'REVERSE_ALPHABETICAL'},
  ]

  const loadFiles = ({page, search, sortBy}) => {
    if(page===1)dispatch({type:'set_files_data',filesLoading:true})
    else{setLoadingMore(true)}
    axiosCerebrum
      .get(
        `/api/storages/${state.basicData.id}/related`,
        {params:{
          page:page,
          per_page:10,
          sort:sortBy,
          object_name:'FILE',
          'search.name':search && search.trim()!==''?search:undefined,
          relationship:'REFERENCES,K_REFERENCES',
        }}
      ).then(response=>{
        if(response.data.items.length===0){
          dispatch({type:'set_files_data',filesData:response.data})
          setLoadingMore(false);
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,
            {params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
              fl:'*'
            }}
          )
          .then(solrRes=>{
            let data;
            if(page===1){
              data = {...response.data,items:solrRes.data.response.docs}
            }else{
              data = {...response.data,items:[...state.filesData.items,...solrRes.data.response.docs]}
            }
            dispatch({type:'set_files_data',filesData:data})
            setLoadingMore(false)
          })
          .catch(error=>{
            console.log(error);
            dispatch({type:'set_files_data',filesData:state.filesData,filesErrorr:true})
            setLoadingMore(false)
          })
      }).catch(error=>{
        console.log(error);
        dispatch({type:'set_files_data',filesData:state.filesData,filesErrorr:true})
        setLoadingMore(false)
      })
  }

  useEffect(()=>{
    if(state.filesData)return;
    loadFiles({page:1,search:state.searchFilter, sortBy:state.sortBy})
  // eslint-disable-next-line
  },[])

  const onNameFilterChange = value => {
    dispatch({type:'set_search_filter',searchFilter:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadFiles({page:1,search:value,sortBy:state.sortBy})
    },250)
  }

  const onSortChange = value => {
    dispatch({type:'set_sort_by',sortBy:value})
    loadFiles({page:1,search:state.searchFilter,sortBy:value})
  }


  useEffect(()=>{
    if(state.filesData && !state.filesError && isInViewport(listRef) && !state.filesLoading &&  !loadingMore && state.filesData.page<state.filesData.pages){
      loadFiles({page:state.filesData.page+1,nameFilter:state.searchFilter,sortBy:state.sortBy})
    }
   // eslint-disable-next-line
  },[loadingMore, state.filesData])

  window.onscroll = () => {
    if(state.filesData && isInViewport(listRef) && !loadingMore && state.filesData.page<state.filesData.pages){
      loadFiles({page:state.filesData.page+1,nameFilter:state.searchFilter,sortBy:state.sortBy})
    }
  }


  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickBulkEdit = () => {
		reduxDispatch({
      type:actions.SET_BULK_EDIT_PARAM,
      data:{
        url: `/api/storages/${state.basicData.id}/related`,
        type:'cerebrum',
        params:{
          page:1,
          per_page:500,
          sort:state.sortBy,
          object_name:'FILE',
          'search.name':state.searchFilter && state.searchFilter.trim()!==''?state.searchFilter:undefined,
          relationship:'REFERENCES,K_REFERENCES'
        },
        redirectUrl:window.location.pathname.split('?')[0]
      }
    })
		history.push('/bulk_edit')
	}

  return (
    <div className={classes.root}>
      <div style={{marginTop:8,display:'flex',alignItems:'flex-start',flexWrap:'wrap',justifyContent:'space-between'}}>
        <Typography className={classes.title} style={{marginBottom:24}}>{`${state.filesData?formatNumber(state.filesData.total):'0'} FILE(S)`}</Typography>
        <div style={{display:'flex',flexShrink:0,marginBottom:24}}>
          <InputBase
            value={state.searchFilter}
            onChange={event => onNameFilterChange(event.target.value)}
            variant={'filled'}
            placeholder={'Search'}
            className={classes.nameFilter}
            endAdornment={
              <IconButton 
                disabled={state.searchFilter===''} 
                onClick={()=>onNameFilterChange('')}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:state.searchFilter===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
          <Select
            className={classes.selector}
            value={state.sortBy}
            onChange={event => onSortChange(event.target.value)}
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
          >
            {
              sortByItems.map(el => (
                <MenuItem  className={classes.menuItem} value={el.value}>
                  <span>{el.dispName}</span>
                </MenuItem>
              ))
            }
          </Select>
          {
            checkCanBulkUpdate({sessionData}) && 
            <div style={{marginLeft:24,width:24,height:24,marginTop:8,cursor:'pointer'}} onClick={onListActionClick}>
              {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
            </div>
          }
          
          {
            checkCanBulkUpdate({sessionData}) && 
            <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
              <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                  <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                  <MenuList className={classes.listContainer}>
                    <MenuItem onClick={()=>{onClickBulkEdit()}} className={classes.menuItem} >
                      <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Bulk update</Typography>
                    </MenuItem>
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Popper>
          }
        </div>
      </div>
      {
        state.filesLoading && <div style={{marginTop:60,textAlign:'center'}}><CircularProgress color='secondary'/></div>
      }
      {
        state.filesError && <Typography className={classes.normalText} style={{marginTop:30}}>Error occurred loading files</Typography>
      }
      {
        !state.filesError && !state.filesLoading && state.filesData && 
        <SolrList
          history={history}
          list={state.filesData.items}
          display={true}
          hideTopMessage
          enableEdit
          onDescriptionUpdated={({item,description})=>{
            dispatch({
              type:'set_files_data',
              filesData:{
                ...state.filesData,
                items:state.filesData.items.map(el=>{
                  if(el.id!==item.id)return el;
                  return {...el,description}
                })
              }
            })
          }}
        />
      }
      {
        !state.filesError && !state.filesLoading && state.filesData && state.filesData.total===0 &&
        <Typography className={classes.normalText}  style={{marginTop:30}}>No files found</Typography>
      }
      <div
        style={{ marginBottom: 40, display:'flex',justifyContent:'center' }}
        ref={listRef}
      >
        {
          loadingMore && <CircularProgress color='secondary'/>
        }
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Files));