import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Checkbox, Typography,  Select, MenuItem, IconButton,CircularProgress} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { collectionIds, getCollectionColour, getIconComponent } from '../../../utilities'

const styles = theme => ({
  inputBlock:{
    marginBottom:40,
    display:'flex',
    alignItems:'flex-start'
  },
  checkbox:{
    marginRight:27,
    flexGrow:0,
    flexShrink:0,
    padding:0,
    marginTop:16
  },
  title:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    width:240,
    marginRight:40,
    flexGrow:0,
    flexShrink:0,
    marginTop:16
  },
  helperText:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginLeft:16
  },
  selector: {
    ...theme.components.selector,
    width: 400,
    '& div div': {
      paddingLeft: 16,
      paddingTop: 16,
      paddingBottom: 16,
      fontSize:16
    }
  },
  editTypeSelector:{
    width:200,
    marginRight:40
  },
  valueSelector:{
    width:400
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  menu:{
    height:400
  },

  subSectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    marginTop:12,
    marginBottom:8
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  }
})

function LinkToCollection(props) {

  const {
    classes,
    state,
    dispatch,
  } = props;


  const loadCollectionInstances = ({collectionId,page=1}) => {
    dispatch({
      type:'set_collection_instance_map',
      collectionInstanceMap:{
        ...state.collectionInstanceMap,
        [collectionId]:page===1?undefined:state.collectionInstanceMap[collectionId],
        [`${collectionId}_loading`]:true,
        [`${collectionId}_error`]:false
      }
    })
    axiosCerebrum
      .get(`/api/collectioninstances?collection_id=${collectionId}&per_page=10&page=${page}`)
      .then(response=>{
        dispatch({
          type:'set_collection_instance_map',
          collectionInstanceMap:{
            ...state.collectionInstanceMap,
            [collectionId]:page===1?response.data:{...response.data,items:[...state.collectionInstanceMap[collectionId].items,...response.data.items]},
            [`${collectionId}_loading`]:false,
            [`${collectionId}_error`]:false
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_collection_instance_map',
          collectionInstanceMap:{
            ...state.collectionInstanceMap,
            [collectionId]:undefined,
            [`${collectionId}_loading`]:false,
            [`${collectionId}_error`]:true
          }
        })
      })
  }

  const onCollectionChecked = ( collection) => {
    let checked = !state.changedFields.find(el=>el.collectionId===collection.id)
    if(!checked){
      dispatch({
        type:'set_changed_fields',
        changedFields: [...state.changedFields.filter(el=>el.collectionId!==collection.id)]
      })
    }else{
      let category = collection.collection_type_txt;
      dispatch({
        type:'set_changed_fields',
        changedFields: [
          ...state.changedFields,
          {
            field:category==='GLOSSARY'?'RELATED':'MEMBER_OF',
            collectionId: collection.id,
            values:[],
            operation:'ADD_TO_EXISTING'
          }
        ]
      })
    }
  }

  const onChangeOperation = (value, collectionId) => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.collectionId!==collectionId)return el;
        else{
          return {
            ...el,
            operation:value,
            values:value==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }

  const onRemoveCollectionInstance = (instanceId, collection) => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.collectionId!==collection.id)return el;
        else{
          return {
            ...el,
            values:el.values.filter(v=>v!==instanceId)
          }
        }
      })
    })
  }

  const onSelectInstance = (instanceIds, collectionId) => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.collectionId!==collectionId)return el;
        else{
          return {
            ...el,
            values:[instanceIds[instanceIds.length-1]].filter(el=>el!==undefined)
          }
        }
      })
    })
  }

  return (
    <div className={classes.root}>
      {
        state.properties.filter(el=>!el.id.includes('default_prop_')).map(collection=>(
          <div className={classes.inputBlock}>
            <Checkbox 
              onChange={(event, checked)=>onCollectionChecked(collection)} 
              className={classes.checkbox} 
              data-test-id={`${collection.name_txt.replace(/\s/g,'-').toLowerCase()}-checkbox`}
              color='primary' 
              checked={state.changedFields.find(el=>el.collectionId===collection.id)}
            />
            <Typography className={classes.title}>{collection.name_txt}</Typography>
            <div style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.collectionId===collection.id)?undefined:0.5}}>
              <Select
                inputProps={{
                  disabled:!state.changedFields.find(el=>el.collectionId===collection.id)
                }}
                data-test-id={`${collection.name_txt.replace(/\s/g,'-').toLowerCase()}-operation-selector`}
                value={state.changedFields.find(el=>el.collectionId===collection.id)?state.changedFields.find(el=>el.collectionId===collection.id).operation:'ADD_TO_EXISTING'}
                className={classes.selector + ' ' + classes.editTypeSelector}
                onChange={event=>onChangeOperation(event.target.value,collection.id)}
                disableUnderline
              > 
                {
                  [
                    collectionIds.dqDimensions===collection.id?undefined:{name:'Add to existing',value:'ADD_TO_EXISTING'},
                    {name:'Replace',value:'REPLACE'},
                    {name:'Clear',value:'CLEAR'}
                  ].filter(el=>el).map(el=>(
                    <MenuItem className={classes.menuItem} value={el.value}>
                      {el.name}
                    </MenuItem>
                  ))
                }
              </Select>

              <div>
                {
                  state.changedFields.find(el=>el.collectionId===collection.id)?.operation!=='CLEAR' && 
                  <div>
                    <Select
                      disabled={!state.changedFields.find(el=>el.collectionId===collection.id)}
                      multiple={true}
                      value={state.changedFields.find(el=>el.collectionId===collection.id)?.values||[]}
                      data-test-id={`${collection.name_txt.replace(/\s/g,'-').toLowerCase()}-selector`}
                      className={classes.selector + ' ' + classes.valueSelector + (state.changedFields.find(el=>el.collectionId===collection.id)?.values.length===0?' '+classes.errorBorder:'') }
                      onChange={event=>onSelectInstance(event.target.value, collection.id)}
                      renderValue={()=>`Select ${collection.name_txt}`}
                      placeholder={`Select ${collection.name_txt}`}
                      displayEmpty={true}
                      disableUnderline
                      MenuProps={{
                        className:classes.menu,
                        onScroll:(event)=>{
                          if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
                            if(state.collectionInstanceMap[collection.id] && state.collectionInstanceMap[collection.id].page<state.collectionInstanceMap[collection.id].pages){
                              loadCollectionInstances({
                                collectionId:collection.id,
                                page: state.collectionInstanceMap[collection.id].page+1,
                              })
                            }
                          }
                        }
                      }}
                      onClick={()=>{
                        if(!state.collectionInstanceMap[collection.id] && !state.collectionInstanceMap[`${collection.id}_loading`] ){
                          loadCollectionInstances({collectionId:collection.id})
                        }
                      }}    
                    > 
                      {
                        state.collectionInstanceMap[collection.id] && 
                        state.collectionInstanceMap[collection.id].total===0 && 
                        <Typography className={classes.normalText}>No instance under this collection</Typography>
                      }
                      {
                        state.collectionInstanceMap[collection.id] && 
                        state.collectionInstanceMap[collection.id].items.map(instance=>(
                          <MenuItem className={classes.menuItem} value={instance.id}>
                            <Checkbox color='primary' checked={state.changedFields.find(el=>el.collectionId===collection.id)?.values?.includes(instance.id)}/>
                            {instance.name}
                          </MenuItem>
                        ))
                      }
                      {
                        state.collectionInstanceMap[`${collection.id}_error`] && <Typography className={classes.normalText}>Error occurred loading collection instances</Typography>
                      }
                      <div style={{display:'flex',justifyContent:'center'}}>
                        {
                          state.collectionInstanceMap[`${collection.id}_loading`] && 
                          <CircularProgress color='secondary' style={{margin:'6px auto',width:24,height:24}}/>
                        }
                      </div>
                    </Select>
                    {
                      state.changedFields.find(el=>el.collectionId===collection.id)?.values.length>0 && 
                      <div >
                        <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                        <div style={{display:'flex',flexWrap:'wrap'}}>
                          {
                            state.changedFields.find(el=>el.collectionId===collection.id)?.values.map(el=>(
                              <div 
                                data-test-classname={`${collection.name_txt.replace(/\s/g,'-').toLowerCase()}-chip`} 
                                style={{width:'max-content',marginRight:6,marginBottom:6,padding:'0px 6px',height:32, borderRadius:16, display:'flex',background:getCollectionColour(collection.name_txt).colour,alignItems:'center'}}
                              >
                                <Typography 
                                  style={{color:getCollectionColour(collection.name_txt).fontColour,marginLeft:6}}
                                >
                                  {state.collectionInstanceMap[collection.id].items.find(i=>i.id===el).name}
                                </Typography>
                                <IconButton 
                                  className={classes.clearButton} 
                                  style={{color:getCollectionColour(collection.name_txt).fontColour,marginLeft:20,padding:3,marginRight:3,background:getCollectionColour(collection.name_txt).colour}} 
                                  onClick={()=>onRemoveCollectionInstance(el,collection)}
                                >
                                  {getIconComponent({label:'clear', size:20, colour:getCollectionColour(collection.name_txt).fontColour})}
                                </IconButton>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        ))
        
      }
      
    </div>
  )
}

LinkToCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LinkToCollection));