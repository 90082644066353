import React, { useEffect, useReducer, useState } from 'react';
import { withStyles, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useGetSolr from '../../hooks/useGetSolr';
import Body from '../../components/UserRiskDashboard/Body/Body';
// import axiosCerebrum from '../../axios-cerebrum';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { removeUrlQueryArg, setInitialState} from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
// import timezoneDetect from 'jstimezonedetect';
import { palette, genericColour } from '../../components/UserRiskDashboard/Components/utils'
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import SearchResultModal from '../../components/UI/SearchResultModal/SearchResultModal';
import { globalListenerRef } from '../../GlobalListenerRef';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const initialState = {
    tabState: 0,
    usageBySourceData:{},
    sources:[],
    // new grid
    sourceColourMap:{},
    gridShowMiniMap:true,
    usageTabState:0,
    usageData:{
      data:{
        selectedTile:'DATABASE',
        lineStyle:'line',
      },
      content:{
        selectedTile:'REPORT',
        lineStyle:'line',
      },
      people:{
        selectedTile:'DATA_USERS_LAST_7D',
        lineStyle:'line',
      }
    },
    governanceTabState:0,
    governanceUsageFilter:{
      0:['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','NO USAGE'],
      1:['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','NO USAGE'],
      2:['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','NO USAGE'],
    },
    governanceData:{
      data:{},
      content:{},
      source:{}
    },
    issueData:{},
    usageSource:undefined,
    governanceSource:undefined,
    sourceLoadSort:'LAST_LOADED_AT_DESC',
    //////////////////////
    // demo
    lifecycleTabState:0,
    lifecycleDB:{0:1000,1:1000},
    runningCode:'',

  }

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_running_code':
      return {
        ...state,
        // runningCode:action.runningCode
      }
    case 'set_sources':
      return {
        ...state,
        sources:action.sources
      }
    case 'set_obejct_count_by_source':
      return {
        ...state,
        objectCountBySource:action.objectCountBySource
      }
    case 'set_grid_nodes':
      return {
        ...state,
        gridNodes:action.gridNodes
      }
    case 'set_grid_links':
      return {
        ...state,
        gridLinks:action.gridLinks
      }
    case 'set_grid_show_inactive':
      return {
        ...state,
        gridShowInactive: action.gridShowInactive
      }
    case 'set_grid_show_minimap':
      return {
        ...state,
        gridShowMiniMap:action.gridShowMiniMap
      }
    case 'set_grid_loading':
      return {
        ...state,
        gridLoading:action.gridLoading
      }
    case 'set_source_colour_map':{
      return {
        ...state,
        sourceColourMap:action.sourceColourMap
      }
    }
    case 'set_db_list':{
      return {
        ...state,
        dbList:action.dbList,
        dbListLoading:action.dbListLoading,
        dbListError:action.dbListError
      }
    }
    case 'set_usage_tab_state':{
      return {...state,usageTabState:action.usageTabState}
    }
    case 'set_usage_data':{
      return {...state,usageData:action.usageData}
    }
    case 'set_usage_by_source_data':{
      return {...state,usageBySourceData:action.usageBySourceData}
    }
    case 'set_usage_by_source_tab_state':{
      return {...state,usageBySourceTabState:action.usageBySourceTabState}
    }
    case 'set_usage_source':{
      return {...state, usageSource:action.usageSource}
    }
    case 'set_governance_tab_state':{
      return {...state, governanceTabState:action.governanceTabState}
    }
    case 'set_governance_usage_filter':{
      return {...state,governanceUsageFilter:action.governanceUsageFilter}
    }
    case 'set_governance_source':{
      return {...state, governanceSource:action.governanceSource}
    }
    case 'set_usage_count_by_object':
      return {...state,usageCountByObject:action.usageCountByObject}
    case 'set_usage_count_by_object_by_source':
      return {...state,usageCountByObjectBySource:action.usageCountByObjectBySource}
    case 'set_governance_data':{
      return {...state, governanceData:action.governanceData}
    }
    case 'set_issue_data':{
      return {...state,issueData:action.issueData}
    }
    case 'set_platform_sources':{
      return {
        ...state,
        platformSources:action.platformSources,
        platfomrSourcesLoading:action.platformSourcesLoading,
        platformSourcesError:action.platformSourcesError
      }
    }
    case 'set_source_load_sort':{
      return {
        ...state,
        sourceLoadSort:action.sourceLoadSort
      }
    }
    default:
      throw new Error("Action type not supoorted");
  }
}

const UserRiskDashboard = props => {


  const {
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
    variant
  } = props;

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName')
  const persetUsageSource = urlSearch.get('usageSourceId')
  const presetUsageTile = urlSearch.get('usageSelectedTile')
  
  let storedInitialState = {...setInitialState(pageCache,initialState)};
  const [state, dispatch] = useReducer(reducer, storedInitialState);

  const [modalQuery, setModalQuery] = useState(undefined);
  const [modalFilter, setModalFilter] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalObjectType, setModalObjectType] = useState(undefined);

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  let usageTabOptions = ['DATA','CONTENT','BY SOURCES']
  // let govTabOptions = ['ALL DATA','ALL CONTENT','BY SOURCE']

  const {
    data:dbList,
    loading:dbListLoading,
    error:dbListError,
    fetchList:dbListFetch
  } = useGetSolr({
    url:'/api/sources',
    params:{
      per_page:200,
      types:'IDENTITY_STORE',
      sort:'ALPHABETICAL',
      active_flag:true
    },
    preventAuto:true
  })

  useEffect(()=>{ 
    let validList = dbList?dbList.items.filter(el=>el.id!==995):state.dbList
    dispatch({
      type:'set_db_list',
      dbList:validList,
      dbListError:dbListError,
      dbListLoading:dbListLoading,
    })
    dispatch({
      type:'set_sources',
      sources:dbList?dbList.items:state.sources||[],
    })
    if(dbList){
      let colourMap = {};
      validList.forEach((el,index)=>{
        if(el.id===1000){
          colourMap[el.id]=genericColour;
        }else{
          colourMap[el.id] = palette[index%palette.length]
        }
      })
      dispatch({
        type:'set_source_colour_map',sourceColourMap:colourMap
      })
      let validSources = validList
      let databases = validList.filter(el=>el.source_template.type==='DATABASE');
      if(validSources.length>0){
        dispatch({type:'set_usage_source',usageSource:validSources[0].id});
      }
      if(databases.length>0){
        dispatch({type:'set_governance_source',governanceSource:databases[0].id});
      }
      let usageBySourceData = {};
      validSources.forEach(el=>{
        usageBySourceData[el.id] = state.usageBySourceData[el.id]||{}
      });
      dispatch({type:'set_usage_by_source_data',usageBySourceData})
    }
     // eslint-disable-next-line
  },[dbList,dbListLoading,dbListError])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.modalQuery ){
        setModalQuery(msg.data.modalQuery)
      }
      if(msg.data.modalFilter){
        setModalFilter(msg.data.modalFilter)
      }
      if(msg.data.modalObject){
        setModalObjectType(msg.data.modalObject)
      }
      if(msg.data.modalQuery || msg.data.modalFilter || msg.data.modalObject) setModalOpen(true)
    }
    window.removeEventListener('message',globalListenerRef.gridMsgListener);
    globalListenerRef.gridMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.gridMsgListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.gridMsgListener);})
  // eslint-disable-next-line
  },[])

  
  useEffect(()=>{
    if(tabName && usageTabOptions.includes(tabName)){
      dispatch({type:'set_usage_tab_state',usageTabState:usageTabOptions.indexOf(tabName)})
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    // eslint-disable-next-line
  },[tabName])

  useEffect(()=>{
    if(state.dbList && persetUsageSource && state.dbList.find(el=>el.id+''===persetUsageSource)){
      dispatch({type:'set_usage_source',usageSource:parseInt(persetUsageSource)})
      if(presetUsageTile){
        dispatch({type:'set_usage_by_source_data',usageBySourceData:{...state.usageBySourceData,[parseInt(persetUsageSource)]:{...(state.usageBySourceData[parseInt(persetUsageSource)]||{}),selectedTile:presetUsageTile } }})
      }
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['usageSourceId','usageSelectedTile']}));
    }
    // eslint-disable-next-line
  },[persetUsageSource,presetUsageTile,state.dbList])


  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])


  let tabBarEl, title, label;
  title = 'Data User & Access Risk'
  label = 'insights'
  tabBarEl = (
    <TabBar
      tabOptions={['BY SOURCE']}
      tabState={state.usageTabState}
      setTabState={value => dispatch({ type: 'set_usage_tab_state', usageTabState: value })}
      minWidth={200}
      maxWidth={200}
      disableUnderline={true}
    />
  )
  
  useEffect(()=>{
    if(!state.dbList)dbListFetch()
    addHistory({url:window.location.pathname, iconLabel:label, title: title, subTitle:title,type:'application'})
    // eslint-disable-next-line
  },[])
  

  if (state.dbListLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.dbListError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.dbList){
    return <div></div>
  }

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={title}
              subtitle={' '}
              minHeight={100}
              label={label}
              buttons={[<div style={{height:48,margin:8}}></div>]}
            />
          </div>)}
        tabBar={tabBarEl}
        body={
          <Body
            history={history}
            dispatch={dispatch}
            state={state}
            sessionData={sessionData}
            variant={variant}
          />
        }
      />
      <SearchResultModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        propObjectType={modalObjectType} 
        propQuery={modalQuery} 
        propFilter={modalFilter} 
        resultItemVariant={"simplified"}
        hiddenComponents={['columnSelector','filterEdit','filter','listTitle','filterReset','searchBar','header','tab','cartButton','downloadButton']}
      />
    </div>
  )
}


UserRiskDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['usage','governance','issue','data_load']).isRequired,
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserRiskDashboard));