import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import {  getIconComponent, getInitials,getUserRoles } from '../../../utilities';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import axiosCerebrum from '../../../axios-cerebrum';
import keycloakInstance from '../../../keycloak-instance';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
  },
  title: {
    height: '24px',
    width: '284px',
    color: theme.palette.primary.main,
    fontSize: '13.75px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '24px'
  },
  spacingDivs: {
    marginBottom: '2rem'
  },
  description: {
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
    color: theme.palette.primaryText.light,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  alertText: {
    textAlign: 'justify',
    //overflow:'scroll',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    color: theme.palette.primaryText.light,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  headerText: {
    color: theme.palette.primary.main,
    marginBottom: '1rem',
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  alertTitle:{
    '& h6':{
      color: theme.palette.primaryText.main,
    },
    fontSize: '19.64px',
    fontWeight: '500',
    letterSpacing: '0.25px',
    lineHeight: '26px'
  },
  tableTitle:{
    color:theme.palette.primary.main,
    fontSize:12,
    letterSpacing:2
  },
  columnRow:{
    paddingLeft:16,
    // width:624,
    height:48,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  rowText:{
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    fontSize:16
  },
  avatar:{
    width:24,
    height:24,
    color:'#fff',
    background:theme.palette.avatar.main,
    borderRadius:12,
    marginLeft:14,
    marginRight:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:12
  },
  banner:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    height:56,
    width:'100%',
    background:'#f5f5f5',
    marginBottom:24
  },
  modalPaper:{
    background:theme.palette.background.main,
    border:theme.palette.border.main,
    width:'35vw',
    minWidth:600,
    maxWidth:800,
  },
  itemChip:{
    width:'max-content',
    maxWidth:'100%',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    borderRadius:16,
    padding:'4px 0',
    '& span':{
      padding:'0 8px',
      fontSize:13.75
    }
  },
  targetItemChip:{
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  },
  tooltip:{
    fontSize:13.75
  }
})

const ConfirmProfile = props => {

  const {
    theme,
    classes,
    state,
    dispatch,
    history,
    onLoginSuccess
  } = props;

  // const dispatchRedux = useDispatch()

  const {
    sendAlert
  } = useAlert({})


  function handleClose() {
    dispatch({ type: 'set_alert', alert: false })
  }
  
  function createUserOnboard() {
        axiosCerebrum
          .get('/api/me')
          .then(async response=>{
            let data = {};
            data.user={...keycloakInstance.tokenParsed,...response.data};
            data.user.id=response.data.id;
            data.keycloak = keycloakInstance;
            let roles = [];
            if(keycloakInstance.tokenParsed.realm_access && keycloakInstance.tokenParsed.realm_access.roles){
              roles = getUserRoles(keycloakInstance.tokenParsed.realm_access.roles)
            }
            if(state.alias.length>0){
              await axiosCerebrum.post(
                `/api/merges`,
                {
                  "target": response.data.id,
                  "object_type": "USER",
                  "candidates": state.alias.map(el=>el.id),
                  "action":'ADD_TO_EXISTING'
                }
              )
            }
            localStorage.setItem('userInfo',JSON.stringify(response.data))
            if(state.team.length>0){
              let teams = [];
              let existingtUserGroupData = [];
              try{
                existingtUserGroupData = localStorage.getItem('userGroupData')?JSON.parse(localStorage.getItem('userGroupData')):[]
              }catch{}

              // add new team links
              for(let i=0; i<state.team.length; i++){
                if(existingtUserGroupData.find(g=>g.id===state.team[i].id)){
                  teams.push(existingtUserGroupData.find(g=>g.id===state.team[i].id))
                  continue;
                }
                await axiosCerebrum
                  .put(`/api/groups/${state.team[i].id}/related?object_id=${response.data.id}&relationship=MEMBERS`)
                  // eslint-disable-next-line
                  .then(response=>{
                    if(response.data){
                      teams.push(response.data)
                    }
                  })
                  .catch(error=>{
                    console.log(error)
                  })
              }
              // remove deleted team links
              for(let i=0; i<existingtUserGroupData.length; i++){
                if(!state.team.find(t=>t.id===existingtUserGroupData[i].id)){
                  await axiosCerebrum
                    .delete(`/api/groups/${existingtUserGroupData[i].id}/related?object_id=${response.data.id}&relationship=MEMBERS`)
                    // eslint-disable-next-line
                    .then(response=>{
                      teams = teams.filter(t=>t.id!==existingtUserGroupData[i].id)
                    })
                    .catch(error=>{
                      console.log(error)
                    })
                }
              }
              localStorage.setItem('userGroupData', JSON.stringify(teams));
            }

            await axiosCerebrum
              .get(
                `/api/users/${response.data.id}/related?object_name=GROUP&relationship=MEMBER_OF`,
              )
              .then(response => {
                history.push(['10','40'].some(el=>roles.includes(el))?'grid':'/home')
                onLoginSuccess(data);
              })
              .catch(error=>{
                history.push(['10','40'].some(el=>roles.includes(el))?'grid':'/home')
                onLoginSuccess(data);
              })
            sendAlert({message:"Onboarding completed. It may take up to 24 hours to complete the linking process. In that time some information about you may be missing.",type:'info'})
          })
          .catch(error => {
            console.log(error, 'Error getting user data');
            sendAlert({message:"Error occurred processing onboarding",type:'error'})
          })
  }

  // const constructReferenceSources = sources => {
  //   if(!sources || sources.length===0)return 'NA';
  //   return sources.map(el=>el.reference_source.name).join(', ')
  // }
  
  return (
    <div className={classes.block}>
      {
        state.alias.length>1 &&
        <div className={classes.banner}> 
          <ErrorOutlineIcon style={{marginRight: '1.5rem',color: '#FC642D',fontSize: 32}}/>
          <Typography style={{fontSize:16}}>You have selected different Accounts to link. Please check this is correct before continuing.</Typography>
        </div>
      }
      <div className={classes.body}>
        <div className={classes.spacingDivs}>
        <Typography className={classes.headerText}>TEAM</Typography>
          {
            state.team.length>0 && state.team.map(el=>(
              <div style={{display:'flex',alignItems:'center',width:640,marginBottom:6}}>
                <div className={classes.avatar}>{getInitials(el.name_txt)}</div>
                <Typography color='textSecondary' className={classes.description}>{el.name_txt}</Typography>
              </div>
            ))
          }
          {
            state.team.length===0 && 
            <div style={{display:'flex',alignItems:'center',width:640}}>
              <Typography color='textSecondary' className={classes.description}>No team selected</Typography>
            </div>
          }
         

        </div>
        <Typography className={classes.headerText}>LINKED ACCOUNTS</Typography>
        {
          state.alias.length===0 && <Typography className={classes.description}>No accounts selected</Typography>
        }
        {
          state.alias.length>0 &&
          <div style={{display:'flex',marginBottom:8}}>
           <Typography color='primary' style={{fontSize:12,flex:'0 1 300px',marginLeft:56,paddingRight:16}}>NAME</Typography>
            <Typography color='primary' style={{fontSize:12,flex:'0 1 200px',paddingRight:16}}>ACCOUNT TYPE</Typography>
            <Typography color='primary' style={{fontSize:12,flex:'0 1 300px',paddingRight:16}}>SOURCE</Typography>
            <Typography color='primary' style={{fontSize:12,flex:'1 1 300px'}}>ALTERNATE SOURCE</Typography>
          </div>
        }
        {
          state.alias.map(el=>(
            <div className={classes.columnRow}>
              {getIconComponent({label:'user',size:24,colour:theme.palette.primaryText.light})}
              <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 300px',marginLeft:16,paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                <KTooltip title={`${el.user.username}${el.user.username!==el.name?` (${el.name})`:''}`} classes={{tooltip:classes.tooltip}}>
                  <div className={classes.itemChip + ' ' + classes.targetItemChip}>
                    <span>{el.user.username}{el.user.username!==el.name?` (${el.name})`:''}  </span>
                  </div>
                </KTooltip>
              </Typography>
              <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 200px',paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                {el.user.account_type}  
              </Typography>
              <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 300px',paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                {el.source.name}
              </Typography>
              <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'1 1 300px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                {el.reference_sources.map(el=>el.reference_source.name).join(', ')}
              </Typography>
            </div>
          ))
        }
      </div>
      <Dialog
        open={state.alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper:classes.modalPaper
        }}
      >
        <DialogTitle className = {classes.alertTitle} id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              state.alias.length === 0 &&
              <div>
                <Typography color='textSecondary' className={classes.alertText}>{"You have selected no ID's to link to."}</Typography>
                <Typography color='textSecondary' className={classes.alertText}>{"You can add ID's at a later time by selecting 'Settings' from the 'User Options' menu item"}</Typography>
              </div>
            }
            {
              state.alias.length>0 &&
              <div>
                <Typography color='textSecondary' className={classes.alertText}>You have chosen to link your account to{'\n\n'}{state.alias.map(el=>`- ${el.user.username}${el.user.username!==el.name?` (${el.name})`:''}`).join('\n')}</Typography>
                <Typography color='textSecondary' className={classes.alertText}>{"Make sure the above is correct before proceeding"}</Typography>
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus style={{width:56}}>
            CANCEL
          </Button>
          <Button onClick={createUserOnboard} color='secondary' style = {{width:56,marginLeft:8}} >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmProfile.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    //loggedIn: state.auth.logged_in
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginSuccess: (data) => dispatch(actions.loginSuccess(data)),
    onLogoutSuccess: data=>dispatch(actions.logoutSuccess(data))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ConfirmProfile)));