  
export const checkClassName = (rootEl, className) => {
  let hasClassName = false;
  let el = rootEl;
  if(!el)return false;
  while(el){
    if(el.className && el.className.includes && el.className.includes(className)){
      hasClassName = true;
      break;
    }
    el = el.parentElement;
  }
  return hasClassName;
}