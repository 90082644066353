import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import TeamSelector from '../../UI/SearchSelector/TeamSelector';
import { getCustomerName, getIconComponent } from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
    width: '40rem'
  },
  subTitle: {
    height: '24px',
    width: '292.68px',
    color: theme.palette.primary.main,
    fontSize: 20,
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  subLine: {
    width:640,
    color: theme.palette.primaryText.light,
    fontSize: 12,
    marginBottom:16
  },
  welcomeTitle:{
    fontSize:20,
    color:theme.palette.primary.main,
    marginBottom:6
  },
  welcomeText:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap',
    marginBottom:40,
    width:640,
  },
  selector:{
    ...theme.components.selector,
    width:'100%',
    height:50,
    '& div div':{
      padding:'6px 16px',
      fontSize:16
    }
  },
})

const AddDetails = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  let customerName = getCustomerName();
  let isReceiveEmail = 'false';
  let receiveEmailSettingID;
  try{
    isReceiveEmail = JSON.parse(localStorage.getItem('userSettings')).items.find(el=>el.user_settings_template.name==='Enable Daily Briefing Email').value;
    receiveEmailSettingID = JSON.parse(localStorage.getItem('userSettings')).items.find(el=>el.user_settings_template.name==='Enable Daily Briefing Email').id;
  }catch{}

  const [searchValue, setSearchValue] = useState('')
  const [receiveEmail, setReceiveEmail] = useState(isReceiveEmail)

  const {
    sendAlert
  } = useAlert({})

  const onChangeReceiveEmail = value => {
    axiosCerebrum 
      .put(`/api/me/settings/${receiveEmailSettingID}`,{value})
      .then(response=>{
        axiosCerebrum 
          .get(`/api/me/settings`,{params:{per_page:50}})
          .then(response=>{
            localStorage.setItem('userSettings',JSON.stringify(response.data))
          })
          .catch(error=>{
            console.log(error)
          })
        setReceiveEmail(value)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred updating the setting, please try again', type: 'error' })
      })
  }


  return (
    <div>
      <div className={classes.block}>
        <Typography className={classes.welcomeTitle}>WELCOME TO K</Typography>
        <Typography className={classes.welcomeText}>
            K is your guide to discover, understand, trust and govern data products found in {customerName!==''?'the ' + customerName:"your organisation's"} data ecosystem.
            {`\n\n`}
            To get started, you should customise your experience by adding your Team and linking your Accounts that you use.
            {`\n\n`}
            You can update your Team and Accounts (and other settings like Dark Mode) at any time by going to Profile Settings. It is available by clicking on your Avatar in the bottom left corner of your screen.
        </Typography>
        <Typography color='primary' className={classes.subTitle}>TEAM</Typography>
        <Typography color='primary' className={classes.subLine}>
          Join a Team in K. Contact your Administrator if you cannot find your team. 
        </Typography>
        <div style={{width:640}}>
          <TeamSelector
            clearTeam={(el)=>dispatch({type:'set_team',team:state.team.filter(t=>t.id!==el.id)})}
            autoSuggestion={true}
            selectedGroup={state.team}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setGroup={el=>{!state.team.find(t=>t.id===el.id) && dispatch({ type: 'set_team', team: [...state.team,el] })}}
            multiple
          /> 
        </div>

        <Typography className={classes.subTitle} style={{marginTop:46}}>DAILY BRIEFING </Typography>
        <Typography className={classes.subLine}>
          Receive a daily email with the latest news from your data ecosystem
        </Typography>
        <div style={{width:'100%'}}>
          <Select
            className={classes.selector}
            value={receiveEmail}
            onChange={event=>onChangeReceiveEmail(event.target.value)}
            renderValue={value=>{
              return (
                <div style={{display:'flex',alignItems:'center',padding:0}}>
                  <div style={{width:24,height:24,marginRight:16,padding:0}}>{getIconComponent({label:'email',size:24,colour:theme.palette.primary.main})}</div>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.light}}>
                    {value==='true'?'Yes I want to receive the daily briefing':'No I do not want to receive the daily briefing'}
                  </Typography>
                </div>
              )
            }}
          >
            <MenuItem value={'true'}>
              Yes I want to receive the daily briefing
            </MenuItem>
            <MenuItem value={'false'}>
              No I do not want to receive the daily briefing
            </MenuItem>
          </Select>
        </div>
      </div>
    </div>
  )
}

AddDetails.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

export default withTheme()(withStyles(styles)(AddDetails));