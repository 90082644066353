import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, CircularProgress, Typography, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../../axios-solr';
import { getDispFields, onClickResultItem } from '../../../../utilities';
import KTooltip from '../../../UI/KTooltip/KTooltip';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../../UI/ContextMenu/ContextMenu'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import TabBar from '../../../UI/TabBar/TabBar';
import { contentItems, dataItems } from '../../utils';
import AscIcon from '@material-ui/icons/ArrowUpward'
import DescIcon from '@material-ui/icons/ArrowDownward'

const styles = theme => ({
  title: {
    fontSize: 20,
    textTransform:'uppercase',
    color:theme.palette.header.main,
  },
  columnRow:{
    display:'flex',
    alignItems:'center',
    marginBottom:16,
    paddingLeft:16,
  },
  columnText:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    marginRight:16,
    textTransform:'uppercase',
    letterSpacing:1.5,
    alignItems:'center',
    display:"flex"
  },
  tableRow:{
    display:'flex',
    paddingLeft:16,
    overflow:'hidden',
    alignItems:'center',
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  tableNameText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  tableText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    boxSizing:'border-box'
  },
  pageBtn:{
    height:36,
    minWidth:40
  },
})

function DetailModal(props) {

  const {
    theme,
    classes,
    state,
    object,
    modalOpen, 
    setModalOpen
  } = props;

  const [data, setData] = useState({})
  const [page, setPage] = useState(1)
  const [tabState, setTabState] = useState(0)
  const [sort, setSort] = useState('name_srt asc')


  const loadUserDetail = ({start, sortValue=sort}) => {
    if(object.object_type_srt!=='USER' &&  (!object.users_msrt || object.usernames_msrt.length===0)){
      setData({docs:[],numFound:0})
      return;
    }
    setData({...data,loading:true,docs:[]})
    axiosSolr
      .post(
        `/solr/impact/select`,{params:{
          q:"*",
          fq:`username_srt:(${object.usernames_msrt.map(el=>`"${el}"`).join(' OR ')}) AND extract_id:${object.extract_id}` + 
              (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          rows:10,
          start:start,
          sort: sortValue
        } }
      )
      .then(response=>{
        setData(response.data.response)
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  } 

  const loadDataDetail = ({start, tab, sortValue=sort}) => {
    let items;
    if(tab===0)items = dataItems;
    if(tab===1)items = contentItems;
    setData({...data,loading:true,docs:[]})
    axiosSolr
      .post(
        `/solr/impact/select`,{params:{
          q:"*",
          fq:`object_type_srt:(${items.join(' OR ')}) AND usernames_msrt:"${object.username_srt}" AND extract_id:${object.extract_id}` + 
              (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          rows:10,
          start:start,
          sort: `${sortValue}`
        } }
      )
      .then(response=>{
        setData(response.data.response)
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  } 

  const loadDetail = ({start=0, tab=tabState, sortValue=sort}) => {
    if(object.object_type_srt==='USER'){
      loadDataDetail({start, tab, sortValue})
    }else{
      loadUserDetail({start, sortValue})
    }
  }

  const onChangePage = newPage => {
    loadDetail({start:(newPage-1)*10})
    setPage(newPage)
  }

  useEffect(()=>{
    if(!modalOpen || !object){
      setPage(1)
      setData({})
      return;
    }
    if(data.loading)return;
    loadDetail({})
  // eslint-disable-next-line
  },[modalOpen, object])
  
  if(!object)return <div></div>

  let columns;
  if(object.object_type_srt==='USER'){
    columns = [
      {name:'Name',field:'name_srt',sortable:true},
      {name:'Source',field:'source_srt',sortable:true},
      {name:'Type',field:'object_type_srt',sortable:true},
      {name:'Owner',field:'owners_msrt'}
    ]
  }
  else{
    columns = [
      {name:'Name',field:'name_srt',sortable:true},
      {name:'Type',field:'user_type_srt',sortable:true},
      {name:'Team',field:'teams_msrt'}
    ]
  }

  let flexConfig = {};
  columns.forEach(c=>{
    if(c.field==='max_level_srt'){
      flexConfig[c.field] = '0 0 80px'
    }
    else if(c.field==='name_srt'){
      flexConfig[c.field] = '2 0 280px'
    }
    else{
      flexConfig[c.field] = `1 1 136px`
    }
  })

  // returns a list of page numbers e.g. [1,2,3,4], ['...',5,6,7,'...',30]
  const generatePageList = () => {
    let totalPages = data.numFound>0?Math.ceil(data.numFound/10):1;
    let pages = [];
    if(totalPages<=5){
      for(let i=1; i<=totalPages; i++){
        pages.push(i)
      }
      return pages;
    }
    if([1,2].includes(page)){
      return [1,2,3,'...',totalPages]
    }
    if([totalPages, totalPages-1].includes(page)){
      return [1,'...',totalPages-2,totalPages-1,totalPages];
    }
    return [1,'...',page-1, page, page+1,'...',totalPages]
  }

  let impactedItemName = 'USERS'
  if(object.object_type_srt==='USER'){
    impactedItemName = tabState===0?'DATA ASSETS':'CONTENT'
  }

  const getSortIcon = column => {
    if(sort===`${column} asc` ){
      return <AscIcon style={{width:16,height:16,marginLeft:6,color:theme.palette.primaryText.main,position:'relative',bottom:1}}/>
    }
    if(sort===`${column} desc`){
      return <DescIcon style={{width:16,height:16,marginLeft:6,color:theme.palette.primaryText.main,position:'relative',bottom:1}}/>
    }
  }

  const setColumnSort = field => {
    let newSort;
    if(sort===`${field} asc`){
      newSort = `${field} desc`
    }else{
      newSort = `${field} asc`
    }
    setSort(newSort)
    loadDetail({start:0, sortValue:newSort})
  }

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <Paper style={{margin:'7vh auto 0 auto',width:800,maxWidth:'90%',overflow:'auto',maxHeight:'90%',boxSizing:'border-box',padding:24,paddingBottom:32,paddingTop:0,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start',marginBottom:24,position:'sticky',top:0,background:theme.palette.background.main,paddingTop:24}}>
          <Typography className={classes.title} style={{marginRight:12}}>{data?data.numFound:0} IMPACTED {impactedItemName} OF {object.object_type_srt.replace(/_/g,' ')} {object.name_srt}</Typography>
          <Button variant='outlined' color="primary" onClick={()=>{setModalOpen(false)}}>CLOSE</Button>
        </div>
        {
          object.object_type_srt==='USER' && 
          <div style={{marginLeft:-56,marginBottom:16,marginTop:-12}}>
            <TabBar
              tabOptions={['DATA','CONTENT']}
              tabState={tabState}
              setTabState={value=>{
                setTabState(value)
                setPage(1)
                loadDetail({start:0, tab:value})
              }}
              minWidth={200}
              maxWidth={200}
              disableUnderline={true}
            />
          </div>  
        }
        {
          data && data.numFound>0 && 
          <div className={classes.columnRow}>
            {
              data && data.numFound>0 && columns.map(c=>(
                <Typography onClick={()=>c.sortable && setColumnSort(c.field)} style={{flex:flexConfig[c.field],cursor:c.sortable?'pointer':undefined}} className={classes.columnText}>
                  {c.name}
                  {getSortIcon(c.field)}
                </Typography>
              ))
            }
          </div>
        }
        <div style={{height:490}}>
          {
            data && data.docs && data.docs.map(d=>(
              <div>
                <ContextMenuTrigger id={d.id}>
                  <div 
                    className={classes.tableRow}
                    onClick={()=>{
                      let item = {...d,id:d.object_id}
                      onClickResultItem({item, id:item.id, label:item.object_type_srt,newWindow:true})
                    }}
                  >
                    {
                      columns.map(c=>(
                        c.field==='name_srt' && d.object_type_srt!=='USER'?
                        <div style={{flex:flexConfig[c.field], marginRight:16, overflow:'hidden'}}>
                          <KTooltip title={getDispFields(d, 'dispTitle')}>
                            <Typography className={classes.tableNameText} >
                              {getDispFields(d, 'dispTitle')}
                            </Typography>
                          </KTooltip>
                          <KTooltip title={getDispFields(d, 'dispSubtitle')}>
                            <Typography className={classes.tableText} style={{marginRight:0}} >
                              {getDispFields(d, 'dispSubtitle')}
                            </Typography>
                          </KTooltip>
                        </div>
                        :
                        <KTooltip title={d[c.field]} >
                          <Typography style={{flex:flexConfig[c.field], paddingLeft:c.field==='max_level_srt'?16:0}} className={classes.tableText}>
                            {d[c.field]}
                          </Typography>
                        </KTooltip>
                      ))
                    }
                  </div>
                </ContextMenuTrigger>
                <ContextMenu id={d.id}>
                  <CustomMenu
                    item={{...d,id:d.object_id}}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </div>
            ))
          }
          {
            data.loading && 
            <div style={{display:'flex',justifyContent:'center'}}>
              <CircularProgress color='secondary'/>
            </div>
          }
          {
            data.error && 
            <Typography>Error occurred loading details</Typography>
          }
          {
            data.numFound===0 && 
            <Typography>No item found</Typography>
          }
        </div>
        {
          !isNaN(data.numFound) && 
          <div style={{display:'flex',justifyContent:'center',marginTop:24}}>
            {
              generatePageList().map((el,index)=>{
                if(typeof(el)=='number'){
                  return (
                    <Button 
                      variant={el===page?'contained':'outlined'} 
                      color='primary' 
                      data-test-id={`detail-pagination-button-${el}`}
                      className={classes.pageBtn} 
                      style={{marginRight:index===generatePageList().length-1?0:16}}
                      onClick={()=>onChangePage(el)}
                    >
                      {el}
                    </Button>
                  )
                }
                else{
                  return <MoreIcon style={{width:24,height:24,marginRight:16,color:theme.palette.primaryText.light}}/>
                }
              })
            }
          </div>
        }
      </Paper>
    </Modal>
  )
}

DetailModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DetailModal));