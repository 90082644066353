import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, CircularProgress, Typography, Checkbox} from '@material-ui/core';
import {useStore} from 'react-redux'
import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import { mapSearchObjectName, getIconComponent, mapObjectName } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';

const styles = theme => ({
  tableRow:{
    display:'flex',
    alignItems:'center',
    marginLeft:16
  },
  itemRow:{
    display:'flex',
    alignItems:'center',
    marginLeft:16,
    fontSize:12,
    color:theme.palette.primaryText.main,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main
  },
  tooltip:{
    fontSize:13.75
  },
})

function ChooseItem(props) {

  const {
    theme,
    classes,
    state,
    dispatch,
  } = props;

  const store = useStore();
  const [nameSrt, setNameSrt] = useState();

  useEffect(()=>{
    let url = store.getState().bulkEdit.url;
    let params = store.getState().bulkEdit.params;
    let type = store.getState().bulkEdit.type;
    if(state.itemList || !url)return;
    if(url.includes('/solr/search'))params.fl = 'id,name_txt,location_txt,description,object_type_txt,code_type_txt'
    dispatch({
      type:'set_item_list',itemListLoading:true
    })
    if(type==='solr'){
      axiosSolr
        .get(url,{params})
        .then(response=>{
          let list = response.data.response.docs;
          if(url.includes('/solr/search')){
            list = list.map(el=>({
              id:el.id,
              name:el.name_txt,
              location:el.location_txt,
              description:el.description,
              labels:el.object_type_txt==='CODE'?el.code_type_txt:mapSearchObjectName(el.object_type_txt)
            }))
          }
          dispatch({
            type:'set_item_list',itemList:list.map(el=>({...el,selected:true}))
          })
        })
        .catch(error=>{
          console.log(error);
          dispatch({
            type:'set_item_list',itemListError:true
          })
        })
    }
    if(type==='cerebrum'){
      axiosCerebrum
        .get(url,{params})
        .then(response=>{
          axiosSolr
            .get(
              '/solr/search/select',
              {params:{
                q:'*',
                fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                fl:'labels:object_type_txt,id,location:location_txt,name:name_txt,description',
                rows:response.data.items.length
              }}
            )
            .then(response=>{
              dispatch({
                type:'set_item_list',itemList:response.data.response.docs.map(el=>({...el,selected:true}))
              })
            })
            .catch(error=>{
              console.log(error);
              dispatch({
                type:'set_item_list',itemListError:true
              })
            })
        })
        .catch(error=>{
          console.log(error);
          dispatch({
            type:'set_item_list',itemListError:true
          })
        })
    }

  // eslint-disable-next-line
  },[])

  const onSelectAll = (checked) => {
    dispatch({
      type:'set_item_list',itemList:state.itemList.map(el=>({...el,selected:checked}))
    })
  }

  const onSelectItem = (checked, item) => {
    dispatch({
      type:'set_item_list',itemList:state.itemList.map(el=>{
        if(el.id===item.id){
          return {...el,selected:checked}
        }else{
          return el;
        }
      })
    })
  }

  const getList = list => {
    if(!nameSrt)return list;
    if(nameSrt==='asc')return list.sort((a,b)=>a.name<b.name?-1:1)
    if(nameSrt==='desc')return list.sort((a,b)=>a.name<b.name?1:-1)
  }


  return (
    <div className={classes.root}>
      {
        !store.getState().bulkEdit.url && <Typography style={{color:theme.palette.primaryText.main}}>No item selected for bulk update.</Typography>
      }
      {
        state.itemListLoading && <CircularProgress color='secondary'/>
      }
      {
        state.itemListError && <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading items.</Typography>
      }
      {
        state.itemList && 
        <div>
          <Typography style={{fontSize:20,marginBottom:4,color:theme.palette.header.main,}}>{state.itemList.filter(el=>el.selected).length} {state.itemList.length>0?mapObjectName(state.itemList[0].labels):'ITEM'}(S) TO UPDATE</Typography>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>Bulk update is limited to 1k item(s)</Typography>
          <div className={classes.tableRow}>
            <KTooltip classes={{tooltip:classes.tooltip}} title={state.itemList.every(el=>el.selected)?'Unselect all':'Select all'}>
              <div className={classes.tableTitle} style={{width:76,flexShrink:0}}>
                <Checkbox
                  color='primary'
                  data-test-id="select-all-checkbox"
                  checked={state.itemList.every(el=>el.selected)}
                  onChange={(event,checked)=>onSelectAll(checked)}
                  className={classes.checkbox}
                />
              </div>
            </KTooltip>
            <div className={classes.tableTitle} style={{width:150,flexShrink:0,marginLeft:16}}>
              TYPE
            </div>
            <div className={classes.tableTitle}  style={{flex:'1 0 242px',paddingRight:8,alignItems:'center'}}>
              <span style={{fontSize:12,letterSpacing:2,cursor:'pointer',marginRight:8}} onClick={()=>setNameSrt(nameSrt==='asc'?'desc':'asc')}>
                NAME
              </span>
              {
                nameSrt==='asc' && 
                getIconComponent({label:'up',size:16,colour:theme.palette.primary.main})
              }
              {
                nameSrt==='desc' && 
                getIconComponent({label:'down',size:16,colour:theme.palette.primary.main})
              }
            </div>
            <div className={classes.tableTitle}  style={{width:192,paddingright:8,flexShrink:0,flexGrow:1}}>
              DESCRIPTION
            </div>
            <div className={classes.tableTitle}  style={{width:500,flexShrink:1,flexGrow:1}}>
              LOCATION
            </div>
          </div>
          {
            getList(state.itemList).map(el=>(
              <div className={classes.itemRow} data-test-classname="list-item">
                <div className={classes.tableTitle} style={{width:76,flexShrink:0}}>
                  <Checkbox
                    data-test-classname="select-checkbox"
                    color='primary'
                    checked={el.selected?true:false}
                    onChange={(event,checked)=>onSelectItem(checked,el)}
                    className={classes.checkbox}
                  />
                </div>
                <div style={{width:150,flexShrink:0,marginLeft:16}} data-test-classname="list-item-object-type">
                  {el.labels.replace(/_/g,' ')}
                </div>
                <div style={{flex:'1 0 242px',paddingRight:8,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}} data-test-classname="list-item-name">
                  {el.name}
                </div>
                <div style={{width:192,flexShrink:0,flexGrow:1,wordBreak:'break-word', padding:"16px 8px 16px 0"}} data-test-classname="list-item-description">
                  {el.description && el.description.length>100?el.description.slice(0,97)+'...':el.description}
                </div>
                <div style={{width:500,flexShrink:1,flexGrow:1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}} data-test-classname="list-item-location">
                  {el.location || el.nodeKey}
                </div>
              </div>
            ))
          }
        </div>
      }

    </div>
  )
}

ChooseItem.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ChooseItem));