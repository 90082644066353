import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, LinearProgress, Typography, Button} from '@material-ui/core';
import { useStore, useDispatch} from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { sendMessage } from '../../../utilities';

const styles = theme => ({
  barColor:{
    background:'#7ED321'
  }
})

function LoadingScreen(props) {

  const {
    theme,
    classes,
    state,
    history,
    editMode,
  } = props;

  const store = useStore();  
  const reduxDispatch = useDispatch()

  useEffect(()=>{
    if(state.batchComplete && store.getState().bulkEdit.redirectUrl){
      reduxDispatch({
        type:actions.REMOVE_PAGE_CACHE,
        data:document.location.protocol + "//" + document.location.host + (store.getState().bulkEdit.redirectUrl || '/home')
      })
    }
  // eslint-disable-next-line
  },[state.batchComplete])

  const onClose = () => {
    history.push(store.getState().bulkEdit.redirectUrl||'/home');
    if(editMode==='cart_bulk_edit'){
      setTimeout(()=>{
        sendMessage({
          clear_cart_items:true,
          ids:state.itemList.map(el=>el.id)
        })
      },666)
    }
  }

  return (
    <div className={classes.root}>
      {
        state.batchLoading && 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:200}}>
          <LinearProgress color='secondary' style={{width:400,height:8}}/>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:24}}>Bulk update in progress.</Typography>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:4}}>Note: Updates may not appear in Search results immediately.</Typography>
        </div>
      }
      {
        state.batchComplete && 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:200}}>
          <LinearProgress variant='determinate' style={{width:400,height:8}} classes={{barColorPrimary:classes.barColor}}/>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:24}}>Bulk update completed.</Typography>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:4}}>Note: Updates may not appear in Search results immediately.</Typography>
          <Button color='primary' variant='contained' onClick={onClose} style={{marginTop:24}}>
            {editMode==='cart_bulk_edit'?"RETURN TO CART":'CLOSE'}
          </Button>
        </div>
      }
    </div>
  )
}

LoadingScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LoadingScreen));