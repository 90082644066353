import React, { useState, useRef} from 'react';
import { withTheme, withStyles, TextField, InputAdornment, IconButton, Popper } from '@material-ui/core';
import { getCustomerName, getIconComponent, getPartialMatchSearchString, getUserRoles, onClickResultItem } from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import Suggestions from './Suggestions'
import { useStore } from 'react-redux'
import { withRouter } from 'react-router-dom'
import KTooltip from '../../UI/KTooltip/KTooltip';
import SearchModeSelector from '../SearchModeSelector/SearchModeSelector';

const styles = theme => ({
  inputBase:{
    color:`${theme.palette.primaryText.main} !important`,
    fontSize:20,
    // paddingLeft:8,
    marginTop:`0 !important`,
    // padding:'8px 0'
  },
  inputField:{
    width:'100%',
    backgroundColor:theme.palette.chip.main,
    borderRadius:4,
    '& input':{
      padding:'8px 0'
    },
    border:`1px solid ${theme.palette.border.main}`,
    // marginLeft:-1
  },
  inputlabel:{
    color:`${theme.palette.primaryText.main}`,
    fontSize:20,
    marginTop:-12,
    marginLeft:48,
  },
  smallInputlabel:{
    fontSize:16,
    marginTop:-13
  },
  inputLabelFocused:{
    display:'none',
    color:`${theme.palette.primary.main} !important`,
  },
  inputLabelShrink:{
    color:theme.palette.primary.main,
    marginTop:-20,
    marginBottom:4,
    marginLeft:0,
  },
  searchBarWrapper:{
    width:'100%',
    paddingBottom:1,
    display:'flex',
    alignItems:'center',
    overflow:'hidden'
  },
  textFieldContainer:{
    background:`${theme.palette.background.main} !important`,
  },
  searchIcon:{
    padding:8
  },
  hiddenLabel:{
    visibility:'hidden'
  },
});



const SearchBar = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
    onSearch,
    hideLabel,
    placeholder,
    isPlugin,
    indexName='search'
  } = props;

  const [anchor, setAnchor] = useState()
  const [popperOpen, setPopperOpen] = useState(false)
  const [suggestions, setSuggestions] = useState({loading:false,error:false,suggestionList:undefined,highlightList:undefined})
  const searchTimeoutRef = useRef()
  const [helpIconHovered, setHelpIconHovered] = useState(false)

  const store = useStore();
  let sessionData = store.getState().auth.session_user;
  let roles = getUserRoles(sessionData.user_role)

  let customerName = getCustomerName()

  let searchPlaceHolder = `Search ${customerName===''||isPlugin?'your':('the ' + customerName)} data ecosystem`;
  if(placeholder)searchPlaceHolder = placeholder;

  const onClickSearch = () => {
    clearTimeout(searchTimeoutRef.current)
    setPopperOpen(false)
    if(state.view==='landing' && !isPlugin){
      dispatch({type:'set_view',view:'main_search'})
    }
    onSearch({searchStr:state.searchValue,isFiredBySearchBar:true,isFromLandingPage:state.view==='landing'});
  }

  const onClickSuggestion = (obj,isHistory) => {
    if(!isHistory){
      onClickResultItem({label:obj.object_type_txt,id:obj.id,item:obj,history})
      return;
    }
    if(state.view==='landing' && !isPlugin){
      dispatch({type:'set_view',view:'main_search'})
    }
    let searchValue, objectType;
    if(isHistory){
      searchValue = obj.terms;
    }else{
      if(obj.isHL){
        searchValue = obj.query;
        objectType = obj.object_type;
      }else{
        searchValue = obj.name_txt;
        objectType = obj.object_type_txt;
        if(objectType==='COLLECTION_INSTANCE'){
          objectType = obj.collection_type_txt;
        }
      }
      if(!isPlugin){
        dispatch({type:'set_selected_object_type',selectedObjectType:objectType})
      }
    }
    dispatch({type:'set_search_value',searchValue:searchValue})
    onSearch({searchStr:searchValue, objectType,isFiredBySearchBar:true,isFromLandingPage:state.view==='landing'})
    setPopperOpen(false)
  }

  const onClearInput = () => {
    dispatch({type:'set_search_value',searchValue:''})
  }

  const getHistory = () => {
    if(indexName!=='search')return;
    setSuggestions({loading:true, isHistory:true});
    axiosCerebrum 
      .get('/api/me/searches',{params:{per_page:20,sort:'END_DESC'}})
      .then(response=>{
        setSuggestions({suggestionList:response.data.items,isHistory:true})
      })
      .catch(error=>{
        console.log(error)
        setSuggestions({error:true,isHistory:true})
      })
  }

  const getSuggestionOrder = (suggestion) => {
    let order = {
      'CONTENT_APP':10,
      'REPORT':20,
      'SHEET':30,
      'DATASET':33,
      'DATASET_TABLE':35,
      'DATASET_FIELD':37,
      'TABLE':40,
      'COLUMN':50,
      'GLOSSARY':60,
      'KNOWLEDGE':70,
      'LIST':80,
      'DATA_GOVERNANCE':90,
      'DATA_MANAGEMENT':100,
      'DATABASE':110,
      'SCHEMA':120,
      'DATA_PIPELINE':130,
      'FILE':140,
      'USER':150,
      'TEAM':160,
      'TAG':170,
      'ISSUE':180,
      'DATA_QUALITY_TEST':190
    }
    if(roles.every(el=>el==='20' || el==='90')){
      order = {
        ...order,
        'DATABASE':70,
        'SCHEMA':60,
        'GLOSSARY':80,
        'KNOWLEDGE':90,
        'LIST':100,
        'DATA_GOVERNANCE':110,
        'DATA_MANAGEMENT':120,
      }
    }
    return order[suggestion.groupValue] || 200
  }

  const getSuggestions = async (query) => {
    setSuggestions({loading:true})
    let additionalFilters = [
      '-object_type_srt:(GENERAL_EVENT)',
      'active_srt:YES',
      'reference_srt:NO'
    ];
    if(state.view==='landing' && state.selectedObjectType && state.selectedObjectType!=='TERM'){
      additionalFilters.push(`object_type_srt:${state.selectedObjectType}`)
    }
    else if(state.view==='landing' && state.selectedObjectType==='TERM'){
      additionalFilters.push(`collection_type_srt:GLOSSARY`)
    }
    let groups = [];
    let error = false;
    let genericPayload = {
      q:getPartialMatchSearchString(query),
      fq:[...additionalFilters, '-object_type_srt:(COLLECTION_INSTANCE OR COLLECTION)'].join(' AND '),
      "qf": "name_txt name_srt alternate_name_srt alternate_name_txt",
      "group.limit": 3,
      "qs": "3",
      "pf": "name_txt alternate_name_txt",
      "ps": "3",
      "bf": "product(relevance_weight_srt,12)",
      "mm": "1<1 3<50%",
      "group": true,
    }
    await axiosSolr
      .get(
        `/solr/${indexName}/select`,
        {params:{
          "group.field": "object_type_srt",
          ...genericPayload
        }}
      )
      .then(response=>{
        groups.push(...response.data.grouped.object_type_srt.groups)
      })
      .catch(error=>{
        console.log(error)
        error = true;
      })
    if(error){
      setSuggestions({error:true})
      return;
    }

    genericPayload.fq = [...additionalFilters, 'object_type_srt:(COLLECTION_INSTANCE OR COLLECTION)','-collection_type_srt:(AUTOMATED OR CHANGE OR KNOWLEDGE)'].join(' AND ')

    await axiosSolr
      .get(
        `/solr/${indexName}/select`,
        {params:{
          "group.field": "collection_type_srt",
          ...genericPayload
        }}
      )
      .then(response=>{
        groups.push(...response.data.grouped.collection_type_srt.groups)
      })
      .catch(error=>{
        console.log(error)
        error = true;
      })
    if(error){
      setSuggestions({error:true})
      return;
    }
    groups.sort((a,b)=>getSuggestionOrder(a) - getSuggestionOrder(b))
    setSuggestions({
      suggestionList:groups,
    })
  }

  const onInputChange = event => {
    dispatch({type:'set_search_value',searchValue:event.target.value})
    clearTimeout(searchTimeoutRef.current)
    if(event.target.value.trim()===''){
      setPopperOpen(false)
      return;
    }
    let value = event.target.value;
    let el = event.currentTarget.parentElement;
    searchTimeoutRef.current = setTimeout(()=>{
      setAnchor(el)
      setPopperOpen(true)
      getSuggestions(value)
    },250)
  }

  const onInputClick = event => {
    if(popperOpen)return;
    setAnchor(event.currentTarget)
    setPopperOpen(true)
    if(state.searchValue.trim()==='' ){
      getHistory();
    }else{
      getSuggestions(state.searchValue)
    } 
  }

  let searchValue = state.searchValue + (state.mainSearchUrlFilter && state.mainSearchUrlFilter.length>0?` AND ${state.mainSearchUrlFilter.join(' AND ')}`:'')

  return (
    <div className={classes.searchBarWrapper} id="basic_search_bar_wrapper">
      <TextField
        InputLabelProps={{
          className:classes.inputlabel + (isPlugin?' ' + classes.smallInputlabel:''),
          classes:{
            shrink:classes.inputLabelShrink,
            focused:classes.inputLabelFocused,
          },
          shrink:searchValue!==''
        }}
        InputProps={{
          className:classes.inputBase,
          disableUnderline:true,
          // placeholder:searchPlaceHolder,
          onKeyDown: event => { 
            if (event.keyCode === 13) { 
              onClickSearch();
          }},
          onClick:onInputClick,
          startAdornment: (
            <InputAdornment position="start" >
              <IconButton
                onClick={onClickSearch}
                className={classes.searchIcon}
                disabled={state.searchValue===''}
              >
                {
                  getIconComponent({label:'search',size:24,colour:theme.palette.primaryText.light})
                }
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" >
              {
                state.searchValue!=='' &&
                <IconButton
                  onClick={onClearInput}
                  className={classes.searchIcon}
                  style={{marginRight:6}}
                  id="basic_seach_bar_clear_button"
                >
                  {
                    getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
                  }
                </IconButton>
              }
              {
                !isPlugin && 
                <KTooltip title="Search tips including Advance Filtering">
                  <IconButton
                    id="basic_search_bar_help_button"
                    onClick={()=>window.open('https://kada-knowledge-library.scrollhelp.site/home/searching-for-data','_blank')}
                    className={classes.searchIcon}
                    style={{marginRight:8}}
                    onMouseEnter={()=>setHelpIconHovered(true)}
                    onMouseLeave={()=>setHelpIconHovered(false)}
                  >
                    {
                      getIconComponent({label: helpIconHovered?'help_contained':'help',size:24,colour:theme.palette.primaryText.light})
                    }
                  </IconButton> 
                </KTooltip>
              }
            </InputAdornment>
          ),
        }}
        disableUnderline={true}
        className={classes.inputField}
        // placeholder={hideLabel?'':searchPlaceHolder}
        label={hideLabel?'':searchPlaceHolder}
        value={searchValue}
        onChange={event=>onInputChange(event)}
      />
      <Popper anchorEl={anchor} style={{width:anchor?anchor.getBoundingClientRect().width+2:undefined,minWidth:600,marginTop:2,marginLeft:-2}} open={popperOpen && indexName==='search'} placement="bottom-start">
        <Suggestions
          isHistory={suggestions.isHistory}
          loading={suggestions.loading}
          error={suggestions.error}
          suggestionList={suggestions.suggestionList}
          // highlightList={suggestions.highlightList}
          setPopperOpen={setPopperOpen}
          onClickSuggestion={onClickSuggestion}
          isPlugin={isPlugin}
        />
      </Popper>
      {
        !isPlugin && 
        <SearchModeSelector
          state={state}
          dispatch={dispatch}
          onSearch={onSearch}
        />
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(withRouter(SearchBar)));
