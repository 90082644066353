import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withTheme, Typography, withStyles, Button, Modal, Paper,  CircularProgress, Divider } from '@material-ui/core';
import {collectionIds, getIconComponent, getLabelPlural, mapObjectName, mapSearchObjectName, toTitleCase} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import {checkFilled,EditFields} from '../CollectionInstanceEditFields/CollectionInstanceEditFields';
import SearchSelector from '../SearchSelector/SearchSelector';
import {useStore} from 'react-redux'

const styles = theme => ({
  modalContainer:{
    width:880,
    maxWidth:'85vw',
    margin:'auto',
    marginTop:'15vh',
    outline:'none',
  },
  formBody:{
    // minHeight:'60vh',
    paddingLeft:24,
    paddingBottom:8,
    paddingRight:24,
    paddingTop:24,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    overflow:'hidden'
  },
  modalTitle:{
    fontSize:20,
    color:theme.palette.primary.main,
    marginBottom:8,
    wordBreak:'break-word'
  },
  listContainer:{
    maxHeight:'20vh',
    overflow:'auto',
    marginTop:8,
    marginBottom:24,
    ...theme.components.customScroll
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  listItemContainer:{
    display:'flex',
    alignItems:'center',
    height:47,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    overflow:'hidden'
  },
  captionTitle:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:2
  }
})

function ListModalAdder(props) {

  const {
    theme,
    classes,
    object,
    modalOpen,
    setModalOpen,
    state,
    dispatch
  } = props;

  let objectLabel = object.object_type_txt || object.labels;
  if(object.object)objectLabel = object.object.name;
  objectLabel = mapSearchObjectName(objectLabel, object.code_type_txt)

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const [linkedList, setLinkedList] = useState()
  const [myList, setMyList] = useState()

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [searchValue, setSearchValue] = useState('')
  const modalStates = ['link','create']
  const [modalState, setModalState] = useState(modalStates[0])

  const [listTemplateData, setListTemplateData] = useState()
  const [values, setValues] = useState({});

  const [userMap, setUserMap] = useState({})
  const [collectionMap, setCollectionMap] = useState({})

  const loadLinkedList = ({page=1,previousList=[]}) => {
    if(page===1){
      setLinkedList({loading:true,error:false})
    }
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(objectLabel)}/${object.id}/related/collections`,
        {params:{
          parent_name:'List',
          per_page:50,
          page
        }}
      )
      .then(response=>{
        if(response.data.page<response.data.pages){
          loadLinkedList({page:response.data.page+1,previousList:[...previousList,...response.data.items]})
        }else{
          setLinkedList({data:[...previousList,...response.data.items]})
        }
      })
      .catch(error=>{
        console.log(error)
        setLinkedList({error:true})
      })
  }

  const loadMyList = ({page=1,previousList=[]}) => {
    if(page===1){
      setMyList({loading:true,error:false})
    }
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/collections`,
        {params:{
          parent_name:'List',
          per_page:50,
          relationship:'MODIFIES,CREATOR_OF',
          page,
          sort:'END_DESC'
        }}
      )
      .then(response=>{
        if(response.data.page<response.data.pages){
          loadMyList({page:response.data.page+1,previousList:[...previousList,...response.data.items]})
        }else{
          let finalList = [];
          [...previousList,...response.data.items].forEach(el=>{
            if(!finalList.find(l=>l.id===el.id))finalList.push(el)
          });
          setMyList({data:finalList})
        }
      })
      .catch(error=>{
        console.log(error)
        setMyList({error:true})
      })
  }

  const loadListTemplateData = () => {
    setListTemplateData({loading:true})
    axiosCerebrum
      .get(`/api/collections/${collectionIds.list}`)
      .then(response=>{
        setListTemplateData({data:response.data})
      })
      .catch(error=>{
        setListTemplateData({error:true})
      })
  }

  useEffect(()=>{
    if(modalOpen){
      loadLinkedList({})
    }
    if(modalOpen && (!myList || myList.error)){
      loadMyList({})
    }
  // eslint-disable-next-line
  },[modalOpen,modalState])

  useEffect(()=>{
    if(modalState===modalStates[1] && (!listTemplateData  || listTemplateData.error)){
      loadListTemplateData()
    }
  // eslint-disable-next-line
  },[modalState])

  const setModifiedUser = (list) => {
    axiosCerebrum
      .put(`/api/me/follows/${list.id}?type=OPT_IN`)
      .catch(error=>{
        console.log(error)
      })
    if(myList && myList.data && myList.data.find(l=>l.id===list.id))return;
    axiosCerebrum
      .put(
        `/api/collectioninstances/${list.id}/related?relationship=MODIFIED_BY&object_id=${sessionData.id}`
      )
      .catch(error=>{
        console.log(error)
      })

  }

  const refreshNoteTab = () => {
    if(!state || !state.notes || !dispatch)return;
    dispatch({
      type:'set_notes'
    })
  }

  const onAddList = (list) => {
    axiosCerebrum
      .put(`/api/collectioninstances/${list.id}/related?object_id=${object.id}&relationship=MEMBERS`)
      .then(response=>{
        if(linkedList && linkedList.data){
          setLinkedList({data:[response.data,...linkedList.data]})
        }
        axiosCerebrum
          .put(`/api/me/follows/${list.id}?type=OPT_IN`)
          .catch(error=>{
            console.log(error)
          })
        setModifiedUser(list)
        refreshNoteTab()
      })
      .catch(error=>{
        let msg = `Error occurred adding the list, please try again`
        if(error.response && error.response.status && error.response.status===409){
          msg = 'The list is already added'
        }
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  const onRemoveList = (list) => {
    axiosCerebrum
      .delete(`/api/collectioninstances/${list.id}/related?object_id=${object.id}&relationship=MEMBERS`)
      .then(response=>{
        if(linkedList && linkedList.data){
          setLinkedList({data:linkedList.data.filter(el=>el.id!==list.id)})
        }
        setModifiedUser(list)
        refreshNoteTab()
      })
      .catch(error=>{
        let msg = `Error occurred deleting the list, please try again`
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  const onCreateList = () => {
    axiosCerebrum
      .post(
        `/api/collectioninstances`,
        {
          "collection_id": collectionIds.list,
          "description": (values['description'] || '').replace(/^(<br>|\s)*/,'').replace(/(<br>|\s)*$/,''),
          "name": values['name'],
          "properties": {
          },
          "source_id": 1000
        }
      )
      .then(response=>{
        if(myList && myList.data){
          setMyList({data:[{...response.data,isNewList:true},...myList.data]})
        }
        axiosCerebrum
          .put(`/api/me/follows/${response.data.id}?type=OPT_IN`)
          .catch(error=>{
            console.log(error)
          })
        setValues({})
        setModalState(modalStates[0])
      })
      .catch(error=>{
        setAlertMessage('Error occurred creating the list, please try again')
        setAlertOpen(true)
      })
  }

  const generateListItem = ({list, action}) => {
    return (
      <div className={classes.listItemContainer}>
        <div style={{width:24,height:24,marginLeft:16,marginRight:32,flexGrow:0,flexShrink:0}}>
          {getIconComponent({label:'list',size:24,colour:theme.palette.primary.main})}
        </div>
        <Typography style={{marginRight:16,flexGrow:1,flexShrink:1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',color:theme.palette.primaryText.main}}>
          {list.name}
        </Typography>
        {
          list.isNewList && 
          <div style={{height:28,borderRadius:14,width:50,background:'#ff9801',color:'#fff',fontSize:13.75,display:'flex',justifyContent:'center',alignItems:'center',marginRight:8}}>
            New
          </div>
        }
        {
          action==='remove' && 
          <Button data-test-id={`list-remove-button-${list.name.replace(/\s+/g,'-').toLowerCase()}`} color='secondary' variant='outlined' onClick={()=>onRemoveList(list)} style={{marginRight:8,padding:'2px 8px'}}>REMOVE</Button>
        }
        {
          action==='add' && 
          <Button data-test-id={`list-add-button-${list.name.replace(/\s+/g,'-').toLowerCase()}`} color='primary' variant='outlined' onClick={()=>onAddList(list)} style={{marginRight:8,padding:'2px 8px'}}>ADD</Button>
        }
      </div>
    )
  }

  let title;
  if(modalState===modalStates[0])title = `Add ${object.name || object.name_txt} to list(s)`
  if(modalState===modalStates[1])title = `Create a new list`

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    >
      <div className={classes.modalContainer}>
        {
          modalState === modalStates[0] && 
          <Paper className={classes.formBody}>
            <Typography className={classes.modalTitle}>{title}</Typography>
            <Button color='secondary' style={{marginBottom:16,paddingLeft:0,paddingRight:0}} onClick={()=>setModalState(modalStates[1])}>CREATE A NEW LIST</Button>
            <Divider style={{background:theme.palette.listItemDivider.main}}/>
            <div style={{marginTop:24,marginBottom:24}}>
              <SearchSelector
                url='/solr/search/select'
                params={{
                  q: `${searchValue}*`,
                  fq:`object_type_srt:COLLECTION_INSTANCE AND collection_srt:LIST` + (linkedList && linkedList.data && linkedList.data.length>0?` AND -id:(${linkedList.data.map(el=>el.id).join(' OR ')})`:'') ,
                  fl:'*',
                  rows:20
                }}
                autoFocus
                testID="list-modal-search"
                removeFLModify={false}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder='Search for a list' 
                onResultClick={el=>onAddList(el)}
              />
            </div>
            <Typography className={classes.captionTitle}>
              ADDED TO LISTS
            </Typography>
            <div className={classes.listContainer}>
              {
                linkedList && linkedList.loading && 
                <CircularProgress color='secondary'/>
              }
              {
                linkedList && linkedList.error && 
                <Typography className={classes.normalText}>Error occurred loading list</Typography>
              }
              {
                linkedList && linkedList.data && linkedList.data.length===0 && 
                <Typography className={classes.normalText}>This item does not belong to any list</Typography>
              }
              {
                linkedList && linkedList.data && linkedList.data.map(el=>generateListItem({list:el,action:'remove'}))
              }
            </div>
            <Typography className={classes.captionTitle}>
              MY LISTS
            </Typography>
            <div className={classes.listContainer}>
              {
                myList && myList.loading && 
                <CircularProgress color='secondary'/>
              }
              {
                myList && myList.error && 
                <Typography className={classes.normalText}>Error occurred loading list</Typography>
              }
              {
                myList && myList.data && (myList.data.length===0 || myList.data.filter(el=>!linkedList || !linkedList.data || !linkedList.data.find(l=>l.id===el.id)).length===0) && 
                <Typography className={classes.normalText}>No list found</Typography>
              }
              {
                myList && myList.data && myList.data.filter(el=>!linkedList || !linkedList.data || !linkedList.data.find(l=>l.id===el.id)).map(el=>generateListItem({list:el,action:'add'}))
              }
            </div>
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:36}}>
              <Button data-test-id="list-modal-close-button" color='primary' onClick={()=>setModalOpen(false)}>CLOSE</Button>
            </div>
          </Paper>
        }

        {
          modalState === modalStates[1] && 
          <Paper className={classes.formBody} style={{maxHeight:'65vh',overflow:'auto'}}>
            <Typography className={classes.modalTitle} style={{marginBottom:24}}>{title}</Typography>
            {
              listTemplateData && listTemplateData.error && 
              <Typography className={classes.normalText}>Error occurred loading template</Typography>
            }
            {
              listTemplateData && listTemplateData.loading && 
              <div style={{display:'flex',justifyContent:'center'}}><CircularProgress color='secondary'/></div>
            }
            <div className={classes.listContainer} style={{maxHeight:'50vh',paddingRight:2}}>
              {
                listTemplateData && listTemplateData.data && 
                <EditFields
                  values={values}
                  properties={listTemplateData.data.properties}
                  setValues={setValues}
                  collectionMap={collectionMap}
                  setCollectionMap={setCollectionMap}
                  userMap={userMap}
                  setUserMap={setUserMap}
                  collection={listTemplateData.data}
                  objectLabel={toTitleCase(mapObjectName(objectLabel).replace(/_/g,' '))}
                />
              }
            </div>
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:36}}>
              <Button data-test-id="list-modal-create-button" color='primary' style={{marginRight:8}} disabled={!listTemplateData || !listTemplateData.data || !checkFilled(listTemplateData.data.properties,values)} onClick={()=>onCreateList()}>CREATE</Button>  
              <Button data-test-id="list-modal-cancel-button" color='secondary' onClick={()=>{setValues({});setModalState(modalStates[0]);}}>CANCEL</Button>
            </div>
          </Paper>
        }
       
        <ModalAlert
          setOpen={setAlertOpen}
          open={alertOpen}
          message={alertMessage}
          type={'error'}
        />
      </div>
    </Modal>
  )
}

ListModalAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  state: PropTypes.object,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(ListModalAdder));