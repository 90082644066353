import { withTheme, withStyles } from '@material-ui/core';
import React, {} from 'react';
import { getIconComponent } from '../../../utilities';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  root:{
    width:34,
    height:68,
    borderRadius:4,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.chipBorder.main}`
  }
})


const KSwimLaneControl = (props) => {
  const { data, theme, classes, id } = props;

  return (
    <KTooltip title={data.tooltip}>
      <div id={id} className={classes.root} onClick={data.onClick}>
        {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.main})}
      </div>
    </KTooltip>
  );
};


export default withTheme()(withStyles(styles)(KSwimLaneControl));