import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, LinearProgress, Modal, Paper, Button} from '@material-ui/core';
import { getDispFields, getIconComponent } from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import theme from '../../../theme';
import ReactFlowWrapper from '../../UI/ReactFlowWrapper/ReactFlowWrapper';
import ELK from 'elkjs'
import { globalListenerRef } from '../../../GlobalListenerRef';
import axiosSolr from '../../../axios-solr';
import ChipMenuSelector from '../../UI/SearchSelector/ChipMenuSelector';
import { SolrLongListLoader } from '../../../LongListLoader';
import { groupInitHeight, groupWidth, nodeOneLineHeight, nodeWidth } from '../../UI/Lineage/LineageGraph/loadUtils';
import { getLineageRelation, onDownloadMap } from '../../UI/Lineage/utils';
import KTooltip from '../../UI/KTooltip/KTooltip';
import DetailDrawer from './DetailDrawer/DetailDrawer';
import LinkDetailDrawer from './DetailDrawer/LinkDetailDrawer';

const styles = theme => ({
  buttonText:{
    cursor:"pointer",
    fill:theme.palette.primary.main,
    fontSize:13.75,
  },
  button:{
    width:20,
    height:20,
    borderRadius:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  selectorText:{
    fontSize:13,
    letterspacing:1,
    marginRight:6
  }
})


function Grid(props) {
  const {
    classes,
    // history,
    state,
    dispatch,
  } = props;

  const nodes = useRef(state.mapData?.nodes);
  const links = useRef(state.mapData?.links);

  const chartWrapperRef = useRef(null);

  const [fullScreen, setFullScreen] = useState(false)
  const [graphHeight, setGraphHeight] = useState(500)
  const [fullScreenGraphWidth, setFullScreenGraphWidth] = useState(window.innerWidth-180)
  const [fullScreenGraphHeight, setFullScreenGraphHeight] = useState(window.innerHeight-136)

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [linkDrawerOpen, setLinkDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)


  useEffect(()=>{
    if(chartWrapperRef.current){
      setGraphHeight(window.innerHeight - chartWrapperRef.current.getBoundingClientRect().top - 100)
    }
  // eslint-disable-next-line
  },[chartWrapperRef.current])

  useEffect(()=>{
    window.removeEventListener('resize',globalListenerRef.gridResizeListener)
    globalListenerRef.gridResizeListener = () => {
      setFullScreenGraphWidth(window.innerWidth-180)
      setFullScreenGraphHeight(window.innerHeight-136)
    }
    window.addEventListener('resize',globalListenerRef.gridResizeListener)
    return ()=>{
      window.removeEventListener('resize',globalListenerRef.gridResizeListener)
      dispatch({type:'set_map_data',mapData:{links:links.current,nodes:nodes.current}})
    }
  // eslint-disable-next-line
  },[])

  const getParentNodes = async ids => {
    let data = [];
    let error;
    // load parents in chunks of 50
    let promises = []
    for(let i=0;i<ids.length;i+=50){
      promises.push(
        axiosSolr
          .post(
            `/solr/search/select`,{params:{
              q:'*',
              fq:`id:(${ids.slice(i,i+50).join(' OR ')})`,
              rows:50
            }}
          )
          .then(response=>{
            data.push(...response.data.response.docs)
          })
          .catch(error=>{
            console.log('error',error)
            error = true;
          }
        )
      )
    }
    await Promise
      .all(promises)
      .catch(error=>{
        console.log(error)
        error = true;
      })
    return {data,error}
  }

  const getLinks = async (nodesTmp, showRef) => {
    let linksTmp = [];
    let linkedItems = [];
    let error;

    let promises = []
    const isManualLink = relationship => {
      if(relationship.includes('SOURCE'))return true;
      return false;
    }
    // async for loop 
    for(let i=0;i<nodesTmp.length;i++){
      let node = nodesTmp[i];
      if(error)break;
      promises.push(
        axiosCerebrum
          .get(
            `/api/${node.object_type_txt.toLowerCase()}s/${node.id}/related`,{
              params:{
                relationship:getLineageRelation(node.object_type_txt,'downstream').join(','),
                object_name:'DATABASE,TOOL',
                object_reference: showRef?true:false,
                active_flag:true,
                object_active_flag:true,
                per_page:75
              }
            }
          )
          .then(response=>{
            response.data.items.forEach(d=>{
              if(!nodes.current?.find(n=>n.id===d.id) && !nodesTmp.find(n=>n.id===d.id) && !linkedItems.find(l=>l.id===d.id))linkedItems.push(d);
              if(linksTmp.find(l=>l.source===node.id && l.target===d.id && isManualLink(l.data.relationship)===isManualLink(d.relationship)))return;
              let curveOffset = 0;

              if(linksTmp.find(l=>l.source===node.id && l.target===d.id))curveOffset = -30;
              linksTmp.push({
                source:node.id,
                target:d.id,
                type:'KLineageEdge',
                data:{
                  relationship: d.relationship,
                  curveOffset,
                  lineType:d.relationship.includes('SOURCE')?'dashed':'solid',
                }
              })
            })
          })
      )
    }

    await Promise
      .all(promises)
      .catch(error=>{
        console.log(error)
        error = true;
      })

    linksTmp.forEach(l=>{
      l.id = l.source + '-' + l.target + '-' + l.data.relationship
    })

    return {linksTmp,linkedItems,error}
  }

  const constructNode = ({d, y}) => {
    return ({
      id:d.id,
      type:'KLineageNode',
      extent:'parent',
      x:0,
      y,
      parentNode:d.parent_id_txt, 
      width:nodeWidth,
      height:nodeOneLineHeight,
      data:{
        id:d.id,
        label:getDispFields(d,'dispTitle'),
        obj:d,
        indentSize:2,
      }
    })
  }

  const constructGroupNode = ({d, height}) => {
    return ({
        id:d.id,
        type:'KGroup',
        x:0,
        y:0,
        width:groupWidth,
        height,
        data:{
          label: d.alternate_name_txt,
          subTitle: d.name_txt,
          onTitleClick: ()=>window.open(`/profile/source/${d.id}`,'_blank'),
          icon:d.source_type_txt,
          leftHandleOffset:-8,
          rightHandleOffset:0,
        }
    })
  }

  const constructGraph = async data => {
    let nodesTmp = [];
    let linksTmp = [];

    let parentIds = []

    data.forEach(d=>{
      if(!d.parent_id_txt)return;
      if(!parentIds.includes(d.parent_id_txt)){
        parentIds.push(d.parent_id_txt)
      }
      nodesTmp.push(constructNode({d,y: groupInitHeight + nodeOneLineHeight*(nodesTmp.filter(n=>n.parentNode===d.parent_id_txt).length)}))
    })

    let parentsData = await getParentNodes(parentIds)
    if(parentsData.error){
      dispatch({type:'set_map_data',mapData:{error:true}})
      return;
    }
    let parentsArr = []
    parentsData.data.forEach(d=>{
      parentsArr.push( constructGroupNode({d, height:groupInitHeight + nodeOneLineHeight*(nodesTmp.filter(n=>n.parentNode===d.id).length)}))
    })

    let linksData = await getLinks(data)
    if(linksData.error){
      dispatch({type:'set_map_data',mapData:{error:true}})
      return;
    }

    linksTmp = linksData.linksTmp.filter(l=>nodesTmp.find(n=>n.id===l.source) && nodesTmp.find(n=>n.id===l.target));
    
    const sortNodes = (a,b) => {
      // sort by links attached 
      let aLinks = linksTmp.filter(l=>l.source===a.id || l.target===a.id).length;
      let bLinks = linksTmp.filter(l=>l.source===b.id || l.target===b.id).length;
      return bLinks - aLinks;
    }

    nodesTmp.sort(sortNodes)

    nodesTmp = [...parentsArr,...nodesTmp]

    let elk = new ELK();

    elk
      .layout({
        id:'root_graph',
        layoutOptions: { 
          // 'graphviz.algorithm':'neato',
          // 'elk.spacing.nodeNode':100,
          'elk.algorithm':'disco',
          // 'elk.force.repulsion':10,
          // 'elk.force.temperature':0.01,
          // 'elk.force.repulsivePower':5,
          // 'elk.separateConnectedComponents':false,
          // 'elk.spacing.componentComponent':10.0,
          // 'elk.alignment':'RIGHT',
          // 'elk.layered.nodePlacement.strategy':'LINEAR_SEGMENTS',
          // 'elk.layered.highDegreeNodes.treeHeight':10,
          'elk.spacing.nodeNode':120,
          // 'elk.direction':'RIGHT',
          // 'elk.nodeSize.fixedGraphSize':false,
        },
        children:nodesTmp.filter(n=>n.type==='KGroup').map(el=>({...el,width:el.width*1.2,height:el.height*1.2})),
        edges:linksTmp.map(el=>({
          ...el,
          sources:[nodesTmp.find(n=>n.id===el.source).parentNode],
          targets:[nodesTmp.find(n=>n.id===el.target).parentNode]
        }))
      })
      .then((g1) => {
        elk 
          .layout({
            id:'overlap_removal',
            layoutOptions: { 
              'elk.algorithm':'sporeOverlap',
              'elk.spacing.nodeNode':180,
              'elk.overlapRemoval.maxIterations':50
            },
            children:g1.children,
            edges:g1.edges
          })
          .then(g=>{
            nodesTmp.forEach(n=>{
              if(n.type==='KGroup'){
                let detail = g.children.find(c=>c.id===n.id);
                if(detail){
                  n.x = detail.x;
                  n.y = detail.y;
                }
              }
            })
            nodes.current = nodesTmp;
            links.current = linksTmp;
            dispatch({type:'set_map_data',mapData:{links:linksTmp,nodes:nodesTmp}})
          })
          .catch(error=>{
            console.log(error)
            dispatch({type:'set_map_data',mapData:{error:true}})
          });
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_map_data',mapData:{error:true}})
      });
  }

  const loadOnboardedItems = () => {
    nodes.current = null;
    links.current = null;
    SolrLongListLoader({
      url:`/solr/search/select`,
      params:{
        q:"*",
        fq:"object_type_srt:(DATABASE OR TOOL) AND reference_srt:NO AND active_srt:YES",
        sort:'name_srt asc'
      },
      rows:50,
      onStartLoad:()=>{
        dispatch({type:'set_map_data',mapData:{loading:true}})
      },
      onFinishLoad:data=>{
        constructGraph(data.data)
      },
      onError:()=>{
        dispatch({type:'set_map_data',mapData:{error:true}})
      }
    })
  }

  useEffect(()=>{
    if(!nodes.current || !links.current){
      loadOnboardedItems()
    }
  // eslint-disable-next-line
  },[])


  const onSethideMiniMap = () => {
    dispatch({type:'set_map_show_minimap',mapShowMiniMap:!state.mapShowMiniMap})
  }

  const onRestore = () => {
    loadOnboardedItems()
  }

  const updateFocusLink = (ids) => {
    for(let i=0; i<links.current.length; i++){
      let currentLink = links.current[i]
      if(ids.includes(currentLink.id)){
        currentLink.selected=true
      }else{
        currentLink.selected=false
      }
    }
    forceUpdate()
  }

  const tagConnectedLinks = ids => {
    let connectedLinks = []
    for(let i=0; i<links.current.length; i++){
      let currentLink = links.current[i]
      if(ids.includes(currentLink.source) || ids.includes(currentLink.target)){
        connectedLinks.push(currentLink.id)
      }
    }
    updateFocusLink(connectedLinks)
  }

  const updateFocusedNode = (ids, doNotTraverse) => {

    const taggedIDs = [...ids];

    const tagLinkedNodes = (ids, direction) => {
      ids.forEach(id=>{
        let linksTmp = links.current.filter(l=>l[direction==='upstream'?'target':'source']===id)
        let nextLevelIds = linksTmp.map(l=>direction==='upstream'?l.source:l.target).filter(id=>!taggedIDs.includes(id))
        taggedIDs.push(...nextLevelIds)
        tagLinkedNodes(nextLevelIds,direction)
      })
    }

    if(!doNotTraverse){
      tagLinkedNodes(ids, 'upstream')
      tagLinkedNodes(ids, 'downstream')
    }
    
    for(let i=0; i<nodes.current.length; i++){
      let node = nodes.current[i];
      if(!taggedIDs.includes(node.id)){
        node.selected = false
      }else{
        node.selected = true
      }
    }

    forceUpdate()
  }

  const onItemClick = el => {
    document.querySelector(`[data-id="${el.id}"]`)?.click()
    if(!nodes.current || !nodes.current.find(n=>n.id===el.id))return;
    updateFocusedNode([el.id])
    tagConnectedLinks([el.id])
    if(nodes.current.find(n=>n.id===el.id)?.refLoaded)return;
  }

  const onSelectionChange = data => {
    if(data.edges.length===0 && data.nodes.length===0){
      updateFocusLink([])
      updateFocusedNode([])
    }
  }

  const onNodeDragStop = (event, node) => {
    nodes.current.find(n=>n.id===node.id).x = node.position.x;
    nodes.current.find(n=>n.id===node.id).y = node.position.y;
  }

  const postProcessNodes = nodes => {
    let numSelected = nodes.filter(n=>n.selected).length
    return nodes
      // .filter(n=>n.data.obj.active_flag===true || state.gridShowInactive)
      .filter(n=>!isNaN(n.x) && !isNaN(n.y))
      .map((n,index)=>({
        ...n,
        position:{
          x:n.x,
          y:n.y
        },
        data:{
          ...(n.data||{}),
          height:n.height,
          width:n.width,
          faded:!n.selected && numSelected && !(n.type==='KGroup' && nodes.find(c=>c.parentNode===n.id && c.selected)),
          onClick:()=>{
            onItemClick(n.data.obj)
            setDrawerOpen(true)
            setLinkDrawerOpen(false)
            setSelectedItem(n)
          }
        },
      }))
  } 

  const postProcessLinks = links => {
    let selectedNodes = nodes.current.filter(n=>n.selected)
    let numSelected = selectedNodes.length;

    return links
      .map(l=>{
        l.data.onClick = () => {
          updateFocusLink([l.id])
          updateFocusedNode([l.source,l.target],true)
          setDrawerOpen(false)
          setLinkDrawerOpen({source:nodes.current.find(n=>n.id===l.source),target:nodes.current.find(n=>n.id===l.target)})
        }
        // l.data.disableHover = true;
        if(!numSelected)return {
          ...l,
          data:{
            ...l.data,
            faded:true
          }
        };
        if(!l.selected){
          return {
            ...l,
            data:{
              ...l.data,
              faded:true
            }
          }
        }else{
          return {
            ...l,
            data:{
              ...l.data,
              faded:false,
              colour:theme.palette.primary.main,
            }
          
          }
        }
      })
  }

  let mapComponent = (
    <div>
      <div style={{display:'flex',marginBottom:8}}>
        <div style={{flexGrow:1}}>
          <Typography style={{fontSize:20,color:theme.palette.header.main,}}>DATA ECOSYSTEM</Typography>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>
          Data Sources, Tool and Reporting Platforms that make up the Data Ecosystem
          </Typography>
        </div>
        <div style={{flexGrow:0, flexShrink:0, display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:24}}> 
          <KTooltip title={`Download ecosystem map`}>
            <div className={classes.button} style={{marginRight:16}} onClick={()=>onDownloadMap('data_ecosystem_map')}>
              {getIconComponent({label:'download',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip>
          <KTooltip title={`${state.mapShowMiniMap?'Hide':'Show'} minimap`}>
            <div className={classes.button} style={{marginRight:16}} onClick={onSethideMiniMap}>
              {getIconComponent({label:state.mapShowMiniMap?'hide_window':'show_window',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip>
          <KTooltip title={`Full screen`}>
            <div className={classes.button} style={{marginRight:16}} onClick={()=>setFullScreen(!fullScreen)}>
              {getIconComponent({label:fullScreen?"zoom_in_map":'zoom_out_map',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip>
          <KTooltip title={`Reload map`}>
            <div className={classes.button} style={{marginRight:16}} onClick={onRestore}>
              {getIconComponent({label:'refresh',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip> 

          {
            fullScreen && 
            <Button color='primary' style={{padding:'0 4px',marginLeft:16,minWidth:0}} onClick={()=>setFullScreen(!fullScreen)}>CLOSE</Button>
          }
        </div>
      </div>
      {
        state.mapData?.loading && 
        <div style={{textAlign:'center',marginTop:168}}>
          <LinearProgress color='secondary' style={{width:200,margin:'auto',marginBottom:24}}/>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>Generating ecosystem map</Typography>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginTop:8}}>Geez you have a lot … this may take a few seconds.</Typography>
        </div>
      }
      {
        nodes.current?.length===0 && 
        <Typography>No sources to be displayed</Typography>
      }
      {
        nodes.current && !state.mapData?.loading && 
        <div ref={chartWrapperRef}>
          <div style={{display:'flex',alignItems:'center',paddingBottom:16}}>
            <Typography className={classes.selectorText}>
              GO TO: 
            </Typography>
            <ChipMenuSelector
              displayValue={'Search for a Database or Tool'}
              placeholder={'Search for a Database or Tool'}
              values={
                nodes.current
                  ?.filter(el=>el.type==='KLineageNode')
                  .map(el=>{
                    return el.data.obj
                  })
                  .sort((a,b)=>a.name_txt.localeCompare(b.name_txt))
              }
              onItemClick={onItemClick}
            />
          </div>
          {
            chartWrapperRef.current &&
            <div style={{width:fullScreen?fullScreenGraphWidth:chartWrapperRef.current.getBoundingClientRect().width,height:fullScreen?fullScreenGraphHeight-98:graphHeight}}>
              <ReactFlowWrapper
                initialNodes={postProcessNodes(nodes.current)}
                initialLinks={postProcessLinks(links.current)}
                fitView={true}
                hideMiniMap={!state.mapShowMiniMap}
                alwaysShowAllNodes={true}
                onSelectionChange={onSelectionChange}
                showEdgeBelowNode
                onNodeDragStop={onNodeDragStop}
                onFocusOffset={{x:400,y:0}}
              />
            </div>
          }
        </div>
      }
      
    </div>
  )

  let drawerComponent = (
    <>
      <DetailDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        selectedItem={selectedItem}
        onItemClick={el=>{
          onItemClick(el)
          setSelectedItem(nodes.current.find(n=>n.id===el.id))
        }}
      />
      
      <LinkDetailDrawer
        drawerOpen={linkDrawerOpen}
        setDrawerOpen={setLinkDrawerOpen}
        sourceItem={linkDrawerOpen.source}
        targetItem={linkDrawerOpen.target}
        onItemClick={el=>{
          onItemClick(el)
          setSelectedItem(nodes.current.find(n=>n.id===el.id))
        }}
      />
    </>
  )

  return (
    <>
      {
        fullScreen?
        <Modal open={fullScreen} onClose={()=>setFullScreen(false)}>
          <Paper style={{width:fullScreenGraphWidth,padding:30,marginLeft:60, marginTop:30, height:fullScreenGraphHeight}}>
            {mapComponent}
            {drawerComponent}
          </Paper>
        </Modal>
        :
        <>
          {mapComponent}
          {drawerComponent}
        </>
      }
    </>
  )

}

Grid.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(Grid));