import React, {useEffect, useReducer} from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/BulkEdit/Body/Body';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { useStore} from 'react-redux'
import ProfileHeader3 from '../../components/UI/ProfileHeader/ProfileHeader3';

const styles = theme => ({
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
});

export const defaultFields = [
  {
    "field": "DESCRIPTION",
    "operation": "REPLACE",
    "values": [
        ""
    ]
  },
  {
    "field": "MEMBER_OF",
    "id": "category",
    "operation": "REPLACE",
    "values": []
  },
  {
    "field": "CLASSIFIED_BY",
    "operation": "REPLACE",
    "values": []
  },
  {
    "field": "MEMBER_OF",
    "id": "domain",
    "operation": "ADD_TO_EXISTING",
    "values": []
  },
  {
    "field": "VERIFIED_BY",
    "operation": "REPLACE",
    "values": []
  },
  {
    "field": "NOT_VERIFIED_BY",
    "operation": "REPLACE",
    "values": []
  },
  {
    "field": "OWNED_BY",
    "operation": "REPLACE",
    "values": []
  },
  {
    "field": "STEWARDED_BY",
    "operation": "ADD_TO_EXISTING",
    "values": []
  }
]

export const defaultProperties = [
  {name_txt:'Description',id:'default_prop_description'},
  {name_txt:'Categories',id:'default_prop_category'},
  {name_txt:'Classification',id:'default_prop_classification'},
  {name_txt:'Domains',id:'default_prop_domain'},
  {name_txt:'Verified for',id:'default_prop_use_case'},
  {name_txt:'Not Verified for',id:'default_prop_not_verified_use_case'},
  {name_txt:'Owner',id:'default_prop_owner'},
  {name_txt:'Stewards',id:'default_prop_steward'}
]

const initialState = {
  activeStep:0,
  changedFields:defaultFields,
  allChangedFields:defaultFields,
  userMap:{},
  collectionInstanceMap:{},
  properties:defaultProperties
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_active_step':
      return {
        ...state,
        activeStep: action.activeStep,
      }
    case 'set_item_list':
      return {
        ...state,
        itemList:action.itemList,
        itemListLoading:action.itemListLoading,
        itemListError:action.itemListError
      }
    case 'set_selected_item_type':
      return {
        ...state,
        selectedItemType:action.selectedItemType
      }
    case 'set_properties':
      return {
        ...state,
        properties:action.properties
      }
    case 'set_domain_list':
      return {
        ...state,
        domainList:action.domainList
      }
    case 'set_category_list':
      return {
        ...state,
        categoryList:action.categoryList
      }
    case 'set_verified_list':
      return {
        ...state,
        verifiedList:action.verifiedList
      }
    case 'set_access_role_list':
      return {
        ...state,
        accessRoleList:action.accessRoleList
      }
    case 'set_classification_list':
      return {
        ...state,
        classificationList:action.classificationList
      }
    case 'set_channel_list':
      return {
        ...state,
        channelList:action.channelList
      }
    case 'set_changed_fields':
      return {
        ...state,
        changedFields:action.changedFields
      }
    case 'set_all_changed_fields':
      return {
        ...state,
        allChangedFields:action.allChangedFields
      }
    case 'set_user_map':
      return {
        ...state,
        userMap:action.userMap
      }
    case 'set_linkable_collection':
      return {
        ...state,
        linkableCollection:action.linkableCollection,
        linkableCollectionError:action.linkableCollectionError,
        linkableCollectionLoading:action.linkableCollectionLoading
      }
    case 'set_batch_status':
      return {
        ...state,
        batchLoading:action.batchLoading,
        batchError:action.batchError,
        batchComplete:action.batchComplete
      }
    case 'set_loading_screen':
      return {
        ...state,
        loadingScreen:action.loadingScreen
      }
    case 'set_collection_instance_map':{
      return {
        ...state,
        collectionInstanceMap:action.collectionInstanceMap
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const ContentProfile = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const store = useStore();
  const presetDescription = store.getState().bulkEdit.presetDescription
  const editMode = store.getState().bulkEdit.editMode;

  useEffect(()=>{
    if(presetDescription){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields.filter(el=>el.field!=='DESCRIPTION'),
          {
            field:'DESCRIPTION',
            operation:'REPLACE',
            values:[presetDescription||''],
          }
        ]
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  let title = 'Bulk Update';
  if(state.itemList && state.itemList.length>0 && editMode==='same_name_description_only'){
    title = `Bulk update description for ${state.itemList[0].name}`
  }

  let subTitle;
  let bannerdisplayText = "Changes from Bulk Update are not currently captured in the objects' change timeline"
  let bannerCause = "bulk_edit_info"
  
  if(state.operation==="link_to_collection"){
    bannerdisplayText = "Linked collections are immediately visible on the updated item's profile page. Updates to search results / filters will occur overnight."
    bannerCause = "bulk_edit_link_collection_info"
  }
  
  
  return (
    <div>
      <ProfileLayout
        header={
          <ProfileHeader3
            title={title}
            history={history}
            subtitle={subTitle}
            minHeight={108}
            bannerdisplayText={bannerdisplayText}
            bannerCause={bannerCause}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
      />
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ContentProfile)));