import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Select, Typography, InputBase } from '@material-ui/core';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { getDispFields, getIconComponent } from '../../../utilities';

const styles = theme => ({
  selector:{
    ...theme.components.selector,
    height:32,
    borderRadius:16,
    border:`1px solid ${theme.palette.primary.main}`,
    background:theme.palette.background.main,
    overflow:'hidden',
    '& div div':{
      color:theme.palette.primary.main,
      fontSize:13,
      paddingLeft:12,
      paddingRight:32
    },
    '& div svg':{
      marginRight:4,
      color:theme.palette.primary.main
    }
  },
  inputBase:{
    ...theme.components.inputBase,
    minWidth:350,
    height:42,
    width:'100%',
    position:'sticky',
    top:0,
    borderRadius:0
  }
})


const ChipMenuSelector = props => {
  const {
    classes,
    onItemClick,
    placeholder,
    displayValue,
    values
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [filteredValues, setFileredValues] = useState(values);

  useEffect(()=>{
    if(searchValue){
      setFileredValues(values.filter(el=>el.name_txt.toLowerCase().includes(searchValue.toLowerCase())))
    }else{
      setFileredValues(values);
    }
  // eslint-disable-next-line
  },[searchValue])

  return (
    <Select
      className={classes.selector}
      renderValue={()=>displayValue}
      value='none'
      disableUnderline
      MenuProps={{
        MenuListProps:{
          disablePadding:true
        }
      }}
    >
      <div>
        <InputBase
          className={classes.inputBase}
          value={searchValue}
          onClick={event=>{event.stopPropagation();event.preventDefault()}}
          placeholder={placeholder}
          inputProps={{
            onClick:event=>{
              event.stopPropagation();event.preventDefault()
            },
          }}
          onChange={event=>{
            setSearchValue(event.target.value);
          }}
        />
      </div>
      <div>
        {
          filteredValues.map(el=>{
            return (
              <SimpleResultItem
                key={el.id}
                title={getDispFields(el,'dispTitle')}
                label={el.object_type_txt}
                iconLabel={getIconComponent({label:el.source_type_txt})?el.source_type_txt:el.object_type_txt}
                item={el}
                subTitle={getDispFields(el,'dispSubtitle')}
                showUnderline
                onClick={(event)=>{onItemClick(el)}}
                keepPropagation
              />
            )
          })
        }
      </div>
      {
        filteredValues.length === 0 &&
        <Typography 
          style={{fontSize:13,marginLeft:16,marginTop:16}}
          onClick={event=>{event.stopPropagation();event.preventDefault()}}
        >
          No results found
        </Typography>
      }
    </Select>
  )
}

ChipMenuSelector.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  onItemClick: PropTypes.func,
  displayValue: PropTypes.func,
  values: PropTypes.array,
  placeholder: PropTypes.string,
}



export default withTheme()(withStyles(styles)(ChipMenuSelector));