import React, { useEffect, useRef, useState, useCallback } from 'react';
import { withTheme, withStyles, Typography, Button, LinearProgress,Paper,Modal, Checkbox,  MenuItem, Select, Radio } from '@material-ui/core';
import {  getIconComponent, getTestScoreColor,getTrustScoreColor, sendMessage } from '../../../../utilities';
import PropTypes from 'prop-types';
import {globalListenerRef} from '../../../../GlobalListenerRef';
import LineageDetailDrawer from '../LineageDetailDrawer';
import ObjectDetailDrawer from '../../KLineageFramework/ObjectDetailDrawer';
import {useStore} from 'react-redux'
import ReactFlowWrapper from '../../ReactFlowWrapper/ReactFlowWrapper';
import { checkCanAddToCart, checkCollectionLinkable, checkIsBusinessUserOnly, checkProfileEditable } from '../../../../permissionChecker';
import { bindActionsToLinks, bindActionsToNodes, bindLoadUpstremCodeAction, checkHasLineage, checkIsReferencePlaceHolder, constructLinks, enrichData, forceBindActionToNodes, generateGroups, getCerebrumLabel, loadNodes, postCheckLinks, processRawData, thirdLevelObjects } from './loadUtils';
import { addNodeToGroup, assignGroupInitialPos, layoutGraph, onAppendChildren, onToggleShowFullName, setReferencePlaceholderPosition } from './layoutUtils';
import axiosCerebrum from '../../../../axios-cerebrum';
import { onDownloadMap } from '../utils';
import KTooltip from '../../KTooltip/KTooltip';
import { contentItems, dataItems, userItems } from '../../../ImpactAssessment/utils';
import ViewSelector from './ViewSelector';
import { setNodeFocus } from './viewUtils';
import axiosSolr from '../../../../axios-solr';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  // tooltip:{
  //   cursor:'pointer'
  // },
  chartWrapper:{
    marginTop:12,
  },
  tooltip:{
    fontSize:13.75
  },
  quickFilter:{
    borderRadius:3,
    '& div div':{
      paddingLeft:8
    },
    '& svg':{
      color:theme.palette.primaryText.light
    }
  },
  switchIcon:{
    width:16,
    height:16
  },
  switchBar:{
    height:10,
    marginTop:-5,
    width:28,
    marginLeft:-14
  },
  button:{
    width:20,
    height:20,
    borderRadius:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  emotRoot: {
    marginTop:80,
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center'
  },
  emot:{
    fontSize:40,
    color:theme.palette.primaryText.main
  },
  text:{
      fontSize:16,
      color:theme.palette.primaryText.light,
      marginTop:24
  },
  banner:{
    height:24,
    borderRadius:12,
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    display:"flex",
    alignItems:'center',
    padding:'0 8px',
    width:'max-content'
  },
  clickableText:{
    textDecoration:'underline',
    cursor:'pointer',
    color:'inherit',
    fontSize:'inherit'
  },
  checkbox:{
    paddingLeft:0,
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    ...theme.components.hideScroll,
    overflow:"auto"
  },
	menuItem:{
		// padding:'10px 10px 10px 16px',
		// color:theme.palette.primaryText.main,
		// '&:hover':{
    //   background: theme.palette.hovered.main 
    // }
	},
  selector: {
    ...theme.components.titleSelector,
    height:24,
    borderRadius:12,
    maxWidth:210,
    '& div div':{
      fontSize:13,
      width:'max-content',
      maxWidth:178,
      padding:'1px 24px 0px 8px'
    }
  },
  legendText:{
    fontSize:12,
    color:theme.palette.primary.main,
    marginLeft:16,
    letterSpacing:1.5
  },
  legendItemText:{
    marginLeft:8,
    color:theme.palette.primaryText.light,
    fontSize:12, 
  }   
})

function LineageGraph(props) {

  const {
    classes,
    state,
    dispatch,
    history,
    theme,
    root,
    rootLabel,
    centerTopOffSet = -150,
    stickyTop,
    isApp,
    isLinkable,
    disableFocus, 
    forceFocusOption,
    downstreamOnly,
    upstreamOnly,
    hideReplaced,
    hideChildList,
    hideGroup,
    disableCache,
    forceIsShowReference,
    defaultFullScreen,
    onCloseFullScreen
  } = props;
  
  const isRootInactive = root.active_flag===false

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  let isEditable = checkProfileEditable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  const isDestroyed = useRef(false)

  const [, updateState] = useState();
  const forceUpdateTree = useCallback(() => updateState({}), []);
  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const isShowReference = useRef(typeof(forceIsShowReference)==='boolean'?forceIsShowReference:state.lineageData.isShowReference)
  const showReferenceXaxis = useRef([])
  const showReferenceTimeout = useRef()
  const isShowFullName = useRef(state.lineageData.isShowFullName)
  const showFullNameTimeout = useRef()
  const focusView = useRef(forceFocusOption?.focus || state.lineageData.focus || [])
  const subFocusView = useRef(forceFocusOption?.subFocus || state.lineageData.subFocus || {})
  const viewType = useRef(state.lineageData.viewType||'or')
  const isExclude = useRef(state.lineageData.isExclude||false)
  const tagView = useRef(state.lineageData.tagView || localStorage.getItem('lineage_tag_view') ||'trust')
  const [lineageDrawerOpen, setLineageDrawerOpen] = useState(false);
  const [objectDrawerOpen, setObjectDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState();
  const [linkedItem, setLinkedItem] = useState();
  const nodes = useRef(state.lineageData.nodes || [])
  const links = useRef(state.lineageData.links || [])
  const [fullScreenGraphWidth, setFullScreenGraphWidth] = useState(window.innerWidth-180)
  const [fullScreenGraphHeight, setFullScreenGraphHeight] = useState(window.innerHeight-192)

  const [graphHeight, setGraphHeight] = useState(600)
  const [graphWidth, setGraphWidth] = useState(1000)
  const [fullScreen, setFullScreen] = useState(defaultFullScreen || false)

  const chartWrapperRef = useRef()
  const nodeWidth = 270;

  const currentLoadID = useRef(1)
  const abortRef = useRef(new AbortController())

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`lineage-graph-${root.id}`,
    isCancelledRef
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])


  let collectionLinkable = checkCollectionLinkable({sessionData})

  useEffect(()=>{
    window.removeEventListener('resize',globalListenerRef.lineageFullScreenResizeListener)
    globalListenerRef.lineageFullScreenResizeListener = () => {
      setFullScreenGraphWidth(window.innerWidth-180)
      setFullScreenGraphHeight(window.innerHeight-192)
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      if(!chartWrapperRef.current)return;
      setGraphHeight(Math.max(window.innerHeight,window.outerHeight) - chartWrapperRef.current.getBoundingClientRect().top + 300)
    }
    window.addEventListener('resize',globalListenerRef.lineageFullScreenResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.lineageFullScreenResizeListener)
  },[])
  

  useEffect(()=>{
    if(chartWrapperRef.current && !fullScreen){
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      setGraphHeight(Math.max(window.innerHeight,window.outerHeight) - chartWrapperRef.current.getBoundingClientRect().top + 300)
    }
    setLinkedItem()
    setSelectedItem()
    setLineageDrawerOpen(false)
    setObjectDrawerOpen(false)
  // eslint-disable-next-line
  },[fullScreen, chartWrapperRef.current])


  const generateCustomRightClickAction = (item) => {
    return [
      isEditable? {
        name:'Edit Properties',
        iconLabel:'edit',
        isStandalone:true,
        hideBottomBorder:true,
        customMargin:'0px',
        id:'edit_property',
        onClick:()=>{
          setSelectedItem(item)
          updateFocusedNode([item.id])
          setObjectDrawerOpen(true)
          setTimeout(()=>sendMessage({mini_profile_edit:'true'}))
        }
      }:undefined,
      isEditable? {
        name:'Edit Lineage',
        iconLabel:'edit',
        id:'edit_lineage',
        isStandalone:true,
        customMargin:'0px 0px 4px',
        onClick:()=>{
          setSelectedItem(item)
          updateFocusedNode([item.id])
          setObjectDrawerOpen(true)
          setTimeout(()=>sendMessage({open_mini_profile_link_modal:true}),500)
        }
      }:undefined,
      checkCanAddToCart({sessionData,objectType:(item.data.obj?.type||'').toUpperCase()})?{
        id:'addToCart'
      }:undefined,
      collectionLinkable?{
        name:'Add to Collection Instance',
        iconLabel:'collection',
        id:'link_to_collection',
        onClick:()=>{
          setSelectedItem(item)
          updateFocusedNode([item.id])
          setObjectDrawerOpen(true)
          setTimeout(()=>window.postMessage({mini_profile_link_collection:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port))
        }
      }:undefined,
      {
        name:'Add Tag',
        iconLabel:'tag',
        onClick:()=>{
          setSelectedItem(item)
          updateFocusedNode([item.id])
          setObjectDrawerOpen(true)
          setTimeout(()=>window.postMessage({mini_profile_add_tag:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port))
        }
      },
      !checkIsBusinessUserOnly({sessionData}) && item.data.obj?.active_txt==='YES' && [...contentItems,...dataItems,...userItems].includes(item.data.obj?.object_type_txt) ?
      {
        name:'Assess Downstream Impact',
        iconLabel:'ia_downstream',
        isStandaloneBottom:true,
        onClick:()=>{
          history.push(`/impact_assessment?targetObjectId=${item.data.obj.id.toLowerCase()}`)
        }
      }:undefined,
      !checkIsBusinessUserOnly({sessionData}) && item.data.obj?.active_txt==='YES' && [...contentItems,...dataItems,...userItems].includes(item.data.obj?.object_type_txt) ?
      {
        name:'Assess Upstream Dependencies',
        iconLabel:'ia_upstream',
        isStandaloneBottom:true,
        onClick:()=>{
          history.push(`/dependency_assessment?targetObjectId=${item.data.obj.id.toLowerCase()}`)
        }
      }:undefined,
    ].filter(el=>el!==undefined)
  }

  const forceUpdate = () => {
    if(isDestroyed.current===true)return;
    forceUpdateTree()
  }

  const updateFocusLink = (ids) => {
    for(let i=0; i<links.current.length; i++){
      let currentLink = links.current[i]
      if(ids.includes(currentLink.id)){
        currentLink.selected=true
      }else{
        currentLink.selected=false
      }
    }
    forceUpdate()
  }
  
  const setBorderHeighlightNode = ids => {
    for(let i=0; i<nodes.current.length; i++){
      let node = nodes.current[i];
      if(thirdLevelObjects.includes(getCerebrumLabel(node.data.obj))){
        if(ids.includes(node.parentNode)){
          node.data.borderColour = 'transparent'
          node.data.borderWidth = 6
        }else{
          node.data.borderColour = undefined
          node.data.borderWidth = undefined
        }
      }else{
        if(!ids.includes(node.id)){
          node.data.borderWidth = undefined;
        }else{
          node.data.borderWidth = 6;
          node.selected = true;
        }
      }
      
    }
    forceUpdate()
  }

  const updateFocusedNode = ids => {
    for(let i=0; i<nodes.current.length; i++){
      let node = nodes.current[i];
      if(!ids.includes(node.id)){
        node.selected = false
      }else{
        node.selected = true
        
      }
    }
    forceUpdate()
  }

  // forceRootTag = upstream / downstream
  const tagNodePath = (id, ignoreNodes, forceRootTag) => {

    let highlightLinks = []
    let highlightNodes = []

    let taggedMap = {
      'upstream':[], 'downstream':[]
    }
    
    const tagDownstream = (nodeID) => {
      if(nodes.current.find(n=>n.id===nodeID) && nodes.current.find(n=>n.id===nodeID).isRoot){
        highlightNodes.push(nodeID)
        if(forceRootTag!=='downstream')return;
      }
      if(taggedMap.downstream.includes(nodeID))return;
      taggedMap.downstream.push(nodeID)
      let downstreamLinks = links.current.filter(l=>l.source===nodeID && l.target!==nodeID);
      if(downstreamLinks.length===0)return;
      highlightLinks.push(...downstreamLinks.map(l=>l.id))
      highlightNodes.push(...downstreamLinks.map(l=>l.target))
      downstreamLinks.forEach(l=>{
        tagDownstream(l.target)
      })
    }

    const tagUpstream = nodeID => {
      if(nodes.current.find(n=>n.id===nodeID) && nodes.current.find(n=>n.id===nodeID).isRoot){
        highlightNodes.push(nodeID)
        if(forceRootTag!=='upstream')return;
      }
      if(taggedMap.upstream.includes(nodeID))return;
      taggedMap.upstream.push(nodeID)
      let upstreamLinks = links.current.filter(l=>l.target===nodeID && l.source!==nodeID);
      if(upstreamLinks.length===0)return;
      highlightLinks.push(...upstreamLinks.map(l=>l.id))
      highlightNodes.push(...upstreamLinks.map(l=>l.source))
      upstreamLinks.forEach(l=>{
        tagUpstream(l.source)
      })
    }
    
    if(forceRootTag==='downstream'){
      tagDownstream(id)
    }else if(forceRootTag==='upstream'){
      tagUpstream(id)
    }else{
      tagDownstream(id)
      tagUpstream(id)
    }

    if(!ignoreNodes){
      updateFocusedNode([id,...highlightNodes])
    }
    updateFocusLink(highlightLinks)
  }
  
  function onNodeClick(){
    if(this.type==='KLineageNode'){
      setNodeFocus({nodes:nodes.current, links:links.current, focus:focusView.current, subFocus:subFocusView.current, type:viewType.current, isExclude:isExclude.current})
      this.data.faded = false;
      setBorderHeighlightNode([this.id])
      if(this.id===root.id){
        // setObjectDrawerOpen(false)
        // setLineageDrawerOpen(false)
        updateFocusLink([])
        // return;
      }
    }
    setObjectDrawerOpen(true)
    setLineageDrawerOpen(false)
    setSelectedItem(this)
    if(this.isRoot)return;
    if(this.type==='KLineageNode'){
      tagNodePath(this.id)
    }
  }

  async function onNodeExpandClick({direction}){
    setBorderHeighlightNode([this.id])
    if(checkHasLineage({node:this, direction, inactive:isRootInactive}) || direction==='downstream'){
      if(!this[`${direction}_loaded`]){
        await expandNodes({target:this, isRoot:this.isRoot, direction})
      }else{
        this[`${direction}_expanded`] = !this[`${direction}_expanded`];
        this.data[direction==='upstream'?'leftActionIcon':'rightActionIcon'] = this[`${direction}_expanded`]?'remove':'add'
        for(let i=0; i<links.current.length; i++){
          let link = links.current[i];
          if(direction==='upstream' && link.target===this.id){
            link.hidden = !link.hidden;
          }
          if(direction==='downstream' && link.source===this.id){
            link.hidden = !link.hidden;
          }
        }
        forceUpdate()
      }
    }else{
      if(this[`${direction}_code_loaded`]){
        setSelectedItem(this)
        setLinkedItem(this)
        setObjectDrawerOpen(false)
        setLineageDrawerOpen(true)
      }else{
        await showUpstreamCode({target:this})
      }
    }

    if(
      ({node:this, direction})){
      tagNodePath(this.id, this.isRoot, this.isRoot?direction:undefined)
    }
  }

  function loadChildLineage({direction}){
    let lineageFilter;
    if(direction==='upstream'){
      lineageFilter = `active_lineage_inc_reference_srt:(UPSTREAM_DOWNSTREAM OR UPSTREAM_ONLY)`
    }else{
      lineageFilter = `active_lineage_inc_reference_srt:(UPSTREAM_DOWNSTREAM OR DOWNSTREAM_ONLY)`
    }
    axiosSolr
      .get(`/solr/search/select`,{
        params:{
          q:`*`,
          fq:`hierarchy_parent_id_srt:${this.id} AND ${lineageFilter} AND active_srt:YES`,
          // fq:`hierarchy_parent_id_srt:${this.id} `,
          rows:20,
          start:0,
        }
      })
      .then(response=>{
        let list = response.data.response.docs;
        list = processRawData({list, target:this, direction})
        bindActionsToNodes({nodes:list, direction, allNodes:nodes.current, onNodeExpandClick, onNodeClick, generateCustomRightClickAction, updateFocusedNode, loadChildLineage})
        onAppendChildren({parent:this,children:list,nodes:nodes.current})
        this.data.bodyActions = undefined;
        this.data.childLoaded = true;
        nodes.current.push(...list)
        forceUpdate()

        if(response.data.response.numFound===0){
          sendAlert({type:'info',message:`No children with lineage found`})
          return;
        }
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:`Error occurred loading children, please try again`})
      })
  }

  const showUpstreamCode = async ({target}) => {
    target.data.leftActionIcon = 'loading';
    updateFocusedNode([target.id])
    forceUpdate()
    await axiosCerebrum
      .get(
        '/api/queries',
        {params:{
          // lineage_upstream_id:selectedItem.data.obj.id,
          lineage_downstream_id:target.data.obj.id,
          lineage_downstream_edges:'REFERENCES,K_REFERENCES,REFERENCES_SHORTCUT',
          per_page:0,
        }}
      )
      .then(response=>{
        setObjectDrawerOpen(false)
        if(response.data.total===0){
          target.data.leftActionIcon = undefined;
          target.data.leftAction = undefined;
          target.data.leftactionTooltip = undefined;
          setLineageDrawerOpen(false)
          setSelectedItem()
          setLinkedItem()
          sendAlert({type:'info',message:`No upstream code found`})
        }else{
          target[`upstream_code_loaded`] = true
          setSelectedItem(target)
          setLinkedItem(target)
          setLineageDrawerOpen(true)
          target.data.leftActionIcon = 'more_horiz';
        }
      })
      .catch(error=>{
        console.log(error)
        target.data.leftActionIcon = 'more_horiz';
        sendAlert({type:'error',message:`Error occurred loading upstream lineage, please try again`})
      })
    forceUpdate()
  }
  
  const expandNodes = async ({
    target, 
    direction, 
    isRoot, 
    forceObjectTypes, 
    forceRelation, 
    forceYPosition, 
    keepLoading, 
    hideEmptyMessage, 
    showReference = isShowReference.current, 
    showFullName = isShowFullName.current,
    loadID = currentLoadID.current
}) => {
    if(!isRoot){
      if(direction==='upstream'){
        target.data.leftActionIcon = 'loading';
      }else{
        target.data.rightActionIcon = 'loading';
      }
      forceUpdate()
    }
    try{
      // process nodes
      let originalList = await loadNodes({target, direction, checkChildren: !thirdLevelObjects.includes(rootLabel), forceObjectTypes, forceRelation, hideEmptyMessage, inactive:isRootInactive, sendAlert })
      if(loadID!==currentLoadID.current)return;
      originalList = await enrichData({list:originalList, currentList:nodes.current, target,direction});
      if(loadID!==currentLoadID.current)return;
      let list = originalList.filter(l=>!nodes.current.find(n=>n.id===l.id))
      list = processRawData({list, target, direction})
      let referencePlaceholderNode = list.find(el=>checkIsReferencePlaceHolder(el));
      list = list.filter(el=>!checkIsReferencePlaceHolder(el));
      bindActionsToNodes({nodes:list, direction, allNodes:nodes.current, onNodeExpandClick, onNodeClick, generateCustomRightClickAction, updateFocusedNode, loadChildLineage})
      // process groups & layout
      let groups = await generateGroups({newList:list, onNodeClick,currentNodes:nodes.current, onNodeExpandClick, direction, inactive:isRootInactive})
      if(loadID!==currentLoadID.current)return;
      assignGroupInitialPos({groups:referencePlaceholderNode?[...groups,referencePlaceholderNode]:groups, target, direction, currentNodes: nodes.current, forceYPosition})
      nodes.current.push(...groups)
      addNodeToGroup({children:list, currentNodes:nodes.current })
      nodes.current.push(...list)
      layoutGraph({ 
        newNodes: [...groups, ...list], 
        currentNodes: nodes.current, 
        currentLinks: links.current,
        showReference, 
        showReferenceXaxis:showReferenceXaxis.current,
        showFullName,
        getDisplayedTags
      })
      if(referencePlaceholderNode){
        setReferencePlaceholderPosition({node:referencePlaceholderNode, currentNodes:nodes.current})
        nodes.current.push(referencePlaceholderNode)
        list.push(referencePlaceholderNode)
      }
      // process egdes
      let edges = constructLinks({target, direction, children:originalList.filter(l=>l.shouldConstructLink), currentLinks:links.current, defaultSelected:!isRoot})

      bindActionsToLinks({links:edges, nodes:nodes.current, setLinkedItem, updateFocusLink,updateFocusedNode, setSelectedItem, setLineageDrawerOpen, setObjectDrawerOpen})

      links.current.push(...edges)

      postCheckLinks({links:links.current,nodes:nodes.current})

      setNodeFocus({nodes:nodes.current, links:links.current, focus:focusView.current, subFocus:subFocusView.current, type:viewType.current, isExclude:isExclude.current})

      if(!keepLoading){
        let noResult = isRoot || originalList.filter(l=>l.shouldConstructLink).length===0;
        if(direction==='upstream'){
          target.data.leftAction = noResult?undefined:target.data.leftAction;
          target.data.leftActionIcon = noResult?undefined:'remove';
          // bind load upstream code action
          if(isRoot && originalList.filter(l=>l.shouldConstructLink).length===0 && !checkHasLineage({node:target, direction, inactive:isRootInactive})){ 
            bindLoadUpstremCodeAction({node: target, onNodeExpandClick, inactive:isRootInactive})
          }
        }else{
          target.data.rightAction = noResult?undefined:target.data.rightAction;
          target.data.rightActionIcon = noResult?undefined:'remove';
        }
        target[`${direction}_loaded`] = true
        target[`${direction}_expanded`] = true;
      }
    }catch(error){
      console.log(error)
      setError(true)
      sendAlert({type:'error',message:`Error occurred loading lineage, please try again`})
      if(direction==='upstream'){
        target.data.leftActionIcon = 'add';
      }else{
        target.data.rightActionIcon = 'add';
      }
    }
    forceUpdate()
  }
  
  const initialiseTree = async ({showReference = isShowReference.current,  showFullName = isShowFullName.current, loadID}) => {
    if(isDestroyed.current===true)return;
    setError(false)
    setLoading(true)
    try{
      nodes.current = [];
      links.current = [];
      let rootItem = {
        id: root.id,
        name:root.name,
        parent_id: root.parent?root.parent.id:(root.hierarchy_parent_id||undefined),
        parent_name: root.parent?root.parent.name:(root.hierarchy_parent_id||undefined),
        type:rootLabel
      }
      let list = await enrichData({list:[rootItem], currentList:[],abortController:abortRef.current,target:{id:'root'}, isRoot:true});
      if(loadID!==currentLoadID.current)return;
      list  = processRawData({list, target:{id:'root'}})
      let thirdLevelItem, secondLevelItem;
      thirdLevelItem = list.find(l=>thirdLevelObjects.includes(getCerebrumLabel(l.data.obj)))
      secondLevelItem = list.find(l=>!thirdLevelObjects.includes(getCerebrumLabel(l.data.obj)))
      // let isLoadParent = thirdLevelItem;
      // let expandedItem = isLoadParent?list.find(l=>!thirdLevelObjects.includes(getCerebrumLabel(l.data.obj))):list.find(l=>l.id===root.id)
      let expandedItem = thirdLevelItem || secondLevelItem;
      list.forEach(l=>{
        l.isRoot = true;
        forceBindActionToNodes({node:l, onNodeClick, generateCustomRightClickAction, updateFocusedNode})
      })
      if(!expandedItem){
        setLoading(false)
        return;
      };
      if(!downstreamOnly){
        expandedItem.data.leftActionIcon = 'loading';
        expandedItem.data.leftAction = ()=>{};
        expandedItem.data.leftHandleOffset = -6;

        // if(thirdLevelItem){
        //   thirdLevelItem.data.leftActionIcon = 'loading';
        //   thirdLevelItem.data.leftAction = ()=>{};
        //   thirdLevelItem.data.leftHandleOffset = -6;
        // }
      }
      if(!upstreamOnly){
        expandedItem.data.rightActionIcon = 'loading';
        expandedItem.data.rightAction = ()=>{};
        expandedItem.data.rightHandleOffset = -4

        // if(thirdLevelItem){
        //   thirdLevelItem.data.rightActionIcon = 'loading';
        //   thirdLevelItem.data.rightAction = ()=>{};
        //   thirdLevelItem.data.rightHandleOffset = -4;
        // }
      }
      let groups = await generateGroups({newList:list, onNodeClick,currentNodes:[], onNodeExpandClick, direction:'both', inactive:isRootInactive})
      if(loadID!==currentLoadID.current)return;
      groups.forEach(el=>el.isRoot = true);
      assignGroupInitialPos({groups})
      nodes.current.push(...groups)
      addNodeToGroup({children:list, currentNodes:nodes.current })
      nodes.current.push(...list)
      setError(false)
      setLoading(false)
      // forceUpdate()
      if(!downstreamOnly && !hideReplaced){
        await expandNodes({target:expandedItem, direction:'upstream', isRoot:true, forceObjectTypes:[rootLabel], forceRelation:['REPLACES'],  keepLoading:true, hideEmptyMessage:true, showReference, showFullName, loadID})
        if(loadID!==currentLoadID.current)return;
      }
      if(!upstreamOnly && !hideReplaced){
        await expandNodes({target:expandedItem, direction:'downstream', isRoot:true, forceObjectTypes:[rootLabel], forceRelation:['REPLACED_BY'],  keepLoading:true, hideEmptyMessage:true, showReference, showFullName, loadID})
        if(loadID!==currentLoadID.current)return;
      }
      if(!downstreamOnly){
        await expandNodes({target:expandedItem, direction:'upstream', isRoot:true, hideEmptyMessage:true, showReference, showFullName, loadID})
        if(loadID!==currentLoadID.current)return;
      }
      if(!upstreamOnly){
        await expandNodes({target:expandedItem, direction:'downstream', isRoot:true, hideEmptyMessage:true, showReference, showFullName, loadID})
        if(loadID!==currentLoadID.current)return;
      }
      if(thirdLevelItem){
        if(!downstreamOnly ){
          let hasLineage = checkHasLineage({node:secondLevelItem, direction:'upstream', inactive:isRootInactive }) 
          if(links.current.find(l=>l.target===thirdLevelItem.id)){
            if(loadID!==currentLoadID.current)return;
            forceBindActionToNodes({node:secondLevelItem, direction:'upstream', onNodeExpandClick, hasLineage})
            forceUpdate()
          }else if(hasLineage){
            secondLevelItem.data.leftActionIcon = 'loading';
            secondLevelItem.data.leftAction = ()=>{};
            secondLevelItem.data.leftHandleOffset = -6;
            forceUpdate()
            await expandNodes({target:secondLevelItem, direction:'upstream', hideEmptyMessage:true,isRoot:true, showReference, showFullName, loadID})
          }
          if(loadID!==currentLoadID.current)return;
        }
        if(!upstreamOnly ){
          let hasLineage = checkHasLineage({node:secondLevelItem, direction:'downstream', inactive:isRootInactive })
          if(links.current.find(l=>l.source===thirdLevelItem.id)){
            if(loadID!==currentLoadID.current)return;
            forceBindActionToNodes({node:secondLevelItem, direction:'downstream', onNodeExpandClick, hasLineage})
            forceUpdate()
          }else if(hasLineage){
            secondLevelItem.data.rightActionIcon = 'loading';
            secondLevelItem.data.rightAction = ()=>{};
            secondLevelItem.data.rightHandleOffset = -4;
            forceUpdate()
            await expandNodes({target:secondLevelItem, direction:'downstream', hideEmptyMessage:true, isRoot:true, showReference, showFullName, loadID})
          }
          if(loadID!==currentLoadID.current)return;
        }
      }
    }catch(error){
      console.log(error)
      setError(true)
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(error){
      nodes.current = [];
      links.current = [];
    }
  },[error])
  
  useEffect(()=>{
    if(!state.lineageData.nodes || state.lineageData.nodes.length===0){
      abortRef.current.abort()
      abortRef.current = new AbortController();
      currentLoadID.current+=1;
      initialiseTree({loadID:currentLoadID.current})
    }else{
      bindActionsToNodes({nodes:nodes.current, allNodes:nodes.current, onNodeExpandClick, onNodeClick, generateCustomRightClickAction, updateFocusedNode, isRestoreCache:true, loadChildLineage})
      bindActionsToLinks({links:links.current, nodes:nodes.current, allNodes:nodes.current, setLinkedItem, updateFocusLink,updateFocusedNode, setSelectedItem, setLineageDrawerOpen, setObjectDrawerOpen})
    }
    return ()=>{
      isDestroyed.current=true
      if(disableCache)return;
      dispatch({
        type:'set_lineage_data',
        lineageData:{
          ...state.lineageData,
          isShowReference:isShowReference.current,
          isShowFullName:isShowFullName.current,
          focus:focusView.current,
          subFocus:subFocusView.current,
          viewType:viewType.current,
          tagView:tagView.current,
          nodes:nodes.current,
          links:links.current,
        }
      })
    }
  // eslint-disable-next-line
  },[])
  
  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.reload_lineage || msg.data.upstreamAdded || msg.data.upstreamDeleted || msg.data.downstreamAdded || msg.data.downstreamDeleted || msg.data.refreshLineage  ){
        nodes.current = []
        links.current = []
        forceUpdate()
        abortRef.current.abort()
        abortRef.current = new AbortController();
        currentLoadID.current+=1;
        setTimeout(()=>initialiseTree({loadID:currentLoadID.current}))
      }
      if(msg.data.setShowReference){
        if(msg.data.groupId){
          let group = nodes.current.find(n=>n.id===msg.data.groupId)
          let xAxis = group.position.x
          onSetShowReference(false,false,xAxis)
        }else{
          onSetShowReference(true)
        }
      }
    }
    window.removeEventListener('message',globalListenerRef.lineageMsgListener);
    if(isDestroyed.current===true)return
    globalListenerRef.lineageMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.lineageMsgListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.lineageMsgListener);})
   // eslint-disable-next-line
  },[])

  const onRestore = () => {
    nodes.current = [];
    links.current = [];
    if(!disableFocus){
      focusView.current = forceFocusOption?.focus || [];
      subFocusView.current = forceFocusOption?.subFocus || {};
    }
    forceUpdate();
    abortRef.current.abort()
    abortRef.current = new AbortController();
    currentLoadID.current+=1;
    setTimeout(()=>initialiseTree({loadID:currentLoadID.current}))
  }

  const onSethideMiniMap = () => {
    dispatch({type:'set_lineage_data',lineageData:{...state.lineageData,showMiniMap:!state.lineageData.showMiniMap}})
  }

  const onSetShowReference = async (value, isWaiting, xAxis) => {
    clearTimeout(showReferenceTimeout.current)
    if(isShowReference.current===value && (!xAxis || showReferenceXaxis.current.includes(xAxis)) && !isWaiting)return;
    isShowReference.current = value;
    if(xAxis){
      showReferenceXaxis.current = [...showReferenceXaxis.current, xAxis];
    }else{
      showReferenceXaxis.current = [];
    }
    if(nodes.current.find(n=>n.data.rightActionIcon==='loading' || n.data.leftActionIcon==='loading')){
      showReferenceTimeout.current = setTimeout(()=>onSetShowReference(value,true),500)
      return;
    }
    if(value===true){
      layoutGraph({ 
        newNodes: nodes.current.filter(n=>n.data.obj?.reference_txt==='YES'), 
        currentNodes: nodes.current,isReLayout:true, 
        currentLinks: links.current,
        showReference:true, 
        showReferenceXaxis:showReferenceXaxis.current,
        showFullName:isShowFullName.current,
        getDisplayedTags
      })
    }else{
      let referencePlaceholderNodes = nodes.current.filter(n=>checkIsReferencePlaceHolder(n))
      nodes.current = nodes.current.filter(n=>!checkIsReferencePlaceHolder(n))
      layoutGraph({ 
        newNodes: nodes.current, 
        currentNodes: nodes.current, 
        currentLinks: links.current,
        showReference:false, 
        showReferenceXaxis:showReferenceXaxis.current,
        showFullName:isShowFullName.current,
        isReLayout:true,
        getDisplayedTags
      })
      if(referencePlaceholderNodes.length>0){
        referencePlaceholderNodes.forEach(n=>{ 
          setReferencePlaceholderPosition({node:n,currentNodes:nodes.current})
          nodes.current.push(n)
        })
      }
    }
    forceUpdate();
  }
  
  const onSetShowFullName = async (value, isWaiting) => {
    clearTimeout(showFullNameTimeout.current)
    if(isShowFullName.current===value && !isWaiting)return;
    isShowFullName.current = value;
    // if any node is loading, wait till it finishes
    if(nodes.current.find(n=>n.data.rightActionIcon==='loading' || n.data.leftActionIcon==='loading')){
      showFullNameTimeout.current = setTimeout(()=>onSetShowFullName(value,true),500)
      return;
    }
    onToggleShowFullName({nodes:nodes.current, showFullName:value, showReference:isShowReference.current, getDisplayedTags})
    forceUpdate();
  }

  const onChangeFocus = value => {
    if(focusView.current===value)return;
    focusView.current = value
    setNodeFocus({focus:value, subFocus:subFocusView.current, nodes:nodes.current, links:links.current, type:viewType.current, isExclude:isExclude.current})
    forceUpdate();
  }

  const onChangeSubFocus = (focus, value, forcedValue) => {
    if(forcedValue){
      subFocusView.current = forcedValue
    }else{
      subFocusView.current = {...subFocusView.current,[focus]:value}
    }
    setNodeFocus({focus:focusView.current, subFocus:subFocusView.current, nodes:nodes.current, links:links.current, type:viewType.current, isExclude:isExclude.current})
    forceUpdate();
  }

  const onToggleViewType = () => {
    viewType.current = viewType.current==='and'?'or':'and'
    setNodeFocus({focus:focusView.current, subFocus:subFocusView.current, nodes:nodes.current, links:links.current, type:viewType.current, isExclude:isExclude.current})
    forceUpdate();
  }

  const onToggleInclude = () => {
    isExclude.current = !isExclude.current
    setNodeFocus({focus:focusView.current, subFocus:subFocusView.current, nodes:nodes.current, links:links.current, type:viewType.current, isExclude:isExclude.current})
    forceUpdate();
  }

  const onChangeTagView = (view) => {
    if(tagView.current===view)return;
    tagView.current = view;
    localStorage.setItem('lineage_tag_view',view)
    // nodes.current.forEach(n=>{
    //   if(n.type==='KLineageNode'){
    //     n.height = nodeOneLineHeight;
    //     n.data.wrapTitle = false;
    //   }
    // })
    // onToggleShowFullName({nodes:nodes.current, showFullName:isShowFullName.current, showReference:isShowReference.current, getDisplayedTags})
    forceUpdate()
  }

  
  const nodeConnectedToRoot = n => {
    let direction = n.direction;
    let visibleLinks = links.current.filter(l=>isLinkVisible(l));
    let traversedNode = []

    const isReachRoot = nodeID => {
      if(traversedNode.includes(nodeID))return false;
      traversedNode.push(nodeID)
      if(!nodes.current.find(n=>n.id===nodeID))return false;
      if(nodes.current.find(n=>n.id===nodeID).isRoot)return true;
      let links = visibleLinks.filter(l=>l[direction==='downstream'?'target':'source']===nodeID);
      // if(n.id==='6bb03b86-f5a0-38c6-8036-bbebca5da2c9')console.log(links, n.direction)
      let upperLevelIDs = links.map(l=>l[direction==='downstream'?'source':'target'])
      let childUpperLevelIDs = nodes.current.filter(n=>upperLevelIDs.includes(n.parentNode)).map(el=>el.id)
      let thirdLevelChildUpperLevelIDs = nodes.current.filter(n=>childUpperLevelIDs.includes(n.parentNode)).map(el=>el.id)
      let parentUpperLevelIDs = [];
      if(nodes.current.find(n=>n.id===nodeID)?.isChildLineage)parentUpperLevelIDs.push(nodes.current.find(n=>n.id===nodeID).parentNode)
      let ids = [...upperLevelIDs,...childUpperLevelIDs,...thirdLevelChildUpperLevelIDs,...parentUpperLevelIDs]
      if(ids.length===0)return false;
      
      return ids.some(id=>isReachRoot(id))
    }

    return isReachRoot(n.id)
  }

  const onSelectionChange = data => {
    if(data.edges.length===0 && data.nodes.length===0){
      updateFocusedNode([])
      setBorderHeighlightNode([])
      setNodeFocus({nodes:nodes.current, links:links.current, focus:focusView.current, subFocus:subFocusView.current, type:viewType.current, isExclude:isExclude.current})
    }
  }

  // const onEnableNewLineage = () => {
  //   dispatch({
  //     type:"set_lineage_data",
  //     lineageData:{}
  //   })
  //   localStorage.setItem("lineage_v3",true)
  // }

  const isNodeVisible = n => {
    if(!n)return false;
    let children = nodes.current.filter(c=>c.parentNode && c.parentNode===n.id)
    // if(n.data.obj?.object_type_txt==='DATASET'){
    //   let children = nodes.current.filter(c=>c.parentNode && !c.isChildLineage && c.data.obj?.object_type_txt==='DATASET_TABLE' && c.parentNode.split('_')[0]===n.id)
    //   if(children.length!==0)return false;
    // }
    if(n.parentNode && !nodes.current.find(p=>p.id===n.parentNode))return false;
    if(n.isReferecenPlaceholder && showReferenceXaxis.current.includes(n.position.x))return false;
    if(n.isChildLineage && isNodeVisible(nodes.current.find(p=>p.id===n.parentNode)))return true;
    if(!nodeConnectedToRoot(n)){
      return children.some(c=>isNodeVisible(c))
    }
    if(n.data.obj?.reference_txt==='YES' && !isShowReference.current && !n.isRoot){
      let parentNode = nodes.current.find(p=>p.id===n.parentNode)
      if(parentNode.type!=='KGroup')parentNode = nodes.current.find(p=>p.id===parentNode.parentNode)
      if(parentNode && showReferenceXaxis.current.includes(parentNode.position.x))return nodeConnectedToRoot(n);
      return false;
    }
    return Boolean(
      n.isRoot || 
      (
        [n,...children].find(c=>nodeConnectedToRoot(c))
      )
    )
  }
  
  const getActualHeight = n => {
    if(n.type==='KGroup'){
      let children = nodes.current.filter(c=>c.parentNode && c.parentNode===n.id)
      let height = n.height;
      children.forEach(c=>{
        if(isNodeVisible(c)){
          height -= (c.height - getActualHeight(c))
        }else{
          if(!isShowReference.current && c.data.obj?.reference_txt==='YES')return;
          height -= getActualHeight(c)
        }
      })
      return height
    }
    let children = nodes.current.filter(c=>c.parentNode && c.parentNode===n.id)
    let height = n.height;
    children.forEach((c,index)=>{
      if(!isNodeVisible(c)){
        height -= c.height
      }
    })
    // if(n.data.bodyActions)height += n.data.bodyActions.length*30
    return height
  }

  const getDisplayedTags = (tags) => {
    if(!tags)return;
    if(tagView.current==='trust')return tags?.filter(t=>t.id==='trust')
    if(tagView.current==='dq_score')return tags?.filter(t=>t.id==='dq_score')
    if(tagView.current==='knowledge')return tags?.filter(t=>t.id==='knowledge')
    return tags?.filter(t=>t.id==='trust')
  }
  

  const postProcessNodes = (nodes) => {
    const getOrder = n => {
      if(n.type==='KGroup')return 1;
      if(nodes.find(c=>c.parentNode===n.id))return 2;
      return 3;
    }
    
    return nodes
      .filter(g=>
        (isNodeVisible(g)) && 
        (!checkIsReferencePlaceHolder(g) || !isShowReference.current)
      )
      .sort((a,b)=>getOrder(a)-getOrder(b)) // to prevent layer issue
      .map(n=>{
        let isThirdLevel = n.data.obj && thirdLevelObjects.includes(n.data.obj?.type);
        let isSecondLevel = !isThirdLevel && !['KGroup','KButtonNode'].includes(n.type);
        let yOffset = 0;
        if(isThirdLevel){
          nodes.forEach(c=>{
            if(c.parentNode===n.parentNode && !isNodeVisible(c) && c.position.y<n.position.y){
              yOffset -= c.height
            }
          })
        }
        if(isSecondLevel){
          nodes.forEach(c=>{
            if(c.parentNode===n.parentNode && !isNodeVisible(c) && c.position.y<n.position.y){
              yOffset -= (c.height)
            }
            else if(c.parentNode===n.parentNode && c.height!==getActualHeight(c) && c.position.y<n.position.y){
              yOffset -= (c.height-getActualHeight(c))
            }
          })
        }
        return {
          ...n,
          position:{
            x: n.position?.x, 
            y: n.position?.y + yOffset
          },
          hidden:n.type==='KGroup' && hideGroup,
          draggable:false,
          data:{
            ...n.data,
            tags:getDisplayedTags(n.data.tags),
            height:getActualHeight(n), 
            width:n.width,
            draggable:false,
            hideChildrenDropdown: Boolean(thirdLevelObjects.includes(rootLabel) || nodes.find(c=>c.parentNode===n.id) || hideChildList),
            borderColour:isThirdLevel?'transparent':n.data.borderColour,
            borderTopColour:isThirdLevel?theme.palette.listItemDivider.main:undefined,
          }
        }
      })
  }

  const isLinkVisible = l => {
    if(l.hidden)return false
    if(l.data?.relationship?.includes('REFERENCE')){
      let source = nodes.current.find(n=>n.id===l.source)
      let target = nodes.current.find(n=>n.id===l.target)
      if(target?.data?.obj?.object_type_txt==='TABLE' && source?.data?.obj?.object_type_txt==='COLUMN'){
        if(
          links.current.find(sl=>{
            if(sl.source!==l.source)return false;
            let t = nodes.current.find(n=>n.id===sl.target)
            if(t?.parentNode===target.id && t?.data?.obj?.object_type_txt==='COLUMN')return true;
            return false;
          })
        )return false;
      }
      if(target?.data?.obj?.object_type_txt==='COLUMN' && source?.data?.obj?.object_type_txt==='TABLE'){
        if(
          links.current.find(sl=>{
            if(sl.target!==l.target)return false;
            let s = nodes.current.find(n=>n.id===sl.source)
            if(s?.parentNode===source.id && s?.data?.obj?.object_type_txt==='COLUMN')return true;
            return false;
          })
        )return false;
      }
    }
    return true
  }
  
  const postProcessLinks = links => {
    return links
      .filter(l=>isLinkVisible(l))
      .map(l=>{
        let sameSourceLinks = links.filter(a=>a.source===l.source && a.id!==l.id);
        if(sameSourceLinks.find(s=> nodes.current.find(n=>n.id===s.target && n.parentNode && n.parentNode===l.target) )){
          return {
            ...l,
            data:{
              ...l.data,
              opacity:0.3
            }
          }
        } 
        return l
      })
    .map(l=>{
      if(!links.find(c=>c.selected))return l;
      if(!l.selected){
        return {
          ...l,
          data:{
            ...l.data,
            width:2,
            opacity:0.3
          }
        }
      }
      return l;
    })
  }

  let horizontalOffset = 0;
  if(downstreamOnly) horizontalOffset = graphWidth/2
  if(upstreamOnly)horizontalOffset = -graphWidth/2

  const viewOptions = [
    {id:'dq_score',label:'Data Quality'},
    {id:'knowledge',label:'Knowledge'},
    {id:'trust',label:'Trust'}
  ]

  let legendArr;

  if(tagView.current==='trust'){
    legendArr = [
      {text:'Lowest',colour:getTrustScoreColor(0)},
      {text:'Low',colour:getTrustScoreColor(20)},
      {text:'Medium',colour:getTrustScoreColor(40)},
      {text:'High',colour:getTrustScoreColor(60)},
      {text:'Highest',colour:getTrustScoreColor(80)}
    ]
    // legendText = 'TRUST'
  }
  if(tagView.current==='dq_score'){
    legendArr = [
      {text:'0',colour:getTestScoreColor(0,true)},
      {text:'1-25',colour:getTestScoreColor(10,true)},
      {text:'26-50',colour:getTestScoreColor(30,true)},
      {text:'51-75',colour:getTestScoreColor(60,true)},
      {text:'76-99',colour:getTestScoreColor(89,true)},
      {text:'100',colour:getTestScoreColor(100,true)}
    ]
    // legendText = 'DATA QUALITY'
  }


  if(tagView.current==='knowledge'){
    legendArr = [
      {text:'ONE',colour:getTestScoreColor(50,true)},
      {text:'MANY',colour:getTestScoreColor(100,true)}
    ]
    // legendText = 'KNOWLEDGE'
  }

  let isFloatHeader = !isApp && !fullScreen;
  
  let LineageComponent = (
    <div className={classes.root}>
      <div style={{display:'flex',zIndex:23,alignItems:'flex-start',justifyContent:'flex-end',flexWrap:'wrap',position:isFloatHeader?'sticky':undefined,top:isFloatHeader?162:undefined,background:theme.palette.background.main,marginTop:fullScreen?0:isApp?16:-41.75}}>
          <div style={{display:'flex',alignItems:'center',flexGrow:0,flexShrink:0,minHeight:34}}>
            <Typography className={classes.legendText} style={{marginRight:12}}>MAP CONTROLS:</Typography>
            <KTooltip title={`Download lineage map`}>
              <div className={classes.button} style={{marginRight:16}} onClick={()=>onDownloadMap(`${root.name}_lineage_map`)}>
                {getIconComponent({label:'download',size:18,colour:theme.palette.primaryText.light})}
              </div>
            </KTooltip>
            <KTooltip title={`${state.lineageData.showMiniMap?'Hide':'Show'} minimap`}>
              <div className={classes.button} style={{marginRight:16}} onClick={onSethideMiniMap}>
                {getIconComponent({label:state.lineageData.showMiniMap?'hide_window':'show_window',size:18,colour:theme.palette.primaryText.light})}
              </div>
            </KTooltip>
            <KTooltip title={`Full screen`}>
              <div className={classes.button} style={{marginRight:16}} onClick={()=>{if(fullScreen)onCloseFullScreen?.();setFullScreen(!fullScreen)}}>
                {getIconComponent({label:fullScreen?"zoom_in_map":'zoom_out_map',size:18,colour:theme.palette.primaryText.light})}
              </div>
            </KTooltip>
            <KTooltip title={`Reload map`}>
              <div className={classes.button} style={{marginRight:16}} onClick={onRestore}>
                {getIconComponent({label:'refresh',size:18,colour:theme.palette.primaryText.light})}
              </div>
            </KTooltip>
            {
              fullScreen && 
              <Button color='primary' style={{padding:'0 4px',marginLeft:16,minWidth:0}} onClick={()=>{if(fullScreen)onCloseFullScreen?.();setFullScreen(!fullScreen)}}>
                CLOSE
              </Button>
            }
        </div>
      </div>
      <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',flexWrap:'wrap',position:isFloatHeader?'sticky':undefined,top:isFloatHeader?stickyTop||190:undefined,background:theme.palette.background.main,zIndex:23,paddingTop:fullScreen?-36:12}}>
        <>
          <Typography style={{letterSpacing:1,marginRight:8,color:theme.palette.primary.main,fontSize:12,marginBottom:6}}>VIEWING: </Typography>
          <Select
            inputProps={{
              'data-test-id':'lineage-action-button'
            }}
            className={classes.selector}
            style={{marginRight:16,marginBottom:6}}
            value={[]}
            disableUnderline
            renderValue={value=>{
              let shownArr = ['Active']
              if(isRootInactive)shownArr = ['Inactive']
              if(isShowReference.current)shownArr.push('Reference')
              if(isShowFullName.current)shownArr.push('Full names')
              return shownArr.join(', ')
            }}
            displayEmpty
            multiple
          >
            <div>
              <Typography color='primary' style={{fontSize:12,letterSpacing:1.5,marginLeft:16,marginBottom:6}}>
                {
                  function(){
                    let shownArr = ['Active']
                    if(isRootInactive)shownArr = ['Inactive']
                    if(isShowReference.current)shownArr.push('Reference')
                    if(isShowFullName.current)shownArr.push('Full names')
                    return shownArr.length
                  }()
                } SELECTED 
              </Typography>
              <MenuItem key={isRootInactive?'inactive':'active'}  disabled={true} value={isRootInactive?'inactive':'active'} data-test-id="lineage-show-active-button" >
                <Checkbox key={isShowReference.current} disabled={true} className={classes.checkbox} color='primary' checked={true}/>
                {isRootInactive?'Inactive':'Active'}
              </MenuItem>
              <MenuItem key={'reference'} value={'reference'} disabled={typeof(forceIsShowReference)==='boolean'} data-test-id="lineage-show-reference-button" onClick={()=>{onSetShowReference(!isShowReference.current)}} >
                <Checkbox key={isShowReference.current} disabled={typeof(forceIsShowReference)==='boolean'} className={classes.checkbox} color='primary' checked={isShowReference.current}/>
                Reference
              </MenuItem>
              <MenuItem key="full_name" value="full_name"  data-test-id="lineage-show-full-name-button" onClick={()=>{onSetShowFullName(!isShowFullName.current)}} >
                <Checkbox key={isShowFullName.current} className={classes.checkbox} color='primary' checked={isShowFullName.current}/>
                Show full names
              </MenuItem>
              {/* <MenuItem data-test-id="lineage-show-new-lineage-button" onClick={()=>{onEnableNewLineage()}} >
                <Checkbox className={classes.checkbox} color='primary' checked={false}/>
                Use New Lineage UX
              </MenuItem> */}
            </div>
          </Select>
          
          {/* <Typography style={{letterSpacing:1,marginRight:8,color:theme.palette.primary.main,fontSize:12,marginBottom:6}}>INDICATOR: </Typography> */}
          
        </>
        {
          !disableFocus && 
          <ViewSelector
            focusView={focusView.current}
            onChangeFocus={onChangeFocus}
            subFocusView={subFocusView.current}
            onChangeSubFocus={onChangeSubFocus}
            nodes={nodes.current}
            onToggleType={onToggleViewType}
            type={viewType.current}
            isExclude={isExclude.current}
            onToggleInclude={onToggleInclude}
          />
        }
        <div style={{flexGrow:1,marginTop:fullScreen?8:0,marginBottom:8,display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
          {/* <Typography className={classes.legendText}>{legendText}:</Typography> */}
          <Select
            inputProps={{
              'data-test-id':'lineage-action-button'
            }}
            className={classes.selector}
            style={{marginRight:0,marginBottom:0}}
            value={[]}
            disableUnderline
            renderValue={value=>{
              let shownArr = []
              shownArr.push(viewOptions.find(v=>v.id===tagView.current).label)
              return shownArr.join(', ')
            }}
            displayEmpty
            multiple
          >
            <div>
              {
                viewOptions.map(el=>(
                  <MenuItem key={el.id} value={el.id} data-test-id={`lineage-show-${el.id}-tag`} onClick={()=>{onChangeTagView(el.id)}} >
                    <Radio key={tagView.current} className={classes.checkbox} color='primary' checked={tagView.current===el.id}/>
                    {el.label}
                  </MenuItem>
                ))
              }
            </div>
          </Select>
          {
            legendArr.map(el=>(
              <div key={el.text} style={{display:'flex',alignItems:'center',marginLeft:12}}>
                <div style={{width:16,height:16,borderRadius:8,background:el.colour}}></div>
                <Typography className={classes.legendItemText}>{el.text}</Typography>
              </div>
            ))
          }
        </div>
      </div>
      <div style={{marginTop:isApp?8:20}}>
        {
          loading && 
          <div style={{textAlign:'center',marginTop:168}}>
            <LinearProgress color='secondary' style={{width:200,margin:'auto',marginBottom:24}}/>
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>Generating {rootLabel==='COLLECTION'?"":'lineage '}map</Typography>
          </div>
        }

        {
          error?
          <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
            <div className={classes.emotRoot}>
              <Typography className={classes.emot}>¯\_(ツ)_/¯</Typography>
              <Typography className={classes.text}>Unfortunately I am not sure what went wrong.</Typography>
              <Typography className={classes.text}>Let your administrator know so they can fix the problem.</Typography>
            </div>
          </div>
          :
          <div 
            className={classes.chartWrapper} 
            style={{width:fullScreen?fullScreenGraphWidth:graphWidth,height:fullScreen?fullScreenGraphHeight-60:graphHeight}} 
            ref={chartWrapperRef}
          >
            {
              (chartWrapperRef.current || fullScreen) && nodes.current && links.current && !loading &&
              <ReactFlowWrapper
                initialNodes={postProcessNodes(nodes.current)}
                initialLinks={postProcessLinks(links.current)}
                presetCenter={{x:horizontalOffset + nodeWidth/2,y:(graphHeight/2) + centerTopOffSet}}
                centerOffset={{x:nodeWidth/2,y:(graphHeight/2) + centerTopOffSet}}
                onFocusOffset={{x:200,y:0}}
                hideMiniMap={!state.lineageData.showMiniMap}
                onSelectionChange={onSelectionChange}
              />
            }
          </div>
        }
        
      </div>
      {
        lineageDrawerOpen &&
        <LineageDetailDrawer
          history={history}
          drawerOpen={lineageDrawerOpen}
          setDrawerOpen={setLineageDrawerOpen}
          selectedItem={selectedItem}
          linkedItem={linkedItem}
          nodes={nodes.current}
        />
      }
      {
        objectDrawerOpen &&
        <ObjectDetailDrawer
          history={history}
          isLinkable={isLinkable}
          drawerOpen={objectDrawerOpen}
          setDrawerOpen={setObjectDrawerOpen}
          selectedItem={selectedItem}
          tagView={tagView.current}
        />
      }
    </div>
  )
  
  return (
    fullScreen?
    <Modal open={fullScreen} onClose={()=>{onCloseFullScreen?.();setFullScreen(false);}}>
      <Paper style={{width:fullScreenGraphWidth,padding:30,marginLeft:60,marginTop:30, height:fullScreenGraphHeight+50}}>
        {LineageComponent}
      </Paper>
    </Modal>
    :
    LineageComponent
  )
}

LineageGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  root: PropTypes.object.isRequired,
  rootLabel: PropTypes.string.isRequired,
  centerTopOffSet: PropTypes.number,
  isApp: PropTypes.bool,
  disableFocus: PropTypes.bool,
  forceFocusOption: PropTypes.object,
  isLinkable: PropTypes.bool,
  downstreamOnly: PropTypes.bool,
  upstreamOnly: PropTypes.bool,
  hideReplaced: PropTypes.bool,
  hideChildList: PropTypes.bool,
  hideGroup: PropTypes.bool,
  disableCache: PropTypes.bool,
  forceIsShowReference: PropTypes.bool,
  stickyTop: PropTypes.number,
  defaultFullScreen: PropTypes.bool,
  onCloseFullScreen: PropTypes.func,
}

export default withTheme()(withStyles(styles)(LineageGraph));