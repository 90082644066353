import React, {useState, useEffect} from 'react';
import { withTheme, withStyles, Drawer, IconButton, Typography } from '@material-ui/core';
import { getDispFields, getIconComponent, onClickResultItem, } from '../../../../utilities';
import ResultItem2 from '../../../UI/SearchResults/ResultItem2';
import { getIconLabel } from '../../../UI/SearchResults/utils';
import DrawerDragTrigger from '../../../UI/DrawerDragTigger/DrawerDragTrigger';

const styles = theme => ({

  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16,
    letterSpacing:1.5
  }
})


function DetailDrawer(props) {
  const {
    classes,
    theme,
    history,
    drawerOpen,
    setDrawerOpen,
    sourceItem,
    targetItem,
    onItemClick
  } = props;


  let initialWidth = 600;
  try{
    if(localStorage.getItem('sourceLinkDrawerWidth')){
      initialWidth = Number(localStorage.getItem('sourceLinkDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  const [paperWidth, setPaperWidth] = useState(initialWidth)

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])
  

  return (
    <Drawer key={sourceItem?.id+targetItem?.id} anchor="right" open={drawerOpen} PaperProps={{style:{width:paperWidth,minWidth:500}}} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      { 
        sourceItem && targetItem && 
        <>
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
              setPaperWidth(paperWidth+diff)
              localStorage.setItem('sourceLinkDrawerWidth',paperWidth+diff)}}
          />
          <div style={{marginLeft:24}}>
            <div style={{display:'flex',alignItems:'flex-start',paddingTop:24,paddingBottom:40}}>
              <Typography style={{fontSize:20}}>Source to Source Lineage</Typography>
              <div style={{flexGrow:1}}></div>
              <IconButton 
                onClick={()=>setDrawerOpen(false)} 
                style={{width:28,height:28,padding:8}}
              >
                {
                  getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
                }
              </IconButton>
            </div>
            <Typography style={{fontSize:16,fontWeight:700,marginBottom:16}}>
              Select an asset to navigate to it on the map
            </Typography>
            <Typography className={classes.sectionHeader}>SOURCE</Typography>
            <ResultItem2
              item={sourceItem.data.obj}
              key={sourceItem.data.obj.id}
              dispBody={getDispFields(sourceItem.data.obj,'dispBody')}
              dispSubtitle={getDispFields(sourceItem.data.obj,'dispSubtitle')}
              dispTitle={getDispFields(sourceItem.data.obj,'dispTitle')}
              label={getIconLabel({label:sourceItem.data.obj.object_type_txt||sourceItem.data.obj.type, item:sourceItem.data.obj})}
              showIcon
              showUnderline
              tailObject={
                <div>
                  <IconButton 
                    onClick={(event)=>{
                      onClickResultItem({id:sourceItem.data.obj.id,label:sourceItem.data.obj.object_type_txt||sourceItem.data.obj.type,history:history,newWindow:true,item:sourceItem.data.obj})
                      event.stopPropagation()
                    }}
                  >
                    {getIconComponent({label:"open",size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </div>
              }
              tailObjectHoverable
              onClick={() => onItemClick(sourceItem.data.obj)}
            />
            <div style={{height:40}}></div>
            <Typography className={classes.sectionHeader}>TARGET</Typography>
            <ResultItem2
              item={targetItem.data.obj}
              key={targetItem.data.obj.id}
              dispBody={getDispFields(targetItem.data.obj,'dispBody')}
              dispSubtitle={getDispFields(targetItem.data.obj,'dispSubtitle')}
              dispTitle={getDispFields(targetItem.data.obj,'dispTitle')}
              label={getIconLabel({label:targetItem.data.obj.object_type_txt||targetItem.data.obj.type, item:targetItem.data.obj})}
              showIcon
              showUnderline
              tailObject={
                <div>
                  <IconButton 
                    onClick={(event)=>{
                      onClickResultItem({id:targetItem.data.obj.id,label:targetItem.data.obj.object_type_txt||targetItem.data.obj.type,history:history,newWindow:true,item:targetItem.data.obj})
                      event.stopPropagation()
                    }}
                  >
                    {getIconComponent({label:"open",size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </div>
              }
              tailObjectHoverable
              onClick={() => onItemClick(targetItem.data.obj)}
            />
          </div>
        </>
      }
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(DetailDrawer));
