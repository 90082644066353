import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, MenuItem, Checkbox, Popper, Paper, ClickAwayListener } from '@material-ui/core';
import { globalListenerRef } from '../../../../../../GlobalListenerRef';
import KTooltip from '../../../../KTooltip/KTooltip';
import { getIconComponent, sendMessage } from '../../../../../../utilities';
import NodeFilter from './NodeFilter';
import { onDownloadMap } from '../../../utils';
import NodeSearch from './NodeSearch';
import Alert from 'react-s-alert';

const styles = theme => ({
  root:{
    position:'fixed',
    width:48,
    borderRadius:4,
    // add shadow on all sides
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
    transition:'max-height 0.3s',
    overflow:'hidden',
    zIndex:99,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
  },
  expandTrigger:{
    width:48,
    height:48,
    flexShrink:0,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  controlRoot:{
    width:48,
    height:48,
    borderRadius:3,
    flexShrink:0,
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '&:hover':{
      background:theme.palette.hovered.main,
    }
  },
  shortcutWrapper:{
    width:'max-content',
    height:24,
    background:'#E0E0E0',
    color:'#000',
    padding:'0px 4px',
    borderRadius:6,
    fontSize:13.75,
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
})

function MainControls(props) {
  const {
    classes,
    theme,
    disableFocus,
    onChangeMapControl,
    initialiseTree,
    mapControls,
    childrenMap,
    currentGroups
  } = props;

  const [left, setLeft] = useState(120);
  const [top, setTop] = useState(73);
  const [visible, setVisible] = useState(false)
  // const [expanded, setExpanded] = useState(false)
  const [anchor, setAnchor] = useState()
  const [selectedControl, setSelectedControl] = useState()
  
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false)

  const getPos = () => {
    let offset = 0;
    if(window.innerWidth>2560){
      offset = -72
    }
    setLeft((document.getElementById('profile-content')?.getBoundingClientRect().left || 180) + offset)
    setTimeout(()=>{
      setTop(document.getElementById('profile-content')?.getBoundingClientRect().top || 73)
    },500)
  }

  useEffect(()=>{
    setTimeout(()=>{
      setVisible(true)
    },500)

    getPos()

    window.removeEventListener('resize',globalListenerRef.lineageFilterResizeListener)
    globalListenerRef.lineageFilterResizeListener = () => {
      getPos()
    }
    window.addEventListener('resize',globalListenerRef.lineageFilterResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.lineageFilterResizeListener)
  },[])

  useEffect(()=>{

    window.removeEventListener('message',globalListenerRef.lineageFilterMsgListener)
    globalListenerRef.lineageFilterMsgListener = (msg) => {
      if(msg.data.close_control_drawer){
        setAnchor()
        setSelectedControl()
      }
    }
    window.addEventListener('message',globalListenerRef.lineageFilterMsgListener)
    return ()=>window.removeEventListener('message',globalListenerRef.lineageFilterMsgListener)
  },[])


  // const onEnableOldLineage = () => {
  //   dispatch({
  //     type:"set_lineage_data",
  //     lineageData:{}
  //   })
  //   localStorage.removeItem("lineage_v3")
  // }

  const onToggleShowActiveOnly = () => {
    onChangeMapControl({control:'isShowActiveOnly',value:mapControls.isShowActiveOnly?false:true})
  }

  const onToggleShowReference = () => {
    onChangeMapControl({control:'isShowReference',value:mapControls.isShowReference?false:true})
  }

  if(!visible) return null

  const controls = [
    {
      iconLabel:'search',
      tooltip:'search on map',
      id:'search'
    },
    disableFocus?undefined:{
      iconLabel:'filter',
      tooltip:'filters',
      id:'filter',
      isFocused:mapControls.focusView?.length>0
    },
    {
      iconLabel:'visibility_on',
      tooltip:'view options',
      id:'view-options'
    },
    {
      iconLabel:'recenter',
      tooltip:'recenter map',
      id:'receter',
      onClick:()=>{sendMessage({'react-flow-recenter':true})}
    },
    {
      iconLabel:'refresh',
      tooltip:'reload map',
      id:'reload',
      onClick:initialiseTree
    },
    {
      iconLabel:'download',
      tooltip:"download map",
      id:'download',
      disabled:downloadButtonDisabled,
      onClick:()=>{
        Alert.info("Downloading map. Please wait.", {
          position: 'top',
          effect: 'stackslide',
          timeout:3000
        })
        setDownloadButtonDisabled(true)
        setTimeout(()=>{
          onDownloadMap(`lineage_map`,()=>{
            setDownloadButtonDisabled(false);
            Alert.closeAll()
          })
        },500)
        
      }
    },
    {
      iconLabel:'keyboard',
      tooltip:(
        <div style={{padding:16}}>
          <div style={{display:'flex',alignItems:'center'}}>
            <div className={classes.shortcutWrapper}>Alt</div>
            <span style={{marginLeft:8,marginRight:8}}>/</span>
            <div className={classes.shortcutWrapper}>Option</div>
            <span style={{marginLeft:8}}>Hold to enable swimlane edit</span>
          </div>      
        </div>
      ),
      id:'shortcut'
    }
  ].filter(el=>el)

  return (
    <div 
      className={classes.root}
      style={{
        top,
        left,
        // maxHeight:expanded?'100vh':48,
        maxHeight:'100vh',
        minHeight:48,
      }}
    >
      {/* <div 
        className={classes.expandTrigger}
      >
        <KTooltip title={expanded?'Hide map controls':'Show map controls'} placement={'right-start'}>
          <IconButton
            onClick={()=>{
              if(expanded){
                setAnchor()
                setSelectedControl()
              }
              setExpanded(!expanded)
            }}
          >
            {
              getIconComponent({
                label:'map',
                size:24,
                colour:expanded?theme.palette.primary.main:theme.palette.primaryText.light,
              })
            }
          </IconButton>
        </KTooltip>
      </div> */}
      {
        controls.map(c=>{
          return (
            <KTooltip title={c.tooltip} placement={'right-start'}>
              <div 
                className={classes.controlRoot}
                style={{
                  background:c.disabled?theme.palette.background.main:(c.isFocused?theme.palette.primary.dark:(selectedControl===c.id?theme.palette.hovered.main:undefined)),
                  cursor:c.disabled?'default':undefined,
                }}
                id={c.buttonId}
                onClick={e=>{
                  if(c.disabled)return;
                  if(c.onClick){
                    c.onClick()
                    setAnchor()
                    setSelectedControl()
                    return;
                  }
                  if(selectedControl===c.id){
                    setAnchor()
                    setSelectedControl()
                    return;
                  }
                  setAnchor(e.currentTarget)
                  setSelectedControl(c.id)
                }}
              >
                {
                  getIconComponent({
                    label:c.iconLabel,
                    size:24,
                    colour:c.isFocused?theme.palette.background.main:theme.palette.primaryText.light,
                  })
                }
              </div>
            </KTooltip>
          )
        })
      }

      <ClickAwayListener 
        onClickAway={()=>{
          setAnchor()
          setSelectedControl()
        }}
      >
        <Popper style={{zIndex:1290}} open={anchor} anchorEl={anchor} placement='right-start'>
          <div>
            {
              selectedControl==='search' && 
              <NodeSearch
                childrenMap={childrenMap}
                currentGroups={currentGroups}
              />
            }
            {
              selectedControl==='filter' && 
              <NodeFilter
                focusView={mapControls.focusView}
                onChangeFocus={focus=>{
                  onChangeMapControl({control:'focusView',value:focus})
                }}
                subFocusView={mapControls.subFocusView}
                onChangeSubFocus={subFocus=>{
                  onChangeMapControl({control:'subFocusView',value:subFocus})
                }}
                childrenMap={childrenMap}
                onToggleType={viewType=>{
                  onChangeMapControl({control:'focusViewType',value:viewType})
                }}
                type={mapControls.focusViewType}
                isExclude={mapControls.isViewExclude}
                onToggleInclude={isExclude=>{
                  onChangeMapControl({control:'isViewExclude',value:isExclude})
                }}
              />
            }
            {
              selectedControl==='view-options' && 
              <Paper
                style={{marginLeft:16,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}
              >
                {/* <MenuItem data-test-id="lineage-show-old-lineage-button" onClick={()=>{onEnableOldLineage()}} >
                  <Checkbox style={{paddingLeft:0}} color='primary' checked={true}/>
                  Use New Lineage UX
                </MenuItem> */}
                <MenuItem data-test-id="lineage-show-active-button" onClick={()=>{onToggleShowActiveOnly()}} >
                  <Checkbox style={{paddingLeft:0}} color='primary' checked={mapControls.isShowActiveOnly}/>
                  Show active only
                </MenuItem>
                <MenuItem data-test-id="lineage-show-active-button" onClick={()=>{onToggleShowReference()}} >
                  <Checkbox style={{paddingLeft:0}} color='primary' checked={mapControls.isShowReference===true}/>
                  Show reference
                </MenuItem>
              </Paper>
            }
          </div>
        </Popper>
      </ClickAwayListener>
    </div>
  )
}

MainControls.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  disableFocus: PropTypes.bool,
  onChangeMapControl: PropTypes.func,
  mapControls: PropTypes.object,
  dispatch: PropTypes.func,
  childrenMap: PropTypes.object,
  currentGroups: PropTypes.array,
}

export default withTheme()(withStyles(styles)(MainControls));