import React, { Component } from 'react';
import { withTheme, withStyles, Typography, Badge, IconButton } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { getInitials, getIconComponent, getUserRoles, updateElement, checkOwnedCount, checkStewardedCount, sendMessage, getCustomerName } from '../../utilities';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import CartIcon from '@material-ui/icons/ShoppingCart'
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import HistoryIcon from '@material-ui/icons/History'
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import AddKnowledgeIcon from '@material-ui/icons/QuestionAnswerOutlined'
import {globalListenerRef} from '../../GlobalListenerRef';
import { loadHistory } from '../../HistoryManager'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { getDataAppListMap, getEcosystemListMap, getInsightsListMap, getListItemJson, getUserSettingList, logoutItemConfig } from './utils';
import { checkCanAddToCart,checkIsBusinessUserOnly,checkIsDataGov } from '../../permissionChecker';
import axiosCerebrum from '../../axios-cerebrum';
import { palette } from '../../theme';
import { getContentContainerStyle } from '../../components/UI/ProfileLayoutNew/layoutUtils';
import axiosSolr from '../../axios-solr';

const iconColour = localStorage.hasOwnProperty('dark')?'#fff':'#757575'
const navBarIconColour = localStorage.hasOwnProperty('dark')?palette.primaryText.main:'#757575'
const navBarIconSelectedColour = localStorage.hasOwnProperty('dark')?'#000':'#000'
const navBarBackground = localStorage.hasOwnProperty('dark')?'#23272a':'#F7F8FA'
const navBarBorder = localStorage.hasOwnProperty('dark')?'#666':'#ccc'
const selectedTabBackground = localStorage.hasOwnProperty('dark')?'#BDBFBF':'#dedede'
const hoveredTabBackground = localStorage.hasOwnProperty('dark')?lighten('#23272a',0.5):'#eee'

const styles = theme => ({
  root: {
    position: 'fixed',
    backgroundColor: navBarBackground,
    borderRight: `1px solid ${navBarBorder}`,
    height: '100%',
    boxSizing: 'border-box',
    padding: '10px 0',
    zIndex: 1299,
    transition:'width 0.2s ease-in-out',
  },
  drawerRoot:{
    zIndex:1298
  },
  divider:{
    overflow:'hidden',
    fontSize:12,
    color:navBarBorder,
    whiteSpace:'nowrap',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    boxSizing: 'border-box',
  },
  listItem: {
    color: theme.palette.primaryText.main,
    display: 'flex',
    justifyContent: 'center',
    cursor:'pointer',
    overflow:'visible',
    padding: `4px 0`,
    marginBottom:8,
    borderRadius:0,
    '&:hover': {
      backgroundColor: hoveredTabBackground,
    },
  },
  wideBar:{
    padding: `4px 16px`,
    justifyContent:'flex-start',
    borderRadius:3,
  },
  selectedListItem:{
    backgroundColor: selectedTabBackground,
    '& svg':{
      color:`${navBarIconSelectedColour} !important`
    },
    '& span':{
      color:`${navBarIconSelectedColour} !important`
    }
  },
  listItemIcon: {
    margin: 0,
    overflow:'hidden'
  },
  listItemText:{
    marginLeft:8,
    fontSize:13.75,
    textDecoration:'none',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  logo: {
    width: 40,
    height:40,
  },
  icon: {
    fontSize: 24,
    color: navBarIconColour
  },
  navLink:{
    textDecoration:'none',
  },
  activeLink: {
    '& $listItem': {
      backgroundColor: selectedTabBackground,
    },
    '& $icon': {
      color: navBarIconSelectedColour,
    },
    '& *:hover':{
      textDecoration:'none !important',
    },
    textDecoration:'none !important',
  },
  inactiveLink: {
    cursor:'default',
    '& *': {
      cursor:'default',
    },
  },
  menu: {
    left: 8,
    // '& a': {
    //   textDecoration: 'none',
    //   outline: 'none',
    // },
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: 8,
    margin: '0 auto',
    backgroundColor:theme.palette.avatar.main,
  },
  modalRoot: {
    borderRadius: 4,
    marginTop: 100
  },
  tileBox: {
    display: 'flex',
    marginRight:24
  },
  openIcon: {
    '&:hover': {
      cursor: 'pointer !important',
    }
  },
  headerTitle: {
    color: theme.palette.primaryText.main,
    fontSize: 20,
    marginTop:24,
    marginLeft:16,
    marginBottom:24,
  },
  listTitle: {
    marginBottom:16,
    fontSize: 12,
    color:theme.palette.header.main,
    letterSpacing: '2px',
  },
  listItemTitle: {
    height: '24px',
    color: theme.palette.primaryText.main,
    
    fontSize: '13.75px',
    lineHeight: '20px'
  },
  listItemDescription: {
    color: theme.palette.primaryText.light,
    fontSize: 12,
  },
  badge:{
    backgroundColor:'#DE350C',
    color:'#fff !important',
    '& *':{
      color:'#fff !important',
    }
  },
  badgeRoot:{
    '& span':{
      color:'#fff !important',
    }
  },
  adminListItem:{
    paddingRight:16,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  adminModal:{
    background:theme.palette.background.main,
    paddingLeft:24
  },
  historyChipContainer:{
    width:384,
    paddingLeft:16,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  customScroll:{
    ...theme.components.customScroll
  }
});

class NavBar extends Component {

  state = {
    newQuestions:0,
    newNotifications: 0,
    isOwner:false,
    isSteward:false,
    cartCount:0,
    navBarWidth:40,
    hasDQ:false,
    ownOrStewardDomain:false
  }

  openPortal = event => {
    this.setState({ ...this.state, 'left': false });
    window.open('https://kada-knowledge-library.scrollhelp.site/home/', '_blank')
  }

  loadCartCount = () => {
    axiosCerebrum
      .get(
        `/api/me/cart`,{
          per_page:0,
        }
      )
      .then(response=>{
        this.setState({cartCount:response.data.total})
      })
      .catch(error=>{
        console.log(error)
      })
  }

  componentDidMount = () => {
    // knowledge listener
    window.removeEventListener('message',globalListenerRef.navbarMessageListener)
    if(!globalListenerRef.navbarMessageListener){
      globalListenerRef.navbarMessageListener = event=>{
        if(event.data.surveyAnswered){
          this.setState({newQuestions:this.state.newQuestions-1})
        }
      }
    }
    window.addEventListener("message", globalListenerRef.navbarMessageListener);
    // cart listener
    window.removeEventListener('message',globalListenerRef.navbarCartListener)
    globalListenerRef.navbarCartListener = event=>{
      if(event.data.reloadCart){
        this.loadCartCount()
      }
    }
    window.addEventListener("message", globalListenerRef.navbarCartListener);
    // nav bar resize listener
    this.setState({navBarWidth:getContentContainerStyle().navBarWidth})
    window.removeEventListener('resize',globalListenerRef.navResizeListener)
    globalListenerRef.navResizeListener = () => {
      this.setState({navBarWidth:getContentContainerStyle().navBarWidth})
    }
    window.addEventListener('resize',globalListenerRef.navResizeListener)

    ///////////////
    if(!this.props.sessionData || !this.props.sessionData.user.id)return;
    this.setState({newNotifications:this.props.sessionData.user.user_stats.new_notifications})
    if(this.props.sessionData.user.user_stats.new_notifications>0)updateElement('browser_tab_icon',[{name:'href',value:process.env.PUBLIC_URL+'/KADA_logo_notification.svg'}])
    if(localStorage.getItem('userInfo')){
      this.setState({newQuestions:JSON.parse(localStorage.getItem('userInfo')).user_stats.surveys_unanswered})
    }
    const checkOwner = async () => {
      let ownedCount = await checkOwnedCount(this.props.session_user)
      if(ownedCount.total>0){
        this.setState({isOwner:true})
      }
    }
    checkOwner();
    const checkSteward = async () => {
      let stewardCount = await checkStewardedCount(this.props.session_user)
      if(stewardCount.total>0){
        this.setState({isSteward:true})
      }
    }
    checkSteward()
    if(checkCanAddToCart({sessionData:this.props.session_user,ignoreObjectType:true}))this.loadCartCount()

    axiosSolr
      .get(`/solr/data_quality_test_result/select`,{
        params:{
          q:"*",
          fq:"*",
          rows:0
        }
      })
      .then(response=>{
        this.setState({hasDQ:response.data.response.numFound>0})
      })
      .catch(error=>{
        console.log(error)
      })

    axiosCerebrum
      .get(
        `/api/users/${this.props.session_user.id}/related/collections`,{params:{
          category:'PLATFORM',
          parent_name:'domain',
          relationship:'OWNER_OF,STEWARD_OF',
          per_page:0
        }}
      )
      .then(response=>{
        if(response.data.total>0){
          this.setState({ownOrStewardDomain:true})
        }
      })
      .catch(error=>{
        console.log(error)
      })
  }

  toggleDrawer = (open, type) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ ...this.state, drawerOpen: open, drawerType: type });
  };

  render() {
    const { classes, theme, sessionData, session_user } = this.props;

    const roles = this.props.session_user?getUserRoles(this.props.session_user.user_role):[];

    const { userManagement, Collections, integrations, platformSettings } = getListItemJson(roles)

    const isDataGov = session_user && checkIsDataGov({sessionData:session_user})

    const getHistoryListItem = el => {
      return (
        <div className={classes.historyChipContainer} onClick={()=>{this.props.history.push(el.url);this.toggleDrawer(false)()}}>
          <div style={{display:'flex',overflow:'hidden',alignItems:'flex-start'}}>
            <div style={{paddingTop:'12px',width:24,height:24}}>
              {getIconComponent({label:el.iconLabel,size:24,colour:theme.palette.primary.main})}
            </div>
            <div style={{flexGrow:1,overflow:'hidden',padding:'12px 12px 12px 0',marginLeft:16}}>
              <Typography data-test-classname="history-item-title" style={{color:theme.palette.header.main,fontSize:13.75,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                {el.title}
              </Typography>
              <Typography data-test-classname="history-item-subtitle" style={{color:theme.palette.primaryText.light,marginTop:4,fontSize:12,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                {el.subTitle}
              </Typography>
            </div>
          </div>
          <Divider style={{background:theme.palette.listItemDivider.main}} />
        </div>
        
      )
    }

    const historyList = () => (
      <div style={{height:'100vh',width:500,display:'flex',flexDirection:'column'}}>
        <Typography  className={classes.headerTitle}>Recently Viewed</Typography>
        <Divider style={{background:theme.palette.listItemDivider.main,marginBottom:24}} />
        {
          loadHistory().length===0 && <Typography style={{color:theme.palette.primaryText.main,width:320,marginLeft:16,}}>No history found</Typography>
        }
        <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingBottom:24}}>
          {
            loadHistory().map(el=>(
              getHistoryListItem(el)
            ))
          }
        </div>
      </div>
    )
    
    const getSettingListItem = el => {
      return (
        <div className={classes.tileBox} data-test-id={`platform-setting-${el.title.toLowerCase().replace(/\s/g,'-')}`}>
          <ListItem className={classes.adminListItem} button onClick={(event) => {this.toggleDrawer(false)(); (el.route && typeof(el.route) === 'string' && el.route.includes('keycloak/auth/admin')) ? window.open(`${el.route}`) : this.props.history.push(el.route);}} key={el.title}>
            <ListItemIcon style={{marginTop:-18}}> {getIconComponent({ label: el.icon, size: 25, colour: theme.palette.primary.main })}</ListItemIcon>
            <div style={{ display: 'block' }}> <Typography className={classes.listItemTitle}> {el.title} </Typography>
              <Typography className={classes.listItemDescription}> {el.description} </Typography>  </div>
          </ListItem>
        </div>
      )
    }


    const settingList = () => (
      <div style={{height:'100vh',width:500,display:'flex',flexDirection:'column'}}>
        <Typography  className={classes.headerTitle}>Settings</Typography>
        <Divider style={{background:theme.palette.listItemDivider.main}} />

        <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingBottom:24,marginTop:40}}>
          {
            (roles.includes("00") || roles.includes("10") || roles.includes("40")) &&
            <List style={{marginBottom:40}}>
              <Typography className={classes.listTitle} style={{marginLeft:16}}>{'ADMINISTRATION'}</Typography>
              {userManagement.filter(el=>el && el.roles.some(r=>roles.includes(r))).map(el => getSettingListItem(el))}
            </List>
          }


          <List style={{marginBottom:40}}>
            <Typography style={{ marginLeft: 16}} className={classes.listTitle}>{'COLLECTIONS'}</Typography>
            {Collections.filter(el=>el.roles.some(r=>roles.includes(r))).map(el =>  getSettingListItem(el))}
          </List>


          {
            (roles.includes("00") || roles.includes('10')) &&
            <List style={{marginBottom:40}}>
              <Typography style={{ marginLeft: 16 }} className={classes.listTitle}>{'INTEGRATIONS'}</Typography>

              {integrations.filter(el=>el.roles.some(r=>roles.includes(r))).map(el =>  getSettingListItem(el))}
            </List>
          }

          {
            roles.includes("00") &&
            <List style={{marginBottom:40}}>
              <Typography style={{ marginLeft: 16 }} className={classes.listTitle}>{'PLATFORM MANAGEMENT'}</Typography>
              {platformSettings.map(el =>  getSettingListItem(el))}
            </List>
          }
        </div>
      </div>
    );



    const getInsightsListItem = el => {
      return (
        <div className={classes.tileBox} data-test-id={el.testID}>
          <ListItem 
            className={classes.adminListItem} 
            button 
            onClick={(event)=>{
              this.toggleDrawer(false)();
              if(el.onClick)el.onClick();
              if(el.route){
                if(el.isNewWindow){
                  window.open(el.route, '_blank')
                }else{
                  this.props.history.push(el.route)
                }
              }
            }} 
            key={el.title}
          >
            <ListItemIcon style={{marginTop:-18}}> {getIconComponent({ label: el.icon, size: 25, colour: theme.palette.primary.main })}</ListItemIcon>
            <div style={{ display: 'block' }}> <Typography className={classes.listItemTitle}> {el.title} </Typography>
              <Typography className={classes.listItemDescription}> {el.description} </Typography>  </div>
          </ListItem>
        </div>
      )
    }

    const insightsListMap = getInsightsListMap({
      hasDQ:this.state.hasDQ, 
      roles, 
      isSteward:this.state.isSteward, 
      isOwner:this.state.isOwner,
      isDataGov:isDataGov,
      ownOrStewardDomain:this.state.ownOrStewardDomain
    })

    const insightsList = () => (
      <div style={{height:'100vh',width:500,maxWidth:'90vw',display:'flex',flexDirection:'column'}}>
        <Typography  className={classes.headerTitle}>Insights & Dashboards</Typography>
        <Divider style={{background:theme.palette.listItemDivider.main}} />

        <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingBottom:24,marginTop:40}}>
          {
            Object.keys(insightsListMap).map(k=>(
              <List style={{marginBottom:40}}>
                <Typography className={classes.listTitle} style={{marginLeft:16}}>{k}</Typography>
                {insightsListMap[k].map(el => getInsightsListItem(el))}
              </List>
            ))
          }
        </div>
      </div>
    );

    let customerName = getCustomerName()

    const ecosystemMap = getEcosystemListMap()

    const ecosystemList = () => (
      <div style={{height:'100vh',width:500,maxWidth:'90vw',display:'flex',flexDirection:'column'}}>
        <Typography  className={classes.headerTitle}>{customerName+' '}Data Ecosystem</Typography>
        <Divider style={{background:theme.palette.listItemDivider.main}} />

        <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingBottom:24,marginTop:40}}>
          {
            Object.keys(ecosystemMap).map(k=>(
              <List style={{marginBottom:40}}>
                <Typography className={classes.listTitle} style={{marginLeft:16}}>{k}</Typography>
                {ecosystemMap[k].map(el => getInsightsListItem(el))}
              </List>
            ))
          }
        </div>
      </div>
    );

    const userSttingMap = getUserSettingList({
      sessionData:this.props.session_user,
      history:this.props.history,
    })

    const userSettingList = () => (
      <div style={{height:'100vh',width:500,maxWidth:'90vw',display:'flex',flexDirection:'column'}}>
        <Typography  className={classes.headerTitle}>My Settings</Typography>
        <Divider style={{background:theme.palette.listItemDivider.main}} />

        <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingBottom:24,marginTop:40}}>
          {
            Object.keys(userSttingMap).map(k=>(
              <List style={{marginBottom:40}}>
                <Typography className={classes.listTitle} style={{marginLeft:16}}>{k}</Typography>
                {userSttingMap[k].map(el => getInsightsListItem(el))}
              </List>
            ))
          }
        </div>
        <div style={{paddingBottom:8}}>
          {
            getInsightsListItem(logoutItemConfig)
          }
        </div>
      </div>
    );

    const dataAppMap = getDataAppListMap({sessionData:session_user})

    const dataAppList = () => (
      <div style={{height:'100vh',width:500,maxWidth:'90vw',display:'flex',flexDirection:'column'}}>
        <Typography className={classes.headerTitle}>K Applications</Typography>
        <Divider style={{background:theme.palette.listItemDivider.main}} />

        <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingBottom:24,marginTop:40}}>
          {
            Object.keys(dataAppMap).map(k=>(
              <List style={{marginBottom:40}}>
                <Typography className={classes.listTitle} style={{marginLeft:16}}>{k}</Typography>
                {dataAppMap[k].map(el => getInsightsListItem(el))}
              </List>
            ))
          }
        </div>
      </div>
    )

    
    const hasRole = roles.length>0;
    const onboarded = sessionData?!Boolean(sessionData.user.new_user):false;
    const homeLink = '/home'
    const shouldHighlightHomeIcon = ([homeLink].some(e=>window.location.pathname===e))
    const searchLink = '/basic_search'
    const shouldHighlightSearchIcon = ([searchLink].some(e=>window.location.pathname===e))
    const knowledgeCntreLink = '/add_knowledge'
    const shouldHighlightKnowledgeCentreIcon = ([knowledgeCntreLink].some(e=>window.location.pathname===e))
    const filterLink = '/filters'
    const shouldHighlightFilterIcon = ([filterLink].some(e=>window.location.pathname===e))
    const inventoryLink = '/inventory'
    const shouldHighlightInventoryIcon = ([inventoryLink].some(e=>window.location.pathname===e))
    const cartLink = '/cart'
    const shouldHighlightCartIcon = ([cartLink].some(e=>window.location.pathname===e))
    const activityLink = '/my_data'
    const shouldHighlightActivityIcon = ([activityLink].some(e=>window.location.pathname===e))
    const alertLink = '/my_alerts'
    const shouldHighlightAlertIcon = ([alertLink].some(e=>window.location.pathname===e))

    const shouldHighlightDashboardIcon = (['/dashboard/data_owner','/dashboard/data_steward','/insights/usage','/insights/governance','/insights/data_quality','/dashboard/issue','/dashboard/data_load'].some(e=>window.location.pathname===e)) || (this.state.drawerType==='insights' && this.state.drawerOpen)
    const shouldHighlightEcosystemIcon = (['/my_ecosystem'].some(e=>window.location.pathname===e)) || (this.state.drawerType==='ecosystem' && this.state.drawerOpen)
    const shouldHighlightDataAppIcon = (['/impact_assessment','/dependency_assessment','/code_search','/question_board','/bulk_edit_templated','/my_tasks'].some(e=>window.location.pathname===e)) || (this.state.drawerType==='dataApp' && this.state.drawerOpen)
    const showCart = checkCanAddToCart({sessionData:session_user,ignoreObjectType:true})
    
    const shouldHighlightHistory = this.state.drawerType==='history' && this.state.drawerOpen
    const shouldHighlightSettings = this.state.drawerType==='setting' && this.state.drawerOpen
    const shouldHighlightUserSettings = this.state.drawerType==='userSetting' && this.state.drawerOpen

    const isWideBar = this.state.navBarWidth>40

    // let versionNumber = localStorage.getItem('k_platform_version')


    return (
      <div className={classes.root} style={{width:this.state.navBarWidth}}>
        <List component='nav' className={classes.list} style={{padding:isWideBar?'0px 8px':0}}>
          <div style={{flexGrow:1}}>
            <ListItem style={{
              color: iconColour,
              display: 'flex',
              justifyContent:isWideBar?'flex-start':'center',
              padding: '8px 0',
              paddingLeft:isWideBar?16:0,
              paddingBottom:12,
            }} >
              {
                getIconComponent({label:'k_logo',size:28,colour:iconColour})
              }
              {
                isWideBar &&
                <span className={classes.listItemText} style={{fontSize:18,letterSpacing:1.5,fontWeight:400,marginLeft:4}}>KADA</span>
              }
            </ListItem>

            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',overflow:'visible',marginBottom:20}}>
              <div className={classes.divider} style={{marginRight:2}}>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</div>
              <IconButton 
                onClick={()=>{
                  let newWidth = this.state.navBarWidth>40?40:175
                  this.setState({navBarWidth:newWidth})
                  localStorage.setItem('navBarWidth',newWidth)
                  sendMessage({newNavbarWidth:newWidth})
                }}
                style={{width:20,height:20,borderRadius:2,border:`1px solid ${navBarBorder}`,marginRight:isWideBar?-18:-9,background:theme.palette.background.main}}
              >
                {getIconComponent({label:this.state.navBarWidth<=40?'expand_right':'expand_left',size:20,colour:theme.palette.primaryText.light})}
              </IconButton>
            </div>
            
            {
              hasRole &&
              <div>
                <NavLink
                  to={'/home'}
                  exact
                  activeClassName={onboarded ? classes.activeLink : null}
                  className={onboarded?classes.navLink:classes.inactiveLink}
                  onClick={event=>{
                    if(!onboarded){event.preventDefault()}
                  }}
                >
                  <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightHomeIcon?' '+classes.selectedListItem:'')} data-tip={isWideBar?'':'Home'} onClick={this.toggleDrawer( false)}>
                    <Badge badgeContent={this.state.newNotifications} classes={{badge:classes.badge,root:classes.badgeRoot}}>
                      <ListItemIcon id="nav_bar_home_button"   onSelect className={classes.listItemIcon} >
                        <HomeIcon className={classes.icon} />
                      </ListItemIcon>
                    </Badge>
                    {
                      isWideBar &&
                      <span className={classes.listItemText}>Home</span>
                    }
                  </ListItem>
                </NavLink>

                {
                  (this.state.isSteward || this.state.isOwner || isDataGov || ['10','40'].find(el=>roles.includes(el)) ) && 
                  <div> 
                    <ListItem 
                      disabled={onboarded ? false : true}
                      className={
                        classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightDashboardIcon?' '+classes.selectedListItem:'')
                      } 
                      style={{cursor:'pointer'}}
                      data-tip={isWideBar?'':'Insights'}
                      onClick={event=>{
                        if(this.state.drawerOpen && this.state.drawerType==='insights'){
                          this.toggleDrawer(false)()
                        }else{
                          this.toggleDrawer(true,'insights')()
                        }
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon} >
                        {getIconComponent({label:'insights',size:24,colour:navBarIconColour})}
                      </ListItemIcon>
                      {
                        isWideBar &&
                        <span className={classes.listItemText}>Insights</span>
                      }
                    </ListItem>

                  </div>
                }

                <div> 
                  <ListItem 
                    disabled={onboarded ? false : true}
                    className={
                      classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightEcosystemIcon?' '+classes.selectedListItem:'')
                    } 
                    style={{cursor:'pointer'}}
                    data-tip={isWideBar?'':'Ecosystem'}
                    onClick={event=>{
                      if(this.state.drawerOpen && this.state.drawerType==='ecosystem'){
                        this.toggleDrawer(false)()
                      }else{
                        this.toggleDrawer(true,'ecosystem')()
                      }
                    }}
                  >
                    <ListItemIcon className={classes.listItemIcon} >
                      {getIconComponent({label:'my_ecosystem',size:24,colour:navBarIconColour})}
                    </ListItemIcon>
                    {
                      isWideBar &&
                      <span className={classes.listItemText}>Ecosystem</span>
                    }
                  </ListItem>
                </div>

                <NavLink
                  to={'/basic_search'}
                  exact
                  onClick={(event)=>{
                    if(!onboarded){
                      event.preventDefault()
                      return
                    }
                    // setTimeout(()=>{
                    let interval = setInterval(()=>{
                      if(window.location.pathname!=='/basic_search')return;
                      window.postMessage({
                        resetSearch:true
                      },document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                      clearInterval(interval)
                    },16)
                    
                    if(window.location.pathname==='/basic_search' && window.location.search===''){
                      event.preventDefault()
                    }
                    // this.props.removePageCache('/basic_search')
                  }}
                  activeClassName={onboarded ? classes.activeLink : null}
                  className={onboarded?classes.navLink:classes.inactiveLink}
                >
                  <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightSearchIcon?' '+classes.selectedListItem:'')} data-tip={isWideBar?'':'Search'} onClick={this.toggleDrawer(false)}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <SearchIcon className={classes.icon} />
                    </ListItemIcon>
                    {
                      isWideBar &&
                      <span className={classes.listItemText}>Search</span>
                    }
                  </ListItem>
                </NavLink>

                <NavLink
                  to={filterLink}
                  isActive={()=>{
                    return window.location.pathname.includes(filterLink)
                  }}
                  onClick={(event)=>{
                    if(!onboarded){
                      event.preventDefault()
                      return
                    }
                  }}
                  exact
                  activeClassName={onboarded ? classes.activeLink : null}
                  className={onboarded?classes.navLink:classes.inactiveLink}
                >
                  <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightFilterIcon?' '+classes.selectedListItem:'')} tton data-tip={isWideBar?'':'Filters'} onClick={this.toggleDrawer(false)}>
                    <ListItemIcon className={classes.listItemIcon} >
                      {getIconComponent({label:'filter_list',size:24,colour:navBarIconColour})}
                    </ListItemIcon>
                    {
                      isWideBar &&
                      <span className={classes.listItemText}>Filters</span>
                    }
                  </ListItem>
                </NavLink>

                <NavLink
                  to={inventoryLink}
                  isActive={()=>{
                    return window.location.pathname.includes(inventoryLink)
                  }}
                  onClick={(event)=>{
                    if(!onboarded){
                      event.preventDefault()
                      return
                    }
                  }}
                  exact
                  activeClassName={onboarded ? classes.activeLink : null}
                  className={onboarded?classes.navLink:classes.inactiveLink}
                >
                  <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightInventoryIcon?' '+classes.selectedListItem:'')} tton data-tip={isWideBar?'':'Inventory'} onClick={this.toggleDrawer(false)}>
                    <ListItemIcon className={classes.listItemIcon} >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill={shouldHighlightInventoryIcon?navBarIconSelectedColour:navBarIconColour}  
                        width={24} height={24} viewBox="0 0 24 24" 
                      >
                        <path d="M0 0h24v24H0z" fill="none"/><path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z"/>
                      </svg>
                    </ListItemIcon>
                    {
                      isWideBar &&
                      <span className={classes.listItemText}>Inventory</span>
                    }
                  </ListItem>
                </NavLink>
                {
                  !checkIsBusinessUserOnly({sessionData:session_user}) &&
                  <div> 
                    <ListItem 
                      disabled={onboarded ? false : true}
                      className={
                        classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightDataAppIcon?' '+classes.selectedListItem:'')
                      } 
                      style={{cursor:'pointer'}}
                      data-tip={isWideBar?'':'K Applications'}
                      onClick={event=>{
                        if(this.state.drawerOpen && this.state.drawerType==='dataApp'){
                          this.toggleDrawer(false)()
                        }else{
                          this.toggleDrawer(true,'dataApp')()
                        }
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        {getIconComponent({label:'data_solution',size:24,colour:navBarIconColour})}
                      </ListItemIcon>
                      {
                        isWideBar &&
                        <span className={classes.listItemText}>Apps</span>
                      }
                    </ListItem>
                  </div>
                }

                <div className={classes.divider} style={{marginTop:16,marginBottom:16}}>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</div>

                <NavLink
                  to={alertLink}
                  exact
                  activeClassName={onboarded ? classes.activeLink : null}
                  className={onboarded?classes.navLink:classes.inactiveLink}
                  onClick={event=>{
                    if(!onboarded){event.preventDefault()}
                  }}
                >
                  <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightAlertIcon?' '+classes.selectedListItem:'')} data-tip={isWideBar?'':'My Alerts'} onClick={this.toggleDrawer( false)}>
                    <ListItemIcon className={classes.listItemIcon}>
                      {getIconComponent({label:'alert',size:24,colour:navBarIconColour})}
                    </ListItemIcon>
                    {
                      isWideBar &&
                      <span className={classes.listItemText}>Alerts</span>
                    }
                  </ListItem>
                </NavLink>

                {
                  showCart && 
                  <NavLink
                    to={cartLink}
                    exact
                    activeClassName={onboarded ? classes.activeLink : null}
                    className={onboarded?classes.navLink:classes.inactiveLink}
                    onClick={event=>{
                      if(!onboarded){event.preventDefault()}
                    }}
                  >
                    <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightCartIcon?' '+classes.selectedListItem:'')} data-tip={isWideBar?'':'Cart'} onClick={this.toggleDrawer( false)}>
                      <Badge badgeContent={this.state.cartCount} classes={{badge:classes.badge,root:classes.badgeRoot}}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <CartIcon className={classes.icon} />
                        </ListItemIcon>
                      </Badge>
                      {
                        isWideBar &&
                        <span className={classes.listItemText}>Cart</span>
                      }
                    </ListItem>
                  </NavLink>
                }

                {
                  ['10','20','40','90'].find(el=>roles.includes(el)) &&
                  <NavLink
                    to={knowledgeCntreLink}
                    exact
                    activeClassName={onboarded ? classes.activeLink : null}
                    className={onboarded?classes.navLink:classes.inactiveLink}
                    onClick={event=>{
                      if(!onboarded){event.preventDefault()}
                    }}
                  >
                    <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightKnowledgeCentreIcon?' '+classes.selectedListItem:'' )} data-tip={isWideBar?'':'Knowledge Center'} onClick={this.toggleDrawer(false)}>
                      <Badge  badgeContent={this.state.newQuestions} classes={{badge:classes.badge,root:classes.badgeRoot}}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <AddKnowledgeIcon className={classes.icon} />
                        </ListItemIcon>
                      </Badge>
                      {
                        isWideBar &&
                        <span className={classes.listItemText}>Knowledge</span>
                      }
                    </ListItem>
                  </NavLink>
                }

                <NavLink
                  to={activityLink}
                  exact
                  activeClassName={onboarded ? classes.activeLink : null}
                  className={onboarded?classes.navLink:classes.inactiveLink}
                  onClick={event=>{
                    if(!onboarded){event.preventDefault()}
                  }}
                >
                  <ListItem disabled={onboarded ? false : true} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightActivityIcon?' '+classes.selectedListItem:'' )} data-tip={isWideBar?'':'My Activity'} onClick={this.toggleDrawer(false)}>

                    <ListItemIcon className={classes.listItemIcon}>
                      {getIconComponent({label:'my_activity',size:24,colour:navBarIconColour})}
                    </ListItemIcon>
                    {
                      isWideBar &&
                      <span className={classes.listItemText}>Activity</span>
                    }
                  </ListItem>
                </NavLink>

              </div>
            }
          </div>


          <div className={classes.divider} style={{marginTop:16,marginBottom:16}}>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</div>

          <div style={{flexGrow:0}}>

            <div>
              <ListItem disabled={!onboarded} data-testid='recently-viewed' className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightHistory?' '+classes.selectedListItem:'')} onClick={this.state.drawerOpen && this.state.drawerType==='history' ? this.toggleDrawer(false) : this.toggleDrawer(true,'history')}>
                <ListItemIcon  className={classes.listItemIcon} data-tip={isWideBar?'':'Recently Viewed History'}>
                  <HistoryIcon className={classes.icon} />
                </ListItemIcon>
                {
                  isWideBar &&
                  <span className={classes.listItemText}>History</span>
                }
              </ListItem>
            </div>

            {
              ['00','10','40'].some(el=>roles.includes(el)) &&
              <div>
                <ListItem disabled={!onboarded} data-testid='setting-admin' className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightSettings?' '+classes.selectedListItem:'')} onClick={this.state.drawerOpen && this.state.drawerType==='setting' ? this.toggleDrawer(false) : this.toggleDrawer(true,'setting')}>
                  <ListItemIcon  className={classes.listItemIcon} data-tip={isWideBar?'':'Platform Settings'}>
                    <SettingsIcon className={classes.icon} />
                  </ListItemIcon>
                  {
                    isWideBar &&
                    <span className={classes.listItemText}>Settings</span>
                  }
                </ListItem>
              </div>
            }

            <ListItem disabled={!onboarded} onClick={this.state.drawerOpen && this.state.drawerType==='userSetting' ? this.toggleDrawer(false) : this.toggleDrawer(true,'userSetting')} className={classes.listItem + (isWideBar?' '+classes.wideBar:'') + (shouldHighlightUserSettings?' '+classes.selectedListItem:'')}  >
              <ListItemIcon className={classes.listItemIcon} data-tip={isWideBar?'':'User Settings'}>
                <Avatar className={classes.avatar}><text style={{color:'#fff',fontSize:12}}>{getInitials(sessionData?sessionData.user.name:'')}</text></Avatar>
              </ListItemIcon>
              {
                isWideBar &&
                <span className={classes.listItemText}>{session_user.name}</span>
              }
            </ListItem>

          </div>
        </List>

        <ReactTooltip place="right" type="dark" effect="solid" />
        
        <Drawer classes={{paper:classes.adminModal,root:classes.drawerRoot}} open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}>
          <div style={{marginLeft:this.state.navBarWidth}}>
            {this.state.drawerType==='setting' && settingList()}
            {this.state.drawerType==='history' && historyList()}
            {this.state.drawerType==='insights' && insightsList()}
            {this.state.drawerType==='ecosystem' && ecosystemList()}
            {this.state.drawerType==='userSetting' && userSettingList()}
            {this.state.drawerType==='dataApp' && dataAppList()}
          </div>
        </Drawer>

      </div >
    )
  }

}

const mapStateToProps = state => {
  return {
    sessionData: state.auth.sessionData,
    avatarURL: state.auth.avatarURL
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logoutSuccess()),
    removePageCache: (cacheId) => dispatch(actions.removePageCache(cacheId))
  }
}

export default withTheme()(withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))));