import React, {useEffect,useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Checkbox, Typography, Select, MenuItem, IconButton, Avatar, Button, InputBase} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { collectionIds, getCollectionColour, getFontColourByBackground, getIconComponent, getInitials } from '../../../utilities'
import SearchSelector from '../../UI/SearchSelector/SearchSelector'
import {useStore} from 'react-redux'
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import { Editor as MDEditor } from '@toast-ui/react-editor';
import { getPlatformSettings } from '../../../permissionChecker';
import HierarchySearchSelector from '../../UI/SearchSelector/HierarchySearchSelector';
const styles = theme => ({
  inputBlock:{
    marginBottom:40,
    display:'flex',
    alignItems:'flex-start'
  },
  checkbox:{
    marginRight:27,
    flexGrow:0,
    flexShrink:0,
    padding:0
  },
  columnHeader:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.light,

    width:240,
    marginRight:40,
    flexGrow:0,
    flexShrink:0,
  },
  propertyCheckBox:{
    marginTop:16
  },
  title:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    width:240,
    marginRight:40,
    flexGrow:0,
    flexShrink:0,
    marginTop:16
  },
  inputBase:{
    ...theme.components.inputBase,
    flexGrow:1,
    width:'100%',
    '& input':{
      paddingTop:12,
      paddingBottom:12,
      paddingLeft:16
    },
    '& textarea':{
      paddingTop:12,
      paddingBottom:12,
      paddingLeft:16
    }
  },
  helperText:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginLeft:16
  },
  selector: {
    ...theme.components.selector,
    '& div div': {
      paddingLeft: 16,
      paddingTop: 15,
      paddingBottom: 15,
      fontSize:16
    }
  },
  editTypeSelector:{
    width:200,
    marginRight:40
  },
  valueSelector:{
    width:400
  },
  avatar: {
    fontSize: 12,
    height: 25,
    width: 25,
    backgroundColor: theme.palette.avatar.main,
  },
  verifiedCheckBox:{
    color:`${theme.palette.success.main} !important`,
  },
  notVerifiedCheckBox:{
    color:`${theme.palette.error.main} !important`,
  },
  subSectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    marginTop:12,
    marginBottom:8
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  }
})

function EditProperties(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    editMode
  } = props;


  const MDRef = useRef()

  const store = useStore();
  const presetDescription = store.getState().bulkEdit.presetDescription
  const [descriptionUpdateFlag, setDescriptionUpdateFlag] = useState(0)

  const [ownerSearchValue, setOwnerSearchValue] = useState('');
  const [stewardSearchValue, setStewardSearchValue] = useState('');
  let userFilter = getPlatformSettings(4008)?.value;

  const [verifiedSearch, setVerifiedSearch] = useState('');
  const [verifiedNotSearch, setVerifiedNotSearch] = useState('');
  const [classifiedSearch, setClassifiedSearch] = useState('');
  const [domainSearch, setDomainSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState('');

  useEffect(()=>{
    if(!state.domainList){
      const loadDomain = (page=1,previousData = []) => {
        axiosCerebrum
          .get(`/api/collectioninstances?collection_id=${collectionIds.domain}&page=${page}&per_page=100`)
          .then(response=>{
            if(response.data.page<response.data.pages){
              loadDomain(response.data.page+1,[...previousData,...response.data.items])
            }else{
              dispatch({type:'set_domain_list',domainList:[...previousData,...response.data.items]} );
            }
          })
      }
      loadDomain()
    }
    if(!state.catgoryList){
      const loadCategory = (page=1,previousData = []) => {
        axiosCerebrum
          .get(`/api/collectioninstances?collection_id=${collectionIds.category}&page=${page}&per_page=100`)
          .then(response=>{
            if(response.data.page<response.data.pages){
              loadCategory(response.data.page+1,[...previousData,...response.data.items])
            }else{
              dispatch({type:'set_category_list',categoryList:[...previousData,...response.data.items]} );
            }
          })
      }
      loadCategory()
    }
    if(!state.verifiedList){
      const loadVerified = (page=1,previousData = []) => {
        axiosCerebrum
          .get(`/api/collectioninstances?collection_id=${collectionIds.verifiedUseCase}&page=${page}&per_page=100`)
          .then(response=>{
            if(response.data.page<response.data.pages){
              loadVerified(response.data.page+1,[...previousData,...response.data.items])
            }else{
              dispatch({type:'set_verified_list',verifiedList:[...previousData,...response.data.items]} );
            }
          })
      }
      loadVerified()
    }
    if(!state.classificationList){
      const loadClassification = (page=1,previousData = []) => {
        axiosCerebrum
          .get(`/api/collectioninstances?collection_id=${collectionIds.classification}&page=${page}&per_page=100`)
          .then(response=>{
            if(response.data.page<response.data.pages){
              loadClassification(response.data.page+1,[...previousData,...response.data.items])
            }else{
              dispatch({type:'set_classification_list',classificationList:[...previousData,...response.data.items]} );
            }
          })
      }
      loadClassification();
    }
  // eslint-disable-next-line
  },[])

  const onDescriptionChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='DESCRIPTION');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields.filter(el=>el.field!=='DESCRIPTION'),
          {
            field:'DESCRIPTION',
            operation:'REPLACE',
            values:[presetDescription||''],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='DESCRIPTION')
      })
    }
  }

  const onChangeDescription = value => {
    if(value.length>3000)return value.slice(0,3000);
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='DESCRIPTION')return el;
        else{
          return {
            ...el,
            values:[value]
          }
        }
      })
    })
  }

  const onDomainChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='domain');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields,
          {
            field:'MEMBER_OF',
            id:'domain',
            operation:'ADD_TO_EXISTING',
            values:[],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='MEMBER_OF' || el.id!=='domain')
      })
    }
  }

  const onChangeDomainOperation = value => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='MEMBER_OF'|| el.id!=='domain')return el;
        else{
          return {
            ...el,
            operation:value,
            values:value==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }

  const onRemoveDomain = id => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='MEMBER_OF'|| el.id!=='domain')return el;
        else{
          return {
            ...el,
            values:el.values.filter(el=>el!==id)
          }
        }
      })
    })
  }

  const onSelectDomain = values => {
    // if(id==='placeholder')return;
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='MEMBER_OF'|| el.id!=='domain')return el;
        else{
          let value = values.filter(v=>v!=='placeholder')[0];
          if(el.values.includes(value)){
            return {
              ...el,
              values:el.values.filter(v=>v!==value)
            }
          }else{
            return {
              ...el,
              values:[...el.values,value]
            }
          }
        }
      })
    })
  }
  ////////// category
  const onCategoryChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='category');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields,
          {
            field:'MEMBER_OF',
            id:'category',
            operation:'REPLACE',
            values:[],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='MEMBER_OF' || el.id!=='category')
      })
    }
  }

  const onChangeCategoryOperation = value => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='MEMBER_OF'|| el.id!=='category')return el;
        else{
          return {
            ...el,
            operation:value,
            values:value==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }

  const onRemoveCategory = id => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='MEMBER_OF'|| el.id!=='category')return el;
        else{
          return {
            ...el,
            values:el.values.filter(el=>el!==id)
          }
        }
      })
    })
  }

  const onSelectCategory = values => {
    // if(id==='placeholder')return;
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='MEMBER_OF'|| el.id!=='category')return el;
        else{
          let value = values.filter(v=>v!=='placeholder')[0];
          if(el.values.includes(value)){
            return {
              ...el,
              values:[]
            }
          }else{
            return {
              ...el,
              values:[value]
            }
          }
        }
      })
    })
  }
  ///////////////
  
  const onVerifiedChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='VERIFIED_BY');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields,
          {
            field:'VERIFIED_BY',
            operation:'REPLACE',
            values:[],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='VERIFIED_BY')
      })
    }
  }

  const onVerifiedNotChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='NOT_VERIFIED_BY');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields,
          {
            field:'NOT_VERIFIED_BY',
            operation:'REPLACE',
            values:[],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='NOT_VERIFIED_BY')
      })
    }
  }

  const onChangeVerifiedOperation = value => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='VERIFIED_BY')return el;
        else{
          return {
            ...el,
            operation:value,
            values:value==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }
  
  const onChangeNotVerifiedOperation = value => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='NOT_VERIFIED_BY')return el;
        else{
          return {
            ...el,
            operation:value,
            values:value==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }

  const onSelectVerified = values => {
    // if(id==='placeholder')return;
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='VERIFIED_BY')return el;
        else{
          let value = values.filter(v=>v!=='placeholder')[0];
          if(el.values.includes(value)){
            return {
              ...el,
              values:el.values.filter(v=>v!==value)
            }
          }else{
            return {
              ...el,
              values:[...el.values,value]
            }
          }
        }
      })
    })
  }

  const onRemoveVerified = id => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='VERIFIED_BY')return el;
        else{
          return {
            ...el,
            values:el.values.filter(el=>el!==id)
          }
        }
      })
    })
  }


  const onSelectVerifiedNot = values => {
    // if(id==='placeholder')return;
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='NOT_VERIFIED_BY')return el;
        else{
          let value = values.filter(v=>v!=='placeholder')[0];
          if(el.values.includes(value)){
            return {
              ...el,
              values:el.values.filter(v=>v!==value)
            }
          }else{
            return {
              ...el,
              values:[...el.values,value]
            }
          }
        }
      })
    })
  }

  const onRemoveVerifiedNot = id => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='NOT_VERIFIED_BY')return el;
        else{
          return {
            ...el,
            values:el.values.filter(el=>el!==id)
          }
        }
      })
    })
  }

  const onClassificationChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='CLASSIFIED_BY');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields,
          {
            field:'CLASSIFIED_BY',
            operation:'REPLACE',
            values:[],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='CLASSIFIED_BY')
      })
    }
  }

  const onChangeClassifiedOperation = op => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='CLASSIFIED_BY')return el;
        else{
          return {
            ...el,
            operation:op,
            values:op==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }


  const onSelectClassified = id => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='CLASSIFIED_BY')return el;
        else{
          return {
            ...el,
            values:id
          }
        }
      })
    })
  }

  const onOwnerChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='OWNED_BY');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields,
          {
            field:'OWNED_BY',
            operation:'REPLACE',
            values:[],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='OWNED_BY')
      })
    }
  }

  const onSetOwner = user => {
    dispatch({
      type:'set_user_map',
      userMap:{...state.userMap,[user.id]:user}
    })
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='OWNED_BY')return el;
        else{
          return {
            ...el,
            values:[user.id]
          }
        }
      })
    })
  }

  const onChangeOwnerdOperation = op => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='OWNED_BY')return el;
        else{
          return {
            ...el,
            operation:op,
            values:op==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }

  const onRemoveOwner = () => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='OWNED_BY')return el;
        else{
          return {
            ...el,
            values:[]
          }
        }
      })
    })
  }
  
  const onStewardChecked = () => {
    let checked = !state.changedFields.find(el=>el.field==='STEWARDED_BY');
    if(checked){
      dispatch({
        type:'set_changed_fields',
        changedFields:[
          ...state.changedFields,
          {
            field:'STEWARDED_BY',
            operation:'ADD_TO_EXISTING',
            values:[],
          }
        ]
      })
    }else{
      dispatch({
        type:'set_changed_fields',
        changedFields:state.changedFields.filter(el=>el.field!=='STEWARDED_BY')
      })
    }
  }

  const onChangeStewardOperation = value => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='STEWARDED_BY')return el;
        else{
          return {
            ...el,
            operation:value,
            values:value==='CLEAR'?[]:el.values
          }
        }
      })
    })
  }

  const onSetSteward = user => {
    dispatch({
      type:'set_user_map',
      userMap:{...state.userMap,[user.id]:user}
    })
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='STEWARDED_BY')return el;
        else{
          if(el.values.includes(user.id))return el;  
          return {
            ...el,
            values:[...el.values,user.id]
          }
        }
      })
    })
  }

  const onRemoveSteward = id => {
    dispatch({
      type:'set_changed_fields',
      changedFields: state.changedFields.map(el=>{
        if(el.field!=='STEWARDED_BY')return el;
        else{
          return {
            ...el,
            values:el.values.filter(el=>el!==id)
          }
        }
      })
    })
  }

  useEffect(()=>{
    if(editMode==='same_name_description_only' && !state.changedFields.find(el=>el.field==='DESCRIPTION')){
      onDescriptionChecked(true)
    }
  // eslint-disable-next-line
  },[])

  let descriptionValue = state.changedFields.find(el=>el.field==='DESCRIPTION')?state.changedFields.find(el=>el.field==='DESCRIPTION').values[0]:'';

  let domainBlock = state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='domain');
  let domainOperation = domainBlock?domainBlock.operation:'ADD_TO_EXISTING';
  let domain = domainBlock?domainBlock.values:[];

  let categoryBlock = state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='category');
  let categoryOperation = categoryBlock?categoryBlock.operation:'REPLACE';
  let category = categoryBlock?categoryBlock.values:[];

  let verifiedBlock = state.changedFields.find(el=>el.field==='VERIFIED_BY');
  let verifiedNotBlock = state.changedFields.find(el=>el.field==='NOT_VERIFIED_BY');
  let verifiedOperation = verifiedBlock?verifiedBlock.operation:'REPLACE';
  let notVerifiedOperation = verifiedNotBlock?verifiedNotBlock.operation:'REPLACE';
  let verified = verifiedBlock?verifiedBlock.values:[];
  let verifiedNot = verifiedNotBlock?verifiedNotBlock.values:[]

  let classifiedBlock = state.changedFields.find(el=>el.field==='CLASSIFIED_BY');
  let classified = classifiedBlock?.values?.[0];
  let classificationOperation = classifiedBlock?classifiedBlock.operation:'REPLACE';

  let ownerBlock = state.changedFields.find(el=>el.field==='OWNED_BY');
  let owner = ownerBlock && ownerBlock.values[0]?state.userMap[ownerBlock.values[0]]:undefined;
  let ownerOperation = ownerBlock?ownerBlock.operation:'REPLACE';

  let stewardBlock = state.changedFields.find(el=>el.field==='STEWARDED_BY');
  let stewardOperation = stewardBlock?stewardBlock.operation:'ADD_TO_EXISTING';
  let stewards = stewardBlock?stewardBlock.values.map(el=>state.userMap[el]):[]

  if(state.selectedItemType==='DATA_QUALITY_TEST'){
    return (
      <div className={classes.root}>
        <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
          <Typography style={{color:theme.palette.header.main,fontSize:20}}>ADD CHANGES</Typography>
        </div>
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>
          Add details you wish to change on the selected Item(s)
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
        <Typography style={{color:theme.palette.header.main,fontSize:20}}>ADD {editMode==='same_name_description_only'?'DESCRIPTION':'CHANGES'}</Typography>
      </div>
      <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>
        {
          editMode==='same_name_description_only'?
          'Add a description to be added to all the items selected':
          'Add details you wish to change on the selected Item(s)'
        }
      </Typography>


      {
        editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock} style={{alignItems:'center'}}>
          {
            editMode!=='same_name_description_only' && 
            <Checkbox 
              data-test-id="select-all-checkbox"
              onChange={(event, checked)=>{
                if(state.changedFields.length<state.allChangedFields.length){
                  dispatch({
                    type:'set_changed_fields',
                    changedFields:state.allChangedFields
                  })
                }
                else{
                  dispatch({
                    type:'set_changed_fields',
                    changedFields:[]
                  })
                }
              }} 
              checked={state.changedFields.length===state.allChangedFields.length}
              className={classes.checkbox + ' ' + classes.propertyCheckBox} 
              style={{marginTop:0}}
              color='primary' 
            />
          }
          <Typography className={classes.columnHeader}>PROPERTY</Typography>
          <div style={{width:640, display:'flex',alignItems:'center'}}>
            <Typography className={classes.columnHeader} style={{flexGrow:1}}>CHANGES</Typography>
            <Button 
              color='secondary'
              onClick={()=>{
                dispatch({
                  type:'set_changed_fields',
                  changedFields:[...state.allChangedFields]
                })
                setDescriptionUpdateFlag(descriptionUpdateFlag+1)
              }}
            >
              RESET ALL
            </Button>
          </div>
        </div>
      }

      {/* description */}
      <div className={classes.inputBlock}>
        {
          editMode!=='same_name_description_only' && 
          <Checkbox 
            data-test-id="edit-description-checkbox"
            onChange={(event, checked)=>onDescriptionChecked(checked)} 
            className={classes.checkbox + ' ' + classes.propertyCheckBox} 
            color='primary' 
            checked={state.changedFields.find(el=>el.field==='DESCRIPTION')}
          />
        }
        <Typography className={classes.title}>Description</Typography>
        <div style={{width:640,opacity:state.changedFields.find(el=>el.field==='DESCRIPTION')?undefined:0.5}}>
          <div>
            {
              state.changedFields.find(el=>el.field==='DESCRIPTION')?
              <MDEditor
                autofocus={false}
                key={descriptionUpdateFlag}
                ref={MDRef}
                height={'300px'}
                initialValue={descriptionValue}
                hideModeSwitch
                initialEditType="wysiwyg"
                useCommandShortcut={true}
                onChange={()=>onChangeDescription(MDRef.current.getInstance().getMarkdown().slice(0,3000))}
                previewStyle="vertical"
                theme={localStorage.hasOwnProperty('dark')?"dark":"white"}
                usageStatistics={false}
                toolbarItems={
                  [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'indent', 'outdent'],
                    ['table','link'],
                    ['code', 'codeblock']
                  ]
                }
              />
              :
              <InputBase
                value={descriptionValue}
                disabled={true}
                // onChange={(event)=>{onChangeDescription(event.target.value)}}
                variant={'filled'}
                className={classes.inputBase}
                multiline={true}
                rows={9}
              />
            }
          </div>
          <Typography 
            className={classes.helperText}
            style={{marginTop:4}}
          >
            {'Max 3000 char'}
          </Typography>
        </div>
      </div>

      {/* category */}
      {
        state.categoryList && editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock}>
          <Checkbox
            onChange={(event, checked)=>onCategoryChecked(checked)}
            className={classes.checkbox+ ' ' + classes.propertyCheckBox}
            color='primary'
            data-test-id="edit-category-checkbox"
            checked={state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='category')}
          />
          <Typography className={classes.title}>Category</Typography>
          <div style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='category')?undefined:0.5}}>
            <Select
              inputProps={{
                disabled:!state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='category')
              }}
              value={categoryOperation}
              className={classes.selector + ' ' + classes.editTypeSelector }
              data-test-id="edit-category-operation-selector"
              onChange={event=>onChangeCategoryOperation(event.target.value)}
              disableUnderline
            >
              {
                [
                  {name:'Replace',value:'REPLACE'},
                  {name:'Clear',value:'CLEAR'}
                ].map(el=>(
                  <MenuItem className={classes.menuItem} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              categoryOperation!=='CLEAR' &&
              <div style={{width:400}}>
                <HierarchySearchSelector
                  searchValue={categorySearch}
                  setSearchValue={setCategorySearch}
                  rootId={collectionIds.category}
                  fq={
                    `object_type_srt:COLLECTION_INSTANCE AND collection_srt:CATEGORY AND collection_type_srt:PLATFORM` +
                    (category.length>0?` AND -id:(${category.join(' OR ')})`:'')
                  }
                  rootLabel={'collection'}
                  onResultClick={item=>{
                    onSelectCategory([item.id])
                    setCategorySearch('')
                  }}
                  height={51}
                  clearable
                  testID="edit-category-search"
                  placeholder={`Search for a category`}
                  isItemSelected={item=>{
                    return category.includes(item.id)
                  }}
                  borderColour={category.length>0?undefined:theme.palette.error.main}
                />
                {
                  category.length>0 &&
                  <div>
                    <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                      {
                        category.map(el=>{
                          let background = state.categoryList.find(l=>l.id===el)?.properties?.[1] || theme.palette.primary.dark;
                          return (
                            <div style={{width:'max-content',marginRight:6,marginBottom:6,padding:'0px 6px',height:32, borderRadius:16, display:'flex',background,alignItems:'center'}}>
                              <Typography style={{color:getFontColourByBackground(background),marginLeft:6}}>{state.categoryList.find(v=>v.id===el).name}</Typography>
                              <IconButton className={classes.clearButton} style={{color:getFontColourByBackground(background),marginLeft:20,padding:3,marginRight:3,background:background}} onClick={()=>onRemoveCategory(el)}>
                                {getIconComponent({label:'clear', size:20, colour:getFontColourByBackground(background)})}
                              </IconButton>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }

      {/* classification */}
      {
        state.classificationList && editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock}>
          <Checkbox 
            data-test-id="edit-classification-checkbox"
            onChange={(event, checked)=>onClassificationChecked(checked)} 
            className={classes.checkbox+ ' ' + classes.propertyCheckBox} 
            color='primary' 
            checked={state.changedFields.find(el=>el.field==='CLASSIFIED_BY')}
          />
          <Typography className={classes.title}>Classification</Typography>
          <div  style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.field==='CLASSIFIED_BY')?undefined:0.5}}>
            <Select
              inputProps={{
                disabled:!state.changedFields.find(el=>el.field==='CLASSIFIED_BY')
              }}
              data-test-id="edit-classification-operation-selector"
              value={classificationOperation}
              className={classes.selector + ' ' + classes.editTypeSelector}
              onChange={event=>onChangeClassifiedOperation(event.target.value)}
              disableUnderline
            > 
              {
                [
                  {name:'Replace',value:'REPLACE'},
                  {name:'Clear',value:'CLEAR'}
                ].map(el=>(
                  <MenuItem className={classes.menuItem} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              classificationOperation!=='CLEAR' && 
              <div style={{width:400}}>
                <HierarchySearchSelector
                  searchValue={classifiedSearch}
                  setSearchValue={setClassifiedSearch}
                  rootId={collectionIds.classification}
                  fq={
                    `object_type_srt:COLLECTION_INSTANCE AND collection_srt:CLASSIFICATION AND collection_type_srt:PLATFORM` +
                    (classified?` AND -id:(${classified})`:'')
                  }
                  rootLabel={'collection'}
                  onResultClick={item=>{
                    onSelectClassified([item.id])
                    setClassifiedSearch('')
                  }}
                  height={51}
                  clearable
                  testID="edit-classification-search"
                  placeholder={`Search for a classification`}
                  isItemSelected={item=>{
                    return classified===item.id
                  }}
                  borderColour={classified?undefined:theme.palette.error.main}
                />

                {
                  classified && 
                  <div >
                    <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                      <div style={{width:'max-content',marginRight:6,marginBottom:6,padding:'0px 6px',height:32, borderRadius:16, display:'flex',background:getCollectionColour('classification','manual').colour,alignItems:'center'}}>
                        <Typography style={{color:getCollectionColour('classification','manual').fontColour,marginLeft:6}}>{state.classificationList.find(v=>v.id===classified).name}</Typography>
                        <IconButton className={classes.clearButton} style={{color:getCollectionColour('classification','manual').fontColour,marginLeft:20,padding:3,marginRight:3,background:getCollectionColour('classification','manual').colour}} onClick={()=>onSelectClassified([])}>{getIconComponent({label:'clear', size:20, colour:getCollectionColour('classification','manual').fontColour})}</IconButton>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }

      {/* domain */}
      {
        state.domainList && editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock}>
          <Checkbox 
            onChange={(event, checked)=>onDomainChecked(checked)} 
            className={classes.checkbox+ ' ' + classes.propertyCheckBox} 
            data-test-id="edit-domain-checkbox"
            color='primary' 
            checked={state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='domain')}
          />
          <Typography className={classes.title}>Domain</Typography>
          <div style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='domain')?undefined:0.5}}>
            <Select
              inputProps={{
                disabled:!state.changedFields.find(el=>el.field==='MEMBER_OF' && el.id==='domain')
              }}
              value={domainOperation}
              className={classes.selector + ' ' + classes.editTypeSelector }
              data-test-id="edit-domain-operation-selector"
              onChange={event=>onChangeDomainOperation(event.target.value)}
              disableUnderline
            > 
              {
                [
                  {name:'Add to existing',value:'ADD_TO_EXISTING'},
                  {name:'Replace',value:'REPLACE'},
                  {name:'Clear',value:'CLEAR'}
                ].map(el=>(
                  <MenuItem className={classes.menuItem} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              domainOperation!=='CLEAR' && 
              <div style={{width:400}}>
                <HierarchySearchSelector
                  searchValue={domainSearch}
                  setSearchValue={setDomainSearch}
                  rootId={collectionIds.domain}
                  fq={
                    `object_type_srt:COLLECTION_INSTANCE AND collection_srt:DOMAIN AND collection_type_srt:PLATFORM` +
                    (domain.length>0?` AND -id:(${domain.join(' OR ')})`:'')
                  }
                  rootLabel={'collection'}
                  onResultClick={item=>{
                    onSelectDomain([item.id])
                    setDomainSearch('')
                  }}
                  isItemSelected={item=>{
                    return domain.includes(item.id)
                  }}
                  height={51}
                  clearable
                  testID="edit-domain-search"
                  placeholder={`Search for a domain`}
                  borderColour={domain.length>0?undefined:theme.palette.error.main}
                />
                {
                  domain.length>0 && 
                  <div >
                    <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                      {
                        domain.map(el=>(
                          <div style={{width:'max-content',marginRight:6,marginBottom:6,padding:'0px 6px',height:32, borderRadius:16, display:'flex',background:theme.palette.primary.dark,alignItems:'center'}}>
                            <Typography style={{color:theme.palette.background.main,marginLeft:6}}>{state.domainList.find(v=>v.id===el).name}</Typography>
                            <IconButton className={classes.clearButton} style={{color:theme.palette.background.main,marginLeft:20,padding:3,marginRight:3,background:theme.palette.primary.dark}} onClick={()=>onRemoveDomain(el)}>{getIconComponent({label:'clear', size:20, colour:theme.palette.background.main})}</IconButton>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }


      {/* verified */}
      {
        state.verifiedList && editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock}>
          <Checkbox 
            onChange={(event, checked)=>onVerifiedChecked(checked)} 
            className={classes.checkbox+ ' ' + classes.propertyCheckBox} 
            color='primary' 
            data-test-id="edit-verified-checkbox"
            checked={state.changedFields.find(el=>el.field==='VERIFIED_BY')}
          />
          <Typography className={classes.title}>Verified for</Typography>
          <div style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.field==='VERIFIED_BY')?undefined:0.5}}>
            <Select
              inputProps={{
                disabled:!state.changedFields.find(el=>el.field==='VERIFIED_BY')
              }}
              value={verifiedOperation}
              className={classes.selector + ' ' + classes.editTypeSelector }
              data-test-id="edit-verified-operation-selector"
              onChange={event=>onChangeVerifiedOperation(event.target.value)}
              disableUnderline
            > 
              {
                [
                  {name:'Replace',value:'REPLACE'},
                  {name:'Clear',value:'CLEAR'}
                ].map(el=>(
                  <MenuItem className={classes.menuItem} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              verifiedOperation!=='CLEAR' && 
              <div 
                style={{width:400}}
              >
                <HierarchySearchSelector
                  searchValue={verifiedSearch}
                  setSearchValue={setVerifiedSearch}
                  rootId={collectionIds.verifiedUseCase}
                  fq={
                    `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"VERIFIED USE CASE" AND collection_type_srt:PLATFORM` +
                    (verifiedNot.length>0?` AND -id:(${verifiedNot.join(' OR ')})`:'') + 
                    (verified.length>0?` AND -id:(${verified.join(' OR ')})`:'')
                  }
                  rootLabel={'collection'}
                  onResultClick={item=>{
                    onSelectVerified([item.id])
                    setVerifiedSearch('')
                  }}
                  height={51}
                  clearable
                  testID="edit-verified-search"
                  placeholder={`Search for a verified use case`}
                  isItemSelected={item=>{
                    return verified.includes(item.id)
                  }}
                  isItemDisabled={item=>{
                    return verifiedNot.includes(item.id)
                  }}
                  borderColour={verified.length>0?undefined:theme.palette.error.main}
                />

                {
                  verified.length>0 && 
                  <div>
                    <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                      {
                        verified.map(el=>(
                          <div style={{width:'max-content',marginRight:6,marginBottom:6,padding:'0px 6px',height:32, borderRadius:16, display:'flex',background:getCollectionColour('verified').colour,alignItems:'center'}}>
                            <Typography style={{color:getCollectionColour('verified').fontColour,marginLeft:6,  maxWidth:220,overflow:"hidden",textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{state.verifiedList.find(v=>v.id===el).name}</Typography>
                            <IconButton className={classes.clearButton} style={{marginLeft:16,padding:3,marginRight:3,background:getCollectionColour('verified').colour}} onClick={()=>onRemoveVerified(el)}>{getIconComponent({label:'clear', size:20, colour:getCollectionColour('verified').fontColour})}</IconButton>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }

      {/* not verified */}
      {
        state.verifiedList && editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock}>
          <Checkbox 
            onChange={(event, checked)=>onVerifiedNotChecked(checked)} 
            className={classes.checkbox+ ' ' + classes.propertyCheckBox} 
            color='primary' 
            data-test-id="edit-not-verified-checkbox"
            checked={state.changedFields.find(el=>el.field==='NOT_VERIFIED_BY')}
          />
          <Typography className={classes.title}>Not Verified for</Typography>
          <div style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.field==='NOT_VERIFIED_BY')?undefined:0.5}}>
            <Select
              inputProps={{
                disabled:!state.changedFields.find(el=>el.field==='NOT_VERIFIED_BY')
              }}
              value={notVerifiedOperation}
              className={classes.selector + ' ' + classes.editTypeSelector }
              data-test-id="edit-not-verified-operation-selector"
              onChange={event=>onChangeNotVerifiedOperation(event.target.value)}
              disableUnderline
            > 
              {
                [
                  {name:'Replace',value:'REPLACE'},
                  {name:'Clear',value:'CLEAR'}
                ].map(el=>(
                  <MenuItem className={classes.menuItem} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              notVerifiedOperation!=='CLEAR' && 
              <div style={{width:400}}>
                <HierarchySearchSelector
                  searchValue={verifiedNotSearch}
                  setSearchValue={setVerifiedNotSearch}
                  rootId={collectionIds.verifiedUseCase}
                  fq={
                    `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"VERIFIED USE CASE" AND collection_type_srt:PLATFORM` +
                    (verifiedNot.length>0?` AND -id:(${verifiedNot.join(' OR ')})`:'') + 
                    (verified.length>0?` AND -id:(${verified.join(' OR ')})`:'')
                  }
                  rootLabel={'collection'}
                  onResultClick={item=>{
                    onSelectVerifiedNot([item.id])
                    setVerifiedNotSearch('')
                  }}
                  height={51}
                  clearable
                  testID="edit-verified-not-search"
                  placeholder={`Search for a use case`}
                  isItemSelected={item=>{
                    return verifiedNot.includes(item.id)
                  }}
                  isItemDisabled={item => {
                    return verified.includes(item.id)
                  }}
                  borderColour={verifiedNot.length>0?undefined:theme.palette.error.main}
                />
                {
                  verifiedNot.length>0 && 
                  <div >
                    <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                      {
                        verifiedNot.map(el=>(
                          <div style={{width:'max-content',marginRight:6,marginBottom:6,padding:'0px 6px',height:32, borderRadius:16, display:'flex',background:getCollectionColour('verified not').colour,alignItems:'center'}}>
                            <Typography style={{color:getCollectionColour('verified not').fontColour,marginLeft:6, maxWidth:220,overflow:"hidden",textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{state.verifiedList.find(v=>v.id===el).name}</Typography>
                            <IconButton className={classes.clearButton} style={{marginLeft:16,padding:3,marginRight:3,background:getCollectionColour('verified not').colour}} onClick={()=>onRemoveVerifiedNot(el)}>{getIconComponent({label:'clear', size:20, colour:getCollectionColour('verified not').fontColour})}</IconButton>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
      

      {/* owner */}
      {
        editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock}>
          <Checkbox 
            onChange={(event, checked)=>onOwnerChecked(checked)} 
            className={classes.checkbox+ ' ' + classes.propertyCheckBox} 
            color='primary' 
            data-test-id="edit-owner-checkbox"
            checked={state.changedFields.find(el=>el.field==='OWNED_BY')}
          />
          <Typography className={classes.title}>Owner</Typography>
          <div  style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.field==='OWNED_BY')?undefined:0.5}}>
            <Select
              inputProps={{
                disabled:!state.changedFields.find(el=>el.field==='OWNED_BY')
              }}
              data-test-id="edit-owner-operation-selector"
              value={ownerOperation}
              className={classes.selector + ' ' + classes.editTypeSelector }
              onChange={event=>onChangeOwnerdOperation(event.target.value)}
              disableUnderline
            > 
              {
                [
                  {name:'Replace',value:'REPLACE'},
                  {name:'Clear',value:'CLEAR'}
                ].map(el=>(
                  <MenuItem className={classes.menuItem} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              ownerOperation!=='CLEAR' && 
              <div>
                <div style={{width:400}}>
                  <SearchSelector 
                    disabled={!state.changedFields.find(el=>el.field==='OWNED_BY')}
                    url='/solr/search/select'
                    testID={'edit-owner-search'}
                    params={{
                      q: `${ownerSearchValue}*`,
                      fq:'object_type_srt:USER AND account_type_srt:"USER_ACCOUNT" AND -merge_type_srt:CANDIDATE'+
                          (userFilter==='data_roles'?' AND data_role_kc_msrt:"DATA OWNER"':''),
                      fl:'labels:object_type_txt,id,name:name_txt,groups:teams_txts',
                      rows:10
                    }}
                    searchValue={ownerSearchValue}
                    setSearchValue={setOwnerSearchValue}
                    placeholder='Search for a user' 
                    onResultClick={el=>onSetOwner(el)}
                    removeFLModify={false}
                    borderColour={owner?undefined:theme.palette.error.main}
                  />
                </div>
                {
                  owner && 
                  <div>
                    <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                    <div style={{width:'max-content',padding:'0px 6px',height:32, display:'flex',borderRadius:16,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.chip.main,alignItems:'center'}}>
                      <Avatar align='center' className={classes.avatar}>{getInitials(owner.name)}</Avatar>
                      <Typography style={{marginLeft:10,color:theme.palette.primaryText.main}}>{owner.name}</Typography>
                      <IconButton className={classes.clearButton} style={{marginLeft:20,padding:3,marginRight:3,background:theme.palette.chip.main}} onClick={()=>onRemoveOwner()}>{getIconComponent({label:'clear', size:20,colour:theme.palette.primaryText.main})}</IconButton>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
      

      {/* steward  */}
      {
        editMode!=='same_name_description_only' &&
        <div className={classes.inputBlock}>
          <Checkbox 
            onChange={(event, checked)=>onStewardChecked(checked)} 
            className={classes.checkbox+ ' ' + classes.propertyCheckBox} 
            color='primary' 
            data-test-id="edit-steward-checkbox"
            checked={state.changedFields.find(el=>el.field==='STEWARDED_BY')}
          />
          <Typography className={classes.title}>Steward</Typography>
          <div  style={{flexGrow:1,display:'flex',alignItems:'flex-start',opacity:state.changedFields.find(el=>el.field==='STEWARDED_BY')?undefined:0.5}}>
            <Select
              inputProps={{
                disabled:!state.changedFields.find(el=>el.field==='STEWARDED_BY')
              }}
              value={stewardOperation}
              className={classes.selector + ' ' + classes.editTypeSelector }
              data-test-id="edit-steward-operation-selector"
              onChange={event=>onChangeStewardOperation(event.target.value)}
              disableUnderline
            > 
              {
                [
                  {name:'Add to existing',value:'ADD_TO_EXISTING'},
                  {name:'Replace',value:'REPLACE'},
                  {name:'Clear',value:'CLEAR'}
                ].map(el=>(
                  <MenuItem className={classes.menuItem} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              stewardOperation!=='CLEAR' && 
              <div>
                <div style={{width:400}}>
                  <SearchSelector 
                    disabled={!state.changedFields.find(el=>el.field==='STEWARDED_BY')}
                    url='/solr/search/select'
                    testID={'edit-steward-search'}
                    params={{
                      q: `${stewardSearchValue}*`,
                      fq:'object_type_srt:USER AND -merge_type_srt:CANDIDATE'+
                          (userFilter==='data_roles'?' AND data_role_kc_msrt:"DATA STEWARD"':'') + 
                          (stewards?.length>0?` AND -id:(${stewards.map(el=>el.id).join(' ')})`:'')
                          ,
                      fl:'labels:object_type_txt,id,name:name_txt,teams_txts',
                      rows:10
                    }}
                    searchValue={stewardSearchValue}
                    setSearchValue={setStewardSearchValue}
                    placeholder='Search for a user' 
                    onResultClick={el=>onSetSteward(el)}
                    removeFLModify={false}
                    borderColour={stewards.length===0?theme.palette.error.main:undefined}
                  />
                </div>
                {
                  stewards.length>0 && 
                  <div>
                    <Typography className={classes.subSectionHeader}>SELECTED</Typography>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                      {
                        stewards.map(el=>(
                          <div style={{width:'max-content',marginRight:6,marginBottom:6,padding:'0px 6px',border:`1px solid ${theme.palette.border.main}`,height:32, display:'flex',borderRadius:16,background:theme.palette.chip.main,alignItems:'center'}}>
                            <Avatar align='center' className={classes.avatar}>{getInitials(el.name)}</Avatar>
                            <Typography style={{marginLeft:10,color:theme.palette.primaryText.main}}>{el.name}</Typography>
                            <IconButton className={classes.clearButton} style={{marginLeft:20,padding:3,marginRight:3,background:theme.palette.chip.main}} onClick={()=>onRemoveSteward(el.id)}>{getIconComponent({label:'clear', size:20, colour:theme.palette.primaryText.main})}</IconButton>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }

    </div>
  )
}

EditProperties.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(EditProperties));