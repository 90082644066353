import React, {useEffect, useRef, useState} from 'react';
import { withTheme, Typography, withStyles, Button, CircularProgress } from '@material-ui/core';
import { sendMessage } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import SnackBar from '../SnackBar/SnackBar';
import PropTypes from 'prop-types'
import useAlert from '../../../hooks/useAlert';
import { withRouter } from 'react-router-dom'

const styles = theme => ({
});

export const addToCartLimit = 1000;

const CartAdder = props => {

  const {
    classes,
    history,
    theme,
    objectIds,
    onFinish
  } = props;

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarButton, setSnackBarButton] = useState([]);
  const [keepSnackbarOpen, setKeepSnackBarOpen] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const onAddToCart = () => {
    let waitTimeout = setTimeout(()=>{
      setKeepSnackBarOpen(true)
      setSnackBarOpen(true)
      setSnackBarMessage(
        <div style={{display:'flex'}}>
          <CircularProgress color='secondary' style={{width:24,height:24}}/>
          <Typography style={{fontSize:13.75,marginLeft:16,color:theme.palette.snackbarContent.main}}>Adding to cart...</Typography>
        </div>
      )
    },1000)
    
    let promises = [];
    
    let chunks = objectIds.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/500)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])

    chunks.forEach(chunk=>{
      promises.push(axiosCerebrum.post(`/api/me/cart`,{object_ids:chunk}))
    })

    Promise
      .all(promises)
      .then(response=>{
        clearTimeout(waitTimeout)
        setKeepSnackBarOpen(false)
        setSnackBarOpen(true)
        setSnackBarMessage(`${objectIds.length} item(s) added to cart`)
        setSnackBarButton(<Button color='secondary' variant="outlined" onClick={()=>history.push('/cart')}>GO TO CART</Button>)
        sendMessage({reloadCart:true})
        onFinish && onFinish()
      })
      .catch(error=>{
        console.log(error)
        setSnackBarOpen(false)
        onFinish && onFinish()
        sendAlert({message:'Error occurred adding items to Cart',type:'error'})
      })
  }

  useEffect(()=>{
    if(!objectIds)return;
    if(objectIds.length===0){
      sendAlert({message:'No item to be added',type:'info'})
      onFinish && onFinish()
      return;
    }
    if(objectIds.length>0){
      onAddToCart()
    }
  // eslint-disable-next-line
  },[objectIds])


  return (
    <div className={classes.root}>
      <SnackBar
         snackBarOpen={snackBarOpen}
         keepOpen={keepSnackbarOpen}
         setSnackBarOpen={setSnackBarOpen}
         message={snackBarMessage}
         buttons={snackBarButton}
      />
    </div>
  )
}

CartAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  objectIds: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(withRouter(CartAdder)));
