import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Button } from '@material-ui/core';
import { getIconComponent, getInitials, getKnowledgeBaseUrl, getLabelPlural, headerAutoCollectionList, onClickResultItem } from '../../../utilities';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import axiosCerebrum from '../../../axios-cerebrum'
import { globalListenerRef } from '../../../GlobalListenerRef';
import Tags from './Tags';
import BreadCrumb from './BreadCrumb';
import TitleSection from './TitleSection'

const styles = theme => ({
  root: {
    display: 'flex',
    width:'100%',
    justifyContent: 'space-between',
    flexGrow: 1,
    minHeight:120,
    background:theme.palette.background.main
  },
  subtextReadOnly: {
    textTransform: 'capitalize',
    fontSize: `13.75px !important`,
    '& span':{
      fontSize: `13.75px !important`,
    },
    marginTop:20,
    whiteSpace: 'pre-wrap',
    color: theme.palette.primaryText.main,
  },
  progress: {
    padding: '12px'
  },
  button: {
    margin: theme.spacing.unit,
    alignSelf: 'flex-start',
    paddingRight: 24,
  },
  bannerButton:{
    padding:'4px 8px',
    marginLeft:24,
    flexShrink:0,
    '&:hover':{
      background:theme.palette.secondary.main,
      color:theme.palette.background.main
    }
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },
  profileIcon: {
    marginTop: 48,
    marginLeft:-58,
  },
  mainContent: {
    flexGrow: 1,
    marginLeft:18,
    width:'100%'
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'start',
    flexGrow: 0,
    flexShrink:0,
    marginTop:0
  },
  description: {
    whiteSpace: 'pre-wrap',
    fontSize: '0.85rem',
    marginTop: '1rem',
    color: '#888'
  },
  '@media (max-width: 1350px)': {
    profileIcon: {
      display: 'none'
    },
    mainContent: {
      marginLeft:0
    }
  },
  bannerText: {
    fontSize: 16,
    flexGrow:0,
    color:theme.palette.primaryText.main,
    '& *':{
      color:theme.palette.primaryText.main,
      fontSize: 16,
    }
  },
  banner: {
    // height: '56px',
    flexGrow:1,
    backgroundColor: theme.palette.inputBackground.main,
    border:`1px solid ${theme.palette.border.main}`,
    minWidth:700,
    minHeight:52,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    padding:'0px 4px'
  },
  headerAvatar:{
    background:theme.palette.avatar.main,
    color:'#fff',
    width:80,
    height:80,
    borderRadius:40,
    marginRight:30,
    marginTop:32,
    flexShrink:0,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:32,
  },
});



const ProfileHeader = props => {

  const {
    theme,
    classes,
    label,
    iconLabel,
    title,
    copiedTitle,
    subtitle,
    description,
    buttons,
    data,
    tags,
    // creatorData,
    sourceType,
    history,
    isShell,
    // banner
    tabOptions,
    bannerdisplayText,
    bannerCause,
    bannerData,
    bannerAction,
    state,
    dispatch,
    tagsFetch,
    shouldLoadLinkedInstance,
    shouldLoadBreadCrumb,
    // linkedInstances,
    // linkedInstanceFetch,
    //////
    avatarIcon,
    ///
    customTags,
    ////
    onClickAddTag,
    // setEditing,
    minHeight=140
  } = props;

  const [hoveredTag, setHoveredTag] = useState()

  let linkedInstances = state && state.collectionInstancesData?state.collectionInstancesData.items:[]

  const linkedInstanceFetch = () => {
    const loadInstances = async () => {
      let autoRes = []
      await axiosCerebrum
        .get(
          '/api/stats',
          {params:{
            object_ids:data.id
          }}
        )
        .then(response=>{
          let item = response.data.items[0];
          headerAutoCollectionList.forEach(el=>{
            if(item[el]===null || !item[el])return;
            autoRes.push({
              name:item[el],
              parent_name:el,
              isAuto:true
            })
          })
        })
        .catch(error=>{
          console.log(error)
        })
      let manualRes = await axiosCerebrum
        .get(
          `/api/${getLabelPlural(data.object?data.object.name:label)}/${data.id.toLowerCase()}/related/collections`,
          {params:{
            category:'DATA_MANAGEMENT,DATA_GOVERNANCE,PLATFORM',
            relationship:'MEMBER_OF,MEMBER_OF_AUTO',
            per_page:300
          }}
        )
        .catch(error=>{
          console.log(error)
        })
      let finalRes = {
        total:(autoRes.length)+(manualRes?manualRes.data.total:0),
        items:[...autoRes,...(manualRes?manualRes.data.items:[])]
      }
      // window.postMessage({reload_link_modal:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      dispatch({
        type:'set_collection_instances',
        collectionInstancesData:finalRes
      })
    }
    loadInstances()
  }

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.headerInstanceReloadListener)
    if(shouldLoadLinkedInstance && !state.collectionInstancesData){
      linkedInstanceFetch()
      globalListenerRef.headerInstanceReloadListener = (msg) => {
        if(msg.data.reload_header_instance ){
          linkedInstanceFetch();
        }
      }
      window.addEventListener('message',globalListenerRef.headerInstanceReloadListener)
    }

    return ()=>{
      window.removeEventListener('message',globalListenerRef.headerInstanceReloadListener)
    }

  // eslint-disable-next-line
  },[])


  const seeDetails = () => {
    if(bannerCause==='issue'){
      dispatch({type:'set_tab_state',tabState:tabOptions.indexOf('ISSUES')})
    }
    else if(bannerCause==='external_issue'){
      window.open(data.external_url,'_blank')
    }
    else if(bannerCause==='emailNotConfigured'){
      window.open('/admin/integrations?capability=EMAIL','_blank')
    }
    else if (bannerCause === 'replacedByDate') {
      if(state.replacedObject){
        onClickResultItem({item:state.replacedObject,label:state.replacedObject.object_type_txt,id:state.replacedObject.id,history})
      }else{
        dispatch({ type: 'set_tab_state', tabState: tabOptions.indexOf('LINEAGE') }) 
      }
    }
    else if (bannerCause==='access_key'){
      history.push(`/admin/access_key`)
    }
    else if (bannerCause==='merge'){
      history.push(bannerData.targetUrl)
    }
    else if(bannerCause==='bulk_edit_templated'){
      window.open('https://docs.kada.ai/home/bulk-update-via-data-cart','_blank')
    }
    else if(bannerCause==='bulk_edit_link_collection_info'){
      window.open('https://docs.kada.ai/home/bulk-update-via-data-cart','_blank')
    }
    else return
  }


  let button;
  
  if(
    bannerCause === 'replacedByDate' || 
    // bannerCause === 'question_unanswered' || 
    bannerCause === 'access_key' || 
    bannerCause === 'merge' || 
    (bannerCause === 'issue' && tabOptions) || 
    (bannerCause === 'external_issue' && data.external_url) ||
    bannerCause === 'source_deactivation' || 
    bannerCause === 'emailNotConfigured' || 
    bannerCause ==='bulk_edit_templated' || 
    bannerCause ==='ia_limit' || 
    bannerCause ==='bulk_edit_link_collection_info'
  ){
    let buttonMsg = "SEE DETAILS"
    // if(bannerCause==='question_unanswered')buttonMsg = "ADD AN ANSWER"
    if(bannerCause==='access_key')buttonMsg = "CREATE ACCESS KEY"
    if(bannerCause === 'merge')buttonMsg = 'GO TO MERGED ACCOUNT'
    if(bannerCause==='external_issue'){
      if(data.source_obj){
        buttonMsg = `CLICK TO OPEN IN ${data.source_obj.source_template.name}`
      }
    }
    if(bannerCause==='replacedByDate' && state.replacedObject){
      buttonMsg = 'GO TO REPLACEMENT'
    }
    if(bannerCause==='source_deactivation'){
      buttonMsg = 'REACTIVATE'
    }
    if(bannerCause==='emailNotConfigured'){
      buttonMsg = 'CONFIGURE EMAIL NOTIFICATION'
    }
    if(bannerCause==='bulk_edit_templated'){
      buttonMsg = 'GO TO KNOWLEDGE BASE'
    }
    if(bannerCause==='bulk_edit_link_collection_info'){
      buttonMsg = 'GO TO KNOWLEDGE BASE'
    }
    if(bannerCause==='ia_limit'){
      buttonMsg = 'GO TO KNOWLEDGE BASE'
    }
    button=(
      <Button
        onClick={bannerAction || seeDetails}
        color='secondary'
        variant="outlined"
        className={classes.bannerButton}
      >
        {buttonMsg}
      </Button>
    )
    if(bannerCause==='ia_limit' && !getKnowledgeBaseUrl()){
      button = undefined;
    }
  }
  

  return (
    <div>
      <div className={classes.root} style={{minHeight:minHeight}}>
        {
          (iconLabel || label) && !avatarIcon && (!sourceType || !getIconComponent({ label: sourceType})) &&
          <div className={classes.profileIcon} >
            {getIconComponent({ label: iconLabel || label, size: 40, colour: isShell?theme.palette.primaryText.light:theme.palette.header.main })}
          </div>
        }
        {
          sourceType && !isShell && getIconComponent({ label: sourceType}) &&
          <div className={classes.profileIcon}>
            {
              getIconComponent({ label: sourceType, size: 40, colour:theme.palette.primary.main })
            }
          </div>
        }
        {
          sourceType && isShell && 
          <div className={classes.profileIcon} >
              {getIconComponent({ label: label, size: 40,colour: isShell?theme.palette.primaryText.light:theme.palette.header.main })}
          </div>
        }
        {
          avatarIcon && <div className={classes.headerAvatar}>{getInitials(data.name)}</div>
        }

        <div className={classes.mainContent} id='profile-header-main-content' style={{ marginLeft: (iconLabel || label) ? undefined : 0 }}>
            <div style={{display:'flex',height:56,justifyContent:'space-between',alignItems:'center',marginTop:-12}}>
              {
                shouldLoadBreadCrumb ?
                <div style={{marginTop:28}}>
                  <BreadCrumb data={data}/>
                </div>
                :
                <Typography className={classes.subtextReadOnly} variant='caption'>{subtitle}</Typography>
              }
              <div className={classes.buttonWrapper}>
                {buttons}
              </div>
            </div>

            <TitleSection
              data={data}
              title={title}
              copiedTitle={copiedTitle}
              description={description}
              label={label}
              state={state}
              dispatch={dispatch}
              isShell={isShell}
            />
            
            <Tags
              isShell={isShell}
              data={data}
              label={label} 
              linkedInstances={linkedInstances}
              linkedInstanceFetch={linkedInstanceFetch}
              tags={tags}
              tagsFetch={tagsFetch}
              hoveredTag={hoveredTag}
              setHoveredTag={setHoveredTag} 
              onClickAddTag={onClickAddTag}
              addTagButtonSize={'small'}
              history={history}
              customTags={customTags}
            />
        </div>    
      </div>    
       
      {
        bannerCause &&
        <div style={{display:'flex',marginBottom:4}}>
          <div className={classes.banner} style={{ marginTop:8 }} id='profile-header-banner' >
            <ErrorIcon style={{marginRight: '1.5rem',marginLeft:16,color: '#FC642D',fontSize: 32}}/>
            <span className={classes.bannerText}>{bannerdisplayText}</span>
            {button}
          </div>
        </div>
      }
    </div>
  )
}


ProfileHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  copiedTitle: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.array,
  titleMaxLength: PropTypes.number,
  iconLabel: PropTypes.string,
  subHeader: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  // creatorData,
  sourceType: PropTypes.string,
  history: PropTypes.object,
  isShell: PropTypes.string,
  // banner
  tabOptions: PropTypes.array,
  bannerdisplayText: PropTypes.string,
  bannerCause: PropTypes.string,
  bannerData: PropTypes.object,
  bannerAction: PropTypes.func,
  tagsFetch: PropTypes.func,
  shouldLoadLinkedInstance: PropTypes.bool,
  shouldLoadBreadCrumb: PropTypes.bool,
  // linkedInstances: PropTypes.array,
  // linkedInstanceFetch: PropTypes.func,
  //////
  avatarIcon: PropTypes.bool,
  ///
  customTags: PropTypes.array,
  ////
  onClickAddTag: PropTypes.func,
  setEditing: PropTypes.func,
  minHeight: PropTypes.number
}



export default withTheme()(withStyles(styles)(ProfileHeader));