import { useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import Alert from 'react-s-alert';

const alertStack = {}

function useAlert({id, isCancelledRef, disableStateCache}) {

  useEffect(()=>{
    if(id && alertStack[id]){
      alertStack[id].forEach(alert => {
        Alert[alert.type](alert.message, {
          position: 'top',
          effect: 'stackslide',
        })
      })
      delete alertStack[id]
    }
    // eslint-disable-next-line
  },[])

  const sendAlert = useCallback(({type, message, timeout}) => {
    if (!type || !message) return;
    if(!['error','info'].includes(type))type = 'info';
    if(isCancelledRef?.current && !disableStateCache && id){
      alertStack[id] = [
        ...(alertStack[id]||[]),
        {type, message, id}
      ]
      return;
    }
    Alert[type](message, {
      position: 'top',
      effect: 'stackslide',
      timeout
    })
  // eslint-disable-next-line
  }, [id, isCancelledRef])

  return { sendAlert }

}

useAlert.propTypes = {
  // type: propTypes.oneOf(['error', 'info']),
  // message: propTypes.string,
  id: propTypes.string,
  isCancelledRef: propTypes.object,
  disableStateCache: propTypes.bool,
}

export default useAlert;
