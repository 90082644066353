import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import SearchBar from '../../SearchBar/SearchBar'
import RecommendList from '../RecommendList/RecommendList'
import { getCustomerName } from '../../../../utilities'
 
const styles = theme => ({
  root:{
    maxWidth:1180,
    padding:'0 32px',
    boxSizing:'border-box',
    margin:'120px auto'
  },
  caption:{
    fontSize:12,
    letterSpacing:1,
    color:theme.palette.primaryText.main,
    marginRight:16,
    marginBottom:8
  },
  chip:{
    height:24,
    borderRadius:12,
    padding:'0px 8px',
    border:`1px solid ${theme.palette.primary.main}`,
    color:theme.palette.primary.main,
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    marginRight:16,
    fontSize:13.75,
    marginBottom:8
  },
  selectedChip:{
    background:theme.palette.primary.main,
    color:theme.palette.background.main
  }
});



const LandingBody = props => {

  const {
    classes,
    state,
    dispatch,
    history,
    onSearch,
    indexName
  } = props;
  
  let searchPlaceHolder;
  let customerName = getCustomerName()
  searchPlaceHolder = `Search ${customerName===''?'your':('the ' + customerName)} data ecosystem`;

  return (
    <div className={classes.root}>
      <div id={"search_tutorial_section"}>
        <SearchBar indexName={indexName} state={state} dispatch={dispatch} onSearch={onSearch} placeholder={searchPlaceHolder}/>
      </div>
      <RecommendList state={state} dispatch={dispatch} history={history} onSearch={onSearch}/>
    </div>
  )
}

export default withTheme()(withStyles(styles)(LandingBody));