import React, { useState, useEffect, useRef } from 'react';
import { withTheme, withStyles, CircularProgress, Typography,Modal,Paper,Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import useGetCerebrum from '../../../hooks/useGetCerebrum';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {  getIconComponent, isInViewport, removeUrlQueryArg } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment';
import ClearableSelector from '../../UI/ClearableSelector/ClearableSelector'
import CancelModal from './CancelModal';
import KTooltip from '../../UI/KTooltip/KTooltip';
import 'url-search-params-polyfill';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
  },
  statusChip:{
    width:64,
    textAlign:'center',
    padding:'8px 12px',
    borderRadius:3,
    fontSize:13.75
  },
  dataBoxes: {
    marginBottom: '2.5rem'
  },
  listView: {
    flexBasis: 400,
    flexGrow: 1
  },
  dataBox: {
    margin: '0 1.5rem 1.5rem 0'
  },
  subtitle: {
    fontSize: '0.75rem',
    color: theme.palette.primaryText.light,
    fontWeight: 400,
    marginBottom: '2rem'
  },
  usageTitle: {
    height: '24px',
    width: '240px',
    color: theme.palette.primary.min,
    
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    paddingTop: '24px'
  },
  noUsageSubtitle: {
    height: '16px',
    // width: '264px',
    color: theme.palette.primaryText.light,
    
    fontSize: '11.78px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    paddingBottom: '16px',
    paddingTop: '8px'
  },
  block: {
    marginTop: '3.5rem'
  },
  menuItem: {
    fontWeight: '400',
  },
  button: {
    width: '25%',
    marginRight: '2rem'
  },
  assigneeChip: {
    padding: '0.5rem',
    display: 'inline-block',
    backgroundColor: '#EEE',
    marginTop: '-0.5rem',
    marginBottom: '1rem',
    borderRadius: 5
  },
  chip: {
    backgroundColor: '#EEE',
    borderRadius: 5,
    display: 'inline-block',
    marginRight: 5,
    marginTop: 5,
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    '&:hover $avatar': {
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#DDD'
    }
  },
  avatar: {
    fontSize: 12,
    height: 25,
    width: 25,
    backgroundColor: theme.palette.avatar.main,
  },
  textField: {
    width: '100%',
    marginTop: '0.5rem',
    backgroundColor: '#EEE',
    '& input': {
      //paddingLeft: '1rem',
      height: '2rem'
    },
    '& textarea': {
      //paddingLeft: '1rem',
    },
    paddingTop: '0.25rem'
  },
  subText: {
    fontSize: '0.75rem',
    color: theme.palette.primaryText.light,
  },
  headerText: {
    height: '32px',
    color: theme.palette.primary.main,
    fontSize: '11.78px',
    overflow:'hidden',
    fontWeight: 500,
    letterSpacing: '2px',
    lineHeight: '16px',
    marginRight:16,
    minWidth:20,
  },
  noHistory: {
    width: '240px',
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    color:theme.palette.primaryText.main
  },
  titleBelowLine: {
    height: '24px',
    color: theme.palette.primaryText.light,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    //marginLeft:'18px',
    marginBottom: '24px'
  },
  listItemRoot: {
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover': {
      cursor: 'pointer'
    },
    paddingLeft:0,
    paddingRight:0
  },
  resetButton:{
    padding:0,
    letterSpacing:2,
    height:18,
    '& span':{
      fontSize:12
    }
  },
  refreshButton: {
    height: 36,
    paddingLeft:'8px',
    paddingRight:'8px',
    marginRight:16,
    marginTop:4,
    marginBottom:8,
    minWidth:108,
  },
  modalContainer:{
    display:'flex',
    width:'100vw',
    height:'100vh',
    alignItems:'center',
    justifyContent:'center',
    '&:hover': {
      outline: 'none',
    },
  },
  paperContainer:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    width:'80vw',
    padding:'24px 24px 8px',
    outline: 'none',
  },
  loadingUsage:{
    color:theme.palette.primaryText.main
  },
  listItem:{
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listItemText:{
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16,
    minWidth:20,
  },
  loader:{
    marginBottom:10,
    width:'100%',
  },
  listContainer:{
    width: '100% !important',
    maxHeight:'30vh',
    overflow:'auto',
    '&::-webkit-scrollbar':{
      width: '3px'
    },
    /* Track */
    '&::-webkit-scrollbar-track':{
      background: '#eee',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: '#bbb',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      // background: #555;
    }
  },
  filter:{
    marginRight:16,
    marginBottom:8,
  },
  filterTitle:{
    marginBottom:6,
    color:theme.palette.overline.main
  },
  tooltip:{
    fontSize:13.75,
    wordBreak:'break-word'
  }
});

const Monitor = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const [modalOpen, setModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [loadingMetricsMore, setLoadingMetricsMore] = useState(false);
  const [jobId, setJobId] = useState();
  const [selectedHistory, setSelectedHistory] = useState();
  const metricsLoader = useRef();
  const historyLoader = useRef();
  const errorTextRef = useRef();

  const refreshInterval = useRef()
  const [autoRefreshCount, setAutoRefreshCount] = useState()

  const apiSeqRef = useRef(0)

  const tabState = state.monitorTabState;
  const setTabState = value => dispatch({type:'set_monitor_tab_state',monitorTabState:value})

  const {
    sendAlert
  } = useAlert({
  })

  const urlSearch = new URLSearchParams(window.location.search);
  const presetMonitorSourceId = urlSearch.get('sourceIdFilter');
  const presetDataProcessedFilter = urlSearch.get('dataProcessedFilter');

  const tabOptions = ['ALL','SOURCES']


  let jobTypes = [];
  if(state.jobsData){
    if(tabState===1)jobTypes = state.jobsData.filter(j=>[802,801].includes(j.id))
    else{jobTypes = state.jobsData.filter(el=>el.internal_flag===false)}
  }

  const {
    data: sourcesData,
    error: sourcesError,
    loading: sourcesLoading,
    fetchList: sourcesFecthlist
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      per_page:200,
      types:'DATABASE,TOOL,INTERNAL,IDENTITY_STORE',
      sort:"ALPHABETICAL"
    },
    preventAuto: true
  });
 
  const statusData = [
    {"description":"Job has been submitted and waiting to be run.","status":"SUBMITTED","statusId":1},
    {"description":"Job is still running.","status":"RUNNING","statusId":2},
    {"description":"Job has failed.","status":"FAILED","statusId":3},
    {"description":"Job has completed","status":"COMPLETE","statusId":4},
    {"description":"Job has been killed","status":"KILLED","statusId":5}
  ]

  useEffect(()=>{
    if (!state.monitorSourcesData) sourcesFecthlist();
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    dispatch({ type: 'set_monitor_sources_data', monitorSourcesData: sourcesData || state.monitorSourcesData, monitorSourcesLoading: sourcesLoading,monitorSourcesError:sourcesError })
  // eslint-disable-next-line
  }, [sourcesData, sourcesLoading, sourcesError])

  useEffect(() => {
    if(state.historyData || state.historyLoading || state.historyError){
      setAutoRefreshCount(undefined)
      clearInterval( refreshInterval.current)
      loadMoreHistory({page:1});
    }
  // eslint-disable-next-line
  }, [ state.params])

  useEffect(()=>{
    setAutoRefreshCount()
    clearInterval( refreshInterval.current)
    return ()=>clearInterval( refreshInterval.current)
  },[tabState])

  const shouldLoadMoreHistory = () => {
    return isInViewport(historyLoader) && !state.historyLoading && state.historyData && state.historyData.page<state.historyData.pages
  }

  const loadMoreHistory = ({page=1, params=state.params}) => {
    dispatch({
      type:'set_history_data',
      historyData:page===1?undefined:state.historyData,
      historyLoading:true
    })
    apiSeqRef.current+=1;
    let currentApiSeq = apiSeqRef.current;
    // if(page===1){
    //   clearInterval( refreshInterval.current)
    //   setAutoRefreshCount()
    // }
    axiosCerebrum.get(
      `/api/jobexecutions`, 
      { params: {
        platform_jobs:jobTypes.map(el=>el.name).join(','),
        ...params,
        page,
        per_page:10,
        exclude_internal:true
      }},
    ).then(response => {
      if(currentApiSeq!==apiSeqRef.current)return;
      dispatch({
        type: 'set_history_data', 
        historyData: {
          ...response.data,
          items: page===1?response.data.items:[...state.historyData.items, ...response.data.items],
          rowsPerPage: response.data.per_page,
        }
      })
      if(page===1){
        clearInterval( refreshInterval.current)
        setAutoRefreshCount(30)
        let count = 30;
        refreshInterval.current = setInterval(()=>{
          if(count<=1){
            clearInterval(refreshInterval.current)
            setAutoRefreshCount(undefined)
            loadMoreHistory({page:1, params:{...params}})
          }else{
            count-=1
            setAutoRefreshCount(count)
          }
        },1000)
      }
    }).catch(error => {
      console.log(error)
      dispatch({
        type:'set_history_data',
        historyData:page===1?undefined:state.historyData,
        historyError:true
      })
    })
  }

  useEffect(()=>{
    if(state.historyData || !state.jobsData)return;
    if(presetMonitorSourceId){
      try{
        setTabState(1)
        let newParams = { ...state.params, source_id: Number(presetMonitorSourceId) }
        if(presetDataProcessedFilter)newParams.data_processed = true
        dispatch({ type: 'set_params', params: newParams })
        window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['sourceIdFilter']}));
        loadMoreHistory({params:newParams})
      }catch(error){
        loadMoreHistory({})
        window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['sourceIdFilter']}));
      }
    }else{
      loadMoreHistory({})
    }
    // eslint-disable-next-line
  },[state.jobsData])
    
  useEffect(()=>{
    if(shouldLoadMoreHistory()){
      loadMoreHistory({page:state.historyData.page+1});
    }
    // eslint-disable-next-line
  },[state.historyData,state.historyLoading])

  const shouldLoadMoreMetrics = (target) => {
    return !loadingMetricsMore && state.metricsData && state.metricsData.page<state.metricsData.pages && target.scrollHeight - target.scrollTop + 8 >= target.clientHeight
  }

  const loadMoreMetrics = (id) => {
    setLoadingMetricsMore(true);
    axiosCerebrum.get(
      `/api/jobexecutions/${id}/metrics`,
      {
        params:{
          per_page:10,
          page:state.metricsPage+1,
        }
      }
    ).then(response => {
      dispatch({
        type: 'set_metrics_data', 
        metricsData: {...state.metricsData,items:[...state.metricsData.items,...response.data.items],page: response.data.page},
        metricsError:false,
        metricsLoading:false,
        metricsPage:response.data.page
      })
      setLoadingMetricsMore(false)
    }).catch(error => {
      setLoadingMetricsMore(false)
    })
  }

  useEffect(()=>{
    if(!metricsLoader.current)return;
    if(shouldLoadMoreMetrics(metricsLoader.current)){
      loadMoreMetrics(jobId)
    }
    // eslint-disable-next-line
  },[state.metricsData, metricsLoader.current])

  window.onscroll=()=>{
    if(shouldLoadMoreHistory()){
      clearInterval( refreshInterval.current)
      setAutoRefreshCount()
      loadMoreHistory({page:state.historyData.page+1})
    }
  };
  
  const openItemClick = (item) => {
    axiosCerebrum.get(
      `/api/jobexecutions/${item.id}/metrics`,{
        params:{
          'per_page':10
        }
      }
    ).then(response => {
      dispatch({
        type: 'set_metrics_data', 
        metricsData: response.data,
        metricsPage: response.data.page,
      })
      setJobId(item.id)
      setSelectedHistory(item)
      setModalOpen(true)
    }).catch(error => {
      console.log(error)
    })
  }

  const clearStates = () => {
    dispatch({
      type: 'set_metrics_data', 
      metricsPage: 1,
    })
    setJobId()
    setSelectedHistory()
  }

  const setParams = (val, type) => {
    if (type === 'status') {
      if (val !== 'All') {
        dispatch({ type: 'set_params', params: { ...state.params, job_status: val } })
      }
      else {
        delete state.params['job_status'];
        dispatch({ type: 'set_params', params: {...state.params} })
      }
    }
    if (type === 'source') {
      if(!['All','1000'].includes(val+'')){
        delete state.params['platform_jobs'];
      }
      if (val !== 'All') {
        dispatch({ type: 'set_params', params: { ...state.params, source_id: val } })
      }
      else {
        delete state.params['source_id'];
        dispatch({ type: 'set_params', params: {...state.params} })
      }
    }
    if (type === 'task') {
      if (val !== 'All') {
        dispatch({ type: 'set_params', params: { ...state.params, platform_jobs: val } })
      }
      else {
        delete state.params['platform_jobs'];
        dispatch({ type: 'set_params', params: {...state.params} })
      }
    }
    if (type === 'data_processed') {
      dispatch({ type: 'set_params', params: { ...state.params, data_processed: val===true?true:undefined } })
    }
    
  }

  const getSourceName = (id) => {
    let item;
    item = state.monitorSourcesData && state.monitorSourcesData.items && state.monitorSourcesData.items.find(el => el.id === id);
    return item ? item.name : null
  }

  const getTaskName = (platformJobId,sourceId) => {
    let item;
    if(platformJobId){
      item = state.jobsData && state.jobsData.find(el => el.id === platformJobId);
      return item ? item.name : null
    }
    else{
      item = state.monitorSourcesData && state.monitorSourcesData.items && state.monitorSourcesData.items.find(el => el.id === sourceId);
      return item ? item.name : null
    }
  }

  const onRefreshClick = () => {
   //dispatch({ type: 'set_params', params: {} });
    clearStates();
    dispatch({
      type: 'set_history_data', historyData: undefined, historyError:undefined,historyLoading:undefined
    })
    window.scrollTo({top:0,left:0,behavior:'smooth'})
    clearInterval( refreshInterval.current)
    setAutoRefreshCount()
    loadMoreHistory({page:1});
  }

  const handleCopyClick = str => {
    errorTextRef.current.select();
    document.execCommand('copy');
    sendAlert({message:'Copied error details to Clipboard',type:'info'})
  }

  const getIcon = status => {
    if(status==='RUNNING'){
      return <div className={classes.statusChip} style={{background:'#FC642D',color:'white'}}>Running</div>
    }
    if(status==='SUBMITTED'){
      return <div className={classes.statusChip} style={{background:'#FFAB00',color:'black'}}>Submitted</div>
    }
    if(status==='COMPLETE'){
      return <div className={classes.statusChip} style={{background:'#48D46A',color:'black'}}>Completed</div>
    }
    if(status==='FAILED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Failed</div>
    }
    if(status==='KILLED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Cancelled</div>
    }
  }

  const onReset = () => {
    dispatch({
      type:'set_params',
      params:{
        per_page:10,
        sort:state.params.sort,
      }
    })
  }

  const processArgs = args => {
    if(!args)return 'N/A'
    let strArr = [];
    Object.keys(args).forEach(k=>{
      strArr.push(`${k}: ${args[k].join?args[k].join(', '):JSON.stringify(args[k])}`)
    })
    return strArr.join('  \n')
  }

  const getSortIcon = (field) => {
    if(!state.params?.sort)return<></>
    if(state.params.sort.includes(field))return <span style={{position:'relative',bottom:-2}}>{getIconComponent({label:state.params.sort===`${field}_ASC`?'up':'down',size:13.75,colour:theme.palette.primary.main})}</span>
    return <></>
  }

  const onClickSort = field => {
    let newValue;
    if(state.params.sort===`${field}_ASC`){
      newValue = `${field}_DESC`
    }else{
      newValue = `${field}_ASC`
    }
    dispatch({type:'set_params',params:{...state.params,sort:newValue}})
  }

  if(state.jobsLoading)return <div style={{marginTop:16,display:'flex',justifyContent:'center'}}><CircularProgress color='secondary'/></div>

  if(!state.jobsData)return <div></div>

  if (state.historyError || state.sourcesError) return <Typography>Error loading jobs.</Typography>

  let title, subTitle;
  if(tabState===1){
    title = 'SOURCE LOAD JOBS';
    subTitle = 'List of all source load related jobs that have been run. Sorted by most recently run. '
  }
  if(tabState===0){
    title = 'All JOBS HISTORY';
    subTitle = 'List of all platform related jobs that have been run. Sorted by most recently run. '
  }

  return (
    <div>
      <div style={{float:'left',position:'sticky',top:198}}>
        <VerticalTabBar
          tabOptions={tabOptions}
          tabState={tabState}
          setTabState={value=>{
            setTabState(value)
            onReset()
          }}
        />
      </div>
      <div style={{ paddingBottom: '5rem',marginLeft:280, }}>
        <div style={{paddingBottom:8,background:theme.palette.background.main,position:'sticky',top:198,zIndex:9}}>
          <div style={{display:'flex',alignItems:'flex-start',flexWrap:'wrap'}}>
            <div style={{flexGrow:1,marginRight:16,marginBottom:16,}}>
              <Typography style={{fontSize:20,color:theme.palette.header.main}}>{title}</Typography>
              <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>{subTitle}</Typography>
            </div>
            <div style={{marginBottom:16}}>
              <Button 
                color='primary' 
                variant='contained'
                className={classes.refreshButton} 
                onClick={()=>onRefreshClick()}
                style={{flexShrink:0,background:autoRefreshCount?theme.palette.success.main:undefined,color:autoRefreshCount?'#000':undefined}}
              >
                REFRESH {autoRefreshCount && `(${autoRefreshCount})`}
              </Button>
              <KTooltip title="Cancel all running jobs">
                <Button 
                  color='secondary' 
                  variant='outlined'
                  className={classes.refreshButton} 
                  onClick={()=>setCancelModalOpen({type:'all'})}
                  style={{flexShrink:0}}
                >
                  CANCEL ALL
                </Button>
              </KTooltip>
            </div>      
          </div>
          <div style={{display:"flex"}}>
            <Typography style={{color:theme.palette.primary.main,letterSpacing:2,fontSize:12,marginBottom:8}}>FILTER</Typography>
            <Button color='secondary' style={{marginLeft:24}} className={classes.resetButton} onClick={onReset}>RESET</Button>
          </div>
          <div style={{ display: 'flex', alignItems:'flex-start',flexWrap:'wrap' }}>
            <div className={classes.filter}>
              <ClearableSelector
                value={state.params.job_status || 'All'}
                onChangeValue={event=>setParams(event.target.value, "status")}
                renderValue={value=>'Status: ' + (value==='KILLED'?'CANCELLED':value)}
                width={180}
                options={[
                  {value:'All',name:'All'},
                  ...statusData.map(el=>({value:el.status,name:el.status==='KILLED'?'CANCELLED':el.status}))
                ]}
                onReset={()=>{setParams('All', "status")}}
              />
            </div>
            {
              tabState===1 && 
              <div className={classes.filter}>
                <ClearableSelector
                  value={state.params.source_id || 'All'}
                  onChangeValue={event=>setParams(event.target.value, "source")}
                  renderValue={value=>'Sources: ' + (state.monitorSourcesData && value!=='All'?state.monitorSourcesData.items.find(el=>el.id===value).name:'All')}
                  options={[
                    {value:'All',name:'All'},
                    ...(state.monitorSourcesData?state.monitorSourcesData.items.filter(el=>![995,998].includes(el.id)):[]).map(el=>({value:el.id,name:el.name}))
                  ]}
                  width={180}
                  onReset={()=>{setParams('All', "source")}}
                />
              </div>
            }
            {
              // (!state.params.source_id || state.params.source_id+''==='1000') && 
              <div className={classes.filter}>
                <ClearableSelector
                  value={state.params.platform_jobs || 'All'}
                  onChangeValue={event=>setParams(event.target.value, "task")}
                  renderValue={value=>'Tasks: ' + value}
                  options={[
                    {value:'All',name:'All'},
                    ...jobTypes.map(el=>({value:el.name,name:el.name}))
                  ]}
                  width={180}
                  onReset={()=>{setParams('All', "task")}}
                />
              </div>
            }
            <div className={classes.filter}>
              <ClearableSelector
                value={state.params.data_processed || false}
                onChangeValue={event=>setParams(event.target.value, "data_processed")}
                renderValue={value=>'Exclude: ' + (value===true?'Empty jobs':'None')}
                options={[
                  {value:false,name:'None'},
                  {value:true,name:'Empty jobs'},
                ]}
                width={180}
                onReset={()=>{setParams(false, "data_processed")}}
              />
            </div>
          </div>
          <div style={{ display: 'flex', width:"100%" }}>
            <Typography style={{flex:'0 1 182px' }} color='primary' className={classes.headerText} >STATUS </Typography> 
            <Typography style={{flex:'0 1 182px'}} color='primary' className={classes.headerText} >SOURCE </Typography>
            <Typography style={{flex:'1 1 200px'}} color='primary' className={classes.headerText} >TASK </Typography> 
            <Typography style={{ flex:'0 1 200px',cursor:'pointer'}} onClick={()=>onClickSort('START')} color='primary' className={classes.headerText} >
              START  {getSortIcon('START')}
            </Typography> 
            <Typography style={{flex:'0 1 200px',cursor:'pointer'}} onClick={()=>onClickSort('END')}  color='primary' className={classes.headerText} >
              END {getSortIcon('END')}
            </Typography> 
            <Typography style={{flex:'0 0 76px'}} color='primary' className={classes.headerText} >ACTION </Typography> 
          </div>
        </div>
        <div >
          {
            state.historyData && state.historyData.items.length === 0 && 
            <Typography variant='body2' className = {classes.noHistory} style={{paddingLeft:'1rem'}} >
              {'No items to be displayed'} 
            </Typography>
          }
          {
            state.historyData && state.historyData.items && state.historyData.items.map((item, index) => 
              <div className={classes.listItem} style={{ display: 'flex',alignItems:'center', width:'100%',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,height:48 }}>
                <div style={{flex:'0 1 182px',marginRight:16 }}>
                  {
                    item.job_status
                    ? 
                    <KTooltip title={item.job_status==='KILLED'?'CANCELLED':item.job_status}>
                      <ListItemIcon className={classes.icon}>
                        {getIcon(item.job_status)}
                      </ListItemIcon>
                    </KTooltip> 
                    : null
                  } 
                </div>
                <Typography style={{flex:'0 1 182px'}} className={classes.listItemText} >{getSourceName(item.source_id)} </Typography> 
                <Typography style={{ flex:'1 1 200px'}} className={classes.listItemText} >{getTaskName(item.platform_job_id,item.source_id)} </Typography>
                <Typography style={{ flex:'0 1 200px' }} className={classes.listItemText} >
                  {item.job_started_at?moment(item.job_started_at).format('YYYY-MM-DD HH:mm:ss'):''}
                </Typography> 
                <Typography style={{ flex:'0 1 200px'}} className={classes.listItemText} >
                  {
                    item.job_ended_at && (['FAILED','COMPLETE','KILLED'].includes(item.job_status))?
                      moment(item.job_ended_at).format('YYYY-MM-DD HH:mm:ss')
                      :''
                  }
                </Typography> 
                <div style={{ flex:'0 0 76px',marginRight:16,display:'flex' }}>
                  <KTooltip title="See job details">
                    <IconButton onClick={(event) => openItemClick(item)} style={{padding:4,marginRight:8}}>{getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                  </KTooltip>
                  {
                    ['RUNNING','SUBMITTED'].includes(item.job_status) && 
                    <KTooltip title="Cancel job">
                      <IconButton onClick={(event) => setCancelModalOpen({item,type:'single'})} style={{padding:4}}>{getIconComponent({label:'clear_circle',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                    </KTooltip>
                  }
                </div>
              </div>
            )
          }
          <div 
            id='historyLoader'
            className={classes.loader}
            ref={historyLoader}
          >
            {
              state.historyLoading && 
              <div style={{ textAlign: 'center', marginTop: '5%' }}>
                <CircularProgress color="secondary" />
                <Typography className={classes.loadingUsage}>Loading</Typography>
              </div>
            }
          </div>
        </div>
      </div>



      <CancelModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        loadMoreHistory={loadMoreHistory}
      />

      <Modal 
        open={modalOpen} 
        disableBackdropClick={true} 
        disableEscapeKeyDown={true} 
        hideBackdrop={false} 
        className = {classes.modalContainer}
    >
        {
          modalOpen && selectedHistory &&
          <Paper className = {classes.paperContainer}>
            <Typography style={{fontSize:20,color:theme.palette.header.main}}>
              {getTaskName(selectedHistory.platform_job_id,selectedHistory.source_id).toUpperCase()}
            </Typography>
            <Typography className={classes.titleBelowLine}>{selectedHistory.job_status==='FAILED'?"Details for the job failure":"See metrics for this job"}</Typography> 
            <div>
            <div 
              className={classes.listContainer}
              ref={metricsLoader}
              onScroll={(event)=>{
                if(shouldLoadMoreMetrics(event.target)){
                  loadMoreMetrics(jobId)
                }
              }}
            >
              {
                selectedHistory.job_status==='FAILED' && <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,whiteSpace:'pre-wrap'}}>{selectedHistory.error}</Typography>
              }
              <form style={{ opacity: 0, height: 0 }}>
                <textarea ref={errorTextRef} value={selectedHistory.error}>
                </textarea>
              </form>
              {state.metricsData && selectedHistory.job_status!=='FAILED' && state.metricsData.items.length === 0 && <Typography variant='body2' className = {classes.noHistory} style={{marginTop:'1rem'}} >{'No metrics found'} </Typography> }  
              {
                state.metricsData && state.metricsData.items && state.metricsData.items.map((item, index) =>
                <ListItem
                  key={item.id}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.listItemSelected
                  }}
                >
                  <div style={{width:'100%'}}>
                    {  
                      index === 0 && 
                      <div style={{ display: 'flex',borderBottom:`1px solid #979797`,marginBottom:16 }}>
                        <div style={{ flex:'0 0 200px', marginRight:16 }}> <Typography variant="body1" color='primary' className={classes.headerText} >{'PROCESS DATE'} </Typography> </div>
                        <div style={{ flex:'0 0 300px', marginRight:16 }}> <Typography variant='body1' color='primary' className={classes.headerText} >{'METRIC'} </Typography> </div>
                        <div style={{ flex:'0 0 130px',}}> <Typography variant="body1" color='primary' className={classes.headerText} >{'RESULT'} </Typography> </div>
                        <div style={{ flex:'1 0 250px',marginRight:16 }}> <Typography variant="body1" color='primary' className={classes.headerText} >{'ARGS'} </Typography> </div>
                      </div>
                    }
                    <div style={{ display: 'flex' }}>
                      <Typography variant="body1" className={classes.listItemText}  style={{ flex:'0 0 200px' }}>
                        {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
                      </Typography>
                      <KTooltip title={item.job_metric} style={{ flexGrow: 0 }}  classes={{tooltip:classes.tooltip}}>
                        <Typography style={{ flex:'0 0 300px', marginRight:16 }} variant='body1' className={classes.listItemText} >
                          {item.job_metric}
                        </Typography> 
                      </KTooltip> 
                      <KTooltip title={item.value}  classes={{tooltip:classes.tooltip}}>
                        <Typography variant='body1' className={classes.listItemText}  style={{  flex:'0 0 130px'}}>
                          {item.value} 
                        </Typography> 
                      </KTooltip> 
                      <KTooltip title={processArgs(item.args)} classes={{tooltip:classes.tooltip}}>
                        <Typography style={{ flex:'1 0 250px',marginRight:16}} className={classes.listItemText}> 
                          {processArgs(item.args)}
                        </Typography>
                      </KTooltip> 
                    </div>
                  </div>
              </ListItem>
              )}
            </div>
          </div>
            <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
              {
                selectedHistory.job_status==='FAILED' && selectedHistory.error &&
                <Button color='primary' style={{width:90,height:36,marginTop:'24px',marginRight:12}} onClick={(event)=>{handleCopyClick()}}>COPY</Button>
              }
              <Button color='secondary' style={{width:90,height:36,marginTop:'24px'}} onClick={(event)=>{setModalOpen(false);clearStates()}}>CLOSE</Button>
            </div>
          
          </Paper>  
        }
      </Modal>
    </div>

  )
}


Monitor.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nodeKey: PropTypes.string.isRequired,
  memberData: PropTypes.array,
  memberNum: PropTypes.number,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(Monitor));
