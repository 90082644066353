import React, { useEffect } from 'react';
import {withTheme, withStyles, Typography, CircularProgress, IconButton } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import axiosCerebrum from '../../../axios-cerebrum';
import PropTypes from 'prop-types';
import { getDispFields, getIconComponent, getLabelPlural, onClickResultItem } from '../../../utilities';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { mapRelationshipName } from './utils';
import moment from 'moment';


const styles = theme => ({
  collectionChip:{
    backgroundColor:'#FF9801',
    fontSize:13.75,
    color:'#fff',
    padding:'4px 12px',
    borderRadius:16,
    minWidth:16,
    marginRight:8
  },
  listContainer:{
    maxHeight:'35vh',
    overflow:'auto',
    ...theme.components.customScroll,
    marginBottom:24
  },
  groupHeader:{
    fontSize:12,
    textTransform:'uppercase',
    letterSpacing:2,
    color:theme.palette.primaryText.main,
    marginBottom:6
  }
})


const LinkedList = props => {

  const {
    theme,
    classes,
    history,
    object,
    relations,
    linkableObjects,
    state,
    dispatch,
    setAlertOpen, 
    setAlertMessage,
    setAlertType,
    profileDispatch
  } = props;

  const loadLinkedItem = () => {
    const load = ({prevData=[],page=1}) => {
      axiosCerebrum
        .get(
          `/api/${getLabelPlural(object.object.name)}/${object.id}/related`,{
            params:{
              relationship:relations.join(','),
              object_name:linkableObjects.map(el=>el==='TERM'?'COLLECTION_INSTANCE':el).join(','),
              per_page:20,
              page
            }
          }
        )
        .then(response=>{
          if(response.data.total===0){
            dispatch({
              type:'set_linked_item',
              linkedItemData:[],
              linkedRelations:[...relations],
              linkedObjects:[...linkableObjects]
            })
            return;
          }
          axiosSolr
            .get(`/solr/search/select`,{
              params:{
                q:'*',
                fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                rows:20,
              }
            })
            .then(solrRes=>{
              let data = [
                ...prevData,
                ...response.data.items.map(el=>({
                  ...el,
                  ...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})
                }))
              ];
              if(response.data.page>=response.data.pages){
                dispatch({
                  type:'set_linked_item',
                  linkedItemData:data.filter(el=>el.object_type_txt!=='COLLECTION_INSTANCE'||['DATA_GOVERNANCE','DATA_MANAGEMENT','GLOSSARY'].includes(el.collection_type_txt)),
                  linkedRelations:[...relations],
                  linkedObjects:[...linkableObjects]
                })
              }else{
                load({prevData: data, page: response.data.page+1})
              }
            })
            .catch(error=>{
              console.log(error)
              dispatch({
                type:'set_linked_item',
                linkedItemError:true
              })
            })
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_linked_item',
            linkedItemError:true
          })
        })
    }

    dispatch({
      type:'set_linked_item',
      linkedItemLoading:true,
      linkedRelations:[...relations],
      linkedObjects:[...linkableObjects]
    })

    load({})
  }


  useEffect(()=>{
    if(state.linkedItemLoading)return;
    // if(!state.linkedRelations || !state.linkedObjects){
    //   loadLinkedItem()
    //   return;
    // }
    // if(
    //   state.linkedRelations.every(el=>relations.includes(el)) && 
    //   state.linkedRelations.length===relations.length && 
    //   state.linkedObjects.every(el=>linkableObjects.includes(el)) && 
    //   state.linkedObjects.length===linkableObjects.length 
    // ){
    //   return;
    // }
    loadLinkedItem()
  // eslint-disable-next-line
  },[])
  // },[linkableObjects,relations])
  
  const onRemoveLink = el => {
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=${el.relationship}&object_id=${el.id}`
      )
      .then(response=>{
        if(state.linkedItemData){
          dispatch({
            type:'set_linked_item',
            linkedItemData:state.linkedItemData.filter(d=>d.id!==el.id || d.relationship!==el.relationship),
          })
        }
        if(el.relationship==='REPLACED_BY'){
          profileDispatch && profileDispatch({type:'set_basic_data',basicData:{...object,replace_date:undefined}})
        }
        dispatch({type:'set_link_updated',linkUpdated:true})
        setAlertMessage("Item successfully unlinked")
        setAlertOpen(true)
        setAlertType('info')
      })
      .catch(error=>{
        console.log(error)
        setAlertMessage("Error occurred deleting the link")
        setAlertOpen(true)
        setAlertType('error')
      })
  }

  const generateListItem = el => {

    let tailObject = (
      <div>
        <IconButton 
          data-test-classname="link-modal-list-item-clear"
          data-test-id={`link-modal-list-item-clear-${getDispFields(el,'dispTitle').toLowerCase().replace(/\s/g,'-')}`} 
          style={{padding:6}}
          onClick={(event)=>{event.stopPropagation();onRemoveLink(el)}}
        >
          {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
        </IconButton>
      </div>
    )

    let label = el.object_type_txt;
    if(el.object)label = el.object.name;
    if(!label)label = el.type;
    
    let bodyObject;
    return (
      <SimpleResultItem
        title={getDispFields(el,'dispTitle')}
        titleColour={theme.palette.primaryText.main}
        label={label}
        item={el}
        subTitle={getDispFields(el,'dispSubtitle')}
        showUnderline={true}
        onClick={() => onClickResultItem({id:el.id,label:el.object_type_txt,item:el,history})}
        hideRight
        tailObject={tailObject}
        bodyObject={bodyObject}
      />
    )
  }

  const generateList = list => {
    if(linkableObjects.length===1 && ['COLLECTION_INSTANCE','TERM'].includes(linkableObjects[0]) && relations.length===1){
      let parentMap = {};
      list.forEach(l=>{
        if(!parentMap[l.collection_txt])parentMap[l.collection_txt] = {name: l.collection_txt, items: [] }
        parentMap[l.collection_txt].items.push(l)
      });
      return (
        <div>
          {
            Object.keys(parentMap).map(k=>(
              <div style={{marginBottom:16}}>
                <Typography className={classes.groupHeader}>{parentMap[k].name}</Typography>
                {
                  parentMap[k].items.map(el=>generateListItem(el))
                }
              </div>
            ))
          }
        </div>
      )
    }
    if(relations.length===1){
      return list.map(el=>generateListItem(el))
    }
    
    let parentMap = {};
    list.forEach(l=>{
      if(!parentMap[l.relationship])parentMap[l.relationship] = {
        name: mapRelationshipName(l.relationship, object), 
        items: [] 
      }
      parentMap[l.relationship].items.push(l)
    });

    return (
      <div>
        {
          Object.keys(parentMap).map(k=>(
            <div style={{marginBottom:16}}>
              <Typography className={classes.groupHeader}>
                {
                  parentMap[k].name==='Replaced By'?
                  `Replaced on ${moment(object.replace_date).format('YYYY-MM-DD')}`:
                  parentMap[k].name
                }
              </Typography>
              {
                parentMap[k].items.map(el=>generateListItem(el))
              }
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div style={{flexGrow:1,marginBottom:24}}>
      <Typography style={{color:theme.palette.primary.main, fontSize:12, letterSpacing:2,paddingBottom:12,position:'sticky',top:50,background:theme.palette.background.main}}>
        LINKED
      </Typography>
      {
        state.linkedItemLoading && 
        <CircularProgress color='secondary'/>
      }
      {
        state.linkedItemError && <Typography>Error occurred loading items</Typography>
      }
      {
        state.linkedItemData && state.linkedItemData.length===0 && <Typography>No item added</Typography>
      }
      {
        state.linkedItemData && state.linkedItemData.length>0 && generateList(state.linkedItemData)
      }
    </div>
  )

}

LinkedList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history:PropTypes.object.isRequired,
  object:PropTypes.object.isRequired,
  relations:PropTypes.array.isRequired,
  linkableObjects:PropTypes.array.isRequired,
  state:PropTypes.object.isRequired,
  dispatch:PropTypes.func.isRequired,
  setAlertOpen: PropTypes.func, 
  setAlertMessage: PropTypes.func,
  setAlertType: PropTypes.func
}


export default withTheme()(withStyles(styles)(LinkedList));