import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Drawer, IconButton, Button, Typography } from '@material-ui/core';
import { getIconComponent, onClickResultItem, } from '../../../../utilities';
import MiniProfile from '../../../../containers/MiniProfile/MiniProfile';
import ListLoader from './ListLoader';
import ProfileSideBar from '../../../UI/ProfileSideBar/ProfileSideBar';
import DrawerDragTrigger from '../../../UI/DrawerDragTigger/DrawerDragTrigger';

const styles = theme => ({
  
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main
  },
  customScroll:{
    ...theme.components.hideScroll
  }
})


function DetailDrawer(props) {
  const {
    classes,
    theme,
    history,
    drawerOpen,
    setDrawerOpen,
    selectedItem,
    onItemClick,
  } = props;

  const [upstreamData, setUpstreamData] = useState({})
  const [upstreamRefData, setUpstreamRefData] = useState({})
  const [downstreamData, setDownstreamData] = useState({})
  const [downstreamRefData, setDownstreamRefData] = useState({})
  const [navHistory, setNavHistory] = useState([])


  let initialWidth = 600;
  try{
    if(localStorage.getItem('sourceMapDrawerWidth')){
      initialWidth = Number(localStorage.getItem('sourceMapDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  const [paperWidth, setPaperWidth] = useState(initialWidth)

  const setNavigationHistory = (item) => {
    if(item){
      setNavHistory([...navHistory,item])
    }
  }

  useEffect(()=>{
    setUpstreamData({})
    setDownstreamData({})
    if(selectedItem){
      setNavigationHistory(selectedItem)
    }
    // eslint-disable-next-line
  },[selectedItem])

  useEffect(()=>{
    if(!drawerOpen){
      setNavHistory([])
    }
  },[drawerOpen])


  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])
  
  return (
    <Drawer key={selectedItem?.id} anchor="right" open={drawerOpen} PaperProps={{style:{width:paperWidth,minWidth:500}}} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      { 
        selectedItem && 
        <>
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
              setPaperWidth(paperWidth+diff)
              localStorage.setItem('sourceMapDrawerWidth',paperWidth+diff)}}
          />
          <div style={{display:'flex',alignItems:'flex-start',paddingLeft:24,paddingTop:16,paddingBottom:16}}>
            <Button 
              color='primary' 
              variant='outlined'
              style={{marginRight:12}}
              disabled={navHistory.length<=1}
              onClick={()=>{
                onItemClick(navHistory[navHistory.length-2])
                setNavHistory(navHistory.slice(0,navHistory.length-2))
              }}
            >
              BACK
            </Button>
            <Button 
              color='primary' 
              variant='contained'
              style={{marginRight:12}}
              onClick={()=>onClickResultItem({newWindow:true,item:selectedItem.data.obj,label:selectedItem.data.obj.type || selectedItem.data.obj.object_type_txt, id:selectedItem.data.obj.id })}
            >
              OPEN
            </Button>
            {
              // lineageObjectsMap[mapSearchObjectName(selectedItem.data?.obj?.object_type_txt,selectedItem.data?.obj?.code_type_txt)] && !['COLLECTION','COLLECTION_INSTANCE'].includes(selectedItem.data?.obj?.object_type_txt) && isLinkable && selectedItem.data?.obj?.active_txt==='YES' && 
              // <Button 
              //   color='primary' 
              //   variant='outlined'
              //   style={{marginRight:12}}
              //   onClick={()=>sendMessage({open_mini_profile_link_modal:true})}
              // >
              //   EDIT LINEAGE
              // </Button>
            }
            <div style={{flexGrow:1}}></div>
            <IconButton 
              onClick={()=>setDrawerOpen(false)} 
              style={{width:28,height:28,padding:8}}
            >
              {
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
              }
            </IconButton>
          </div>
          <MiniProfile
            history={history}
            id={selectedItem.data?.obj?.id || selectedItem.id}
            key={selectedItem.id}
            generateCustomBody={({scrollYOffset, scrollContainerRef})=>(
                <>
                  <ProfileSideBar
                    tabOptions={[]}
                    history={history}
                    state={{}}
                    dispatch={()=>{}}
                    mapping={['description_wide']}
                    data={{...selectedItem.data.obj, object:{name:selectedItem.data.obj.object_type_txt}}}
                    alwaysOpenNewTab={true}
                    defaultExpanded={false}
                  />      
                  <Typography style={{fontSize:16,marginBottom:24,fontWeight:700}}>Select an asset to navigate it on the map</Typography>
                  <ListLoader
                    data={upstreamData}
                    setData={setUpstreamData}
                    refData={upstreamRefData}
                    setRefData={setUpstreamRefData}
                    object={selectedItem.data.obj}
                    direction='upstream'
                    onItemClick={onItemClick}
                    scrollContainerRef={scrollContainerRef}
                  />
                  <ListLoader
                    data={downstreamData}
                    setData={setDownstreamData}
                    refData={downstreamRefData}
                    setRefData={setDownstreamRefData}
                    object={selectedItem.data.obj}
                    direction='downstream'
                    onItemClick={onItemClick}
                    scrollContainerRef={scrollContainerRef}
                  />
                </>
              )
            }
          />
        </>
      }
      
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(DetailDrawer));
