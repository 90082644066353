import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography} from '@material-ui/core';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getDispFields, onClickResultItem } from '../../../utilities';

const styles = theme => ({
  tableRow:{
    display:'flex',
    alignItems:'center',
    marginLeft:16
  },
  itemRow:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    paddingLeft:16,
    paddingBottom:16,
    paddingTop:16,
    fontSize:12,
    color:theme.palette.primaryText.main,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  hoverable:{
    cursor:"pointer",
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main
  },
  tooltip:{
    fontSize:13.75
  },
})

function ConfirmChanges(props) {

  const {
    theme,
    classes,
    state,
  } = props;

  const getChangeValues = (change) => {
    if(change.field==='DESCRIPTION')return change.values[0].replace(/^(<br>|\s)*/,'').replace(/(<br>|\s)*$/,'');
    if(['OWNED_BY','STEWARDED_BY'].includes(change.field))return change.values.map(el=>state.userMap[el].name).join(", ");
    if(change.field==='VERIFIED_BY' || change.field==='NOT_VERIFIED_BY')return change.values.map(el=>state.verifiedList.find(v=>v.id===el).name).join(", ");
    if(change.field==='MEMBER_OF' && change.collection_name==='channel')return change.values.map(el=>state.channelList.find(c=>c.id===el).name).join(", ");
    if(change.field==='MEMBER_OF' && change.collection_name==='access role')return change.values.map(el=>state.accessRoleList.find(c=>c.id===el).name).join(", ");
    if(change.field==='MEMBER_OF' && change.id==='domain')return change.values.map(el=>state.domainList.find(c=>c.id===el).name).join(", ");
    if(change.field==='CLASSIFIED_BY')return change.operation==='CLEAR'?'':state.classificationList.find(el=>el.id===change.values[0]).name;
    if(change.field==='MEMBER_OF' && change.id==='category')return change.values.map(el=>state.categoryList.find(c=>c.id===el).name).join(", ");
  }

  const getOperation = op => {
    if(op==='ADD_TO_EXISTING')return 'Add to existing';
    if(op==='REPLACE')return 'Replace';
    if(op==='CLEAR')return 'Clear'
  }

  const getChangeName = change => {
    if(change.field==='DESCRIPTION')return 'Description'
    if(change.field==='OWNED_BY')return 'Owner'
    if(change.field==='MEMBER_OF' && change.id==='domain')return 'Domain'
    if(change.field==='MEMBER_OF' && change.collection_name==='channel')return 'Channel'
    if(change.field==='MEMBER_OF' && change.collection_name==='access role')return 'Access Role'
    if(change.field==='MEMBER_OF' && change.id==='category')return 'Category'
    if(change.field==='STEWARDED_BY')return 'Steward(s)'
    if(change.field==='VERIFIED_BY')return 'Verified use case(s)';
    if(change.field==='NOT_VERIFIED_BY')return 'Not approved for use';
    if(change.field==='CLASSIFIED_BY')return 'Classification'
  }

  const getCollectionName = (id) => {
    return state.properties.find(el=>el.id===id).name_txt
  }

  const getInstanceNames = (collectionId, instanceIds) => {
    return state.collectionInstanceMap[collectionId].items.filter(el=>instanceIds.includes(el.id)).map(el=>el.name).join(', ')
  }

  return (
    <div className={classes.root}>
      <div>
        <Typography style={{color:theme.palette.header.main,fontSize:20,marginBottom:4}}>CONFIRM CHANGE(S)</Typography>
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>The following changes will be made to all item(s) below</Typography>
        <div className={classes.tableRow} style={{marginBottom:12}}>
          <div className={classes.tableTitle} style={{width:180,flexShrink:0,flexGrow:0}}>
            PROPERTY
          </div>
          <div className={classes.tableTitle}  style={{width:300,flexBasis:300,flexShrink:1,flexGrow:0,minWidth:140}}>
            ACTION
          </div>
          <div className={classes.tableTitle}  style={{flex:'1 1 500px'}}>
            VALUE
          </div>
        </div>
        {
          state.changedFields.filter(el=>el.values.length>0 || (el.operation==='CLEAR' && el.field!=='NOT_VERIFIED_BY')).map(el=>(
            <div className={classes.itemRow} data-test-classname="change-item" style={{height:'max-content',paddingTop:8,paddingBottom:8}} >
              <div data-test-classname="change-item-name" style={{width:180,flexShrink:0,flexGrow:0,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                {el.collectionId?getCollectionName(el.collectionId):getChangeName(el)}
              </div>
              <div style={{width:300,flexBasis:300,flexShrink:1,flexGrow:0,minWidth:140}} data-test-classname="change-item-operation">
                {getOperation(el.operation)}
              </div>
              <div style={{flex:'1 1 500px', wordBreak:'break-word' }} data-test-classname="change-item-value">
                {el.collectionId?getInstanceNames(el.collectionId,el.values):getChangeValues(el)}
              </div>
            </div>
          ))
        }
      </div>
      <div style={{marginTop:48}}>
        <Typography style={{color:theme.palette.header.main,fontSize:20,marginBottom:4}}>{state.itemList && state.itemList.filter(el=>el.selected===true).length} ITEM(S) TO UPDATE</Typography>
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>Change above will be applied to the following item(s)</Typography>
        <div className={classes.tableRow}  style={{marginBottom:12}}>
          <div className={classes.tableTitle} style={{width:180,flexShrink:0,flexGrow:0}}>
            TYPE
          </div>
          <div className={classes.tableTitle}  style={{width:300,flexBasis:300,flexShrink:1,flexGrow:0,minWidth:140}}>
            NAME
          </div>
          <div className={classes.tableTitle}  style={{flex:'1 1 500px'}}>
            LOCATION
          </div>
        </div>
        {
          state.itemList && state.itemList.filter(el=>el.selected===true).map(el=>(
            <div 
              className={classes.itemRow + ' ' + classes.hoverable} 
              data-test-classname="change-item"
              onClick={()=>{
                onClickResultItem({item:el,id:el.id,label:el.labels,newWindow:true})
              }}
            >
              <div style={{width:180,flexShrink:0,flexGrow:0,}} data-test-classname="change-item-type">
                {el.labels.replace(/_/g,' ')}
              </div>
              <div style={{width:284,paddingRight:16,wordBreak:'break-word',flexBasis:284,flexShrink:1,minWidth:140,flexGrow:0}} data-test-classname="change-item-name">
                {el.name}
              </div>
              <KTooltip title={getDispFields(el, 'dispSubtitle')} placement={'bottom-start'}>
                <div style={{flex:'1 1 500px',wordBreak:'break-word',}} data-test-classname="change-item-location">
                  <span>{getDispFields(el, 'dispSubtitle')}</span>
                </div>
              </KTooltip>
            </div>
          ))
        }
      </div>
    </div>
  )
}

ConfirmChanges.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ConfirmChanges));