import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Typography, withStyles, Button } from '@material-ui/core';
import SearchBar from '../../SearchBar/SearchBar'
import { formatNumber, getIconComponent } from '../../../../utilities'
import theme from '../../../../theme';
import VerticalTabBar from '../../../UI/VerticalTabBar/VerticalTabBar';
import FilterList from '../FilterList/FilterList'
import ResultList from '../ResultList/ResultList'
import PageController from '../PageController/PageController'
// import {useDispatch, useStore} from 'react-redux'
// import * as actions from '../../../../store/actions/actionTypes';
import { globalListenerRef } from '../../../../GlobalListenerRef'
import { getContentContainerStyle } from '../../../UI/ProfileLayoutNew/layoutUtils';
import SaveFilterModal from '../SaveFilterModal/SaveFilterModal';
import axiosCerebrum from '../../../../axios-cerebrum';
import { generateUrl, getDefaultColumn, getSearchFilterList } from '../Utils/Utils';
import useAlert from '../../../../hooks/useAlert';
// import RecommendList from '../../Landing/RecommendList/RecommendList';

const styles = theme => ({
  root:{
    // margin:'0px auto 40px',
    marginBottom:40,
    paddingTop:32,
    minWidth:800,
    boxSizing:'border-box'
  },
  noResultText:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    marginBottom:40,
    marginTop:40
  },
  header:{
    display:'flex',
    alignItems:'center',
    paddingBottom:4,
    marginBottom:16,
    paddingTop:8,
    position:'sticky',
    background:theme.palette.background.main,
    zIndex:1200,
    top:0,
  },
  searchFilterContainer:{
    display:'flex',
    flexWrap:'wrap',
    paddingBottom:8,
    paddingTop:6
  },
  searchBarWrapper:{
    position:'sticky',
    top:0,
    zIndex:1280,
    // width:400,
    maxWidth:'100%',
    paddingBottom:4,
    paddingTop:12,
    background:theme.palette.background.main,
    transition:'width .2s ease'
  },
  icon:{
    width:40,
    height:40,
    marginRight:16,
    marginLeft:-56,
  },
  headerText:{
    fontSize:34,
    // flexGrow:1,
    color:theme.palette.header.main
  },
  '@media (max-width: 1350px)': {
    icon:{
      display:'none'
    }
  },
  hideScroll:{
    ...theme.components.hideScroll
  }
});



const MainSearchBody = props => {

  const {
    classes,
    state,
    sessionData,
    dispatch,
    isNotSearchPage,
    onChangeTab, 
    onSearch,
    loadSearchResults,
    onChangePage,
    onUpdateFilter,
    onResetAllFilters,
    onUpdateSort,
    history,
    tabGrouping,
    getDefaultParams,
    hiddenComponents,
    addtioonalComponents,
    removeContainerStyle,
    customHeaderFormatter,
    resultItemVariant,
    alwaysOpenNewTab,
    isCreateFilter,
    indexName,
    customEmptyMsg,
    forceGlobalFilterStr,
    forceOnItemClick
  } = props;

  // const reduxDispatch = useDispatch()
  const [rootStyle, setRootStyle] = useState(getContentContainerStyle())
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false)
  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`basic-search-main-body`,
    isCancelledRef
  })

  useEffect(()=>{
    if(state.selectedObjectType){
      let group = tabGrouping.find(el=>el.tabs.includes(state.selectedObjectType))
      if(group){
        window.postMessage({verticalTabBarExpand:group.name},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      }
    }
  // eslint-disable-next-line
  },[state.selectedObjectType, state.searchTabs])


  useEffect(()=>{
    setRootStyle(getContentContainerStyle()); 
    window.removeEventListener('resize',globalListenerRef.serachMainBodyResieListener)
    globalListenerRef.serachMainBodyResieListener = () => {
      setRootStyle(getContentContainerStyle());
    }
    window.addEventListener('resize',globalListenerRef.serachMainBodyResieListener)

    return ()=>{
      window.removeEventListener('resize',globalListenerRef.serachMainBodyResieListener)
      isCancelledRef.current = true
    }
  },[])
  
  const getTabLabel = el => {
    let label = el.objectType;
    if(label==='ALL')label = 'all_search'
    if(el.isCollection || el.collectionType){
      switch(el.collectionType){
        case 'GLOSSARY':
          label = 'glossary'
          break;
        case 'LIST':
          label = 'list'
          break;
        case 'CHANGE':
          label = 'change'
          break;
        case 'COLLABORATION':
          label = 'channel'
          break;
        case 'KNOWLEDGE':
          label = 'note'
          break;
        default:
          label = 'collection'
      }
    }
    return label
  }

  const onSaveFilter = () => {
    let searchConfig = generateUrl({
      mainSearchQuery:state.mainSearchQuery,
      searchFilters: getSearchFilterList({filterStatus:state.filterStatus, negativeFilters:state.negativeFilters}),
      selectedObjectType:state.selectedObjectType,
      defaultColumns:getDefaultColumn({objectType:state.selectedObjectType,tabs:state.searchTabs}),
      selectedColumns:state.selectedColumns,
      searchMode:state.searchMode,
      andFilters:state.andFilters,
    });

    let rawPrams = loadSearchResults({isGenerateParamOnly:true})
    let solrQuery = {
      "q": searchConfig.query,
      "q_raw":rawPrams.q,
      "fq": searchConfig.presetFilter,
      "fq_raw":rawPrams.fq,
      "columns":searchConfig.presetColumn,
      "object_type":searchConfig.object,
      'and_filters':searchConfig.andFilters,
      'search_mode':searchConfig.searchMode
    }
    axiosCerebrum
      .put(
        `/api/filteredlists/${state.isSavedFilter.id}`,{
          // "name": state.isSavedFilter.name,
          // "description": state.isSavedFilter.description,
          // "public": permission==='public',
          "solr_query": solrQuery,
          // "filtered_list_type": "LIST"
        }
      )
      .then(response=>{
        sendAlert({type:'info',message:`Filter "${response.data.name}" successfully saved`})
        history.push('/filters')
      })
      .catch(error=>{
        console.log(error) 
        sendAlert({type:'error',message:`Error occurred saving filter`})
      })
  }
  
  const isTabsShown = state.searchTabs && state.searchTabs.length>0 && (!state.isSavedFilter || state.isSavedFilter.solr_query?.object_type==='ALL') && !hiddenComponents.includes('tab') ;

  const listMinHeight = removeContainerStyle?
                          isNaN(state.resultsTotal)?
                            undefined:
                            Math.min(10,state.resultsTotal)*96 + (state.resultsTotal<10?200:0) :
                          1550
               
  const isEmptyPage = !state.searchTabs && !state.searchTabsLoading && !state.searchTabsError;                        
  
  return (
    <div className={classes.root} style={removeContainerStyle?{minWidth:0,padding:0,margin:0}:rootStyle}>
      {
        !hiddenComponents.includes('header') && 
        <div className={classes.header}>
          <div className={classes.icon}>
            {getIconComponent({label:'search',size:40,colour:theme.palette.header.main})}
          </div>
          <Typography className={classes.headerText}>
            {
              isCreateFilter?
              'Create Filter':
              (state.isSavedFilter?.name || 'Search')
            }
          </Typography>
          {
            state.isSavedFilter && state.isSavedFilter.created_by?.id===sessionData.id && 
            <Button
              color='primary' 
              variant='outlined' 
              style={{marginLeft:24,padding:'2px 12px'}}
              disabled={!state.isFilterOrColumnChanged}
              onClick={()=>{
                onSaveFilter()
              }}
            >
              SAVE
            </Button>
          }
          <Button 
            color='primary' 
            variant='outlined' 
            style={{marginLeft:24,padding:'2px 12px'}}
            onClick={()=>{
              setSaveFilterModalOpen(true)
            }}
            disabled={isEmptyPage}
          >
            {isCreateFilter?'CREATE FILTER':'SAVE AS'}
          </Button>
        </div>
      }
      { 
        !hiddenComponents.includes('searchBar') &&
        <div className={classes.searchBarWrapper}>
          <SearchBar indexName={indexName} state={state} dispatch={dispatch} onSearch={onSearch} placeholder={isCreateFilter?'Search':''} hideLabel={!isCreateFilter || !isEmptyPage}/>
        </div>
      }
      {
        isCreateFilter && !state.searchTabs && !state.searchTabsLoading && !state.searchTabsError && 
        <Typography style={{fontSize:16,marginTop:24,paddingLeft:16}}>Add search criteria to see your filter results</Typography>
      }
      <div className={classes.searchFilterContainer}>
        {
          state.searchTabs && state.searchTabs.length>0 && !hiddenComponents.includes('filter') &&
          <FilterList
            state={state}
            dispatch={dispatch}
            onUpdateFilter={onUpdateFilter}
            onResetAllFilters={onResetAllFilters}
            rootWidth={rootStyle.width-280-rootStyle.paddingLeft*2}
            getDefaultParams={getDefaultParams}
            hiddenComponents={hiddenComponents}
            addtioonalComponents={addtioonalComponents}
            indexName={indexName}
            forceGlobalFilterStr={forceGlobalFilterStr}
          />
        }
      </div>
      
      {
        isTabsShown && 
        <div className={classes.hideScroll} style={{marginTop:4,float:'left',height:'100vh',minHeight:listMinHeight,overflow:'auto'}}>
          <VerticalTabBar
            tabOptions={state.searchTabs.map(el=>el.objectType)}
            renderOptions={value=>`${state.searchTabs.find(el=>el.objectType===value).displayName} (${formatNumber(state.searchTabs.find(el=>el.objectType===value).count)})`}
            tabState={state.searchTabs.map(el=>el.objectType).indexOf(state.selectedObjectType)}
            tabIcons={state.searchTabs.map(el=>getTabLabel(el))}
            setTabState={value => {
              onChangeTab(state.searchTabs[value].objectType)
            }}
            grouping={tabGrouping}
            alwaysShowFullTab
            highlightOnSelected
            width={220}
            hideGroupTitleNames={['ALL']}
          />
          <div style={{height:24}}></div>
        </div>
      }
      <div 
        style={{
          flexGrow:1,
          minHeight:listMinHeight,
          marginLeft:isTabsShown?280:0,
          flexShrink:1
        }}
      >
        {
          ((state.searchTabs && state.searchTabs.length===0)) &&
          <div>
            <Typography className={classes.noResultText}>No results found for "{state.searchValue}"</Typography>
            {
              // <RecommendList state={state} dispatch={dispatch} history={history} onSearch={onSearch} variant={'result_suggestion'}/>
            }
          </div>
          
        }
        {
          (state.searchTabsError || (state.searchResults[state.pageNum] && state.searchResults[state.pageNum].error))  &&
          <Typography style={{marginTop:32,color:theme.palette.primaryText.main}}>Error occurred loading results</Typography>
        }
        {
          (state.searchTabsLoading || (state.searchResults[state.pageNum] && state.searchResults[state.pageNum].loading)) &&
          <div style={{width:"100%",textAlign:'center',marginTop:24}}>
            <CircularProgress color='secondary'/>
          </div>
        }
        {
          state.searchResults[state.pageNum] && state.searchResults[state.pageNum].data && state.searchTabs && 
          <div>
            <ResultList
              state={state}
              dispatch={dispatch}
              isNotSearchPage={isNotSearchPage}
              history={history}
              onUpdateSort={onUpdateSort}
              loadSearchResults={loadSearchResults}
              tabGrouping={tabGrouping}
              hiddenComponents={hiddenComponents}
              customHeaderFormatter={customHeaderFormatter}
              resultItemVariant={resultItemVariant}
              alwaysOpenNewTab={alwaysOpenNewTab}
              indexName={indexName}
              customEmptyMsg={customEmptyMsg}
              forceOnItemClick={forceOnItemClick}
            />
            {
              state.resultsTotal>0 &&
              <PageController
                state={state}
                dispatch={dispatch}
                onChangePage={onChangePage}
                isNotSearchPage={isNotSearchPage}
              />
            }
          </div>
        }
      </div>
      <SaveFilterModal
        state={state}
        modalOpen={saveFilterModalOpen}
        setModalOpen={setSaveFilterModalOpen}
        isCreateFilter={isCreateFilter}
        history={history}
        loadSearchResults={loadSearchResults}
      />
    </div>
  )
}

export default withStyles(styles)(MainSearchBody);
