import React, {useEffect, useState} from 'react';
import { withTheme,withStyles } from '@material-ui/core/styles';
import { Button, } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { formatNumber } from '../../../utilities'

const styles = theme => ({
  button:{
    margin: '8px 0px 8px 16px',
    height: 40,
    minWidth:110,
    flexShrink:0,
    paddingLeft:'8px',
    paddingRight:'8px',
    display:'flex',
    alignItems:'center',
  },
  unFollowedButton:{
    background:theme.palette.unfollowedButtonBackground.main,
    color:theme.palette.unfollowedButtonColor.main,
    '&:hover':{
      background:theme.palette.unfollowedButtonHover.main,
    },
  },
  followedHoveredButton:{
    background:theme.palette.secondary.main,
    border:`1px solid ${theme.palette.secondary.main}`,
    color:'#fff',
    '&:hover':{
      background:theme.palette.secondary.main,
    },
  },
  followedButton:{
    border:`1px solid ${theme.palette.primary.main}`,
    color:theme.palette.primary.main
  },
})


function FollowButton(props) {
  const {
    classes,
    onClick,
    following,
    object,
    size,
    disableCount,
    disableMargin
  } = props;


  const [hovered, setHovered] = useState()
  const [followCount, setFollowCount] = useState()

  const loadFollowCount = () => {
    axiosCerebrum
      .get(`/api/follows`,{params:{object_id:object.id,per_page:0}})
      .then(response=>{
        setFollowCount(response.data.total)
      })
      .catch(error=>{
        console.log(error)
        setFollowCount()
      })
  }

  useEffect(()=>{
    if(!disableCount)loadFollowCount()
  // eslint-disable-next-line
  },[following])

  const getFollowButtonClassName = (isHovered, isFollowed) => {
    if(!isFollowed)return classes.unFollowedButton;
    if(isFollowed && isHovered)return classes.followedHoveredButton
    if(isFollowed && !isHovered)return classes.followedButton
  }


  return (
    <Button 
      data-test-id="follow-button"
      onMouseLeave={()=>setHovered(false)} 
      onMouseOver={()=>{setHovered(true)}} 
      onClick={onClick} 
      className={getFollowButtonClassName(hovered,following) + ' ' + classes.button}  
      style={{minWidth:disableMargin?80:following?130:undefined,height:size==='small'?32:undefined,margin:disableMargin?0:undefined,padding:disableMargin?'4px 8px':undefined}}
    >
      {following?hovered?'UNFOLLOW':'FOLLOWING':'FOLLOW'}{isNaN(followCount) || disableCount?'':` (${formatNumber(followCount)})`}
    </Button>
  )
}

export default withTheme()(withStyles(styles)(FollowButton));