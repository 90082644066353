import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, CircularProgress, Typography, Radio} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { collectionIds, getIconComponent, mapObjectName, tabGroup, titleCaseObjectName } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';
import { defaultFields, defaultProperties } from '../../../containers/BulkEdit/BulkEdit';

const styles = theme => ({
  section:{
    marginBottom:24,
  },
  sectionTitle:{
    fontSize:12,
    letterSpacing:1.5,
    marginBottom:8,
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    paddingLeft:16,
    overflow:'hidden',
    height:48,
    '&:hover':{
      backgroundColor:theme.palette.hovered.main,
      cursor:'pointer',
    }
  },
  itemText:{
    fontSize:16,
    marginLeft:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  warningChip:{
    display:'flex',
    alignItems:'center',
    marginLeft:100, 
    backgroundColor:theme.palette.secondary.main,
    height:24,
    borderRadius:16,
    paddingLeft:8,
    paddingRight:16,     
  },
  warnText:{
    fontSize:13.75,
    color:'#fff',
    marginLeft:12
  }
})

function ChooseCart(props) {

  const {
    theme,
    classes,
    dispatch,
  } = props;

  const [itemCount, setItemCount] = useState({});
  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const loadItemCount = () => {
    setItemCount({loading:true})
    axiosCerebrum
      .get(
        `/api/me/cart/stats`
      )
      .then(response=>{
        let counts = response.data.counts.map(el=>({
          objectType:Object.keys(el)[0],
          count:el[Object.keys(el)[0]]
        }))
        setItemCount({data:counts})
      })
      .catch(error=>{
        console.log(error)
        setItemCount({error:true})
      })
  }

  useEffect(()=>{
    if(!itemCount.data && !itemCount.loading)loadItemCount()
  // eslint-disable-next-line
  },[])

  const processDQDefaultOptions = () => {
    let obj = {
      object_type_txt:'COLLECTION_INSTANCE',
      collection_type_txt:'PLATFORM',
      name_txt:'DATA QUALITY DIMENSION',
      id:collectionIds.dqDimensions,
    }
    dispatch({type:'set_properties',properties:[obj]})

    let field = {
      field:'MEMBER_OF',
      collectionId: collectionIds.dqDimensions,
      values:[],
      operation:'REPLACE'
    };
    dispatch({
      type:'set_changed_fields',
      changedFields: [field]
    })
  }

  const onSelectItem = (item) => {
    dispatch({type:'set_selected_item_type', selectedItemType:item.objectType})
    if(item.objectType==='DATA_QUALITY_TEST')processDQDefaultOptions();
    else{
      dispatch({type:'set_properties',properties:defaultProperties})
      dispatch({type:'set_changed_fields',changedFields: defaultFields})
    }
    dispatch({type:'set_active_step',activeStep:1})
    axiosCerebrum
      .get(
        `/api/me/cart`,{
          params:{
            object_type:item.objectType,
            page:1,
            per_page:1000
          }
        }
      )
      .then(response=>{
        dispatch({
          type:'set_item_list',itemList:response.data.items.map(el=>({
            ...el,
            labels:el.object.name,
            selected:true
          }))
        })
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:"Error occurred selecting items, please try again",type:'error'})
        dispatch({type:'set_selected_item_type'})
        dispatch({type:'set_active_step',activeStep:0})
      })
  }

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,marginBottom:24}}>Select an item type from your cart to update</Typography>
      {
        itemCount.loading && <CircularProgress color='secondary'/>
      }
      {
        itemCount.error && <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading items.</Typography>
      }
      {
        itemCount.data?.length===0 && <Typography style={{color:theme.palette.primaryText.main}}>No items in cart.</Typography>
      }
      {
        itemCount.data && tabGroup.filter(g=>g.tabs.some(t=>itemCount.data.map(el=>el.objectType).includes(t))).map((group,i)=>(
          <div className={classes.section}>
            <Typography className={classes.sectionTitle}>{group.name}</Typography>
            {
              itemCount.data.filter(el=>group.tabs.includes(el.objectType)).map((item,i)=>(
                <div className={classes.listItem} onClick={()=>onSelectItem(item)}>
                  <Radio
                    color='primary'
                    style={{width:24,height:24,padding:0}}
                  />
                  {/* {getIconComponent({label:item.objectType, size:24, colour:theme.palette.primaryText.light})} */}
                  <Typography className={classes.itemText}>{titleCaseObjectName(mapObjectName(item.objectType))}s ({item.count})</Typography>
                  {
                    item.count>1000 && 
                    <div className={classes.warningChip}>
                      {getIconComponent({label:'warning', size:20, colour:'#fff'})}
                      <Typography className={classes.warnText}>Max 1000 per update. First 1000 items selected</Typography>
                    </div>
                  }
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}

ChooseCart.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ChooseCart));