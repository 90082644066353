import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { tileStyles } from './utils/styles';
import CodeDisplay from '../../CodeDisplay/CodeDisplay';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    // border:`1px solid ${theme.palette.listItemDivider.main}`,
    padding:0
  },
})

function DescriptionManager(props) {

  const {
    classes,
    id,
    object,
    onAddTileData,
    tileData,
  } = props;



  const loadDefinitionTile = async (target = object) => {
    if(target.code && target.code.trim()!==''){
      onAddTileData(
        {
          "disp_body":{value:target.code},
          "disp_title":"DEFINITION",
          "disp_type":"text",
          "id":id
        }
      )
    }else{
      onAddTileData(
        {
          "disp_body":{value:''},
          "disp_title":"DEFINITION",
          "disp_type":"text",
          "id":id
        }
      )
    }
  }

  useEffect(()=>{
    if(!object)return;
    loadDefinitionTile()
  // eslint-disable-next-line
  },[object])

  if(!tileData)return <></>

  return (
    <div data-test-id="domain-data-tile" className={classes.root}>
      {
        tileData && tileData.disp_body && tileData.disp_body.value?
        <CodeDisplay code={tileData.disp_body.value}/>
        :
        <Typography style={{fontSize:13.75,marginLeft:8}}>No definition found</Typography>
      }
    </div>
  )
}

DescriptionManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DescriptionManager));