import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, ClickAwayListener, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { getLabelPlural} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import { Editor as MDEditor } from '@toast-ui/react-editor';
import { getAllRules } from '../../InteractiveInput/Templates';
import { getPutPayload } from '../../UpdateInput/utils';
import { GenAILoadingIcon, explainationText, hasLLMKeySet, overwriteTextShort, triggerText } from '../../UpdateInput/genAIComponents';
import { generateDescription } from '../../UpdateInput/genAIUtils';
import useAlert from '../../../../hooks/useAlert';
import InteractiveViewer from '../../InteractiveInput/InteractiveViewer';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    // border:`1px solid ${theme.palette.listItemDivider.main}`,
    padding:0
  },
  genAIButton:{
    ...theme.components.genAIButton
  },
  overwriteGenAIButton:{
    ...theme.components.overwriteGenAIButton
  },
})

function DescriptionManager(props) {

  const {
    classes,
    theme,
    id,
    object,
    onAddTileData,
    tileData,
    isEditable
  } = props;

  const MDRef = useRef()

  const [editing, setEditing] = useState(false)
  const [isAIGenerated, setIsAIGenerated] = useState(false)
  const [AIGenerating, setAIGenerating] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])
  
  const loadDescriptionTile = async (target = object) => {
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(target.object.name)}/${target.id}`,
      )
      .then(response=>{
        let description = response.data.description
        if(description && description.trim()!==''){
          onAddTileData(
            {
              "disp_body":{value:description },
              "disp_title":"DESCRIPTION",
              "disp_type":"text",
              "id":id
            }
          )
        }else{
          onAddTileData(
            {
              "disp_body":{value:''},
              "disp_title":"DESCRIPTION",
              "disp_type":"text",
              "id":id
            }
          )
        }
      })
  }

  useEffect(()=>{
    if(!object || tileData)return;
    loadDescriptionTile()
  // eslint-disable-next-line
  },[object])

  const onSave = () => {
    let newDescription = MDRef.current.getInstance().getMarkdown();
    axiosCerebrum 
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}`,
        getPutPayload({property:'description',value:newDescription.trim(),data:object})
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":{value:response.data.description},
            "disp_title":"DESCRIPTION",
            "disp_type":"text",
            "id":id
          }
        )
        setEditing(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred updating description, please try again', type: 'error'})
      })
  }

  if(!tileData)return <></>
  
  let text = tileData.disp_body.value;

  const AIButton = (
    isEditable && !AIGenerating && ['table','column','dataset_table','dataset_field','content','content_child'].includes(object.object?.name?.toLowerCase()) && !isAIGenerated && 
    hasLLMKeySet() && 
    (
      <div 
        className={text.trim()===''?classes.genAIButton:classes.overwriteGenAIButton}
        onClick={()=>{
          generateDescription({
            data:object,
            onLoading:()=>{
              setAIGenerating(true)
            },
            onError:(msg)=>{
              setAIGenerating(false)
              sendAlert({ 
                message: 'Error generating description, please try again', 
                type: 'error',
                timeout: 99999999
              })
            },
            onSuccess:(value)=>{
              setAIGenerating(false)
              setTimeout(()=>{
                MDRef.current.getInstance().setMarkdown(value)
              },200)
              setIsAIGenerated(true)
            }
          })
        }}
      >
        {text.trim()===''?triggerText:overwriteTextShort}
      </div>
    )
  )
  
  return (
    <div data-test-id="domain-data-tile" className={classes.root}>
      <ClickAwayListener 
        onClickAway={(event)=>{
          setEditing(false)
        }}
      >
        <div style={{width:'100%'}}>
          {
            editing?
            (
              AIGenerating?
              <GenAILoadingIcon/>:
              <div style={{width:'100%',overflow:'hidden'}}>
                <MDEditor
                  ref={MDRef}
                  key={tileData.disp_body.value}
                  height={Math.min(500,Math.max(200,(text||'').length/2.5))+'px'}
                  hideModeSwitch
                  // onChange={()=>setText(MDRef.current.getInstance().getMarkdown())}
                  initialValue={tileData.disp_body.value}
                  // onFocus={()=>{
                  //   MDRef.current.getInstance().moveCursorToEnd()
                  // }}
                  onLoad={()=>{
                    setTimeout(()=>{
                      MDRef.current?.getInstance().moveCursorToEnd()
                    },100)
                  }}
                  initialEditType="wysiwyg"
                  useCommandShortcut={true}
                  previewStyle="vertical"
                  widgetRules={getAllRules()}
                  theme={localStorage.hasOwnProperty('dark')?"dark":"white"}
                  usageStatistics={false}
                  toolbarItems={
                    [
                      ['heading', 'bold', 'italic', 'strike'],
                      ['hr', 'quote'],
                      ['ul', 'ol', 'indent', 'outdent'],
                      ['table','link'],
                      ['code', 'codeblock']
                    ]
                  }
                />
                {AIButton}
                <div style={{display:'flex',paddingLeft:24,marginTop:16}}>
                  <Button color='primary' variant='contained' onClick={onSave}>SAVE</Button>
                  <Button color='secondary' style={{marginLeft:16}} onClick={()=>{setEditing(false)}}>CANCEL</Button>
                </div>
              </div>
            )
            :
            <div ref={popperRef} style={{overflow:'hidden'}} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
              <InteractiveViewer
                key={tileData.disp_body.value}
                initialValue = {tileData.disp_body.value || 'No description provided' }
                id={'description-side-bar'}    
                alwaysShown={true}
                object={object}
                enableCustomContextMenu={true}
              />
              {/* <Typography style={{fontSize:13.75,wordBreak:'break-word',whiteSpace:'pre-wrap',background:'transparent',marginBottom:8}}>
                {tileData.disp_body.value}
              </Typography> */}
            </div>
          }
          {
            !editing && isAIGenerated && 
            <Typography style={{fontSize:12,marginTop:5,marginLeft:8,color:theme.palette.primaryText.light}}>
              {explainationText}
            </Typography>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

DescriptionManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DescriptionManager));