
import {checkIsDarkMode, palette} from '../../../theme'
import { toJpeg } from 'html-to-image';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import { thirdLevelObjects } from './LineageGraph/loadUtils';

export const lineageObjectsMap = {
  DATABASE: ['DATABASE', 'TOOL'],
  TABLE: ['TABLE','MACRO','PROCEDURE','DATASET','DATASET_TABLE','DATA_PIPELINE','FILE','ML_MODEL'],
  COLUMN: ['COLUMN','TABLE','DATASET_FIELD','DATA_PIPELINE','ML_MODEL','MACRO','PROCEDURE'],
  MACRO: ['TABLE','COLUMN','MACRO','PROCEDURE','CONTENT','DATASET','QUERY','DATASET_FIELD','DATASET_TABLE','DATA_PIPELINE','ML_MODEL'],
  PROCEDURE: ['TABLE','COLUMN','MACRO','PROCEDURE','CONTENT','DATASET','DATASET_TABLE','QUERY','DATASET_FIELD','DATA_PIPELINE','ML_MODEL'],
  QUERY:['TABLE','COLUMN','MACRO','PROCEDURE','DATASET','DATASET_TABLE','DATASET_FIELD','DATA_PIPELINE','FILE','ML_MODEL'],
  CONTENT:['TABLE','MACRO','PROCEDURE','CONTENT','CONTENT_CHILD','DATA_PIPELINE','DATASET_TABLE','ML_MODEL','DATASET'],
  REPORT:['TABLE','MACRO','PROCEDURE','CONTENT','CONTENT_CHILD','DATASET_TABLE','ML_MODEL','DATASET'],
  CONTENT_CHILD:['TABLE','COLUMN','MACRO','PROCEDURE','CONTENT','CONTENT_CHILD','DATASET_TABLE','DATASET_FIELD','ML_MODEL'],
  SHEET:['TABLE','COLUMN','MACRO','PROCEDURE','CONTENT','CONTENT_CHILD','DATASET_TABLE','DATASET_FIELD','ML_MODEL'],
  TOOL:['TOOL','DATABASE'],
  DATASET:['TABLE','MACRO','PROCEDURE','CONTENT','DATA_PIPELINE','ML_MODEL','DATASET'],
  DATASET_TABLE:['TABLE','MACRO','PROCEDURE','CONTENT_CHILD','DATASET_TABLE','DATA_PIPELINE','FILE','ML_MODEL'],
  DATASET_FIELD:['COLUMN','MACRO','PROCEDURE','CONTENT_CHILD','DATASET_FIELD','DATA_PIPELINE','ML_MODEL'],
  DATA_PIPELINE:['TABLE','COLUMN','MACRO','PROCEDURE','DATASET','DATASET_TABLE','DATASET_FIELD','DATA_PIPELINE','FILE','ML_MODEL','CONTENT'],
  FILE:['TABLE','COLUMN','MACRO','PROCEDURE','DATASET_TABLE','DATA_PIPELINE','FILE','ML_MODEL'],
  ML_MODEL:['TABLE','COLUMN','MACRO','PROCEDURE','DATASET','CONTENT','CONTENT_CHILD','DATASET_TABLE','DATASET_FIELD','DATA_PIPELINE','FILE'],
  COLLECTION:['COLLECTION','COLLECTION_INSTANCE'],
  COLLECTION_INSTANCE:['COLLECTION_INSTANCE','COLLECTION'],
}

export const getLineageRelation = (type, direction) => {
  if(['DATABASE','TOOL'].includes(type)){
    return direction==='upstream'?['VIEWS','SOURCE_FROM','REFERENCES_SHORTCUT']:['VIEWED_BY','SOURCE_OF','REFERENCED_BY_SHORTCUT']
  }
  return direction==='upstream'?['SOURCE_FROM','K_REFERENCES','REFERENCES','REFERENCES_SHORTCUT','SYNONYM_OF']:['SOURCE_OF','K_REFERENCED_BY','REFERENCED_BY','REFERENCED_BY_SHORTCUT','SYNONYMS']
}


export const graphPalette = {
  TABLE:'#3E79D1',
  COLUMN:'#3E79D1',
  SCHEMA:'#3E79D1',
  DATABASE:'#3E79D1',
  HOST:'#3E79D1',
  SOURCE:'#3E79D1',
  CONTENT:'#D2963E',
  REPORT:'#D2963E',
  CONTENT_APP:'#D2963E',
  CONTENT_CHILD:'#D2963E',
  WORKSPACE:'#D2963E',
  SHEET:'#D2963E',
  FILE:'#D2963E',
  ML_MODEL:'#D2963E',
  STORAGE:'#775DD0',
  TOOL:'#FF694B',
  DATA_PIPELINE:'#FF694B',
  MACRO:'#3E79D1',
  PROCEDURE:'#3E79D1',
  QUERY:'#3E79D1',
  COLLECTION:'#a912bd',
  COLLECTION_INSTANCE:'#E57373',
  GLOSSRY:'#86d2e3',
  DATASET_FIELD:'#00D46A',
  DATASET_TABLE:'#00D46A',
  DATASET:'#00D46A',
  UNKNOWN:palette.primaryText.light
}

export const getBackgroundColour = (objectType, isCheckThirdLevel) => {
  let fn = checkIsDarkMode()?darken:lighten;
  if(thirdLevelObjects.includes(objectType) && isCheckThirdLevel){
    return fn(graphPalette[objectType],checkIsDarkMode()?0.7:0.95)
  }else{
    return fn(graphPalette[objectType],checkIsDarkMode()?0.6:0.8)
  }

}

export const onDownloadMap = (fileName, onFinish) => {
  function downloadImage(dataUrl) {
    const a = document.createElement('a');
  
    a.setAttribute('download', `${fileName}.jpeg`);
    a.setAttribute('href', dataUrl);
    a.click();
    a.remove();
  }
  
  toJpeg(document.getElementsByClassName('react-flow')[0], {
    filter: (node) => {
      // we don't want to add the minimap and the controls to the image
      if (
        node?.classList?.contains('react-flow__minimap') ||
        node?.classList?.contains('react-flow__controls')
      ) {
        return false;
      }
      return true;
    },
    backgroundColor:palette.background.main,
  }).then(d=>{
    downloadImage(d);
    onFinish?.();
  });
}
