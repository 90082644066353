import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AddDetails from '../AddDetails/AddDetails';
import AddIdentifiers from '../Add Identifiers/AddIdentifiers';
import ConfirmProfile from '../ConfirmProfile/ConfirmProfile';

const styles = theme => ({
  root: {
   // marginTop: '2rem'
  }
})

const Body = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    activeStep,
    sessionData
  } = props;
  let bodyContent;


  switch (activeStep) {
    case 0:  //add user details
      bodyContent = (
        <AddDetails
          history={history}
          state = {state}
          dispatch = {dispatch}
          sessionData={sessionData}
        />
      )
      break;
    case 1: // add identifiers
      bodyContent = (
        <AddIdentifiers
        history={history}
        state = {state}
        dispatch = {dispatch}
        />
      )
      break;
      case 2: // confirm profile
      bodyContent = (
        <ConfirmProfile
        history={history}
        state = {state}
        dispatch = {dispatch}
        sessionData={sessionData}
        />
      )
      break;  
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  nodeKey: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
}

export default withStyles(styles)(Body);



