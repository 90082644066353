import React, { useEffect } from 'react';
import {withTheme, withStyles } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import PropTypes from 'prop-types';
import { mapObjectName } from '../../../utilities';
import LabeledSelector from '../LabeledSelector/LabeledSelector';


const styles = theme => ({
  
})


const Filter = props => {

  const {
    theme,
    // classes,
    // history,
    // object,
    selectedObjectType,
    // selectedRelation,
    state,
    dispatch
  } = props;

  const collections = state.filterCollections;
  const setCollections = value => dispatch({type:'set_filter_collections',filterCollections:value})
  const sources = state.filterSources;
  const setSources = value => dispatch({type:'set_filter_sources',filterSources:value})
  const issueSources = state.filterIssueSources;
  const setIssueSources = value => dispatch({type:'set_filter_issue_sources',filterIssueSources:value})
  const teams = state.filterTeams;
  const setTeams = value => dispatch({type:'set_filter_teams',filterTeams:value})
  const userTypes = state.filterUserTypes;
  const setUserTypes = value => dispatch({type:'set_filter_user_types',filterUserTypes:value})
  const databases = state.filterDatabases;
  const setDatabases = value => dispatch({type:'set_filter_databases',filterDatabases:value})
  const schemas = state.filterSchemas;
  const setSchemas = value => dispatch({type:'set_filter_schemas',filterSchemas:value})
  const tables = state.filterTables; 
  const setTables = value => dispatch({type:'set_filter_tables',filterTables:value})
  const locations = state.filterLocations;
  const setLocations = value => dispatch({type:'set_filter_locations',filterLocations:value})

  // const filterSearchValue = state.filterSearchValue;
  // const setFilterSearchValue = value => dispatch({type:'set_filter_search_value',filterSearchValue:value})

  const loadCollections = type => {
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION AND collection_type_srt:(DATA_GOVERNANCE OR DATA_MANAGEMENT)${type==='all'?'': ` AND collection_type_srt:${type}`}`,
              rows:50,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setCollections(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setCollections(prevData)
        })
    };
    load({})
  }

  const loadGlossary = () => {
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION AND collection_type_srt:GLOSSARY`,
              rows:50,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setCollections(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setCollections(prevData)
        })
    };
    load({})
  }

  const loadSources = () => {
    const load = ({prevData=[],page=1}) => {
      axiosSolr
        .get(
          `/api/sources`,{params:{
            per_page:50,
            page,
            internal_flag:false,
            sort:'ALPHABETICAL'
          }}
        )
        .then(response=>{
          let data = [...prevData, ...response.data.items]
          if(response.data.pages>response.data.page){
            load({prevData:data,page:response.data.page+1})
          }else{
            setSources(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setSources(prevData)
        })
    };
    load({})
  }  
  
  
  const loadIssueSources = () => {
    const load = () => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:ISSUE`,
              rows:0,
              facet:'on',
              'facet.field':'source_srt',
              'facet.mincount':1,
              'facet.limit':100
            }
          }
        )
        .then(response=>{
          if(response.data.facet_counts && response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.source_srt ){
            let data = response.data.facet_counts.facet_fields.source_srt.filter(el=>typeof(el)==='string')
            setIssueSources(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setIssueSources([])
        })
    };
    load({})
  }

  const loadTeams = () => {
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:TEAM`,
              rows:50,
              start:prevData.length
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setTeams(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setTeams(prevData)
        })
    };
    load({})
  }

  const loadUserTypes = () => {
    const load = () => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:USER`,
              rows:0,
              facet:'on',
              'facet.field':'account_type_srt',
              'facet.mincount':1,
              'facet.limit':100
            }
          }
        )
        .then(response=>{
          if(response.data.facet_counts && response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.account_type_srt ){
            let data = response.data.facet_counts.facet_fields.account_type_srt.filter(el=>typeof(el)==='string')
            setUserTypes(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setUserTypes([])
        })
    };
    load({})
  }


  const loadDatabases = sourceID => {
    if(sourceID==='all'){
      setDatabases([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:DATABASE${sourceID==='all'?'': ` AND source_id_srt:${sourceID}`}`,
              rows:50,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setDatabases(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setDatabases(prevData)
        })
    };
    load({})
  }

  const loadSchemas = dbID => {
    if(dbID==='all'){
      setSchemas([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:SCHEMA${state.source==='all'?'': ` AND source_id_srt:${state.source}`}${dbID==='all'?'': ` AND database_srt:"${databases.find(d=>d.id===dbID).name_txt}"`}`,
              rows:50,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setSchemas(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setSchemas(prevData)
        })
    };
    load({})
  }

  const loadTables = schemaID => {
    if(schemaID==='all'){
      setTables([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:TABLE${state.source==='all'?'': ` AND source_id_srt:${state.source}`}${state.database==='all'?'': ` AND database_srt:"${databases.find(d=>d.id===state.database).name_txt}"`}${schemaID==='all'?'': ` AND schema_srt:"${schemas.find(d=>d.id===schemaID).name_txt}"`}`,
              rows:50,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setTables(data)
          }
        })
        .catch(error=>{
          console.log(error)
          setTables(prevData)
        })
    };
    load({})
  }

  const loadLocation = (value) => { 
    if(value==='all'){
      setLocations([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:${mapObjectName(selectedObjectType,undefined, true)}${value==='all'?'': ` AND source_id_srt:${value}`}`,
              rows:0,
              facet:'on',
              'facet.field':'location_srt',
              'facet.mincount':1,
              'facet.sort':'index',
              'facet.limit':50,
              'facet.offset':prevData.length
            }
          }
        )
        .then(response=>{
          let data = [];
          if(response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.location_srt){
            let facet = response.data.facet_counts.facet_fields.location_srt;
            facet.forEach((el,index)=>{
              if(index % 2 ===0){
                data.push({
                  name: facet[index],
                  value: facet[index+1]
                })
              }
            })  
          }
          if(data.length===50){
            load({prevData:[...prevData,...data]})
          }else{
            setLocations([...prevData,...data])
          }
        })
        .catch(error=>{
          console.log(error)
          setLocations(prevData)
        })
    };
    load({})
  }

  useEffect(()=>{
    dispatch({type:'set_collection',collection:'all'})
    dispatch({type:'set_collection_type',collectionType:'all'})
    dispatch({type:'set_source',source:'all'})
    dispatch({type:'set_database',database:'all'})
    dispatch({type:'set_schema',schema:'all'})
    dispatch({type:'set_team',team:'all'})
    dispatch({type:'set_user_type',userType:'all'})
    if(['COLLECTION_INSTANCE'].includes(selectedObjectType)){
      loadCollections('all')
    }else if(['TERM'].includes(selectedObjectType)){
      loadGlossary()
    }else if(['USER'].includes(selectedObjectType)){
      loadTeams()
      loadUserTypes()
      dispatch({type:'set_user_type',userType:'USER_ACCOUNT'})
    }
    else if(['ISSUE'].includes(selectedObjectType)){
      loadIssueSources()
    }
    else{
      loadSources()
    }
  // eslint-disable-next-line
  },[selectedObjectType])

  const onChangeCollectionType = value => {
    dispatch({type:'set_collection_type',collectionType:value})
    dispatch({type:'set_collection',collection:'all'})
    loadCollections(value)
  }

  const onChangeCollection = value => {
    dispatch({type:'set_collection',collection:value})
  }

  const onChangeSource = (value, type) => {
    dispatch({type:'set_source',source:value})
    dispatch({type:'set_database',database:'all'})
    dispatch({type:'set_schema',schema:'all'})
    dispatch({type:'set_table',table:'all'})
    dispatch({type:'set_location',location:'all'})
    if(type==='database'){
      loadDatabases(value)
    }
    if(type==='tool'){
      loadLocation(value)
    }
  }

  const onChangeIssueSource = (value) => {
    dispatch({type:'set_issue_source',issueSource:value})
  }

  const onChangeUserType = value => {
    dispatch({type:'set_user_type',userType:value})
  }

  const onChangeTeam = value => {
    dispatch({type:'set_team',team:value})
  }

  const onChangeDatabase = value => {
    dispatch({type:'set_database',database:value})
    dispatch({type:'set_schema',schema:'all'})
    dispatch({type:'set_table',table:'all'})
    loadSchemas(value)
  }

  const onChangeSchema = value => {
    dispatch({type:'set_schema',schema:value})
    dispatch({type:'set_table',table:'all'})
    loadTables(value)
  }

  const onChangeTable = value => {
    dispatch({type:'set_table',table:value})
  }

  const onChangeLocation = value => {
    dispatch({type:'set_location',location:value})
  }


  let CollectionTypeFilter = (
    <LabeledSelector  
      title="Collection Type"  
      value={state.collectionType}
      testID="link-modal-collection-type-filter"
      onChange={event=>onChangeCollectionType(event.target.value)}
      options={['DATA_GOVERNANCE','DATA_MANAGEMENT'].map(d=>({value:d,name:d.replace(/_/g,' ')}))}
      placeholder="Search for a Collection Type"
    />
  )



  let CollectionFilter = (
    <LabeledSelector  
      title="Collection"  
      value={state.collection}
      testID="link-modal-collection-filter"
      onChange={event=>onChangeCollection(event.target.value)}
      options={collections.map(d=>({value:d.id,name:d.name_txt}))}
      placeholder="Search for a Collection"
    />
  )



  let GlossaryFilter = (
    <LabeledSelector  
      title="Glossary"  
      value={state.collection}
      testID="link-modal-glossary-filter"
      onChange={event=>onChangeCollection(event.target.value)}
      options={collections.map(d=>({value:d.id,name:d.name_txt}))}
      placeholder="Search for a Glossary"
    />
  )

  let userTypeFilter = (
    <LabeledSelector  
      title="Account Type"  
      value={state.userType}
      testID="link-modal-account-type-filter"
      onChange={event=>onChangeUserType(event.target.value)}
      options={userTypes.map(d=>({value:d,name:d}))}
      placeholder="Search for a User Type"
    />
  )
  

  let TeamFilter = (
    <LabeledSelector  
      title="Team"  
      value={state.team}
      testID="link-modal-team-filter"
      onChange={event=>onChangeTeam(event.target.value)}
      options={teams.map(d=>({value:d.id,name:d.name_txt}))}
      placeholder="Search for a Team"
    />
  )


  let DBSourceFilter = (
    <LabeledSelector  
      title="Source"  
      value={state.source}
      testID="link-modal-source-filter"
      onChange={event=>onChangeSource(event.target.value,'database')}
      options={sources.filter(el=>el.source_template.type==='DATABASE').map(d=>({value:d.id,name:d.name}))}
      placeholder="Search for a Source"
    />
  )

  let IssueSourceFilter = (
    <LabeledSelector  
      title="Source"  
      value={state.issueSource}
      testID="link-modal-issue-source-filter"
      onChange={event=>onChangeIssueSource(event.target.value)}
      options={issueSources.map(d=>({value:d,name:d}))}
      placeholder="Search for a Source"
    />
  )

  let ToolSourceFilter = (
    <LabeledSelector  
      title="Source"  
      value={state.source}
      testID="link-modal-source-filter"
      onChange={event=>onChangeSource(event.target.value,'tool')}
      options={sources.filter(el=>el.source_template.type==='TOOL').map(d=>({value:d.id,name:d.name}))}
      placeholder="Search for a Tool"
    />
  )



  let databaseFilter = (
    <LabeledSelector  
      title="Database"  
      value={state.database}
      testID="link-modal-database-filter"
      onChange={event=>onChangeDatabase(event.target.value)}
      options={databases.map(d=>({value:d.id,name:d.name_txt}))}
      placeholder="Search for a Database"
    />
  )




  let schemaFilter = (
    <LabeledSelector  
      title="Schema"  
      value={state.schema}
      testID="link-modal-schema-filter"
      onChange={event=>onChangeSchema(event.target.value)}
      options={schemas.map(d=>({value:d.id,name:d.name_txt}))}
      placeholder="Search for a Schema"
    />
  )



  let tableFilter = (
    <LabeledSelector  
      title="Table"  
      value={state.table}
      testID="link-modal-table-filter"
      onChange={event=>onChangeTable(event.target.value)}
      options={tables.map(d=>({value:d.id,name:d.name_txt}))}
      placeholder="Search for a Table"
    />
  )

  let locationFilter = (
    <LabeledSelector  
      title="Location"  
      value={state.location}
      testID="link-modal-location-filter"
      onChange={event=>onChangeLocation(event.target.value)}
      options={locations.map(d=>({value:d.name,name:`${d.name} (${d.value})`}))}
      placeholder="Search for a Location"
    />
  )

  let filterArr = []

  if(selectedObjectType==='COLLECTION_INSTANCE'){
    filterArr = [CollectionTypeFilter, CollectionFilter]
  }
  if(selectedObjectType==='TERM'){
    filterArr = [GlossaryFilter]
  }
  if(selectedObjectType==='USER'){
    filterArr = [TeamFilter, userTypeFilter]
  }

  if(['TABLE','COLUMN','DATABASE','SCHEMA'].includes(selectedObjectType)){
    filterArr.push(DBSourceFilter)
    if(['SCHEMA','TABLE','COLUMN'].includes(selectedObjectType) && state.source!=='all')filterArr.push(databaseFilter)
    if(['TABLE','COLUMN'].includes(selectedObjectType) && state.database!=='all')filterArr.push(schemaFilter)
    if(selectedObjectType==='COLUMN' && state.schema!=='all')filterArr.push(tableFilter)
  }
  if(['CONTENT_APP','CONTENT','CONTENT_CHILD','DATA_PIPELINE','DATASET','FILE','DATASET_FIELD','DATASET_TABLE','ML_MODEL'].includes(selectedObjectType)){
    filterArr.push(ToolSourceFilter)
    if(state.source!=='all')filterArr.push(locationFilter)
  }
  if(['MACRO','PROCEDURE','QUERY'].includes(selectedObjectType)){
    filterArr.push(DBSourceFilter)
    if(state.source!=='all')filterArr.push(databaseFilter)
  }
  if(['ISSUE'].includes(selectedObjectType)){
    filterArr.push(IssueSourceFilter)
  }

  if(filterArr.length===0)return <></>

  return (
    <div style={{display:'flex',paddingBottom:24,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,marginBottom:24}}>
      {filterArr}
    </div>
    
  )

}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history:PropTypes.object.isRequired,
  selectedObjectType: PropTypes.string.isRequired,
  selectedRelation: PropTypes.string.isRequired,
}


export default withTheme()(withStyles(styles)(Filter));