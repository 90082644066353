import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body from '../../components/Discover/Body/Body';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import {addHistory} from '../../HistoryManager'
import 'url-search-params-polyfill';
import {  removeUrlQueryArg } from '../../utilities';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const initialState = {
    tabState: 0,
    reportsView:'new',
    glossaryView:'new',
    collectionView:'platform',
    listView:'new',
    issueView:'new',
    tagView:'new'
  }

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_reports_data':
      return {
        ...state,
        reportsData:action.reportsData,
        reportsError:action.reportsError,
        reportsLoading:action.reportsLoading
      }
    case 'set_reports_view':
      return {
        ...state,
        reportsView:action.reportsView
      }
    case 'set_glossary_data':
      return {
        ...state,
        glossaryData:action.glossaryData,
        glossaryError:action.glossaryError,
        glossaryLoading:action.glossaryLoading
      }
    case 'set_glossary_view':
      return {
        ...state,
        glossaryView:action.glossaryView
      }
    case 'set_collection_data':
      return {
        ...state,
        collectionData:action.collectionData,
        collectionError:action.collectionError,
        collectionLoading:action.collectionLoading
      }
    case 'set_collection_view':
      return {
        ...state,
        collectionView:action.collectionView
      }
    case 'set_list_data':
      return {
        ...state,
        listData:action.listData,
        listError:action.listError,
        listLoading:action.listLoading
      }
    case 'set_list_view':
      return {
        ...state,
        listView:action.listView
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData:action.issueData,
        issueError:action.issueError,
        issueLoading:action.issueLoading
      }
    case 'set_issue_view':
      return {
        ...state,
        issueView:action.issueView
      }
    case 'set_tag_data':
      return {
        ...state,
        tagData:action.tagData,
        tagError:action.tagError,
        tagLoading:action.tagLoading
      }
    case 'set_tag_view':
      return {
        ...state,
        tagView:action.tagView
      }
    default:
      throw new Error("Action type not supoorted");
  }
}

const Discover = props => {


  const {
    history,
    sessionData,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);


  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');

  const tabOptions = ['REPORTS','TERMS','COLLECTIONS','LISTS','ISSUES','TAGS']

  useEffect(()=>{
    if(tabName){
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    addHistory({url:window.location.pathname, iconLabel:'discover', title: 'Discover', subTitle:'Discover',type:'application'})
    // eslint-disable-next-line
  },[])

  return (
    <ProfileLayout
      header={(
        <div>
          <ProfileHeader
            title={'Discover'}
            subtitle={' '}
            label='discover'
            description={` `}
          />
        </div>)}
      tabBar={
        <TabBar
          tabOptions={tabOptions}
          tabState={state.tabState}
          setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
          minWidth={200}
          maxWidth={200}
          disableUnderline={true}
        />
      }
      body={
        <Body
          history={history}
          dispatch={dispatch}
          state={state}
          sessionData={sessionData}
          tabOptions={tabOptions}
        />
      }
    />
  )
}


Discover.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Discover));