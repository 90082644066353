import React, {useEffect, useRef , useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import { onClickResultItem, isInViewport, toTitleCase, getLabelPlural, getDispFields , getUserRoles, capitalizeFirstWord, trimRichText} from '../../../utilities';
import FeedCard from '../../UI/FeedCard/FeedCard';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment';
import axiosSolr from '../../../axios-solr';
import {useStore} from 'react-redux'
import { generateStartCards, processAdditionalContext } from './utils'
import InteractiveViewer from '../../UI/InteractiveInput/InteractiveViewer';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  listChip:{
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    marginBottom:8,
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  listText:{
    color:theme.palette.primaryText.main,
    marginLeft:10,
    fontSize:13.75
  },
  customScrollBar:{
    /* width */
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  boldText:{
    fontWeight:700
  },
})

const Feed = props => {

  const {
    theme,
    classes,
    history,
    state,
    dispatch,
  } = props;


  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)

  const loadMoreRef = useRef();
  const hoverRef = useRef();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadCard = async ({page} = {page:1}) => {
    dispatch({
      type:'set_notification_data',
      notificationLoading:true,
      notificationData:state.notificationData
    });
    await axiosCerebrum
      .get(
        '/api/me/timeline',
        {params:{
          page:page,
          per_page:5,
          sort:'END_DESC'
        }}
      )
      .then( async response=>{
        let dataNeedsDetails = [];
        response.data.items.forEach(c=>{
          if(c.event.target_object && c.event.target_object.object.name==='COLLECTION_INSTANCE' && !dataNeedsDetails.includes(c.event.target_object.id)){
            dataNeedsDetails.push({id:c.event.target_object.id,label:c.event.target_object.object.name});
          }
          if(c.event.object.object.name==='COLLECTION_INSTANCE' && !dataNeedsDetails.includes(c.event.object.id)){
            dataNeedsDetails.push({id:c.event.object.id,label:c.event.object.object.name});
          }
          if(c.event.object.object.name==='QUERY' && !dataNeedsDetails.includes(c.event.object.id)){
            dataNeedsDetails.push({id:c.event.object.id,label:c.event.object.object.name});
          }
        })
        if(dataNeedsDetails.length>0){
          dataNeedsDetails = dataNeedsDetails.filter(i=>!Object.keys(state.feedObjectDetailsMap).includes(i.id));
          let mapObject = {};
          for(let i=0; i<dataNeedsDetails.length; i++){
            try{
              let fullDetail = {};
              let detailResp = await axiosCerebrum.get(`/api/${getLabelPlural(dataNeedsDetails[i].label)}/${dataNeedsDetails[i].id}`);
              fullDetail = {...detailResp.data};
              if(detailResp.data.parent && detailResp.data.parent.name==='Table Cluster'){
                let additionalDetail = await axiosSolr.get(`/solr/search/select`,{params:{q:"*",fq:`id:${dataNeedsDetails[i].id}`,fl:'tables:tables_txts'}});
                fullDetail = {...fullDetail,...additionalDetail.data.response.docs[0]}
              }
              mapObject[dataNeedsDetails[i].id] = fullDetail;
            }catch(error){
              console.log(`error occurred loading details for ${dataNeedsDetails[i].id}: ` + error)
            }
          }
          dispatch({type:'set_feed_object_details_map',feedObjectDetailsMap:{...state.feedObjectDetailsMap,...mapObject}})
        }
        dispatch({
          type:'set_notification_data',
          notificationData:page===1?response.data:{...response.data,items:[...state.notificationData.items,...response.data.items]}
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_notification_data',
          notificationData:state.notificationData,
          notificationError:true
        })
      })
  }

  const shouldLoadMore = () => {
    return !state.notificationLoading && state.notificationData && state.notificationData.page<state.notificationData.pages && isInViewport(loadMoreRef) && !state.notificationError
  }

  const onFeedScroll = () => {
    clearTimeout(hoverRef.current)
    if(shouldLoadMore()){
      loadCard({page:state.notificationData.page+1})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadCard({page:state.notificationData.page+1})
    }
    // eslint-disable-next-line
  },[state.notificationData])


  useEffect(()=>{
    if(state.notificationData || state.notificationLoading || state.notificationError)return;
    loadCard()
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!state.notificationData || state.notificationData.items.length===0)return;
    // eslint-disable-next-line
  },[state.notificationData])


  const getUserName = card => {
    return card.event.user.name;
  }

  const formatDescription = description => {
    if(!description||description.trim()==='')return 'No description provided.';
    // if(description.length>280)return description.slice(0,297)+'...';
    return trimRichText(description,280)
    // return description
  }

  const getCardTitle = card => {

    let objectBusinessName = card.event.object.alternate_name && card.event.object.alternate_name.trim()!==''?card.event.object.alternate_name : undefined;
    let objectLabel = card.event.object.object.name.replace(/_/g,' ');
    let objectName = getDispFields(card.event.object,'dispTitle');
    let objectDetail = state.feedObjectDetailsMap[card.event.object.id];
    let objectDispLabel = objectLabel.toLowerCase();
    if(objectDetail)objectName = getDispFields({...card.event.object,...objectDetail},'dispTitle');
    if(objectLabel==='COLLECTION INSTANCE' && objectDetail)objectDispLabel = `(${objectDetail.parent.name})`;
    if(roles.every(r=>r==='90') && objectBusinessName)objectName = objectBusinessName;
    if(objectName.length>75)objectName = objectName.slice(0,75)+'...'

    let targetObject, targetObjectName,targetObjectDetail,targetObjectLabel, targetDispLabel, targetBusinessName;
    if(card.event.target_object){
      targetObject = card.event.target_object
      targetBusinessName = card.event.target_object.alternate_name && card.event.target_object.alternate_name.trim()!==''?card.event.target_object.alternate_name : undefined;
      targetObjectLabel = card.event.target_object.object.name.replace(/_/g,' ')
      targetDispLabel = targetObjectLabel.toLowerCase();
      targetObjectName = getDispFields(card.event.target_object,'dispTitle');
      targetObjectDetail = state.feedObjectDetailsMap[card.event.target_object.id];
      if(targetObjectDetail)targetObjectName = getDispFields({...card.event.target_object,...targetObjectDetail},'dispTitle');
      if(targetObjectLabel==='COLLECTION INSTANCE' && targetObjectDetail)targetDispLabel = `(${targetObjectDetail.parent.name})`;
      if(roles.every(r=>r==='90') && targetBusinessName)targetObjectName = targetBusinessName;
      if(targetObjectName.length>75)targetObjectName = targetObjectName.slice(0,75)+'...'
    }

    if(card.event.action==='LINKED' && state.feedObjectDetailsMap[card.event.target_object.id] && state.feedObjectDetailsMap[card.event.target_object.id].parent.category==='KNOWLEDGE'){
      let detail = state.feedObjectDetailsMap[card.event.target_object.id];
      if(detail && detail.parent && detail.parent.name==='Question')return `New ${detail.parent.name} asked about ` + objectName + ' ' + objectDispLabel
      if(detail && detail.parent)return `New ${detail.parent.name} added to ` + objectName + ' ' + objectDispLabel
    }
    if(card.event.action==='LINKED' && state.feedObjectDetailsMap[card.event.target_object.id] && state.feedObjectDetailsMap[card.event.target_object.id].parent.category==='CHANGE'){
      let detail = state.feedObjectDetailsMap[card.event.target_object.id];
      if(detail && detail.parent)return `${detail.parent.name} added to ` + objectName + ' ' + objectDispLabel
    }
    if(card.event.action==='LINKED' && state.feedObjectDetailsMap[card.event.target_object.id] && state.feedObjectDetailsMap[card.event.target_object.id].parent.category==='GLOSSARY'){
      let detail = state.feedObjectDetailsMap[card.event.target_object.id];
      if(detail && detail.parent)return `A new term has been added to ` + objectName + ' ' + objectDispLabel
    }
    if(card.event.action==='LINKED'){
      if(objectLabel==='COLLECTION INSTANCE'){
        return `${objectName} linked to ${targetObjectName}`
      }
      return `${objectName} ` + objectDispLabel + ` linked to ${targetObjectName}`
    }
    if(['ASSIGNED_OWNER','ASSIGNED_STEWARD'].includes(card.event.action)){
      return  `${objectName} ${card.event.action.replace(/_/g,' as ').toLowerCase()} of ${targetObjectName} ${targetDispLabel}`;
    }
    if(card.event.action==='ADDED' && objectLabel==='ISSUE'){
      return 'Issue added to ' + targetObjectName + ' ' + targetDispLabel;
    }
    if(card.event.action==='NEW' && targetObjectLabel==='COLLECTION INSTANCE'){
      let detail = state.feedObjectDetailsMap[card.event.target_object.id];
      if(detail && detail.parent && detail.parent.name==='Table Cluster'){
        if(detail.tables){
          // eslint-disable-next-line
          return 'New cluster: ' + (`${detail.tables.map(el=>el.split('.').slice(-1)[0]).slice(0,4).join(', ')}${detail.tables.length>4?` and ${detail.tables.length-4} other table(s)`:''}`)
        }else{
          return 'New cluster found'
        }
      }
    }
    if(card.event.action==='UPDATED'){
      let isCollection = targetObjectLabel==='COLLECTION INSTANCE';
      let details = state.feedObjectDetailsMap[targetObject.id]
      if( targetObject && card.event.additional_context && !isCollection){
        let additional_context = card.event.additional_context;
        let {updates, deletes} = processAdditionalContext({additional_context, details, isCollection});
        if([...updates,...deletes].length===1){
          let item;
          item = [...updates,...deletes][0];
          if(item.isDescription){
            return  `${targetObjectName} description updated`
          }else{
            return `${targetObjectName} property updated`
          }
        }else{
          return `${targetObjectName} updated`
        }
      }
      if(details && details.parent.name==='Question'){
        return `Question has been updated`
      }
      return `${targetObjectName} updated`
    }
    if(card.event.action==='DATA_QUALITY_TEST_FAILED_RECENTLY_USED'){
      let count;
      if(card.event.additional_context && !isNaN(card.event.additional_context.failed)){
        count = card.event.additional_context.failed
      }
      return `Test(s) on ${count?count +' ':''}data item(s) you use have failed`
    }
    return `${objectName} ` + (objectDispLabel + ' ') + card.event.action.toLowerCase();
  }

  
  const getCardOverline = card => {
    let objectLabel = card.event.object.object.name.replace(/_/g,' ');
    let objectDispLabel = objectLabel.toLowerCase();
    if(objectLabel==='COLLECTION INSTANCE')objectDispLabel = card.event.object.location.split('/')[2]

    let targetObject = card.event.target_object
    let targetObjectLabel = targetObject?targetObject.object.name.replace(/_/g,' '):undefined;
    let targetDispLabel = targetObjectLabel.toLowerCase();
    if(targetObjectLabel==='COLLECTION INSTANCE')targetDispLabel = targetObject.location.split('/')[2]

    if(card.event.action==='LINKED' && targetObject && state.feedObjectDetailsMap[targetObject.id] && state.feedObjectDetailsMap[targetObject.id].parent.category==='KNOWLEDGE'){
      let detail = state.feedObjectDetailsMap[card.event.target_object.id];
      if(detail && detail.parent && detail.parent.name==='Question')return `Question asked about ` + objectDispLabel
      return `Note added to ` + objectDispLabel
    }
    if(card.event.action==='LINKED' && targetObject && state.feedObjectDetailsMap[targetObject.id] && state.feedObjectDetailsMap[targetObject.id].parent.category==='CHANGE'){
      return `Change added to ` + objectDispLabel
    }
    if(card.event.action==='LINKED' && targetObject && state.feedObjectDetailsMap[targetObject.id] && state.feedObjectDetailsMap[targetObject.id].parent.category==='GLOSSARY'){
      return `Glossary term linked to ` + objectDispLabel
    }
    if(card.event.action==='LINKED'){
      if(objectLabel==='COLLECTION INSTANCE'){
        return `${objectDispLabel} linked to ${targetDispLabel}`;
      }
      return `${toTitleCase(objectLabel)} linked to ${targetDispLabel}`
    }
    if(card.event.action==='ASSIGNED_STEWARD' && targetObject){
      return 'Steward assigned to ' + targetDispLabel
    }
    if(card.event.action==='ASSIGNED_OWNER' && targetObject){
      return 'Owner assigned to ' + targetDispLabel;
    }
    if(card.event.action==='ADDED' && targetObject){
      return toTitleCase(objectDispLabel) + ' added to ' + targetDispLabel;
    }
    if(card.event.action==='NEW' && targetObject && targetObjectLabel==='COLLECTION INSTANCE'){
      let detail = state.feedObjectDetailsMap[targetObject.id];
      if(detail && detail.parent && detail.parent.name==='Table Cluster'){
        return 'Table Cluster added'
      }
    }
    if(card.event.action==='UPDATED' ){
      if(targetObjectLabel==='COLLECTION INSTANCE'){
        return `${targetDispLabel} updated`
      }
      return toTitleCase(targetObjectLabel) + (' ' + card.event.action).toLowerCase();
    }
    if(card.event.action==='DATA_QUALITY_TEST_FAILED_RECENTLY_USED'){
      return 'Data Quality Test Failure'
    }
    return toTitleCase(objectLabel) + (' ' + card.event.action).toLowerCase();
  }

  const getBodyText =  card => {
    let message = <span></span>;
    let userName = '';
    let objectUserName = '';
    if(card.event.user){
      if(card.event.user.id===sessionData.id){
        userName = 'you';
      }else{
        userName = card.event.user.name
      }
    };
    if(['ASSIGNED_STEWARD','ASSIGNED_OWNER'].includes(card.event.action)){
      if(card.event.object.id===sessionData.id){
        objectUserName = 'you';
      }else{
        objectUserName = card.event.object.name
      }
    };

    let objectBusinessName = card.event.object.alternate_name && card.event.object.alternate_name.trim()!==''?card.event.object.alternate_name : undefined;
    let objectLabel = card.event.object.object.name.replace(/_/g,' ');
    let objectName = getDispFields(card.event.object,'dispTitle');
    let objectDetail = state.feedObjectDetailsMap[card.event.object.id];
    let objectDispLabel = objectLabel.toLowerCase();
    if(objectDetail)objectName = getDispFields({...card.event.object,...objectDetail},'dispTitle');
    if(objectLabel==='COLLECTION INSTANCE' && objectDetail)objectDispLabel = `(${objectDetail.parent.name})`;
    if(roles.every(r=>r==='90') && objectBusinessName)objectName = objectBusinessName;
    if(objectName.length>75)objectName = objectName.slice(0,75)+'...'


    let targetObject = card.event.target_object;
    let targetObjectName,targetObjectDetail,targetObjectLabel, targetDispLabel, targetBusinessName;
    if(targetObject){
      targetBusinessName = targetObject.alternate_name && targetObject.alternate_name.trim()!==''?targetObject.alternate_name : undefined;
      targetObjectLabel = targetObject.object.name.replace(/_/g,' ')
      targetDispLabel = targetObjectLabel.toLowerCase();
      targetObjectName = getDispFields(targetObject,'dispTitle');
      targetObjectDetail = state.feedObjectDetailsMap[targetObject.id];
      if(targetObjectDetail)targetObjectName = getDispFields({...targetObject,...targetObjectDetail},'dispTitle');
      if(targetObjectLabel==='COLLECTION INSTANCE' && targetObjectDetail)targetDispLabel = `(${targetObjectDetail.parent.name})`;
      if(roles.every(r=>r==='90') && targetBusinessName)targetObjectName = targetBusinessName;
      if(targetObjectName.length>75)targetObjectName = targetObjectName.slice(0,75)+'...'
    }


    let action = card.event.action;
    let extraMsg = '';
    let detail;
    switch(action){
      case 'CREATED':
        if(targetObject)extraMsg = <span> to <span className={classes.boldText}>{targetObjectName}</span></span>
        message = <span>The <span className={classes.boldText}>{objectName}</span> {objectDispLabel} has been {(objectLabel==='CONTENT'?'published':'added')}{extraMsg}</span>
        break;
      case 'DELETED':
        message = <span>The <span className={classes.boldText}>{objectName}</span> {objectDispLabel} has been deleted</span>
        break;
      case 'REPLACED':
        message = <span>{toTitleCase(userName)} replaced the <span className={classes.boldText}>{objectName}</span> {objectDispLabel} with the <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel}</span>
        break;
      case 'CHANGED':
        message =  <span>The <span className={classes.boldText}>{objectName}</span> {objectDispLabel} has been modified</span>
        break;
      case 'VERIFIED':
        message =  <span>{toTitleCase(userName)}{userName==='you'?'':' has'} verified the <span className={classes.boldText}>{objectName}</span> {objectDispLabel} for <span className={classes.boldText}>{targetObjectName}</span> use case(s)</span>
        break;
      case 'CLASSIFIED':
        message = <span>{toTitleCase(userName)}{userName==='you'?'':' has'} classified <span className={classes.boldText}>{objectName}</span> {objectDispLabel} as <span className={classes.boldText}>{targetObjectName}</span></span>
        break;
      case 'LINKED':
        detail = state.feedObjectDetailsMap[targetObject.id];
        if(detail && detail.parent && detail.parent.category==='KNOWLEDGE'){
          let isQuestion = detail.parent.name==='Question'
          // eslint-disable-next-line
          message = <span>A new {detail.parent.name} <span className={classes.boldText}>{targetObjectName}</span> has been {isQuestion?'asked about':'added to'} the <span className={classes.boldText}>{objectName}</span> {objectDispLabel} by {userName}</span>
        }
        else if(detail && detail.parent && detail.parent.category==='CHANGE'){
          message = <span>{toTitleCase(userName)}{userName==='you'?'':' has'} added a new {detail.parent.name} <span className={classes.boldText}>{targetObjectName}</span> to the <span className={classes.boldText}>{objectName}</span> {objectDispLabel}{(detail.properties[8]?`. Change date is set for ${moment(detail.properties[8]).format('ll')}`:'')}</span>
        }
        else if(detail && detail.parent && detail.parent.category==='GLOSSARY'){
          message = <span>The <span className={classes.boldText}>{targetObjectName}</span> term in <span className={classes.boldText}>{detail.parent.name}</span> has been linked to <span className={classes.boldText}>{objectName}</span> {objectDispLabel} by {toTitleCase(userName)}</span>
        }
        else{
          let objectDetail = state.feedObjectDetailsMap[card.event.object.id];
          if(objectLabel==='COLLECTION INSTANCE' && objectDetail){
            extraMsg = <span><span className={classes.boldText}>{capitalizeFirstWord(objectName)}</span> {objectDispLabel}</span>
          }else{
            extraMsg =<span><span className={classes.boldText}>{capitalizeFirstWord(objectName)}</span> {objectDispLabel}</span>
          }
          // eslint-disable-next-line
          message = <span>{extraMsg} has been linked to <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel} by {userName}</span>
        }
        break;
      case 'ASSIGNED_STEWARD':
        message = <span><span className={classes.boldText}>{toTitleCase(objectUserName)}</span> {objectUserName==='you'?'have':'has'} been assigned as a steward of the <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel}{objectUserName==='you'?'':(' by ' + userName)}</span>
        break;
      case 'ASSIGNED_OWNER':
        message = <span><span className={classes.boldText}>{toTitleCase(objectUserName)}</span> {objectUserName==='you'?'have':'has'} been assigned as an owner of the <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel}{(objectUserName==='you'?'':(' by ' + userName))}</span>
        break;
      case 'ADDED':
        if(objectLabel==='ISSUE'){
          message = <span>{toTitleCase(userName)}{userName==='you'?'':' has'} added a new issue <span className={classes.boldText}>{objectName}</span> to the <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel}</span>
        }
        break;
      case 'NEW':
        detail = state.feedObjectDetailsMap[targetObject.id];
        if(detail && detail.parent && detail.parent.name==='Table Cluster'){
          if(detail.tables)message = <span>New table cluster of <span className={classes.boldText}>{detail.tables.map(el=>el.split('.').slice(-1)[0]).join(', ')}</span> has been added. It includes data that you've recently used and may be useful to you.</span>
          return message = <span>New table cluster <span className={classes.boldText}>{detail.name}</span> has been added. It includes data that you've recently used and may be useful to you.</span>
        }
        break;
      case 'UPDATED':
        let isCollection = targetObjectLabel==='COLLECTION INSTANCE';
        if( card.event.additional_context && (!isCollection || state.feedObjectDetailsMap[targetObject.id])){
          let details = state.feedObjectDetailsMap[targetObject.id]
          let additional_context = card.event.additional_context;
          let {updates, deletes} = processAdditionalContext({additional_context, details, isCollection})
          if([...updates,...deletes].length===0){
            message = <span>{toTitleCase(userName)}{userName==='you'?'':' has'} updated the <span className={classes.boldText}>{targetObjectName}</span> {targetObject.location.split('/')[2]}.{`\n\n`}</span>
          }
          else if([...updates,...deletes].length===1 && !isCollection){
            let action, item;
            if(updates.length===1)action = 'updated';
            if(deletes.length===1)action = 'deleted';
            item = [...updates,...deletes][0];
            message = (
              <span style={{whiteSpace:'pre-line'}}>
                <span>{toTitleCase(userName)} {userName==='you'?'':' has'} {action} the {item.isDescription?'description':'property'} on the <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel}.</span>
                <span style={{marginTop:16,display:"block"}}>
                  <span style={{marginRight:8}}>{item.name}:</span>
                  {
                    item.isDescription?
                    <InteractiveViewer scrollElID="home_feed_container" initialValue={formatDescription(item.value)} id={card.id}/>
                    :
                    <span className={classes.boldText}>{item.value}</span>
                  }
                </span>
              </span>
            )
          }
          else{
            let initUpdateText = <span>{toTitleCase(userName)} {userName==='you'?'':' has'} updated the following details on the <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel}.</span>
            let initDeleteText = <span>{updates.length>0?'\n':''}{toTitleCase(userName)}{userName==='you'?'':' has'} deleted the following details on the <span className={classes.boldText}>{targetObjectName}</span> {targetDispLabel}.</span>

            if(isCollection){
              let instanceName = 'instance';
              if(details && details.parent.category==='GLOSSARY')instanceName = 'term'
              initUpdateText = <span>{toTitleCase(userName)} {userName==='you'?'':' has'} updated the following details on the <span className={classes.boldText}>{targetObjectName}</span> {instanceName} {details?`(${details.parent.name}) `:''}.</span>
              initDeleteText = <span>{toTitleCase(userName)} {userName==='you'?'':' has'} deleted the following details on the <span className={classes.boldText}>{targetObjectName}</span> {instanceName} {details?`(${details.parent.name}) `:''}.</span>
              if(details && details.parent.name==='Question'){
                let questionDetail = (
                  <span>
                    {'\n\n'}QUESTION: <span className={classes.boldText}>{details.name}</span>  {'\n'}           
                  </span>
                )
                initUpdateText = <span>{toTitleCase(userName)} {userName==='you'?'':' has'} updated the details on the below question.{questionDetail}</span>
                initDeleteText = <span>{toTitleCase(userName)} {userName==='you'?'':' has'} deleted the details on the below question.{questionDetail}</span>
              }
            }
            message = (
              <span style={{whiteSpace:'pre-line'}}>
                {
                  updates.length>0 && 
                  initUpdateText
                }
                {
                  updates.map(el=>(
                    <span style={{marginTop:16,display:"block"}}>
                      <span style={{marginRight:8}}>{el.name}:</span>
                      {
                        el.isDescription?
                        <InteractiveViewer scrollElID="home_feed_container" initialValue={formatDescription(el.value)} id={card.id}/>
                        :
                        <span className={classes.boldText}>{el.value}</span>
                      }
                    </span>
                  ))
                }
                {
                  deletes.length>0 && 
                  initDeleteText
                }
                {
                  deletes.map(el=>(
                    <span style={{marginTop:16,display:"block"}}>
                      <span className={classes.boldText} style={{marginRight:8}}>{el.name}:</span>
                      {
                        el.isDescription?
                        <InteractiveViewer scrollElID="home_feed_container" initialValue={formatDescription(el.value)} id={card.id}/>
                        :
                        <span className={classes.boldText}>{el.value}</span>
                      }
                    </span>
                  ))
                }
              </span>
            )
          }
        }else{
          message = <span>{toTitleCase(userName)}{userName==='you'?'':' has'} updated the <span className={classes.boldText}>{targetObjectName}</span> {targetObjectLabel.toLowerCase()}</span>
        }
        break;
      case 'DATA_QUALITY_TEST_FAILED_RECENTLY_USED':
        let count;
        if(card.event.additional_context && !isNaN(card.event.additional_context.failed)){
          count = card.event.additional_context.failed
        }
        message = `Data quality tests on ${count?count+' ':''}data item(s) you recently used have failed.\n\nSee Your Data Ecosystem page for more information about the impacts to you.`
        break;
      default:
        
    }
    return message;
  }

  const getTimeText = card => {
    return moment(card.event.updated_at).fromNow();
  }

  const getMainObject = (card) => {
    if(card.event.action==='ADDED' && card.event.object.object.name==='ISSUE'){
      return card.event.object
    }
    if(['ASSIGNED_STEWARD','ASSIGNED_OWNER','ADDED','UPDATED'].includes(card.event.action)){
      return card.event.target_object;
    }
    if(card.event.action==='NEW' && card.event.target_object.object.name==='COLLECTION_INSTANCE'){
      return card.event.target_object;
    }
    if(
      card.event.action==='LINKED' && 
      card.event.target_object && 
      card.event.target_object.object.name==='COLLECTION_INSTANCE' && 
      state.feedObjectDetailsMap[card.event.target_object.id] && 
      state.feedObjectDetailsMap[card.event.target_object.id].parent.name==='Question'){
      return card.event.target_object;
    }
    return  card.event.object
  }

  const getSubtitle = card => {
    let obj = getMainObject(card);
    let details = state.feedObjectDetailsMap[obj.id]
    if(details && details.object.name==='COLLECTION_INSTANCE' && details.parent.name==='Question')return '/KNOWLEDGE/QUESTION'
    return getMainObject(card).location
  }

  const onOpenCard = (card, urlOnly) => {
    let object = getMainObject(card)
    let query = [];
    if(object.object.name==='TABLE' && card.event.target_object && state.feedObjectDetailsMap[card.event.target_object.id] && state.feedObjectDetailsMap[card.event.target_object.id].collection_category==='knowledge'){
      query.push('tabName=DETAILS');
    };
    if(card.event.action==='DATA_QUALITY_TEST_FAILED_RECENTLY_USED'){
      return urlOnly?'/my_data?tabName=USED':history.push('/my_data?tabName=USED')
    }
    if(card.event.action==='REPLACED'){
      query.push('tabName=DETAILS');
    }
    if(card.event.action==='CHANGED'){
      query.push('tabName=CHANGES');
    }
    if(card.event.action==='LINKED'){
      let detail = state.feedObjectDetailsMap[card.event.target_object.id];
      if(detail && detail.parent && detail.parent.category==='CHANGE'){
        query.push('tabName=CHANGES')
      }
      if(detail && detail.parent && detail.parent.category==='GLOSSSARY'){
        query.push('tabName=DETAILS')
      }
      if(detail && detail.parent && detail.parent.category==='KNOWLEDGE' && detail.parent.name!=='Question'){
        query.push('tabName=KNOWLEDGE')
        query.push(`subTabName=${detail.parent.name}`)
      }
      if(detail && detail.parent && detail.parent.name==='Access Role'){
        query.push('tabName=DETAILS')
        // scrollIntoElemenet('profile-access-role-section')
      }
    }
    if(state.feedObjectDetailsMap[object.id]){
      object = {...object, ...state.feedObjectDetailsMap[object.id]}
    }
    return onClickResultItem({item:object, id:object.id, label:object.object.name.toLowerCase(), history:history,query, urlOnly});
  }

  const onSetFollow = (card, type) => {
    if(type==='follow'){
      axiosCerebrum
        .delete(`/api/me/follows/${card.event.object.id}`)
        .then(response=>{
          sendAlert({message:`Start showing notifications for ${card.event.object.name} ${card.event.object.object.name.toLowerCase()}`,type:'info'})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:`Error occurred showing ${card.event.object.name} ${card.event.object.object.name.toLowerCase()}, please try again`,type:'error'})
        })
    }else{
      axiosCerebrum
        .put(`/api/me/follows/${card.event.object.id}?type=OPT_OUT`)
        .then(response=>{
          sendAlert({message:`Stop showing notifications for ${card.event.object.name} ${card.event.object.object.name.toLowerCase()}`,type:'info'})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:`Error occurred hiding ${card.event.object.name} ${card.event.object.object.name.toLowerCase()}, please try again`,type:'error'})
        })
    }

  }

  const getSubActions = async card => {
    let alreadyUnfollowed = false;
    await axiosCerebrum
            .get(`/api/me/follows/${card.event.object.id}`)
            .then(response=>{
              if(response.data.type==='OPT_OUT'){
                alreadyUnfollowed = true
              }
            })
            .catch(error=>{

            })
    if(alreadyUnfollowed){
      return  [{name:'Show notifications',description:'Show posts about this asset',iconLabel:'follow',onClick:()=>onSetFollow(card,'follow')}]
    }else{
      return [{name:'Hide notifications',description:'Stop seeing posts about this asset',iconLabel:'visibility_off',onClick:()=>onSetFollow(card,'unfollow')}]
    }
  }

  window.onresize = () => {
    setInnerHeight(window.innerHeight)
  }

  window.onscroll = () => {
    setInnerHeight(window.innerHeight + window.scrollY)
  }

  return (
    <div style={{width:'100%'}}>
      {/* <div style={{display:'flex',alignItems:'flex-start'}}> */}
        <div id="home_feed_container" style={{height:innerHeight-128,overflow:'auto'}} className={classes.customScrollBar} onScroll={onFeedScroll}>
          {
            state.notificationData && state.notificationData.total===0 && 
            generateStartCards({sessionData, history})
          }
          {
            state.notificationData && state.notificationData.items.map((c,index)=>(
              <div style={{marginBottom:48}}>
                <FeedCard
                  id={`home_feed_card_${index}`}
                  userName={getUserName(c)}
                  overline={getCardOverline(c)}
                  title={capitalizeFirstWord(getCardTitle(c))}
                  // time={moment(c.time_stamp).fromNow()}
                  subTitle={getSubtitle(c)}
                  time={getTimeText(c)}
                  body={getBodyText(c)}
                  mainActions={
                    [{name:'open',onClick:()=>onOpenCard(c),url:onOpenCard(c,true)}]
                  }
                  subActions={
                    async () => getSubActions(c)
                  }
                  count={state.notificationData.total}
                />
              </div>
            ))
          }
          {
            state.notificationError && !state.notificationLoading &&
            <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading notification </Typography>
          }
          <div ref={loadMoreRef} style={{width:'100%',marginTop:20,marginBottom:30,display:'flex',justifyContent:'center'}}>
            {state.notificationLoading &&  <CircularProgress color='secondary' />}
          </div>
          
        </div>
      
    </div>
  )
}


export default withTheme()(withStyles(styles)(Feed));