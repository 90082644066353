import React, { useReducer, useEffect, useState, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { LinearProgress, Typography,} from '@material-ui/core';
import Body from '../../components/IssueProfile/Body/Body';
import { handleShareClick} from '../../utilities';
import { setInitialState, getUserRoles, getDispFields} from '../../utilities';
import axiosCerebrum from '../../axios-cerebrum';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DeleteModal from '../../components/IssueProfile/DeleteModal/DeleteModal'
import TabBar from '../../components/UI/TabBar/TabBar';
import {addHistory} from '../../HistoryManager'
import axiosSolr from '../../axios-solr'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import useAlert from '../../hooks/useAlert';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({

  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
});

const initialState = {
  linkSearchValue:'',
  linkFilter:'all',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_basic_data':
      return {
        ...state,
        basicData:action.basicData,
        loading:action.loading,
        error:action.error
      }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_delete_modal_open':
      return {
        ...state,
        deleteModalOpen:action.deleteModalOpen
      }
    case 'set_link_data':
      return {
        ...state,
        linkData:action.linkData,
        linkError:action.linkError,
        linkLoading:action.linkLoading
      }
    case 'set_link_alert':
      return {
        ...state,
        alertMessage:action.alertMessage,
        alertType:action.alertType,
        alertOpen:action.alertOpen
      }
    case 'set_alert_open':
      return {
        ...state,
        alertOpen:action.alertOpen
      }
    case 'set_db_data':
      return {
        ...state,
        dbData:action.dbData,
        dbError:action.dbError,
        dbLoading:action.dbLoading
      }
    case 'set_tool_data':
      return {
        ...state,
        toolData:action.toolData,
        toolError:action.toolError,
        toolLoading:action.toolLoading
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData:action.insightsData
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    default:
      console.log(action)
      throw new Error("Reducer action not supported.", action);
  }
}

const IssueProfile = props => {
  const {
    match,
    theme,
    history,
    classes,
    pageCache,
    storePageCache,
    sessionData,
  } = props;

  const [state, dispatch] = useReducer(reducer,setInitialState(pageCache,initialState));
  const roles = getUserRoles(sessionData.user_role)
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])


  const fetchList = () => {
    dispatch({type:'set_basic_data',basicData:state.basicData,loading:state.basicData?false:true})
    axiosCerebrum.get(
      `/api/issues/${match.params.id.toLowerCase()}`
    ).then(async response=>{
      let source_obj;
      let assignee;
      // if(response.data.type!=='SET_BY_EXTERNAL_SOURCE' || response.data.source_id!==1000){
      await axiosCerebrum.get(`/api/sources/${response.data.source_id}`).then(srcRes=>source_obj=srcRes.data).catch(error=>{console.log(error)})
      // }
      await axiosCerebrum
        .get(`/api/issues/${match.params.id.toLowerCase()}/related?relationship=ASSIGNED_TO`)
        .then(response=>{
          assignee = response.data.items[0]
        })
      dispatch({type:'set_basic_data',basicData:{...response.data,source_obj,assignee}})
      addHistory({url:window.location.pathname, title: getDispFields(response.data,'dispTitle'), subTitle:getDispFields(response.data,'dispSubtitle'),object:response.data,type:'profile'})
    }).catch(error=>{
      console.log(error)
      dispatch({type:'set_basic_data',error:true})
    })
  }

  const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }


  useEffect(()=>{
    if(!state.basicData)fetchList();
    if(state.followData===undefined){
      followDataFetch()
    }
    // if(!state.linkData)linkFetch();
   // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(state.linkData){
      dispatch({type:'set_link_object_data',linkObjectData:{linkedItems:{'IMPACTS':{items:state.linkData.items.filter(el=>el.relationship==='IMPACTS')}}}})
    }
  },[state.linkData])

  const onClickDelete = () => {
    dispatch({type:'set_delete_modal_open',deleteModalOpen:true})
  }
  


  if (state.loading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.error ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const data = state.basicData;


  const loadLinkData = ({page=1}) => {
    dispatch({type:'set_link_data',linkData:state.linkData,linkLoading:true})
    axiosCerebrum
      .get(`/api/issues/${state.basicData.id}/related`,{params:{relationship:"IMPACTS,IMPACTS_SRC,RELATED",page,per_page:10}})
      .then(response=>{
        if(response.data.total===0){
          dispatch({type:'set_link_data',linkData:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,
            {
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                fl:'*',
                rows:response.data.items.length
              }
            }
          )
          .then(solrRes=>{
            let processedItem = response.data.items;
            processedItem.forEach((el,index)=>{
              processedItem[index] = {...el,...solrRes.data.response.docs.find(t=>t.id===el.id)}
            })
            dispatch({type:'set_link_data',linkData:page===1?{...response.data,items:processedItem}:{...response.data,items:[...state.linkData.items,...processedItem]}})
          })
          .catch(error=>{
            dispatch({type:'set_link_data',linkData:state.linkData})
            console.log(error);
          })
      })
      .catch(error=>{
        dispatch({type:'set_link_data',linkData:state.linkData})
        console.log(error);
      })
  }



  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({message:"Stopped following this issue",type:'info'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:"Error occurred unfollowing this issue",type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({message:"Following this issue",type:'info'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:"Error occurred following this issue",type:'error'})
      })
    }
  }


  let buttons = [];

  if(state.basicData.type && state.basicData.type.toLowerCase()!=='set_by_external_source'){
    buttons.push(
      <ProfileButton
        onClick={() =>onClickDelete()}
        iconLabel='delete'
        iconColour={theme.palette.primaryText.light}
        iconOnly={true}
      />
    )
  }

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )


  const onOpenLinkModal = () => {
    setLinkModalOpen({
      relations:['IMPACTS'],
      linkableObjects:['TABLE','COLUMN','SCHEMA','DATABASE','MACRO','PROCEDURE','CONTENT','CONTENT_APP','CONTENT_CHILD','DATA_PIPELINE','QUERY','FILE'],
      onLinkUpdated:()=>loadLinkData({})
    })
  }

  buttons.push(
    <ProfileButton
      iconLabel='link'
      text='LINK'
      onClick={onOpenLinkModal}
    />
  )

  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )


  let bannerdisplayText, bannerCause, bannerVisibility;
  if (state.basicData.type && state.basicData.source_id!==1000) {
    if(state.basicData.source_obj){
      bannerdisplayText = `This issue is managed in ${state.basicData.source_obj.name}`
    }else{
      bannerdisplayText = 'This issue is managed externally';
    }
    bannerCause="external_issue"
    bannerVisibility = 'visible'
  }

  let showDashboard = roles.find(el=>['10','40'].includes(el));

  return (
    <div>
      
      <DeleteModal
        state={state}
        dispatch={dispatch}
        history={history}
      />

      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['link']}
        history={history}
      />

      <ProfileLayout
        header={
          <div>
            <ProfileHeader
              title={data.name}
              history={history}
              subtitle={
                <span>
                  {showDashboard && <span className={classes.underlineOnHover} onClick={()=>history.push('/dashboard/issue')}>ISSUES</span>}
                  {showDashboard && <span style={{marginLeft:6,marginRight:6}}>/</span>}
                  <span>{'ISSUE-'+data.display_id}</span>
                </span>
                
              }
              state={state}
              dispatch={dispatch}
              nodeKey={match.params.id.toLowerCase()}
              label='issue'
              buttons={buttons}
              data={data}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              bannerdisplayText={bannerdisplayText}
            />
          </div>}
        tabBar={
          <TabBar
            tabOptions={['DETAILS']}
            tabState={0}
            setTabState={value => {}}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            loadLinkData={loadLinkData}
            onOpenLinkModal={onOpenLinkModal}
          />
        }
        sideBar={
          // <SideBar
          //   history={history}
          //   state={state}
          //   fetchList={fetchList}
          //   dispatch={dispatch}
          // />
          <ProfileSideBar
            tabOptions={['DETAILS']}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'issues'}
            data={state.basicData}
            mapping={['issueType','issueSeverity','issueStatus','assignedTo','createdBy','createdOn','sourceType','lastUpdated']}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(IssueProfile)));
