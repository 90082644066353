import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem, Paper, Popper, MenuList, ClickAwayListener, Checkbox, InputBase, IconButton } from '@material-ui/core';
import { onClickResultItem,getDispFields,transformLogScale, getIconComponent, isInViewport, getSearchMode, formatNumber, toTitleCase, getNameSearchQuery } from '../../../utilities';
import UsageListItem from '../../UI/SearchResults/UsageListItem';
import * as actions from '../../../store/actions/index';
import { connect, useStore } from 'react-redux';
import axiosSolr from '../../../axios-solr';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import CartAdder, { addToCartLimit } from '../../UI/CartAdder/CartAdder';
import { checkCanAddToCart, checkCanBulkUpdate } from '../../../permissionChecker';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
	root: {
		width: '100%'
	},
	title: {
		color: theme.palette.header.main,
		fontSize: 20,
		fontWeight: '400'
	},
	subtitle: {
		fontSize: '0.75rem',
		fontWeight: '400',
		color: theme.palette.primaryText.light,
		width: '15rem'
	},
	body: {
	},
	frequencyBar: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	block: {
		marginLeft: '20rem',
		marginTop: '1rem'
	},
	selector: {
		...theme.components.selector,
		width: 180,
	},
	loader: {
		height: 10,
	},
	normalText:{
		color:theme.palette.primaryText.main
	},
	listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
	checkbox:{
		paddingLeft:0
	},
	searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
		width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
	emptyMsg:{
		fontSize:13.75,
		colour:theme.palette.primaryText.main,
		'& span':{
			textDecoration:'underline',
			cursor:'pointer',
		}
	}
})

const Assets = props => {

	const {
		history,
		classes,
		theme,
		isShell,
		state,
		dispatch,
		setBulkEditPrams
	} = props;

	const tableLoader = useRef();
	const [maxFrequency, setMaxFrequency] = useState(0);
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
	const searchTimeoutRef = useRef()
  
  const [searchFilter, setSearchFilter] = useState(state.assetsData?.searchFilter||{})
  const [sortBy, setSortBy] = useState(state.assetsData?.sortBy||{})
  const [isShowDeleted, setIsShowDeleted] = useState(state.assetsData?.isShowDeleted||{})
  const [isShowReference, setIsShowReference] = useState(state.assetsData?.isShowReference||{})
  const [data, setData] = useState(state.assetsData?.data)
  const [selectedType, setSelectedType] = useState(state.assetsData?.selectedType)

	const [addToCartIds, setAddToCartIds] = useState()

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    dispatch({
      type:'set_assets_data',
      assetsData:{
        searchFilter,
        sortBy,
        isShowDeleted,
        isShowReference,
        data,
        selectedType
      }
    })
  // eslint-disable-next-line
  },[searchFilter,sortBy,isShowDeleted,isShowReference,data,selectedType])

	const sortByItems = [
		{ dispName: 'Frequently used', value: 'total_usage_srt desc' },
		{ dispName: 'Recently used', value: 'last_used_srt desc' },
		{ dispName: 'A-Z', value: 'name_srt asc' },
		{ dispName: 'Z-A', value: 'name_srt desc' }
	]

  const loadLinkedObjectTypes = () => {
    setData({loading:true})
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`hierarchy_parent_id_srt:${state.basicData.id}`,
          rows:0,
          'json.facet':{
            object_type_txt:{
              type:'terms',
              field:'object_type_srt',
              limit:20,
            },
            code_type_txt:{
              type:'terms',
              field:'code_type_srt',
              limit:20,
            }
          }
        }}
      )
      .then(response=>{
        let types = response.data.facets.object_type_txt?.buckets?.map(el=>el.val) || []
        let objectMap = {}
        types.filter(el=>el!=='CODE').forEach(t=>{
          objectMap[t] = {}
        })
        if(types.includes('CODE')){
          let codeTypes = response.data.facets.code_type_txt?.buckets?.map(el=>el.val) || []
          codeTypes.forEach(t=>{
            objectMap[t] = {isCode:true}
          })
        }
        if(state.basicData.reference){
          let referenceObj = {}
          Object.keys(objectMap).forEach(k=>{
            referenceObj[k] = true
          })
          setIsShowReference(referenceObj)
        }
        if(state.basicData.active_flag===false){
          let activeObj = {}
          Object.keys(objectMap).forEach(k=>{
            activeObj[k] = true
          })
          setIsShowDeleted(activeObj)
        }
        setData(objectMap)
        if(Object.keys(objectMap)[0])setSelectedType(Object.keys(objectMap)[0])
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  }
  
  useEffect(()=>{
    if(!data)loadLinkedObjectTypes()
    // eslint-disable-next-line
  },[])


	const loadObjects = ({
    start=0,
    sort=sortBy[selectedType]||sortByItems[0].value,
    showDeleted=isShowDeleted[selectedType]||false,
    showReference=isShowReference[selectedType]||false,
    search=searchFilter[selectedType]||'',
    objectType=selectedType,
    addToCart
  }) => {
    if(!addToCart){
      setData({
        ...data,
        [selectedType]:{
          loading:true,
          isCode:data[selectedType].isCode,
          ...(start===0?{}:data[selectedType]),
        }
      })
    }

		if(!sort.includes('name_srt')){sort+=', name_srt asc'}

    let typeFilter = `object_type_srt:${objectType}`;
    if(data[selectedType].isCode)typeFilter = `code_type_srt:${objectType} AND object_type_srt:CODE`

		const queryParams = {
			q: getNameSearchQuery(search),
			fq: `${typeFilter} AND hierarchy_parent_id_srt:${state.basicData.id}${showReference?'':' AND reference_srt:NO'} ${showDeleted?'':'AND active_srt:YES'}`,
			fl:'*',
			start: addToCart?0:start,
			rows: addToCart?addToCartLimit:20,
			sort: search?undefined:sort
		};

		axiosSolr.get('/solr/search/select', { params: queryParams }, { withCredentials: true })
			.then(response => {
        if(addToCart){
          setAddToCartIds(response.data.response.docs.map(el=>el.id))
          return;
        }
				let newData = {...response.data.response}
        setData({
          ...data,
          [selectedType]:{
            ...newData,
            isCode:data[selectedType].isCode,
            docs:start===0?newData.docs:[...data[selectedType].docs,...newData.docs]
          }
        })
			})
			.catch(error => {
        if(addToCart){
          sendAlert({message:'Error occurred adding items to Cart',type:'error'})
					return;
				}
				console.log('Error loading data', error);
        setData({
          ...data,
          [selectedType]:{
            isCode:data[selectedType].isCode,
            error:true
          }
        })
			})
	}
  
  useEffect(()=>{
    if(!data)return;
    if(!selectedType)return;
    if(!data[selectedType] || data[selectedType].loading || data[selectedType].error || data[selectedType].docs)return;
    loadObjects({objectType:selectedType})
    // eslint-disable-next-line
  },[selectedType,data])

	const onChangeSort = (value) => {
		if (value === sortBy[selectedType]) return;
    setSortBy({...sortBy,[selectedType]:value})
		loadObjects({sort:value})
	}

	const onClickShowDeleted = value => {
		loadObjects({showDeleted:value})
    setIsShowDeleted({...isShowDeleted,[selectedType]:value})
	}

	const onClickShowReference = value => {
		loadObjects({showReference:value})
    setIsShowReference({...isShowReference,[selectedType]:value})
	}

  const onClickShowReferenceAndInactive = () => {
    setIsShowReference({...isShowReference,[selectedType]:true})
    setIsShowDeleted({...isShowDeleted,[selectedType]:true})
		loadObjects({showReference:true,showDeleted:true})
  }

	// eslint-disable-next-line
	const shouldLoadMore = () => {
		return isInViewport(tableLoader) && data?.[selectedType] && data[selectedType].docs && data[selectedType].docs.length < data[selectedType].numFound && !data[selectedType].loading
	}

	useEffect(()=>{
		shouldLoadMore() && loadObjects({start:data[selectedType].docs.length})
    // eslint-disable-next-line
	},[data])

	window.onscroll = () => {
    shouldLoadMore() && loadObjects({start:data[selectedType].docs.length})
  }

	const onTableListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}
  
	const onClickViewInSearch = () => {
		if(getSearchMode()==='basic_search'){
      let presetFilter = `hierarchy_parent_id_srt:${state.basicData.id}${isShowReference[selectedType]?'':" AND reference_srt:NO"}${isShowDeleted[selectedType]?"":" AND active_srt:YES"}`;
      history.push(`/basic_search?query=*&object=${selectedType}&presetFilter=${presetFilter}`)
    }
	}

	const onClickBulkEdit = () => {
    let typeFilter = `object_type_srt:${selectedType}`;
    if(data[selectedType].isCode)typeFilter = `code_type_srt:${selectedType} AND object_type_srt:CODE`

		setBulkEditPrams({
			url:'/solr/search/select',
			type:'solr',
			params:{
				q:getNameSearchQuery(searchFilter[selectedType]),
				fq:`${typeFilter} AND hierarchy_parent_id_srt:${state.basicData.id} ${isShowDeleted[selectedType]?'':'AND active_srt:YES'}${isShowReference[selectedType]?"":" AND reference_srt:NO"}`,
				fl:'*',
				rows:1000,
			},
			redirectUrl:window.location.pathname.split('?')[0]
		})
		history.push('/bulk_edit')
	}

  const onAddToCart = () => {
    loadObjects({addToCart:true})
    setListActionOpen(false)
  }

	const onSearchChange = value => {
    setSearchFilter({...searchFilter,[selectedType]:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadObjects({search:value})
    },250)
  }

  const mapDataToRows = data => {
		if (!data) return [];
		let maxFreq = maxFrequency;

		let frequencyArr = data.map(d=>d.total_usage_srt)
		let {logValue} = transformLogScale(frequencyArr,Math.E)
		maxFreq=Math.max(...logValue)

		let rowArray = data.map((table,index) => {
			return {
				...table,
				item:table,
				name: table.name_txt || 'untitled',
				id:table.id,
				description: table.description,
				frequency: Math.max(logValue[index],0),
				labels: table.object_type_txt,
			}
		})
		if (maxFreq > maxFrequency) setMaxFrequency(maxFreq)
		return rowArray;
	}

	if (data?.error ) return (<Typography className={classes.normalText}>Error occurred loading data.</Typography>)
	if (data?.loading ) return (<div style={{display:'flex',justifyContent:'center',marginTop:16}}><CircularProgress color='secondary'/></div>)
  if(!data)return <></>
  if(Object.keys(data).length===0)return <Typography>No child item found</Typography>

	return (
    <div style={{display:'flex',overflow:'hidden'}}>
      <VerticalTabBar
        tabOptions={Object.keys(data)}
        tabState={Object.keys(data).indexOf(selectedType)}
        setTabState={index => setSelectedType(Object.keys(data)[index])}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent:'space-between',flexWrap:'wrap' }}>
          <div style={{ display: 'flex', flexDirection: 'column',marginBottom:24}}>
            <Typography className={classes.title}>{formatNumber(data[selectedType]?.numFound || 0)} {selectedType}(S)</Typography>
            <Typography className={classes.subtitle}>Select a {toTitleCase(selectedType)} to see more details</Typography>
          </div>
          
          <div style={{display:'flex',alignItems:'center',marginBottom:24,flexShrink:0}}>
            <InputBase
              value={searchFilter[selectedType]}
              onChange={event => onSearchChange(event.target.value)}
              variant={'filled'}
              placeholder={'Search'}
              className={classes.searchFilter}
              endAdornment={
                <IconButton 
                  disabled={!searchFilter[selectedType]} 
                  onClick={()=>onSearchChange('')}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:!searchFilter[selectedType]?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
            />
            <Select
              className={classes.selector}
              value={sortBy[selectedType]||sortByItems[0].value}
              onChange={event => onChangeSort(event.target.value)}
              MenuProps={{
                classes:{
                  paper:classes.selectPaper
                }
              }}
              disabled={searchFilter[selectedType]}
            >
              {
                sortByItems.map(el => (
                  <MenuItem  className={classes.menuItem} value={el.value}>
                    <span>{el.dispName}</span>
                  </MenuItem>
                ))
              }
            </Select>
            <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onTableListActionClick}>
              {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
            </div>
            <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
              <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                  <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                  <MenuList className={classes.listContainer}>
                    <MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                      <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                    </MenuItem>
                    {
                      checkCanBulkUpdate({sessionData}) && 
                      <MenuItem onClick={()=>{onClickBulkEdit()}} className={classes.menuItem} >
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Bulk update</Typography>
                      </MenuItem>
                    }
                    {
                      checkCanAddToCart({sessionData, objectType:selectedType}) && 
                      <MenuItem data-test-id="child-list-add-cart" onClick={()=>{onAddToCart()}} className={classes.menuItem} >
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Add to Cart</Typography>
                      </MenuItem>
                    }
                  </MenuList>
                  <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                  <MenuItem onClick={()=>{onClickShowReference(!isShowReference[selectedType])}} className={classes.menuItem} >
                    <Checkbox key={isShowReference[selectedType]} className={classes.checkbox} color='primary' checked={isShowReference[selectedType]}/>
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show reference</Typography>
                  </MenuItem>
                  <MenuItem onClick={()=>{onClickShowDeleted(!isShowDeleted[selectedType])}} className={classes.menuItem} >
                    <Checkbox key={isShowDeleted[selectedType]} className={classes.checkbox} color='primary' checked={!isShowDeleted[selectedType]}/>
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                  </MenuItem>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        </div>

        <div className={classes.body}>
          {
            data?.[selectedType]?.numFound === 0 && 
            (
              !searchFilter[selectedType]?
                <Typography className={classes.emptyMsg}>No active {toTitleCase(selectedType)}s found. <span onClick={onClickShowReferenceAndInactive}>Click to see {[isShowReference[selectedType]?undefined:'reference', isShowDeleted[selectedType]?undefined:'inactive'].filter(el=>el).join('/')} {toTitleCase(selectedType)}s</span> linked to this workspace</Typography>
              :
              <Typography className={classes.emptyMsg}>No {toTitleCase(selectedType)}s found</Typography>
            )
          }
          {
            data?.[selectedType]?.numFound > 0 && !isShell && 
            <div style={{display:'flex',justifyContent:'flex-end'}}><Typography style={{fontSize:12,letterSpacing:2, color:theme.palette.primaryText.main,marginRight:132,marginBottom:8}}>USAGE</Typography></div>
          }
          {
            data?.[selectedType]?.numFound > 0 && mapDataToRows(data[selectedType].docs).map(table => {
              return (
                <UsageListItem
                  key={table.id}
                  item={table}
                  dispTitle={getDispFields(table.item, 'dispTitle')}
                  frequency={table.total_usage_srt}
                  maxFrequency={maxFrequency}
                  enableEdit
                  onDescriptionUpdated={({item,description})=>{
                    setData({
                      ...data,
                      [selectedType]:{
                        ...data[selectedType],
                        docs:data[selectedType].docs.map(el=>{
                          if(el.id!==item.id)return el;
                          return {...el,description}
                        }
                      )}
                    })
                  }}
                  onClick={() => onClickResultItem({id:table.id, label:table.item.object_type_txt, history: history, item: table.item })}
                />
              )
            })
          }
          <div
            style={{ marginBottom: 10, display:'flex',justifyContent:'center' }}
            ref={tableLoader}
          >
            {
              data[selectedType]?.loading && <CircularProgress color='secondary'/>
            }
          </div>
        </div>
      </div>
      <CartAdder
        objectIds={addToCartIds}
        onFinish={()=>setAddToCartIds()}
        history={history}
      />
    </div>
	)
}

Assets.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
		setBulkEditPrams:(data) => dispatch(actions.setBulkEditParam(data))
  }
}
const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(Assets)));