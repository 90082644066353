import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Popper, Paper, List, ListItem, ClickAwayListener, CircularProgress, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import axiosSolr from '../../../axios-solr';
import axiosCerebrum from '../../../axios-cerebrum';
import { getDispFields, getIconComponent } from '../../../utilities'
import SimpleResultItem from '../SearchResults/SimpleResultItem'
import { findHistoryByObjectType } from '../../../HistoryManager'
import UserSuggestionItem from '../SearchResults/UserSuggestionItem';
// import {useStore} from 'react-redux'

const styles = theme => ({
  root: {
    width: '100%',
    display:'flex',
    boxSizing:'border-box'
  },
  input: {
    ...theme.components.inputBase,
    flexGrow:1,
    minHeight:48,
  },
  list:{
    // minWidth:500,
    overflow:'auto',
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.main
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  icon:{
    padding:4,
  },
  listItem:{
    padding:0
  },
  simplifiedSuggestionItem:{
    padding:'8px 16px',
    cursor:'pointer',
    fontSize:13.75,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    color:theme.palette.primaryText.main,
  },
  suggestionHeader:{
    letterSpacing:2,
    fontSize:12,
    color:theme.palette.header.main,
    marginBottom:16,
    marginLeft:16,
    marginTop:16
  }
  // icon: {
  //   width:20
  // }
})


const SearchSelector = props => {
  const {
    theme,
    classes,
    placeholder,
    clearable,
    url,
    isCerebrum,
    params,
    removeFLModify=true,
    onResultClick,
    searchValue,
    setSearchValue,
    noResultMessage,
    firstLevelSuggestionHeader,
    autoSuggestion,
    secondarySuggestionHeader,
    secondarySuggestionParams,
    scrollable,
    renderResults,
    disabled,
    leftIcon,
    defaultSuggestionType,
    forceSuggestionParam,
    postProcessDataList = list => list,
    ignoreAutoCollections,
    suggestionListWidth,
    filterHistoryItem,
    popperDisableportal,
    hideBorder,
    autoFocus,
    keepPopperOnClick,
    suggestionMinWidth,
    height,
    endAdornment,
    removeSuggestionHeader,
    isSimplifiedSuggestion,
    testID,
    borderColour,
    isStatic
  } = props;

  // const [search, setSearch] = useState(false);
  const [inputRef, setInputRef] = useState();
  const [popperOpen, setPopperOpen] = useState(false);
  const scrollRef = useRef();
  const [data, setData] = useState();
  const [fullResponse, setFullResponse] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const typeTimeout = useRef();
  
  const loadData = ({overwriteParams, isPagination, isSuggestion}) => {
    let finalParams = {...params,...(overwriteParams||{})}
    setLoadingMore(true);
    if(isCerebrum || url.includes('/api/')){
      axiosCerebrum
        .get(url,{params:finalParams})
        .then(response => {
          setFullResponse(isPagination?{...response.data,items:[...data,...response.data.items]}:response.data)
          setData(isPagination?[...data,...response.data.items]:response.data.items);
          setPopperOpen(true);
          // setSearch(false);
          setLoadingMore(false);
        })
        .catch(error => {
          console.log(error, 'Error pulling text suggestions');
          // setSearch(false);
          setLoadingMore(false);
        });
    }
    else{
      axiosSolr
        .post(url, {
          params: removeFLModify?finalParams:{
            ...finalParams,
            fl:'labels:object_type_txt,id,name:name_txt,groups:teams_txts, *',
          }
        })
        .then(response => {
          let fullResponse = isPagination?{...response.data,response:{...response.data.response,docs:[...data,...response.data.response.docs]}}:response.data
          let tmpData = isPagination?[...data,...response.data.response.docs]:response.data.response.docs;
          if(isSuggestion && secondarySuggestionParams){
            axiosSolr
              .post(url, {
                params: removeFLModify?secondarySuggestionParams:{
                  ...secondarySuggestionParams,
                  fl:'labels:object_type_txt,id,name:name_txt,groups:teams_txts, *',
                }
              })
              .then(response2 => {
                setFullResponse(fullResponse)
                setData([...tmpData,...response2.data.response.docs.map(el=>({...el, isSecondaryLevel:true}))]);
                setPopperOpen(true);
                // setSearch(false);
                setLoadingMore(false);
              })
              .catch(error => {
                // setSearch(false);
                console.log(error, 'Error pulling text suggestions');
                setLoadingMore(false);
              });
          }else{
            setFullResponse(fullResponse)
            setData(tmpData);
            setPopperOpen(true);
            // setSearch(false);
            setLoadingMore(false);
          }
        })
        .catch(error => {
          // setSearch(false);
          console.log(error, 'Error pulling text suggestions');
          setLoadingMore(false);
        });
      }
  }
 
  const getSuggestion = () => {

    const initialParams = { ...params };
    if(!isCerebrum){
      initialParams.q = '*:*';
    }else{
      initialParams['search.name'] = undefined;
    }
    
    if(defaultSuggestionType && !searchValue.trim() && !forceSuggestionParam){
      let suggestion = findHistoryByObjectType({type:defaultSuggestionType}).filter(el=>!filterHistoryItem || filterHistoryItem(el)).map(el=>({
        ...el.object,
        labels:el.object.object.name.toLowerCase(),
        trustScore:el.object.score,
        nodeKey:el.object.signature,
        isHistory:true
      }));
      if(ignoreAutoCollections){
        suggestion = suggestion.filter(el=>!el.parent || !['AUTOMATED','PLATFORM'].includes(el.parent.category))
      }
      // if(suggestion.length===0){
      //   return;
      // }
      setData(suggestion.slice(0,3));
      setPopperOpen(true);
      // setSearch(false);
      return;
    }
    // if (!autoSuggestion) return;
    loadData({overwriteParams:initialParams, isSuggestion:true});
   
  }


  const onListItemClick = (el) => {
    if(!keepPopperOnClick){
      setPopperOpen(false);
      setSearchValue('');
    }
    onResultClick && onResultClick(el);
  }
  
  const listOnScroll = (event) => {
    if(!scrollable)return;
    // if(!isInViewport(scrollRef))return;
    if(event.target.scrollTop<event.target.scrollHeight-event.target.clientHeight-10)return;
    if(!data)return;
    if(loadingMore)return;
    if(isCerebrum && fullResponse.page>=fullResponse.pages)return;
    if(!isCerebrum && fullResponse.response.numFound<=(fullResponse.response.start+(params.rows||20)))return;
    let overwriteParams = {};
    if(isCerebrum){
      overwriteParams.page = fullResponse.page+1;
    }else{
      overwriteParams.start = fullResponse.response.start+(params.rows||20)
    }
    loadData({overwriteParams,isPagination:true})
  }

  useEffect(()=>{
    if(isStatic)return;
    if (!searchValue?.trim() ){
      setPopperOpen(false);
      setData()
      return;
    };
    setLoadingMore(true)
    setData()
    clearTimeout(typeTimeout.current);
    typeTimeout.current = setTimeout(() => {
      loadData({})
    },500)
  // eslint-disable-next-line
  },[params, searchValue ])

  const getList = (l) => {
    return (
      <>
        {
          l && renderResults && renderResults( postProcessDataList(l),fullResponse) && renderResults( postProcessDataList(l),fullResponse).map(el=>(
            <div 
              onClick={()=>{
                if(!keepPopperOnClick){
                  setPopperOpen(false);
                }
              }}
            >
              {el}
            </div>
          ))
        }
        {l && !renderResults && postProcessDataList(l).length === 0 && !loadingMore && 
          <ListItem style={{ whiteSpace: 'pre-wrap', color:theme.palette.primaryText.main, fontSize:isSimplifiedSuggestion?13.75:undefined }}>
            {noResultMessage || `No results found`}
          </ListItem>
        }
        {l && !renderResults && postProcessDataList(l).map((item,index) => {
          let label = item.labels;
          if(item.object_type_txt)label = item.object_type_txt
          if(item.object)label = item.object.name
          if(label)label=label.toLowerCase()
          if(isSimplifiedSuggestion){
            return (
              <div className={classes.simplifiedSuggestionItem} style={{borderBottom:index===l.length-1?'none':undefined}} onClick={()=>{onListItemClick(item)}}>
                {getDispFields(item,'dispTitle')}
              </div>
            )
          }
          if(label==='user' && !isCerebrum){
            return (
              <UserSuggestionItem item={item} onClick={()=>{onListItemClick(item)}} itemSource="solr" />
            )
          }
          return (
            <div style={{width:'100%',overflow:'hidden'}}>
              <SimpleResultItem
                title={getDispFields(item,'dispTitle')}
                titleColour={theme.palette.primaryText.main}
                label={label}
                item={item}
                subTitle={getDispFields(item,'dispSubtitle')}
                hideRight={true}
                // noPadding={true}
                disableContextMenu
                showUnderline={index!==l.length-1?true:false}
                onClick={() => onListItemClick(item)}
              />
            </div>
          )
        })}
        {
          scrollable?
          <div ref={scrollRef}>
            {loadingMore && <div style={{display:'flex',justifyContent:'center'}}><CircularProgress color='secondary'/></div>}
          </div>
          :
          (loadingMore && <div style={{display:'flex',justifyContent:'center'}}><CircularProgress color='secondary'/></div>)
        }
      </>
    )
  }

  return (
    <Paper elevation={0} className={classes.root} style={{ backgroundColor: '#EEE' , boxShadow: 'none'}}>
      <InputBase
        inputProps={{
          'data-test-id':testID
        }}
        autoFocus={autoFocus}
        className={classes.input}
        style={{
          border:hideBorder?'none':undefined,
          minHeight:height||undefined,
          height,
          fontSize:height && height<=32?13.75:undefined, 
          borderColor:borderColour,
        }}
        placeholder={placeholder || 'Enter search text'}
        value={searchValue}
        disabled={disabled}
        onClick={()=>{
          if(data){
            setPopperOpen(true)
          }else{
            if(autoSuggestion && !data && searchValue?.trim()==='')getSuggestion();
          }
        }}
        onChange={event => {
          let value = event.target.value;
          setSearchValue(value);
        }}
        inputRef={node => {setInputRef(node);}}
        startAdornment={
          leftIcon?
          <IconButton disabled={true} className={classes.icon} style={{marginLeft:4}}>
            {leftIcon}
          </IconButton>
          :undefined
        }
        endAdornment={
          endAdornment || 
          <IconButton onClick={clearable?()=>setSearchValue(''):undefined} disabled={clearable&&searchValue!==''?false:true} className={classes.icon} style={{marginRight:4}}>
            {
              clearable && searchValue!==''?
              getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light}):
              <SearchIcon style={{color:theme.palette.primaryText.light}} />
            }
          </IconButton>
        }
      />
       
      <Popper 
        disablePortal={popperDisableportal} 
        open={popperOpen} 
        anchorEl={inputRef} 
        placement={'bottom-start'} 
        modifiers={
          {
            flip: {
              enabled: true,
            }
            // preventOverflow: {
            //   enabled: true,
            //   boundariesElement: 'scrollParent',
            // },
          }
        }
        style={{marginTop:6, marginLeft:0,width:inputRef?inputRef.getBoundingClientRect().width+38:undefined}}
      >
        <ClickAwayListener 
          onClickAway={(event)=>{
            setPopperOpen(false)
          }}
        >
          <Paper id="search_selector_popper" style={{background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`,width: suggestionListWidth, minWidth:suggestionMinWidth,paddingBottom:8}}>
            <List className={classes.list} style={{maxHeight:isSimplifiedSuggestion?200:320}} id="search_selector_popper_list" onScroll={listOnScroll}>
              {
                !removeSuggestionHeader &&
                (!data?.find(d=>d.isSecondaryLevel) || data?.filter(d=>!d.isSecondaryLevel).length!==0) && 
                <Typography className={classes.suggestionHeader}>
                  {
                    firstLevelSuggestionHeader || 
                    (
                      data && data.every(el=>el.isHistory) && defaultSuggestionType && searchValue==='' && !forceSuggestionParam?
                      'RECENTLY VIEWED':
                      'SEARCH RESULTS'
                    )
                  }
                </Typography>
              }
              {
                (!secondarySuggestionParams || data?.filter(d=>!d.isSecondaryLevel).length>0 || loadingMore) && getList(data?.filter(d=>!d.isSecondaryLevel))
              }
              {
                data?.find(d=>d.isSecondaryLevel) && 
                <>
                  {
                    secondarySuggestionHeader &&
                    <Typography className={classes.suggestionHeader}>
                      {secondarySuggestionHeader}
                    </Typography>
                  }
                  {
                    getList(data.filter(d=>d.isSecondaryLevel))
                  }
                </>
              }
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Paper>
  )
}

SearchSelector.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  isCerebrum: PropTypes.bool,
  params: PropTypes.object.isRequired,
  onResultClick: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  noResultMessage: PropTypes.string,
  firstLevelSuggestionHeader: PropTypes.string,
  autoSuggestion: PropTypes.bool,
  secondarySuggestionHeader: PropTypes.string,
  secondarySuggestionParams: PropTypes.object,
  icon: PropTypes.string,
  added: PropTypes.bool,
  renderResults:  PropTypes.func.isRequired,
  disabled:  PropTypes.bool,
  leftIcon: PropTypes.object,
  scrollable: PropTypes.bool,
  postProcessDataList: PropTypes.func,
  popperDisableportal: PropTypes.bool,
  hideBorder: PropTypes.bool,
  autoFocus:PropTypes.bool,
  removeFLModify: PropTypes.bool,
  keepPopperOnClick: PropTypes.bool,
  suggestionMinWidth: PropTypes.number,
  height: PropTypes.number,
  endAdornment: PropTypes.object,
  removeSuggestionHeader: PropTypes.bool,
  isSimplifiedSuggestion: PropTypes.bool,
  testID: PropTypes.string,
  forceSuggestionParam: PropTypes.object,
  defaultSuggestionType: PropTypes.string,
  ignoreAutoCollections: PropTypes.bool,
  filterHistoryItem: PropTypes.func,
  borderColour: PropTypes.string,
  suggestionListWidth: PropTypes.number,
  isStatic: PropTypes.bool
}



export default withTheme()(withStyles(styles)(SearchSelector));