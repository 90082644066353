import React, { useReducer, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import {withTheme, LinearProgress, Typography, } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/NewTagProfile/Body/Body';
import useGetSolrLossless from '../../hooks/useGetSolrLossless';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import {  handleShareClick, getSearchMode} from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DeletModal from '../../components/NewTagProfile/DeleteModal/DeleteModal'
import axiosCerebrum from '../../axios-cerebrum';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import GenericLinkModal from '../../components/UI/GenericLinkModal/GenericLinkModal';
import useAlert from '../../hooks/useAlert';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});

const initialState = {
  tabState: 0,
  // basic table data
  basicData: null,
  linkObjectType:'TABLE',
  linkType:'TAG_OF',
  source:'all',
  linkData:{},
  linkFilter:{},
  searchFilter:{},
  linkLoading:{},
  linkTabState:0,
  linkError:{},
  linkModalList:[],
  linkSearchSource:'all',
  editingLink:false,
  linkModalOpen:false
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData
      }
    case 'set_link_object_type':
      return {
        ...state,
        linkObjectType:action.linkObjectType
      }
    case 'set_link_modal_open':
      return {
        ...state,
        linkModalOpen:action.linkModalOpen
      }
    
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_delete_modal_open':
      return {
        ...state,
        deleteModalOpen:action.deleteModalOpen
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_editing_link':
      return {
        ...state,
        editingLink:action.editingLink
      }
    case 'set_link_tab_data':
      return {
        ...state,
        linkTabData:action.linkTabData,
        linkTabLoading:action.linkTabLoading,
        linkTabError:action.linkTabError
      }
    case 'set_link_data':
      return {
        ...state,
        linkData:action.linkData
      }
    case 'set_link_filter':
      return {
        ...state,
        linkFilter:action.linkFilter
      }
    case 'set_link_tab_state':
      return {
        ...state,
        linkTabState:action.linkTabState
      }
    case 'set_link_loading':
      return {
        ...state,
        linkLoading:action.linkLoading
      }
    case 'set_link_error':
      return {
        ...state,
        linkError:action.linkError
      }
    case 'set_link_modal_list':
      return {
        ...state,
        linkModalList:action.linkModalList
      }
    case 'set_search_filter':
      return {
        ...state,
        searchFilter:action.searchFilter
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const tabOptions = ['DETAILS', 'RELATED'];


const NewTagProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    label,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const {
    data: fullResponse,
    loading,
    error,
    fetchList
  } = useGetSolrLossless({
    url: `/api/tags/${match.params.id.toLowerCase()}`,
    preventAuto: true
  })

  const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }

  const {
    data: linkTabData,
    loading:linkTabLoading,
    error:linkTabError,
    fetchList:linkTabFetch
  } = useGetCerebrum({
    url:`/api/tags/${match.params.id.toLowerCase()}/related/objects`,
    params:{
      sort:'ALPHABETICAL',
      relationship:'TAG_OF',
      per_page:100
    },
    preventAuto:true
  })

  useEffect(()=>{
    if(!linkTabData){
      dispatch({type:'set_link_data',linkData:{}})
      dispatch({type:'set_link_tab_data',linkTabLoading,linkTabError});
      return;
    }
    dispatch({type:'set_link_data',linkData:{}})
    let filter = {}
    linkTabData.items.forEach((el,index)=>{
      filter[index] = 'ALPHABETICAL'
    })
    if(state.linkTabState>=linkTabData.items.length){
      dispatch({type:'set_link_tab_state',linkTabState:linkTabData.items.length-1})
    }
    dispatch({type:'set_link_filter',linkFilter:filter})
    dispatch({
      type:'set_link_tab_data',
      linkTabData:linkTabData.items.map(el=>el.name)
    })
  // eslint-disable-next-line
  },[linkTabData,linkTabLoading,linkTabError])

  useEffect(()=>{
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  useEffect(() => {
    switch (state.tabState) {
      case 0:
        if (!state.basicData) fetchList();
        break;
      case 1:
        if(!state.linkTabData)linkTabFetch()
        break;
      default:
        break;
    }
  // eslint-disable-next-line
  }, [state.tabState])

  useEffect(() => {
    if (!fullResponse || !fullResponse) return;
    addHistory({url:window.location.pathname, title: fullResponse.name, subTitle:'Tag profile',object:fullResponse,type:'profile'})
    dispatch({ type: 'set_basic_data', basicData: fullResponse })
  }, [fullResponse])

  useEffect(()=>{
    if(!state.basicData)return;
  },[state.basicData])


  if (loading ) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (error) {
    return (
      <DeadEnd
        pageHeader={"Oops! Looks like something went not quite right... "}
        pageMessage={`Unable to find tableau ${match.params.id.toLowerCase()}`}
      />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({type:'info',message:'Stopped following this tag'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({type:'error',message:'Error occurred unfollowing this tag'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({type:'info',message:'Following this tag'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({type:'error',message:'Error occurred following this tag'})
      })
    }
  }


  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_delete_modal_open',deleteModalOpen:true})}
      iconLabel='delete'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />  
  )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({
        type:'set_link_modal_open',
        linkModalOpen:{relations:['MEMBERS'], onLinkUpdated:linkTabFetch}
      })}
      iconLabel='link'
      text='LINK'
    />
  )


  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )


  const navigateLinks = (label,history) => {
    switch (label) {
      case 'host':
        history.push(`/ecosystem`);
        break;
      case 'tags':
          if(getSearchMode()==='basic_search'){
            history.push(`/basic_search?query=*&object=TAG`)
          }
          break;       
      default:
          history.push(`/ecosystem`);
        }
  }

  let title = state.basicData.name || state.basicData.id;
  const nkSplitObject = [{ name: 'TAGS', label: 'tags' } ];

  return (
    <div>
      {
        state.basicData && 
        <GenericLinkModal
          object={state.basicData}
          history={history}
          modalOpen={state.linkModalOpen}
          setModalOpen={value=>dispatch({type:'set_link_modal_open',linkModalOpen:value})}
          linkableObjects={state.linkModalOpen.linkableObjects}
          relations={state.linkModalOpen.relations}
          onLinkUpdated={state.linkModalOpen.onLinkUpdated}
        />
      }
      <DeletModal
        state={state}
        history={history}
        modalOpen={state.deleteModalOpen}
        setModalOpen={open=>dispatch({type:'set_delete_modal_open',deleteModalOpen:open})}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            type='tags'
            title={title}
            subtitle={nkSplitObject && nkSplitObject.map((el) =>
              <span data-testid={`breadcrumb-${el.label}`} className={classes.underlineOnHover} onClick={() => navigateLinks(el.label, history)} >{el.name}</span>
            ).reduce((prev, curr) => [prev, ' / ', curr])}
            nodeKey={match.params.id.toLowerCase()}
            label={label}
            buttons={buttons}
            data={state.basicData}
            creatorData={state.creatorData}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
            <Body
              history={history}
              state={state}
              dispatch={dispatch}
              tabState={state.tabState}
              fetchList={fetchList}
              label={label}
              nodeKey={match.params.id.toLowerCase()}
            />
        }  
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            editable={false}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            mapping={['lastUpdated', 'createdOn']}
            disableTag={true}
            data={state.basicData}
          />
        }
        state={state}
        dispatch={dispatch}
      />
    </div>
  )

}



export default withTheme()(withStyles(styles)(NewTagProfile));
