import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import KTooltip from '../../../KTooltip/KTooltip';
import { tileStyles } from '../utils/styles';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    fontSize: 12,
    color:theme.palette.primaryText.main,
    letterSpacing:1,
    flexGrow:1,
  },
  bottomMessage: {
    fontSize: 12,
    color:theme.palette.primaryText.light,
    maxWidth:240,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  middleMessage:{
    fontSize: 13.75,
    maxWidth:240,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color:theme.palette.primaryText.main
  },
  left:{
    color:theme.palette.primaryText.main,
    marginLeft:3
  },
  body:{
    marginLeft: 16,
    marginRight:20,
    overflow:'hidden'
  },
  content:{
    cursor: 'pointer',
  },
  tooltip:{
    whiteSpace:'pre-wrap',
    fontSize:13.75
  },
  actionText:{
    fontSize:10,
    marginRight:8,
    marginTop:4,
    color:theme.palette.hyperLink.main,
    cursor:'pointer',
    textDecoration:'underline',
    width:'max-content',
    maxWidth:'100%',
  },
  clickableText:{
    '&:hover svg':{
      filter: 'brightness(160%)'
    },
    '&:hover p':{
      textDecoration:"underline"
    }
  }
})

const IconDataTile = props => {

  const {
    classes,
    title,
    text,
    subtext,
    left,
    onClick,
    tooltip,
    action,
    wrapText
  } = props;

  return (
    <KTooltip classes={{tooltip:classes.tooltip}} title={tooltip} disableHoverListener={tooltip?false:true}  disableFocusListener={tooltip?false:true} disableTouchListener={tooltip?false:true}> 
      <div className={classes.root} style={{alignItems:'center'}}  data-test-classname="icon-data-tile">
        <div  style={{width:132,marginRight:12,flexShrink:0}}>
          <Typography className={classes.title} data-test-classname="icon-data-tile-title" >{title}</Typography>
          {
            action &&
            <div style={{display:'flex',flexWrap:'wrap'}}>
              {
                action.map(el=>(
                  <Typography key={el.text} className={classes.actionText} onClick={el.action} style={el.actionColour?{color:el.actionColour,textDecoration:'none'}:undefined}>{el.text}</Typography>
                ))
              }
            </div>
          }
        </div>
        <div className={onClick?classes.content + ' ' + classes.clickableText:null} style={{flexGrow:1,flexShrink:1,display:'flex',overflow:'hidden',alignItems:'center'}} onClick={onClick}>
          <div className={classes.left}><div style={{display: 'flex',justifyContent:'center',alignItems:'center',width:24,height:24}}>{left}</div></div>
          <div className={classes.body}>
            <KTooltip classes={{tooltip:classes.tooltip}} title={text} style={{whiteSpace:wrapText?'pre-wrap':undefined}}>
              <Typography className={classes.middleMessage } data-test-classname="icon-data-tile-main-text">{text}</Typography>
            </KTooltip>
            <KTooltip classes={{tooltip:classes.tooltip}} title={subtext||''}>
              <Typography className={classes.bottomMessage} data-test-classname="icon-data-tile-sub-text">{subtext}</Typography>
            </KTooltip>
          </div>
        </div>
      </div>
    </KTooltip>

  )
}

IconDataTile.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.string,
  left: PropTypes.any,
}

export default withStyles(styles)(IconDataTile);