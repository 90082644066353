import React, {useEffect,useState, useReducer, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/StorageProfile/Body/Body';
import { handleShareClick, setInitialState, getDispFields,getUserRoles } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';
import axiosCerebrum from '../../axios-cerebrum';
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import NoteAdder from '../../components/UI/NoteAdder/NoteAdder'
import AdditionalPropertyAdder from '../../components/UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import ListModalAdder from '../../components/UI/ListModalAdder/ListModalAdder'
import GenericLinkModal from '../../components/UI/GenericLinkModal/GenericLinkModal';
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkProfileEditable, checkTermLinkable } from '../../permissionChecker';
import useAlert from '../../hooks/useAlert';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText: {
    color: theme.palette.primaryText.main,
  }
});

const initialState = {
  tabState:0,
  searchFilter:'',
  sortBy:'ALPHABETICAL',
  usagePeriod:3,
  usageType:'direct',
  noteTabState:0,
  noteDetailMap:{},
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError: action.basicDataError,
        basicDataLoading: action.basicDataLoading
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData:action.insightsData
      }
    case 'set_files_data':
      return {
        ...state,
        filesData:action.filesData,
        filesLoading:action.filesLoading,
        filesError:action.filesError
      }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_search_filter':
      return {
        ...state,
        searchFilter:action.searchFilter
      }
    case 'set_sort_by':
      return {
        ...state,
        sortBy:action.sortBy
      }
    case 'set_link_collection_modal_open':
      return {
        ...state,
        linkCollectionModalOpen:action.linkCollectionModalOpen
      }
    case 'set_collection_instances':
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const StorageProfile = props => {
  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const roles = getUserRoles(sessionData.user_role);
  const tabOptions = ['FILES','DETAILS','KNOWLEDGE',roles.every(el=>el==='90')?undefined:'USAGE'].filter(el=>el!==undefined);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])


  const fetchList = () => {
    dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataLoading:true })
    axiosCerebrum
      .get(`/api/storages/${match.params.id.toLowerCase()}`)
      .then(async cerebrumResponse=>{
        if(!cerebrumResponse.data)return;
        let fullResponse = cerebrumResponse.data;
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${cerebrumResponse.data.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        dispatch({ type: 'set_basic_data', basicData: {...fullResponse,source_data:sourceData }})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:'Storage',object:fullResponse,type:'profile'})
      })
      .catch(error=>{
        console.log(error)
        dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataError:true })
      })
  }


  const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }

  useEffect(()=>{
    if(!state.basicData)fetchList();
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.scrollTo(0,0)
    // eslint-disable-next-line
  },[state.tabState])

  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const data = state.basicData;

  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({message:'Stopped following this storage',type:'info'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:'Error occurred unfollowing this storage',type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({message:'Following this storage',type:'info'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:'Error occurred following this storage',type:'error'})
      })
    }
  }


  let buttons = [];


  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )

  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        checkTermLinkable({sessionData})?{onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'}:undefined,
        checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner})?{onClick:()=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:true}),iconLabel:'info',text:'Add Property'}:undefined
      ]}
    />
  )

  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )

  let bannerdisplayText,bannerCause,bannerVisibility;
  
  if(data && data.active_flag === false ){
    bannerdisplayText = 'This storage has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this storage has been deactivated in K. Information on this storage is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  } 


  return (
    <div>
      {
        state.basicData && 
        <ListModalAdder
          object={state.basicData}
          modalOpen={state.listModalOpen}
          setModalOpen={open=>dispatch({type:'set_list_modal_open',listModalOpen:open})}
          dispatch={dispatch}
          state={state}
        />
      }
      {
        state.basicData && 
        <GenericLinkModal
          object={state.basicData}
          history={history}
          modalOpen={linkModalOpen}
          setModalOpen={setLinkModalOpen}
          linkableObjects={linkModalOpen.linkableObjects}
          relations={linkModalOpen.relations}
          onLinkUpdated={linkModalOpen.onLinkUpdated}
        />
      }
      {
        state.basicData && 
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
          modalOnly={true}
        />
      }
      {
        state.basicData && 
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
          modalOnly={true}
        />
      }
      <ProfileLayout
        header={
          <div>
            <ProfileHeader
              tabOptions={tabOptions}
              type='storage'
              title={state.basicData.name || state.basicData.signature}
              shouldLoadBreadCrumb
              label='storage'
              buttons={buttons}
              data={state.basicData}
              history={history}
              state={state}
              dispatch={dispatch}
              shouldLoadLinkedInstance
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              onClickAddTag={()=>{
                setLinkModalOpen({
                  linkableObjects:['COLLECTION_INSTANCE'],
                  relations:['MEMBER_OF'],
                  onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                })
              }}
            />
          </div>}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            nodeKey={state.basicData.nodeKey}
            label={data.labels}
            tabState={state.tabState}
            fetchList={fetchList}
            data={data}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'storages'}
            mapping={
              ['tags','topTeams','knowledge','lastUpdated','lastUpdatedBy','createdBy']
            }
            data={state.basicData}
          />
        }
        />
    </div>)
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(StorageProfile)));