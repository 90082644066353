import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, IconButton } from '@material-ui/core';
// import { getIconComponent } from '../../../utilities';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { getIconComponent } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    maxWidth:800
  },
  title:{
    fontSize:20,
    color:theme.palette.primaryText.main,
  },
  subtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:24
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    marginTop:36,
    marginBottom:16
  }
})

function AddProperties(props) {

  const {
    theme,
    classes,
    properties,
    setProperties,
    state,
    dispatch
  } = props;

  const [searchValue, setSearchValue] = React.useState('');

  const {
    sendAlert
  } = useAlert({})

  const isDQTest = state.selectedItemType === 'DATA_QUALITY_TEST';

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Add properties</Typography>
      <Typography className={classes.subtitle}>
        { 
          isDQTest?
          '1 property is included in the scope and cannot be removed.':
          '8 properties are included in the scope and cannot be removed. You can add up to 8 custom collections to update.'
        }
      </Typography>
      <SearchSelector 
        url='/solr/search/select'
        testID={'add-property-search'}
        params={{
          q: `${searchValue}*`,
          fq:`object_type_srt:COLLECTION AND collection_type_srt:(GLOSSARY OR DATA_MANAGEMENT OR DATA_GOVERNANCE OR COLLABORATION) ${properties.length>0?`AND -id:(${properties.map(p=>p.id).join(' ')})`:''}`,
          fl:'*',
          sort:'name_srt asc',
          rows:10
        }}
        disabled={isDQTest}
        autoSuggestion
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={isDQTest?'You cannot add custom property for DQ Test':'Search for a property'}
        onResultClick={el=>{
          if(properties.length>=15){
            sendAlert({message:"You can only select max 15 properties to edit",type:'error'})
            return;
          }
          setProperties([...properties,el])
          let field = {
            field:el.collection_type_srt==='GLOSSARY'?'RELATED':'MEMBER_OF',
            collectionId: el.id,
            values:[],
            operation:'ADD_TO_EXISTING'
          };
          dispatch({
            type:'set_changed_fields',
            changedFields: [
              ...state.changedFields,
              field
            ]
          })
          dispatch({
            type:'set_all_changed_fields',
            allChangedFields: [
              ...state.allChangedFields,
              field
            ]
          })
        }}
      />
      {
        properties.filter(p=>!p.id.includes('default_prop_')).length>0 && !isDQTest &&
        <>
          <Typography className={classes.sectionHeader}>CUSTOM COLLECTIONS ADDED</Typography>
          {
            properties.filter(p=>!p.id.includes('default_prop_')).map(p=>(
              <div style={{display:'flex',alignItems:'center',paddingLeft:16,height:48,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
                {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
                <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginLeft:16,flexGrow:1,marginRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{p.name_txt}</Typography>
                <IconButton 
                  onClick={()=>{
                    setProperties(properties.filter(d=>d.id!==p.id))
                    dispatch({
                      type:'set_changed_fields',
                      changedFields: state.changedFields.filter(f=>f.collectionId!==p.id)
                    })
                    dispatch({
                      type:'set_all_changed_fields',
                      allChangedFields: state.allChangedFields.filter(f=>f.collectionId!==p.id)
                    })
                  }}
                >
                  {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </div>
            ))
          }
        </>
      }

      <Typography className={classes.sectionHeader} >DEFAULT PROPERTIES INCLUDED</Typography>
      {
        properties.filter(p=>isDQTest || p.id.includes('default_prop_')).map(p=>(
          <div style={{display:'flex',alignItems:'center',paddingLeft:16,height:48,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
            {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginLeft:16,flexGrow:1,marginRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{p.name_txt}</Typography>
          </div>
        ))
      }
    </div>
  )
}

AddProperties.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddProperties));